import * as React from 'react'

import * as PermissionsSelectors from 'domain/Permission/selectors'

import { BoardActions } from 'domain/Board/actions'

import { Permission, StandardPrivacyActor, PermissionResourceType } from 'shared/models/Permission'
import { BoardSummary } from 'shared/models/BoardSummary'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import Icon from './Icon'
import PermissionsEditorModal from 'components/Permissions/PermissionsEditorModal'

type BoardInfoProps = {
  boardSummary: BoardSummary
}

type BoardInfoStateProps = {
  permissions: Permission<PermissionResourceType.boards>[]
  owners: StandardPrivacyActor[]
  currentUserIsOwner: boolean
}

const BoardInfoContext = React.createContext<BoardInfoStateProps & BoundActionGroups<typeof actionGroups>>(null)

const actionGroups = {
  BoardActions
}

const Provider = ComponentHelpers.createConnectedComponent<BoardInfoProps, BoardInfoStateProps, typeof actionGroups>({
  actionGroups,
  mapStateToProps: (state, outerProps): BoardInfoStateProps => {
    const { boardSummary } = outerProps
    return {
      ...outerProps,
      permissions: PermissionsSelectors.getPermissionsForBoard(state, boardSummary.clientId),
      owners: PermissionsSelectors.getOwnersForBoard(state, boardSummary.clientId),
      currentUserIsOwner: PermissionsSelectors.getCurrentUserIsBoardOwner(state, boardSummary.clientId),
    }
  },
  component: class Provider extends React.Component<React.ContextType<typeof BoardInfoContext>> {
    render() {
      const { children, ...data } = this.props
      return <BoardInfoContext.Provider value={data}>
        {children}
      </BoardInfoContext.Provider>
    }
  }
})

export default function BoardInfoWrapper(props: BoardInfoProps) {
  return <Provider {...props}>
    <BoardInfo {...props} />
  </Provider>
}

class BoardInfo extends React.Component<BoardInfoProps> {
  static contextType = BoardInfoContext
  context!: React.ContextType<typeof BoardInfoContext>

  private permissionModal = React.createRef<PermissionsEditorModal<PermissionResourceType.boards>>()

  render() {
    if (!this.context.owners || !this.context.permissions) return null
    return <>
      Slug: {this.props.boardSummary.slug}
      <br />
      {this.context.owners && this.context.permissions && <>
        { this.context.currentUserIsOwner
            ? <>Modify board permissions</>
            : <>View board permissions</>
        }
        <span style={{ float: "right" }}>
          <Icon
            onClick={() => this.permissionModal.current!.open()}
            fontAwesomeIcon="eye"
            tooltip={
              this.context.currentUserIsOwner ? "Update Permissions and Owners" : `View Permissions and Owners`
            }
          />
          <PermissionsEditorModal
            resourceType={PermissionResourceType.boards}
            entityReadableName={`"${this.props.boardSummary.slug}" board`}
            ref={this.permissionModal}
            canReplacePermissionsAndOwners={this.context.currentUserIsOwner}
            owners={this.context.owners}
            permissions={this.context.permissions}
            permissionsExplicitlySet={this.props.boardSummary.permissionsExplicitlySet}
            onSubmitChanges={this.handleSubmitChanges}
          />
        </span>
      </>}
    </>
  }

  private handleSubmitChanges = (permissions: Permission<PermissionResourceType.boards>[], owners: StandardPrivacyActor[]) => {
    this.context.BoardActions.replacePermissions(this.props.boardSummary.clientId, permissions)
    this.context.BoardActions.replaceOwners(this.props.boardSummary.clientId, owners)
  }
}
