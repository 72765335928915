import {
  REGISTER_AN_ERROR,
  SET_IS_ERROR_OVERLAY_VISIBLE,
} from './constants'

import { getMostRecentErrors } from './selectors'

const ErrorActions = {
  registerAnError: (error) => (dispatch, getState) => {
    const mostRecentErrors = getMostRecentErrors(getState())

    if (!mostRecentErrors.includes(error)) {
      console.error('An error in dispatched action', error, error.stack)
      dispatch({type: REGISTER_AN_ERROR, error})
    }
  },

  close: () => (dispatch) => {
    dispatch({type: SET_IS_ERROR_OVERLAY_VISIBLE, isErrorOverlayVisible: false})
  },

  showRecentErrorsOverlay: () => (dispatch) => {
    dispatch({type: SET_IS_ERROR_OVERLAY_VISIBLE, isErrorOverlayVisible: true})
  },
}

export {ErrorActions}
