import * as React from 'react'
import styles from './RelationEditor.styl'
import {Row, Col} from 'components/common/Layout'
import {ListGroup, ListGroupItem} from 'components/common/Lists'
import Checkbox from 'components/common/Checkbox'
import {Label} from 'components/common/Forms'
import HoverMenu from 'components/common/HoverMenu'

import {HoverMenuItem} from 'components/common/HoverMenu'

type ItemKey = React.Key
interface EntityGroup<Entity> {
  items: Entity[]
  caption: React.ReactChild
  renderItem: (entity: Entity) => React.ReactChild,
  getItemKey: (entity: Entity) => ItemKey
  getMenu?: (entity: Entity) => HoverMenuItem[]
}

type EntityGroupsKeys = 'first' | 'second'

interface EntityGroups<FirstEntity, SecondEntity> {
  first: EntityGroup<FirstEntity>
  second: EntityGroup<SecondEntity>
}

type AnyEntity<FirstEntity, SecondEntity> = FirstEntity | SecondEntity
type AnyEntityGroup<FirstEntity, SecondEntity> = EntityGroup<AnyEntity<FirstEntity, SecondEntity>>

interface SelectedItemInfo<FirstEntity, SecondEntity> {
  groupKey?: EntityGroupsKeys
  item?: AnyEntity<FirstEntity, SecondEntity>
  itemKey?: ItemKey
}

interface RelationEditorProps<FirstEntity, SecondEntity> {
  entityGroups: EntityGroups<FirstEntity, SecondEntity>
  relationExists: (first: FirstEntity, second: SecondEntity) => boolean
  toggleRelation: (first: FirstEntity, second: SecondEntity, newRelationValue: boolean) => void
  onItemSelected?: (selectedItemInfo: SelectedItemInfo<FirstEntity, SecondEntity>) => void
}

interface RelationEditorState<FirstEntity, SecondEntity> {
  selectedItemInfo: SelectedItemInfo<FirstEntity, SecondEntity>
}


class RelationEditor<FirstEntity, SecondEntity> extends React.Component<RelationEditorProps<FirstEntity, SecondEntity>, RelationEditorState<FirstEntity, SecondEntity>> {
  constructor(props) {
    super(props)

    this.state = {
      selectedItemInfo: {}
    }
  }

  render() {
    return <div
      className={styles.container}
    >
      <Row>
        {this._renderEntityGroup('first', this.props.entityGroups.first)}
        {this._renderEntityGroup('second', this.props.entityGroups.second)}
      </Row>
    </div>
  }

  componentWillReceiveProps(nextProps: RelationEditorProps<FirstEntity, SecondEntity>) {
    // selected item instance could change externally, so we need to update the one cached in our state
    if (this._isSomethingSelected()) {
      const selectedGroupKey = this._getSelectedGroupKey()
      const selectedItemKey = this._getSelectedItemKey()

      const updatedSelectedGroup = nextProps.entityGroups[selectedGroupKey] as AnyEntityGroup<FirstEntity, SecondEntity>
      const updatedSelectedItem = updatedSelectedGroup.items.find(item => updatedSelectedGroup.getItemKey(item) === selectedItemKey)

      if (updatedSelectedItem !== this._getSelectedItem()) {
        this._selectItem(updatedSelectedItem
          ? {groupKey: selectedGroupKey, item: updatedSelectedItem, itemKey: selectedItemKey}
          : {}
        )
      }
    }
  }

  _isSomethingSelected = () => !!this._getSelectedGroupKey()

  _isGroupSelected = (groupKey) => this._getSelectedGroupKey() === groupKey

  _getSelectedGroupKey = () => this.state.selectedItemInfo.groupKey

  _getSelectedItemKey = () => this.state.selectedItemInfo.itemKey

  _getSelectedItem = () => this.state.selectedItemInfo.item

  _isItemSelected = ({itemKey, groupKey}) =>
    this._getSelectedGroupKey() === groupKey &&
    this._getSelectedItemKey() === itemKey

  _renderEntityGroup(groupKey: EntityGroupsKeys, entityGroup: AnyEntityGroup<FirstEntity, SecondEntity>) {

    const itemInOtherGroupIsSelected = this._isSomethingSelected() && !this._isGroupSelected(groupKey)

    return <Col xs="6">
      <h5>{entityGroup.caption}</h5>
      <ListGroup>
        {
          entityGroup.items.map((item) => {
            const itemCaption = entityGroup.renderItem(item)
            const itemKey = entityGroup.getItemKey(item)

            return <HoverMenu
              key={itemKey}
              placement="inline"
              menuItems={entityGroup.getMenu && entityGroup.getMenu(item)}
            >
              <ListGroupItem
                active={this._isItemSelected({itemKey, groupKey})}
                tag="button"
                action

                onClick={
                  (event) => {
                    //prevent possible parent form submitting
                    event.preventDefault()
                    //isolate event in general
                    event.stopPropagation()
                    this._selectItem({groupKey, item, itemKey})
                  }
                }
              >
                <Label
                  className={styles.relationCheckBoxLabel}
                >
                  {/* as we always render checkboxes and thus always calculate 'checked' prop,
                    we need to check whether this calculation can be done - that's possbile only when a pair with selected item can be constructed,
                    and that's possible only when the item from other group is selected
                  */}
                  <Checkbox
                    className={!itemInOtherGroupIsSelected ? styles.hiddenRelationCheckbox : null}
                    checked={itemInOtherGroupIsSelected && this._relationExists(groupKey, item)}
                    onChange={() => this._toggleRelation(groupKey, item)}
                  />
                  &nbsp;
                </Label>
                {itemCaption}
              </ListGroupItem>

            </HoverMenu>
          })
        }
      </ListGroup>
    </Col>
  }

  _relationExists(groupKey, item) {
    const {first, second} = this._createPairWithSelectedItem(groupKey, item)

    return this.props.relationExists(first, second)
  }

  _toggleRelation(groupKey, item) {
    const fullRelation = this._createPairWithSelectedItem(groupKey, item)

    const newRelationValue = !this._relationExists(groupKey, item)
    return this.props.toggleRelation(fullRelation.first, fullRelation.second, newRelationValue)
  }

  _createPairWithSelectedItem (groupKey, item) {
    const selectedItemInfo = this.state.selectedItemInfo

    // create {first, second} pair by combining selected item and current item
    return {
      [groupKey]: item,
      [selectedItemInfo.groupKey]: selectedItemInfo.item
    }
  }

  _selectItem(selectedItemInfo: SelectedItemInfo<FirstEntity, SecondEntity>) {
    this.setState({
      selectedItemInfo
    })

    this.props.onItemSelected && this.props.onItemSelected(selectedItemInfo)
  }
}

// workaround for generics to work in JSX without creation of intermediate types or using React.createElement externally
function renderRelationEditor<FirstEntity, SecondEntity> (props: RelationEditorProps<FirstEntity, SecondEntity>) {
  return React.createElement<RelationEditorProps<FirstEntity, SecondEntity>>(RelationEditor, props)
}

export {renderRelationEditor}
