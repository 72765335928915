export function wrapCancellable<T>(promise: Promise<T>): CancellablePromise<T> {
  let cancel: () => void
  let isCancelled = false
  const cancelPromise = new Promise<T>((_, rej) => cancel = () => {
    isCancelled = true
    rej("Promise was cancelled before it resolved")
  })

  async function createWrappedPromise() {
    const returnValue = await Promise.race([cancelPromise, promise])
    if (isCancelled) {
      return Promise.reject("Promise was cancelled after it resolved but before callbacks")
    }
    return returnValue
  }

  return {
    promise: createWrappedPromise(),
    originalPromise: promise,
    cancel
  }
}

export type CancellablePromise<T> = {
  promise: Promise<T>
  originalPromise: Promise<T>
  cancel: () => void
}
