import {
  getActiveGraphLayout,
} from './GraphViewSelector'

import { Set } from 'immutable'

import { types } from './GraphViewConstants'

import * as FixedPointsPanZoom from './FixedPointsPanZoom'

import {
  AppStateRecord
} from 'appRoot/state'

import {
  Reducer
} from 'appRoot/rootReducer'

export default (reducer: Reducer) => {
  reducer.handle(types.ACTIVATE_DOCUMENT_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    const document = action.document ||
      action.documentClientId && state.getIn(['documents', action.documentClientId])

    if (!document) return state

    return state.update(
      'graphView',
      graphView => graphView.set('activeDocument', document)
    )
  })

  reducer.handle(types.REPLACE_COLOR_RULES_RESULTS, (state: AppStateRecord, action: any) => {
    return state
      .setIn(['graphView', 'colorRulesResults'], action.colorRulesResults)
  })

  const undefinedIfEqualTo = (match) => (value) => (value == match ? undefined : value)
  reducer.handle(types.SET_PATH_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(['graphView', 'pathIdeasClientIds'], action.pathIdeasClientIds)
      .setIn(['graphView', 'pathConnectionsClientIds'], action.pathConnectionsClientIds)
  })

  reducer.handle(types.HOVER_IDEA_INSTANCE_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(['graphView', 'hoveredIdeaInstanceId'], action.ideaInstanceId)
  })

  reducer.handle(types.UNHOVER_IDEA_INSTANCE_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .updateIn(
        ['graphView', 'hoveredIdeaInstanceId'],
        undefinedIfEqualTo(action.ideaInstanceId)
      )
  })

  reducer.handle(types.SELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(['graphView', 'selectedConnectionInstanceId'], action.connectionInstanceId)
      .setIn(['graphView', 'selectedConnectionClientId'], action.connectionClientId)
  })

  reducer.handle(types.UNSELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['graphView', 'selectedConnectionInstanceId'],
        undefinedIfEqualTo(action.connectionInstanceId)
      )
      .setIn(
        ['graphView', 'selectedConnectionClientId'],
        undefinedIfEqualTo(action.connectionClientId)
      )
  })

  reducer.handle(types.UNSELECT_ALL_CONNECTION_INSTANCES_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(['graphView', 'selectedConnectionInstanceId'], undefined)
      .setIn(['graphView', 'selectedConnectionClientId'], undefined)
  })

  reducer.handle(types.HOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(['graphView', 'hoveredConnectionInstanceId'], action.connectionInstanceId)
      .setIn(['graphView', 'hoveredConnectionClientId'], action.connectionClientId)
  })

  reducer.handle(types.HOVER_SIDEBAR_IDEA, (state: AppStateRecord, action: any) => {
    return state
      .setIn(['graphView', 'hoveredSidebarIdeaClientId'], action.ideaClientId)
  })

  reducer.handle(types.UNHOVER_SIDEBAR_IDEA, (state: AppStateRecord, action: any) => {
    return state
      .updateIn(['graphView', 'hoveredSidebarIdeaClientId'], undefinedIfEqualTo(action.ideaClientId))
  })

  reducer.handle(types.UNHOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .updateIn(
        ['graphView', 'hoveredConnectionInstanceId'],
        undefinedIfEqualTo(action.connectionInstanceId)
      )
      .updateIn(
        ['graphView', 'hoveredConnectionClientId'],
        undefinedIfEqualTo(action.connectionClientId)
      )
  })

  reducer.handle(types.SET_GRAPH_MODAL, (state: AppStateRecord, action: any) => {
    const {text} = action
    return state
      .setIn(
        ['graphView', 'modalText'],
        text
      )
  })

  reducer.handle(types.SET_SHORTEST_PATH_DAG, (state: AppStateRecord, action: any) => {
    const {source, target, dag} = action
    return state
      .setIn(
        ['graphView', 'shortestDag', `${source}->${target}`],
        dag
      )
  })

  reducer.handle(types.REPORT_CANVAS_CLIENT_RECTANGLES, (state: AppStateRecord, action: any) => {
    return state
    // TODO: store these values in a single field
      .setIn(['graphView', 'canvasClientRect'], action.rectangleValues.wholeCanvas)
      .setIn(['graphView', 'centralCanvasPartClientRectangle'], action.rectangleValues.centralCanvasPart)
  })

  reducer.handle(types.START_DRAGGING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['graphView', 'isDragging'],
        true
      )
      .setIn(
        ['graphView', 'draggedIdeaInstanceId'],
        action.ideaInstanceId
      )
      .setIn(
        ['graphView', 'draggedIdeaInstanceInitialPosition'],
        action.ideaInstancePosition
      )
      .setIn(
        ['graphView', 'draggingStartPosition'],
        action.position
      )
  })

  reducer.handle(types.END_DRAGGING_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(
        ['graphView', 'isDragging'],
        false
      )
      .deleteIn(
        ['graphView', 'draggedIdeaInstanceId']
      )
      .deleteIn(
        ['graphView', 'draggedIdeaInstanceInitialPosition']
      )
      .deleteIn(
        ['graphView', 'draggingStartPosition']
      )
  })

  reducer.handle(types.START_CONNECTING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['graphView', 'isConnecting'],
        true
      )
      .setIn(
        ['graphView', 'firstIdeaClientId'],
        action.ideaClientId
      )
      .setIn(
        ['graphView', 'firstIdeaInstanceId'],
        action.ideaInstanceId
      )
  })

  reducer.handle(types.CONTINUE_CONNECTING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['renderState', 'cursorPositionInGraphView'],
        action.position
      )
  })

  reducer.handle(types.CANCEL_CONNECTING_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(
        ['graphView', 'isConnecting'],
        false
      )
      .deleteIn(
        ['graphView', 'firstIdeaClientId']
      )
      .deleteIn(
        ['renderState', 'cursorPositionInGraphView']
      )
  })

  reducer.handle(types.START_MERGING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['graphView', 'isMerging'],
        true
      )
      .setIn(
        ['graphView', 'firstIdeaClientId'],
        action.ideaClientId
      )
      .setIn(
        ['graphView', 'firstIdeaInstanceId'],
        action.ideaInstanceId
      )
  })

  reducer.handle(types.CONTINUE_MERGING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['renderState', 'cursorPositionInGraphView'],
        action.position
      )
  })

  reducer.handle(types.CANCEL_MERGING_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(
        ['graphView', 'isMerging'],
        false
      )
      .deleteIn(
        ['graphView', 'firstIdeaClientId']
      )
      .deleteIn(
        ['renderState', 'cursorPositionInGraphView']
      )
  })

  reducer.handle(types.START_PANNING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    const activeGraphLayout = getActiveGraphLayout(state).toJS()

    return state
      .setIn(
        ['graphView', 'isPanning'],
        true
      )
      .setIn(
        ['graphView', 'panStartPoint'],
        action.position
      )
      .setIn(
        ['graphView', 'panStartImageTransform'],
        activeGraphLayout.imageTransform
      )
  })

  reducer.handle(types.END_PANNING_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(
        ['graphView', 'isPanning'],
        false
      )
      .deleteIn(
        ['graphView', 'panStartPoint']
      )
  })

  reducer.handle(types.START_RECT_SELECTING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['graphView', 'isRectSelecting'],
        true
      )
      .setIn(
        ['graphView', 'rectSelectionStartPosition'],
        action.position
      )
  })

  reducer.handle(types.CONTINUE_RECT_SELECTING_IN_GRAPH_VIEW, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['graphView', 'rectSelectionCurrentPosition'],
        action.position
      )
  })

  reducer.handle(types.END_RECT_SELECTING_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(
        ['graphView', 'isRectSelecting'],
        false
      )
      .deleteIn(
        ['graphView', 'rectSelectionStartPosition']
      )
      .deleteIn(
        ['graphView', 'rectSelectionCurrentPosition']
      )
  })

  reducer.handle(types.CANCEL_RECT_SELECTING_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(
        ['graphView', 'isRectSelecting'],
        false
      )
      .deleteIn(
        ['graphView', 'rectSelectionStartPosition']
      )
      .deleteIn(
        ['graphView', 'rectSelectionCurrentPosition']
      )
  })

  reducer.handle(types.PUSH_FIXED_POINTS_PAN_ZOOM_STATE, (state: AppStateRecord, action: any) => {
    return state
      .setIn(
        ['graphView', 'fixedPointsPanZoomState'],
        action.fixedPointsPanZoomState,
      )
      .setIn(
        ['graphView', 'isPanning'],
        !FixedPointsPanZoom.isEmpty(action.fixedPointsPanZoomState)
      )
  })

  reducer.handle(types.SET_SHOW_EDGE_LABELS, (state: AppStateRecord, action: any) => {
    return state.update(
      'graphView',
      graphView => graphView.set('showEdgeLabels', action.showEdgeLabels)
    )
  })

  reducer.handle(types.DESELECT_ALL_IN_GRAPH_VIEW, (state: AppStateRecord) => {
    return state
      .setIn(['graphView', 'pathIdeasClientIds'], Set())
  })

  reducer.handle(types.SET_DYNAMIC_IMAGE_TRANSFORM, (state: AppStateRecord, action: any) => {
    return state.update(
      'graphView',
      graphView => graphView.set('dynamicImageTransform', action.dynamicImageTransform)
    )
  })

  reducer.handle(types.REMOVE_DYNAMIC_IMAGE_TRANSFORM, (state: AppStateRecord) => {
    return state.deleteIn(['graphView', 'dynamicImageTransform'])
  })

  reducer.handle(types.SET_IS_EXPANDING_THE_ACTIVE_GRAPH_LAYOUT, (state: AppStateRecord, action: any) => {
    return state.update(
      'graphView',
      graphView => graphView.set('isExpanding', action.isExpanding)
    )
  })

  reducer.handle(types.SET_USING_TRANSIENT_DOCUMENT, (state: AppStateRecord, action: any) => {
    return state.update(
      'graphView',
      graphView => graphView.set('usingTransientDocument', action.usingTransientDocument)
    )
  })

  reducer.handle(types.MOUSE_ENTER, (state: AppStateRecord) => {
    return state.setIn(
      ['graphView', 'mouseInGraphView'],
      true
    )
  })

  reducer.handle(types.MOUSE_LEAVE, (state: AppStateRecord) => {
    return state.setIn(
      ['graphView', 'mouseInGraphView'],
      false
    )
  })
}
