import * as Immutable from 'immutable'
import * as EvImm from 'evolving-immutable'

import * as memoize from 'shared/helpers/memoize'

import { UserProfileSelectors } from 'domain/UserProfile/selectors'
import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'

import { AppStateRecord } from 'appRoot/state'

import { CommentRecord } from 'shared/models/Comment'
import { UserProfileRecord } from 'shared/models/UserProfile'

export type CommentWithAuthorAndEditCapability = {
  comment: CommentRecord, authorUserProfile: UserProfileRecord, editCapability
}

const getAllComments = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({comments: state.get('comments')}),
  calculateResult: ({comments}) => comments.filter((comment) => !comment.isDeleted)
})

const getCommentsByIdeaClientId: (state: AppStateRecord) => Immutable.Map<string, Immutable.Set<CommentWithAuthorAndEditCapability>> =
  EvImm.startChain()
    .memoizeForValue()
    .mapOneToMany({
      allComments: (state: AppStateRecord) => CommentSelectors.getAllComments(state),
      editCapability: (state: AppStateRecord) => CurrentUserSelectors.getEditCapabilityByCommentClientId(state),
      userProfilesByUserId: (state: AppStateRecord) => UserProfileSelectors.getAll(state),
    })
    .memoizeForObject()
    .mapOneToMany({
      commentAuthors: EvImm.startChain()
        .addLeftJoinStep({
          mapLeftToSetOfRightKeys: (comment: CommentRecord) => [comment.userId],
          attachLeftWithMapOfRight: (comment, userProfiles) => ({ comment, authorUserProfile: userProfiles.get(comment.userId) }),
          extractLeftMap: ({ allComments }) => allComments,
          extractRightMap: ({ userProfilesByUserId }) => userProfilesByUserId,
        })
        .endChain(),
      editCapability: ({ editCapability }) => editCapability,
    })
    .addZipStep({
      extractLeftMap: ({ commentAuthors }) => commentAuthors,
      extractRightMap: ({ editCapability }) => editCapability,
      attach: (commentAuthors, editCapability) => ({ ...commentAuthors, editCapability })
    })
    .addGroupStep(({ comment }) => comment.ideaClientId)
    .addMapStep(EvImm.toSet())
    .endChain()

const CommentSelectors = {
  getAllComments,
  getCommentsByIdeaClientId
}

export {CommentSelectors}