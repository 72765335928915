import * as React from 'react'
import * as Immutable from 'immutable'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import { IdeaRecord } from 'shared/models/Idea'
import { AttributeValues } from 'shared/models/AttributeValue'
import { Attribute } from 'shared/models/Attribute'

import { AttributeValueActions } from 'domain/AttributeValue/actions'

import { AttributeValueSelectors } from 'domain/AttributeValue/selectors'
import { AttributeSelectors } from 'domain/Attribute/selectors'
import * as IdeaSelectors from 'domain/Idea/selectors'

interface OuterProps {
  ideaClientId: string
}

interface StateProps extends OuterProps {
  idea: IdeaRecord
  attributes: Immutable.Map<string, Attribute>
  attributeValues: AttributeValues
}

const actionGroups = {
  AttributeValueActions,
}

class IdeaSummary extends React.PureComponent<StateProps & BoundActionGroups<typeof actionGroups>> {
  componentDidMount() {
    this.props.AttributeValueActions.fetchAttributeValuesForIdeaIfMissing(this.props.ideaClientId)
  }

  componentWillReceiveProps(nextProps: StateProps & BoundActionGroups<typeof actionGroups>) {
    if (nextProps.ideaClientId !== this.props.ideaClientId) {
      this.props.AttributeValueActions.fetchAttributeValuesForIdeaIfMissing(this.props.ideaClientId)      
    }
  }

  render() {
    const { idea, attributeValues } = this.props

    if (!idea) {
      return null
    }

    return <>
      {idea.title}
      {attributeValues && <>
        {Object.entries(attributeValues).map(([attributeClientId, attributeValue]) => {
          const attribute = this.props.attributes.get(attributeClientId)
          return <div key={attributeClientId}>
            {attribute ? attribute.name : "unknown attribute"}: {attributeValue}
          </div>
        })}
      </>}
    </>
  }
}

export default ComponentHelpers.createConnectedComponent<OuterProps, StateProps, typeof actionGroups>({
  actionGroups,
  mapStateToProps: (state, outerProps: OuterProps): StateProps => {
    return {
      ...outerProps,
      idea: IdeaSelectors.getByClientId(state, outerProps.ideaClientId),
      attributes: AttributeSelectors.getAll(state),
      attributeValues: AttributeValueSelectors.getAttributeValuesOfIdea(state, outerProps.ideaClientId),
    }
  },
  component: IdeaSummary
})