import {Map} from 'immutable'

import * as DomainConstants from './DomainConstants'
import {ConnectionStateFactory} from './Connection/state'

import Idea from 'shared/models/Idea'
import Comment from 'shared/models/Comment'
import IdeaLike from 'shared/models/IdeaLike'
import CommentLike from 'shared/models/CommentLike'
import GraphLayout from 'shared/models/GraphLayout'
import Document from 'shared/models/Document'
import UserProfile from 'shared/models/UserProfile'

import { AppStateRecord, AppStateSourceObjectType } from 'appRoot/state'
import { initialGraphViewState } from 'components/graph-view/GraphViewState'

interface DomainDataEntityConfig {
  statePartName: keyof AppStateSourceObjectType
  entityFactory: ((entityData: any) => any) | null
  keyExtractor?: (entityData: any) => any
}

// right now only some domain entities are set through this reducer
const domainDataEntitiesConfig: DomainDataEntityConfig[] = [
  { statePartName: 'ideasPermissions', entityFactory: null },
  { statePartName: 'boardsPermissions', entityFactory: null },
  { statePartName: 'ideasOwners', entityFactory: null },
  { statePartName: 'boardsOwners', entityFactory: null },
  { statePartName: 'boardSummaries', entityFactory: null },
  { statePartName: 'ideasCache', entityFactory: Idea },
  { statePartName: 'attributes', entityFactory: null },
  { statePartName: 'comments', entityFactory: Comment },
  { statePartName: 'ideaLikes', entityFactory: IdeaLike },
  { statePartName: 'commentLikes', entityFactory: CommentLike },
  { statePartName: 'graphLayouts', entityFactory: GraphLayout },
  { statePartName: 'documents', entityFactory: Document },
  { statePartName: 'peopleInCurrentBoard', entityFactory: null },

  // TODO: convert userId and other server-based ids to strings for reliability - it's better to have the same type for all ids
  { statePartName: 'userProfiles', entityFactory: UserProfile, keyExtractor: (userProfile) => userProfile.userId },
]

export default (reducer) => {
  reducer.handle(DomainConstants.RESET_CURRENT_BOARD_DATA, (state: AppStateRecord) => {
    return state.merge(EMPTY_BOARD_DATA)
  })

  reducer.handle(DomainConstants.SET_CURRENT_LIST_VIEW_LAYOUT, (state: AppStateRecord, action) => {
    return state.set("currentListViewLayout", action.listViewLayout)
  })

  reducer.handle(DomainConstants.SET_ATTRIBUTES_STATS_FOR_CURRENT_BOARD, (state: AppStateRecord, action) => {
    const newState = state.set("attributesStatsForCurrentBoard", action.attributesStatsForCurrentBoard)
    console.log({ newState })
    return newState;
  })

  reducer.handle(DomainConstants.PUSH_DOMAIN_DATA, (state: AppStateRecord, action) => {
    const newDomainData = action.data
    return state.withMutations((mutableState) => {
      domainDataEntitiesConfig.forEach(({ statePartName, entityFactory, keyExtractor }) => {
        const newDomainDataPart = newDomainData[statePartName]

        if (!newDomainDataPart || newDomainDataPart.length === 0) {
          return
        }

        mutableState.update(statePartName, (statePart) => statePart.withMutations(mutableStatePart => {
          for (const entity of newDomainDataPart) {
            mutableStatePart.set(
              keyExtractor ? keyExtractor(entity) : entity.clientId,
              entityFactory ? entityFactory(entity) : entity
            )
          }
        }))
      })
    })
  })
}

const EMPTY_BOARD_DATA: AppStateSourceObjectType = {
  ideasCache: Map(),
  connectionsCache: ConnectionStateFactory.createInitialConnectionState(),
  colors: Map(),
  attributes: Map(),
  peopleInCurrentBoard: Map(),
  graphLayouts: Map(),
  documents: Map(),
  graphView: initialGraphViewState,
  currentListViewLayout: [],
}
