import { Record } from 'immutable'

import { IdeaRecord } from 'shared/models/Idea'
import { RawPathData } from 'shared/models/Document'

export type RawPathData = RawPathData

export type PathData = {
  sourceIdea: IdeaRecord
  targetIdea: IdeaRecord
  labelText: string
}[]

import { PATH_EQUIVALENCES_STATE_GLOBAL_STATE_KEY } from './constants'

const initialPathEquivalencesState = Record({
  isSelectingPath: false,
  completedPaths: [] as RawPathData[],
  partialPath: [] as RawPathData,
})({})

export type PathEquivalencesState = typeof initialPathEquivalencesState

const initialState = {
  [PATH_EQUIVALENCES_STATE_GLOBAL_STATE_KEY]: initialPathEquivalencesState,
}

export default initialState