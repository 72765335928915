import { Map, Set } from 'immutable'

import Point from 'shared/helpers/Point'
import Polygon from 'shared/helpers/Polygon'
import AffineTransform from 'shared/helpers/AffineTransform'

export const defaultFixedPointsPanZoomState = Map({
  panStartFixedPointsById: Map({}),
  panStartImageTransform: AffineTransform.identityTransform,
  lastKnownImageTransform: AffineTransform.identityTransform,
})

const computeAverageDeltaAndOrigCenter = (pairedUpFixedPointsById) => {
  let totalDelta = Point(0,0)
  let totalOrigCenter = Point(0,0)
  let activePairsCount = 0
  pairedUpFixedPointsById.forEach(({ panStartFixedPoint, currentFixedPoint}) => {
    if(!panStartFixedPoint || !currentFixedPoint) return
    const delta = currentFixedPoint.position.sub(panStartFixedPoint.position)
    totalDelta = totalDelta.add(delta)
    totalOrigCenter = totalOrigCenter.add(panStartFixedPoint.position)
    activePairsCount += 1
  })

  if(activePairsCount > 0) {
    return {
      averageDelta: totalDelta.mult(1/activePairsCount),
      origCenter: totalOrigCenter.mult(1/activePairsCount)
    }
  } else {
    return {
      averageDelta: totalDelta,
      origCenter: totalOrigCenter,
    }
  }
}

const boundedNumber = (min,max) => (num) => Math.min(Math.max(num, min), max)

const computeScalesBetweenTwoPairs = (fixedPointsPair1, fixedPointsPair2) => {
  const orig1 = fixedPointsPair1.panStartFixedPoint.position
  const orig2 = fixedPointsPair2.panStartFixedPoint.position
  const next1 = fixedPointsPair1.currentFixedPoint.position
  const next2 = fixedPointsPair2.currentFixedPoint.position

  const scaleX = Math.abs((orig2.x - orig1.x)) > 10
    ? boundedNumber(0.1,10)(Math.abs((next2.x - next1.x) / (orig2.x - orig1.x)))
    : undefined
  const scaleY = Math.abs((orig2.y - orig1.y)) > 10
    ? boundedNumber(0.1,10)(Math.abs((next2.y - next1.y) / (orig2.y - orig1.y)))
    : undefined
  return [scaleX, scaleY].filter(scale => scale !== undefined)
}

const computePairwiseAverageScale = (pairedUpFixedPointsById) => {
  let totalScale = 1.0
  let scalesCount = 0
  let activePairsCount = 0

  const ids = pairedUpFixedPointsById.keySeq().toList()

  for(let i=0; i<ids.size; ++i) {
    for(let j=0; j<i; ++j) {
      const id1 = ids.get(i)
      const id2 = ids.get(j)

      const pair1 = pairedUpFixedPointsById.get(id1)
      const pair2 = pairedUpFixedPointsById.get(id2)

      const scales = computeScalesBetweenTwoPairs(pair1, pair2)
      scales.forEach(scale => {
        totalScale *= scale
        scalesCount += 1
      })
    }
  }

  if(scalesCount > 0) {
    return Math.pow(totalScale,1/scalesCount)
  } else {
    return totalScale
  }
}

const computeAverageScale = (pairedUpFixedPointsById) => {
  const pairedUpFixedPoints = pairedUpFixedPointsById.valueSeq().toArray()
  if(pairedUpFixedPoints.length < 2) {
    return 1.0
  } else if (pairedUpFixedPoints.length == 2) {
    // Compute the ratio of distances between panStartFixedPoint's and currentFixedPoint's
    const dist1 = pairedUpFixedPoints[0].panStartFixedPoint.position
      .dist(pairedUpFixedPoints[1].panStartFixedPoint.position)
    const dist2 = pairedUpFixedPoints[0].currentFixedPoint.position
      .dist(pairedUpFixedPoints[1].currentFixedPoint.position)

    return boundedNumber(0.1,10)(dist1 !== 0) ? dist2/dist1 : 1.0
  } else {
    // Compute the ratio of areas
    // TODO: ensure the points are in the correct order
    const poly1 = new Polygon(pairedUpFixedPoints
      .map(({ panStartFixedPoint }) => panStartFixedPoint.position)
    )
    const poly2 = new Polygon(pairedUpFixedPoints
      .map(({ currentFixedPoint }) => currentFixedPoint.position)
    )
    const area1 = poly1.GetArea()
    const area2 = poly2.GetArea()
    return boundedNumber(0.1,10)(area1 !== 0) ? area2/area1 : 1.0
  }
}

const fillInMissingCurrentFixedPoints = (pairedUpFixedPointsById) => {
  const constantVector = []
  const matrix = []
  const idsVector = []
  const fixedPointsCount = pairedUpFixedPointsById.size
  let knowPositionsSum = Point(0,0)

  pairedUpFixedPointsById
    .forEach(({ panStartFixedPoint, currentFixedPoint}) => {
      if(!!currentFixedPoint) {
        knowPositionsSum = knowPositionsSum.add(currentFixedPoint.position)
      }
    })

  pairedUpFixedPointsById
    .forEach(({ panStartFixedPoint, currentFixedPoint }, id) => {
      if(!currentFixedPoint) {
        idsVector.push(id)
        const constant = Point(0,0)
          .add(knowPositionsSum)
          .sub(panStartFixedPoint.lastKnownOtherSum)
          .add(panStartFixedPoint.lastKnownPosition.mult(fixedPointsCount-1))
        constantVector.push(constant)
      }
    })

  const dimension = constantVector.length
  if(dimension === fixedPointsCount) {
    throw new Error('Not enough known positions to extrapolate!')
  }

  constantVector.forEach((value, i) => {
    const row = new Array(dimension)
    matrix.push(row)
    for(let j=0; j<dimension; ++j) {
      if(i !== j) {
        row[j] = Point(-1,-1)
      } else {
        row[j] = Point(fixedPointsCount - 1,fixedPointsCount - 1)
      }
    }
  })

  if(dimension > 0) {
    console.log('Computing missing positions')
  }
  const filledInPositions = gaussSeidel2D(dimension, matrix, constantVector)
  if(dimension > 0) {
    console.log('filledInPositions', filledInPositions)
  }

  const filledInPositionByFixedPointId =
    Map(idsVector.map((id, i) => ([id, filledInPositions[i]])))

  return pairedUpFixedPointsById
    .map(({ panStartFixedPoint, currentFixedPoint }, id) => {
      if(!currentFixedPoint) {
        return {
          panStartFixedPoint,
          currentFixedPoint: {
            ...panStartFixedPoint,
            position: filledInPositionByFixedPointId.get(id),
          }
        }
      } else {
        return { panStartFixedPoint, currentFixedPoint }
      }
    })
}

const attachLastKnownPositionsAndOtherSums = (filledInPairedUpFixedPointsById) => {
  let positionSum = Point(0,0)
  filledInPairedUpFixedPointsById.map(({ panStartFixedPoint, currentFixedPoint }) => {
    positionSum = positionSum.add(currentFixedPoint.position)
  })
  return filledInPairedUpFixedPointsById.map(({ panStartFixedPoint, currentFixedPoint }) => {
    return {
      panStartFixedPoint: {
        ...panStartFixedPoint,
        lastKnownPosition: currentFixedPoint.position,
        lastKnownOtherSum: positionSum.sub(currentFixedPoint.position),
      },
      currentFixedPoint,
    }
  })
}

// TODO: deduplicate this from graphViewReducer
const groupFixedPointsById = (fixedPoints) => {
  const toEntry = fixedPoint => ([fixedPoint.id, fixedPoint])
  return Map(fixedPoints.map(toEntry))
}

export const registerFixedPoints = (fixedPointsPanZoomState, fixedPoints, imageTransform) => {
  const currentImageTransform = imageTransform
  const panStartImageTransform = isEmpty(fixedPointsPanZoomState)
    ? currentImageTransform
    : fixedPointsPanZoomState.get('panStartImageTransform')

  const mapToPanStartPosition = (position) => {
    return panStartImageTransform.Transform(currentImageTransform.Reverse().Transform(position))
  }

  const fixedPointsById = groupFixedPointsById(fixedPoints)
  const newPanStartFixedPointsById = fixedPointsById
    .map(fixedPoint => {
      return {
        ...fixedPoint,
        active: true,
        position: mapToPanStartPosition(fixedPoint.position)
      }
    })

  return fixedPointsPanZoomState
    .mergeIn(
      ['panStartFixedPointsById'],
      newPanStartFixedPointsById,
    )
    .set(
      'panStartImageTransform',
      panStartImageTransform,
    )
}

export const unregisterFixedPoints = (fixedPointsPanZoomState, removedFixedPoints) => {
  const removedFixedPointsIds =
    Set(removedFixedPoints.map(fixedPoint => fixedPoint.id))

  let activeFixedPointsCount = 0
  const newFixedPointsById = fixedPointsPanZoomState
    .get('panStartFixedPointsById')
    .map(fixedPoint => {
      if(removedFixedPointsIds.has(fixedPoint.id)) {
        return { ...fixedPoint, active: false }
      } else if (fixedPoint.active) {
        activeFixedPointsCount += 1
        return fixedPoint
      } else {
        return fixedPoint
      }
    })

  if(activeFixedPointsCount == 0) {
    // console.log('Die and be reborn...')
    return defaultFixedPointsPanZoomState
  } else {
    // console.log(
    //   'Still going...',
    //   newFixedPointsById.toJS(),
    // )
    return fixedPointsPanZoomState
      .set(
        'panStartFixedPointsById',
        newFixedPointsById,
      )
  }
}

export const moveFixedPoints = (fixedPointsPanZoomState, currentFixedPoints) => {
  const currentFixedPointsById =
    groupFixedPointsById(currentFixedPoints)
  const panStartFixedPointsById =
    fixedPointsPanZoomState.get('panStartFixedPointsById')

  const pairedUpFixedPointsById = panStartFixedPointsById
    .map((panStartFixedPoint, id) => {
      const currentFixedPoint = currentFixedPointsById.get(id)
      return { panStartFixedPoint, currentFixedPoint }
    })

  const filledInPairedUpFixedPointsById =
    fillInMissingCurrentFixedPoints(pairedUpFixedPointsById)

  const { averageDelta, origCenter } =
    computeAverageDeltaAndOrigCenter(filledInPairedUpFixedPointsById)
  const averageScale =
    computeAverageScale(filledInPairedUpFixedPointsById)
  console.log({ averageDelta, averageScale, origCenter })

  const attachedFilledInPairedUpFixedPointsById =
    attachLastKnownPositionsAndOtherSums(filledInPairedUpFixedPointsById)

  const newLastKnownImageTransform = fixedPointsPanZoomState.get('panStartImageTransform')
    .Scale(origCenter, Point(1,1).mult(averageScale))
    .Translate(averageDelta)

  return fixedPointsPanZoomState
    .set('lastKnownImageTransform', newLastKnownImageTransform)
    .set('panStartFixedPointsById', attachedFilledInPairedUpFixedPointsById
      .map(({ panStartFixedPoint }) => panStartFixedPoint)
    )
}

export const isEmpty = (fixedPointsPanZoomState) => {
  return fixedPointsPanZoomState
    .get('panStartFixedPointsById')
    .filter(fixedPoint => fixedPoint.active)
    .size == 0
}

// from linear-solver

const gaussSeidel2D = (dimension, matrix, vector) => {
  const matrixX = matrix.map(row => row.map(entry => entry.x))
  const matrixY = matrix.map(row => row.map(entry => entry.y))
  const vectorX = vector.map(entry => entry.x)
  const vectorY = vector.map(entry => entry.y)

  const resultX = gaussSeidel(dimension, matrixX, vectorX)
  const resultY = gaussSeidel(dimension, matrixY, vectorY)

  const result = makeVector(dimension)
  return result.map((v,i) => Point(resultX[i], resultY[i]))
}

const makeVector = (dimension, defaultScalar=0) => {
  const vector = new Array(dimension)
  for(let i=0; i<dimension; ++i) {
    vector[i] = defaultScalar
  }
  return vector
}

const epsilon = 0.001

const gaussSeidel = (dimension, matrix, vector) => {
  const resultVector = makeVector(dimension, 1)

  let l1measure = Infinity
  while(l1measure > epsilon) {
    l1measure = 0
    for(let i=0; i<dimension; ++i) {
      let newScalar = 0
      for(let j=0; j<i; ++j) {
        // console.log('matrix[i][j], resultVector[j]', matrix[i][j], resultVector[j])
        newScalar += matrix[i][j] * resultVector[j]
      }
      for(let j=i+1; j<dimension; ++j) {
        // console.log('matrix[i][j], resultVector[j]', matrix[i][j], resultVector[j])
        newScalar += matrix[i][j] * resultVector[j]
      }
      newScalar = (vector[i] - newScalar) / matrix[i][i]
      l1measure += Math.abs(newScalar - resultVector[i])
      resultVector[i] = newScalar
    }
  }
  return resultVector
}

const test = () => {
  console.log('testing gaussSeidel')

  console.log('test1', gaussSeidel(2, [[1,0], [0,1]], [11,17]))
  console.log('test2', gaussSeidel(2, [[1,0], [0,2]], [11,18]))
  console.log('test3', gaussSeidel(2, [[13,1], [1,13]], [22,22]))
  console.log('test3', gaussSeidel(4, [[13,1,1,1], [1,13,1,1], [1,1,13,1], [1,1,1,13]], [1,2,3,4]))

}

// test()
