import { types } from './GraphViewConstants'

export default (reducer) => {

  reducer.handle(types.SET_EVOLVE_THE_ACTIVE_GRAPH_LAYOUT,  (state, action) => {
    return state
      .setIn(['graphView', 'evolveTheActiveGraphLayout'], action.evolveTheActiveGraphLayout)
  })

  reducer.handle(types.SET_SIMULATION_STATE,  (state, action) => {
    return state
      .setIn(['graphView', 'simulationState'], action.simulationState)
  })
}
