import { Map } from 'immutable'

import {
  IMPORT_PROCESS_STATE_GLOBAL_STATE_KEY,
  UPDATE_IMPORT_PROCESS_STATE_ACTION,
} from './constants'

export default (reducer) => {
  reducer.handle(UPDATE_IMPORT_PROCESS_STATE_ACTION, (state, action) => {
    const { mergeProcessStateUpdate } = action
    return state.update(
      IMPORT_PROCESS_STATE_GLOBAL_STATE_KEY,
      (mergeProcessState) => mergeProcessState.merge(Map(mergeProcessStateUpdate))
    )
  })
}