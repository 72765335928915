import { ComponentHelpers } from 'helpers/Component'

import ChangelogView from './ChangelogView'

import * as IdeaSelectors from 'domain/Idea/selectors'
import * as ConnectionSelectors from 'domain/Connection/selectors'
import * as BoardSelectors from 'domain/Board/selectors'
import { ColorSelectors } from 'domain/Color/selectors'
import { UserProfileSelectors } from 'domain/UserProfile/selectors'
import { AttributeSelectors } from 'domain/Attribute/selectors'
import { ChangeLogSelectors } from 'domain/ChangeLog'
import { getCurrentBoardSummary } from 'domain/Board/selectors'

import { IdeaActions } from 'domain/Idea/actions'

const mapStateToProps = (state, outerProps: OuterProps) => ({
  changelog: ChangeLogSelectors.getAll(state),
  allUsers: UserProfileSelectors.getAll(state),
  attributesByClientId: AttributeSelectors.getAll(state),
  ideasByClientId: IdeaSelectors.getFullIdeasCache(state),
  connectionsByClientId: ConnectionSelectors.getFullCache(state),
  colorsById: ColorSelectors.getAllByIds(state),
  colorInstanceByColorId: BoardSelectors.getColorInstanceByColorId(state),
  currentBoardSummary: getCurrentBoardSummary(state),
  ...outerProps,
})

const actionGroups = {
  IdeaActions
}

type OuterProps = { dataFetcher: (additionalData: { cutoffDate: Date }) => void }
type StateProps = ReturnType<typeof mapStateToProps>

export default ComponentHelpers.createConnectedComponent<OuterProps, StateProps, typeof actionGroups>({
  mapStateToProps,
  actionGroups,
  component: ChangelogView
})
