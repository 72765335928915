import * as memoize from 'shared/helpers/memoize'
import { OrderedSet } from 'immutable'
import uniq from 'lodash/uniq'

import { IdeaRecord } from 'shared/models/Idea'

import { createFallbackSortingFunction } from 'shared/helpers/sort'

import * as IdeasSelectors from 'domain/Idea/selectors'

import { AppStateRecord } from 'appRoot/state'

export const getSortedIdeasOfCurrentBoardFactory = (sortFunction: (ideaA: IdeaRecord, ideaB: IdeaRecord) => number) => memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    ideasOfCurrentBoard: IdeasSelectors.getAllInCurrentBoard(state),
  }),
  calculateResult: ({ ideasOfCurrentBoard }) => memoize.memoizeValueForRecentArguments(
    () => ideasOfCurrentBoard.valueSeq().toArray().sort(sortFunction)
  )
})

const prioritizeHighClientIds = (ideaA: IdeaRecord, ideaB: IdeaRecord) => (ideaB.get("clientId") < ideaA.get("clientId")) ? -1 : +1
const prioritizeNewIdeas = (ideaA: IdeaRecord, ideaB: IdeaRecord) => ideaB.get("createdAt").getTime() - ideaA.get("createdAt").getTime()
const prioritizeLikedIdeas = (ideaA: IdeaRecord, ideaB: IdeaRecord) => ideaB.get("likeCount") - ideaA.get("likeCount")
const prioritizeIdeasByStatus = (ideaA: IdeaRecord, ideaB: IdeaRecord) => ideaA.statusAsOrderedNumeric() - ideaB.statusAsOrderedNumeric()

export const getSortedIdeasOfCurrentBoard = getSortedIdeasOfCurrentBoardFactory(
  createFallbackSortingFunction(prioritizeNewIdeas, prioritizeHighClientIds)
)
export const getSortedIdeasOfCurrentBoardByLikes = getSortedIdeasOfCurrentBoardFactory(
  createFallbackSortingFunction(prioritizeLikedIdeas, prioritizeNewIdeas, prioritizeHighClientIds)
)
export const getSortedIdeasOfCurrentBoardByStatus = getSortedIdeasOfCurrentBoardFactory(
  createFallbackSortingFunction(prioritizeIdeasByStatus, prioritizeNewIdeas, prioritizeHighClientIds)
)

// NOTE(@felipap): help me cache this? :)
export const getSortedIdeasByListLayout = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    getSortedIdeasOfCurrentBoard: getSortedIdeasOfCurrentBoard(state),
    listViewLayout: getCurrentListViewLayout(state),
    ideaGetter: IdeasSelectors.getIdeaGetter(state),
  }),
  calculateResult: ({
    getSortedIdeasOfCurrentBoard,
    listViewLayout,
    ideaGetter,
  }) => memoize.memoizeValueForRecentArguments(() =>{
    if (listViewLayout.length) {
      // OrderedSet will get rid of the duplicates.
      const fromListLayout = listViewLayout
        .map(ideaGetter)
        .filter<IdeaRecord>((idea, index): idea is IdeaRecord => {
          if (!idea) {
            console.warn(
              `Idea with clientId ${listViewLayout[index]} in listViewLayout not found`,
              listViewLayout
            )
          }
          return !!idea
        })

      return OrderedSet([
        ...fromListLayout,
        ...getSortedIdeasOfCurrentBoard()
      ]).valueSeq().toArray()
    } else {
      return getSortedIdeasOfCurrentBoard()
    }
  })
})

export const getAllHashtagsOfCurrentBoard = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    sortedIdeasOfCurrentBoard: getSortedIdeasOfCurrentBoard(state)
  }),

  calculateResult: ({ sortedIdeasOfCurrentBoard }) => {
    const allHashtags: { [tag: string]: boolean } = {}

    sortedIdeasOfCurrentBoard().forEach((g: IdeaRecord) =>
      _extractTags(g.title)
        .forEach((tag: string) => {
          allHashtags[tag] = true
        }))
    return OrderedSet<string>(Object.keys(allHashtags))
  }
})

const _extractTags = (text: string): string[] => {
  return uniq(text.match(/#[A-Za-z0-9?*]+/g) || [])
}

export const getCurrentListViewLayout = (state: AppStateRecord) => {
  return state.get("currentListViewLayout")
}