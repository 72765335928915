import { Record, List } from 'immutable'

import { MERGE_PROCESS_STATE_GLOBAL_STATE_KEY } from './constants'

import { MergeEntityObject } from 'shared/models/MergeEntity'

const initialMergeProcessState = Record({
  mergeSuggestions: List<MergeEntityObject>(),
  mergeResolutions: List<MergeEntityObject>(),
  parameters: {
    onlyMergeIdeasCreatedSince: new Date(0),
    onlyMergeIdeasMatchingPerfectly: false,
  },
  uiParameters: {
    showOnlyResolutions: false,
  }
})({})

export type MergeProcessState = typeof initialMergeProcessState

const initialState = {
  [MERGE_PROCESS_STATE_GLOBAL_STATE_KEY]: initialMergeProcessState,
}

export default initialState
