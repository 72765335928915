import * as Immutable from 'immutable'

import * as memoize from 'shared/helpers/memoize'

import { AppStateRecord } from 'appRoot/state'

const _getConnectionState = (state: AppStateRecord) => state.get("connectionsCache")

export const getFullCache = (state: AppStateRecord) => _getConnectionState(state).getFullCache()

export const getAllInCurrentBoard = (state: AppStateRecord) => _getConnectionState(state).getAllByClientId()

export const getAllByIdeaClientId = (state: AppStateRecord) => _getConnectionState(state).getAllByIdeaClientId()

export const getByClientId = (state: AppStateRecord, clientId) => getAllInCurrentBoard(state).get(clientId)

export const getConnectionsBetweenIdeas = (state, ideaClientIds: Immutable.Set<string>) => {
  return ideaClientIds.flatMap((ideaClientId) => {
    const outcomingConnections = getOutcomingConnections(state, ideaClientId);
    const outcomingConnectionsEndingInGivenIdeas = outcomingConnections.filter((connection) =>
      ideaClientIds.has(connection.targetIdeaClientId)).toSet()

    return outcomingConnectionsEndingInGivenIdeas
  })
}

export const getIdeaConnections = (state: AppStateRecord, ideaClientId: string) => getIdeaConnectionsGetter(state)(ideaClientId)

export const getIdeaConnectionsGetter = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    connectionState: _getConnectionState(state),
  }),

  calculateResult: ({connectionState}) => (ideaClientId) => connectionState.getByIdeaClientId(ideaClientId)
})

export const getOutcomingConnections = (state, ideaClientId) =>
  getIdeaConnections(state, ideaClientId)
    .filter((connection) => connection.get('sourceIdeaClientId') === ideaClientId)
