import { Record, Map, Set } from 'immutable'

import { initialGraphViewState } from 'components/graph-view/GraphViewState'

import { IdeaRecord } from 'shared/models/Idea'
import { CommentRecord } from 'shared/models/Comment'
import { UserProfileRecord } from 'shared/models/UserProfile'
import { ColorRecord } from 'shared/models/Color'
import { GraphLayoutRecord } from 'shared/models/GraphLayout'
import { DocumentRecord } from 'shared/models/Document'
import { IdeaLikeRecord } from 'shared/models/IdeaLike'
import { CommentLikeRecord } from 'shared/models/CommentLike'
import { Attribute, AttributeStats } from 'shared/models/Attribute'
import { AttributeValues } from 'shared/models/AttributeValue'
import { GenericProfileRecord } from 'shared/models/ProfileBoardMembership'
import { BoardMembership } from 'shared/models/BoardMembership'

import initialMergeProcessState from 'managers/mergeProcess/state'
import initialImportProcessState from 'managers/importProcess/state'

import {BoardSummary} from 'shared/models/BoardSummary'
import {ConnectionStateFactory} from 'domain/Connection/state'
import {MODULES_STATE_FIELD_IN_ROOT_STATE, MODULES_STATE_INITIAL_VALUE} from 'helpers/ModuleState'

import { AllViewTypes } from 'config/appConfig'
import { OutboundOwnersPayload, PermissionResourceType, OutboundPermissionsPayload } from 'shared/models/Permission'
import {AiSearchState} from "domain/AiSearch/reducer";
//TODO: split this to smaller isolated modules that don't know nothing about the root state (see field modulesStateParts below)
// this will allow to remove this ugly dependency of the global state on all the modules,
// as well as dependency of any module on this global state
const appStateDefaults = {
  // TODO: Separate this to a separate "state manager"
  isErrorOverlayVisible: false,

  mostRecentErrors: [],

  syncState: Map({
    boardClientIdToLastUpdateDate: Map()
  }) as Map<string, any>,

  ideasPermissions: Map<string, OutboundPermissionsPayload<PermissionResourceType.ideas>>(),
  boardsPermissions: Map<string, OutboundPermissionsPayload<PermissionResourceType.boards>>(),

  ideasOwners: Map<string, OutboundOwnersPayload>(),
  boardsOwners: Map<string, OutboundOwnersPayload>(),

  boardSummaries: Map() as Map<string, BoardSummary>,
  boardIdeaClientIds: Map() as Map<string, true>,
  boardMembershipsByIdeaClientId: Map() as Map<string, Map<string, BoardMembership>>,
  visibilityByIdeaClientId: Set() as Set<string>,
  ideasCache: Map() as Map<string, IdeaRecord>,
  connectionsCache: ConnectionStateFactory.createInitialConnectionState(),
  comments: Map() as Map<string, CommentRecord>,
  colors: Map() as Map<number, ColorRecord>,
  userProfiles: Map() as Map<number, UserProfileRecord>,
  peopleInCurrentBoard: Map() as Map<string, GenericProfileRecord>,

  currentListViewLayout: [] as string[],

  ideaLikes: Map() as Map<string, IdeaLikeRecord>,
  commentLikes: Map() as Map<string, CommentLikeRecord>,

  attributes: Map() as Map<string, Attribute>,
  attributesStatsForCurrentBoard: {} as AttributeStats,
  attributeValuesByIdeaClientId: Map() as Map<string, AttributeValues | null>,

  graphLayouts: Map() as Map<string, GraphLayoutRecord>,
  documents: Map() as Map<string, DocumentRecord>,

  isLoading: false as boolean,

  // TODO: Get rid of this, move cursorPositionInGraphView to graph view state
  renderState: Record({
    cursorPositionInGraphView: undefined as {x: number, y: number} | undefined,
  })(),

  loginError: undefined as any,
  loginRedirect: undefined as any,

  currentBoardSlug: null as string | null,
  currentBoardIsFound: false as boolean,

  searchInAllBoards: false as boolean,

  viewType: null as AllViewTypes | null,
  listViewLimit: null as number | null,

  //FIXME: remove use of `initialGraphViewState` after removing all direct access to it
  graphView: initialGraphViewState,

  ...initialMergeProcessState,
  ...initialImportProcessState,

  // have to duplicate initial value here, as Record doesn't support defaultValues in methods correctly:
  // Record.get(aKey, defaultValue) uses passed`defaultValue` only if `key` is not defined in the record initially. but this causes Record.set(aKey, anyValue) to throw
  [MODULES_STATE_FIELD_IN_ROOT_STATE]: MODULES_STATE_INITIAL_VALUE,

  aiSearch: {
    attributeId: null,
    query: '',
    wasQueried: false,
    isLoading: false,
    newIdeaClientIds: [],
    loadedIdeaClientIds: [],
    loadedConnectionClientIds: [],
    aiResponse: '',
    createdNodeClientIds: [],
    addNodesInQueryTermToGraph: false,
    relevancyMap: {},
    unconfirmedClientIds: [],
  } as AiSearchState,
}

class AppState extends Record(appStateDefaults) {}

const initialAppState = new AppState()

export type AppStateRecord = AppState

export type AppStateSourceObjectType = Partial<typeof appStateDefaults>

export default initialAppState
