import * as memoize from 'shared/helpers/memoize'

import { Map } from 'immutable'

import { SelectorHelpers } from 'helpers/Selector'

import { AppStateRecord } from 'appRoot/state'
import { ColorRecord } from 'shared/models/Color'

const ColorSelectors = {
  getAllByIds: (state: AppStateRecord) => state.get('colors'),

  getById: (state: AppStateRecord, id: number) => ColorSelectors.getAllByIds(state).get(id),

  getDisplayColorsByGroupId: memoize.memoizeValueForRecentArguments(
    (colorsCollection: Map<number, ColorRecord>): Map<number, ColorRecord[]> => SelectorHelpers.groupBy(colorsCollection, 'groupId').remove(-1)
  ),
}

export { ColorSelectors }
