import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

const UserProfileDefaultValues = {
  id: '',
  userId: NotNullOrUndefined,
  username: NotNullOrUndefined,
  avatar: null,
  email: '',
  isEmailPublic: true,
  website: '',
  status: '',
  bio: '',
  handle: null,
  interests: null,
  name: '',
  attachedBoardClientId: NotNullOrUndefined,
  type: "userProfile" as "userProfile",

  isSuperUser: false,
  canEditRestrictedAttributes: false,
  canAdminUsers: false,
  canAdminIdeas: false,
  canAdminComments: false,
  canManageTeamCodes: false,
  teamCodes: [] as any[],

  createdAt: NotNullOrUndefined,
  updatedAt: NotNullOrUndefined,
}

type UserProfileRawType = {
  id: string,
  userId: number,
  username: string,
  avatar: string | null,
  email: string,
  isEmailPublic: boolean,
  website: string,
  status: string,
  bio: string,
  handle: null,
  interests: any,
  name: string,
  attachedBoardClientId: string,
  type: "userProfile",

  isSuperUser: boolean,
  canEditRestrictedAttributes: boolean,
  canAdminUsers: boolean,
  canAdminIdeas: boolean,
  canAdminComments: boolean,
  canManageTeamCodes: boolean,
  teamCodes: any[],

  createdAt: Date,
  updatedAt: Date,
}

export type UserProfileSourceObjectType = Partial<UserProfileRawType>

const UnknownUsernameDisplayName = "Anon"

class UserProfileRecord extends CreateRecordClass<UserProfileRawType>(UserProfileDefaultValues, "UserProfile") {
  getDisplayName(this: UserProfileRecord) {
    return this.get("name") || this.get("username") || UnknownUsernameDisplayName
  }
  isAnonymousUser(this: UserProfileRecord) {
    return this.get("status") === 'anon'
  }
}

type UserProfileRecordT = UserProfileRecord
export { UserProfileRecordT as UserProfileRecord }

const UserProfile = Object.assign(
  (stub: UserProfileSourceObjectType | UserProfileRecord): UserProfileRecord => {
    // TODO: Make sure all the users have correct createdAt and updatedAt properties
    // So that this default values (new Date(0)) can be removed
    const createdAt = stub.createdAt ? new Date(stub.createdAt) : new Date(0)
    const updatedAt = stub.updatedAt ? new Date(stub.updatedAt) : new Date(0)
    if(!UserProfile.isUserProfile(stub)) {
      return new UserProfileRecord({
        ...stub,
        createdAt,
        updatedAt,
        teamCodes: stub.teamCodes || []
      })
    }
    return new UserProfileRecord(stub)
  },
  {
    isUserProfile: (obj): obj is UserProfileRecord => (obj instanceof UserProfileRecord),
    getDisplayName: (userProfile: UserProfileRecord | null | undefined) => {
      return userProfile ? userProfile.getDisplayName() : UnknownUsernameDisplayName
    }
  },
)

export default UserProfile
