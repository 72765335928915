import {
  MERGE_PROCESS_STATE_GLOBAL_STATE_KEY,
  UPDATE_MERGE_PROCESS_STATE_ACTION,
} from './constants'

export default (reducer) => {
  reducer.handle(UPDATE_MERGE_PROCESS_STATE_ACTION, (state, action) => {
    const { mergeProcessStateUpdate } = action
    return state.update(MERGE_PROCESS_STATE_GLOBAL_STATE_KEY, (mergeProcessState) => mergeProcessState.merge(mergeProcessStateUpdate))
  })
}