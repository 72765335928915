import apiCall from 'helpers/apiCall'

import { UserConstants } from './constants'
import { UserProfileSelectors } from './selectors'

import { pushAllObjects } from 'domain/GenericActions'

import { ErrorHelpers } from 'shared/helpers/Error'

import UserProfile from 'shared/models/UserProfile'

const rootPath = '/userProfiles'

// This needs to be in a separate function for the redux thunk batching to work properly
// (see acceptBoardData in domain/Board/actions.ts)
const replaceUserProfiles = (userProfiles) => dispatch => {
  dispatch({ type: UserConstants.RESET })
  dispatch(pushAllObjects({ userProfiles }))
}

const UserProfileActions = {
  // FIXME: convert to actual UserProfile
  // for that, convert teamCodes prop to Set
  refetchAll: () => async (dispatch) => {
    const { userProfiles } = await apiCall({
      path: rootPath,
      method: 'GET',
    })
    dispatch(replaceUserProfiles(userProfiles))
  },

  setUserPermission: (userId, permissionName, permissionValue) => async (dispatch) => {
    const { userProfiles } = await apiCall({
      path: `${rootPath}/updatePermissions/${userId}`,
      method: 'POST',
      data: { permissionName, permissionValue },
    })

    dispatch(pushAllObjects({ userProfiles }))
  },

  toggleTeamCode: (userId, teamCode, shouldHaveTeamCode) => async (dispatch, getState) => {
    const user = ErrorHelpers.castToNotNullOrThrow(
      UserProfileSelectors.getById(getState(), userId),
      `No user with id=${userId} exists`
    )

    const currentTeamCodesArray = user.teamCodes || []

    const updatedTeamCodes = shouldHaveTeamCode
      ? currentTeamCodesArray.concat(teamCode)
      : currentTeamCodesArray.filter(teamCodeIn => teamCodeIn !== teamCode)


    const { userProfiles } = await apiCall({
      path: `${rootPath}/updateTeamCodes/${userId}`,
      method: 'POST',
      data: { updatedTeamCodes },
    })

    dispatch(pushAllObjects({ userProfiles }))
  },

  setTemporaryPassword: (userId: number) => async (_dispatch, getState) => {
    const { done, temporaryPassword } = await apiCall({
      path: `/auth/setTemporaryPassword`,
      method: 'POST',
      data: { affectedUserId: userId },
    })

    const userProfile = UserProfileSelectors.getById(getState(), userId)

    if (done) {
      window.alert(
          `The user ${UserProfile.getDisplayName(userProfile)} can now login `
        + `with the temporary password: ${temporaryPassword}`
        + `\n\nPlease provide that temporary password to the user.`
      )
    }
  },

  updateUser: (userId, update) => async (dispatch) => {
    const { userProfiles } = await apiCall({
      path: `${rootPath}/${userId}`,
      method: 'PATCH',
      data: update,
    })

    dispatch(pushAllObjects({ userProfiles }))
  }
}

export { UserProfileActions }
