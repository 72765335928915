import { Map } from 'immutable'

import * as types from './constants'

import { AppStateRecord } from 'appRoot/state'

export default (reducer) => {
  reducer.handle(types.PUSH_ATTRIBUTE_VALUES_INTO_CACHE, (state: AppStateRecord, action) => 
    state.update('attributeValuesByIdeaClientId', attributeValuesByIdeaClientId =>
      attributeValuesByIdeaClientId.set(action.ideaClientId, action.attributeValues)
    )
  )

  reducer.handle(types.RESET_ATTRIBUTE_VALUES_CACHE, (state: AppStateRecord) => 
    state.set('attributeValuesByIdeaClientId', Map())
  )
}
