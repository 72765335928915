import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

import { generateClientId } from 'shared/helpers/Id'

const ConnectionInstanceDefaultValues = {
  id: NotNullOrUndefined,
  connectionClientId: NotNullOrUndefined,
  sourceInstanceId: NotNullOrUndefined,
  targetInstanceId: NotNullOrUndefined,
  simulationPosition: {} as { x: number, y: number },
}

type ConnectionInstanceRawType = {
  id: string
  connectionClientId: string
  sourceInstanceId: string
  targetInstanceId: string
  simulationPosition: { x: number, y: number }
}

type ConnectionInstanceSourceObjectType = Partial<ConnectionInstanceRawType>

class ConnectionInstanceRecord extends CreateRecordClass<ConnectionInstanceRawType>(ConnectionInstanceDefaultValues, "ConnectionInstance") {
  isAttachedToIdeaInstance (ideaInstanceId: string) {
    return this.get('sourceInstanceId') === ideaInstanceId ||
      this.get('targetInstanceId') === ideaInstanceId
  }
}

type ConnectionInstanceRecordT = ConnectionInstanceRecord
export { ConnectionInstanceRecordT as ConnectionInstanceRecord }

const ConnectionInstance = Object.assign(
  (stub: ConnectionInstanceSourceObjectType | ConnectionInstanceRecord): ConnectionInstanceRecord => {
    if(!ConnectionInstance.isConnectionInstance(stub)) {
      stub = stub as ConnectionInstanceSourceObjectType
      stub = {
        simulationPosition: {} as { x: number, y: number },
        ...stub,
        id: stub.id || generateClientId(),
      }
    }
    return new ConnectionInstanceRecord(stub)
  },
  {
    isConnectionInstance: (obj: any): obj is ConnectionInstanceRecord => (obj instanceof ConnectionInstanceRecord)
  }
)

export default ConnectionInstance