import * as React from 'react'
import './Button.styl'

import { Button as ReactstrapButton } from 'reactstrap'

interface ButtonState {
}

class Button extends React.Component<{
  onClick: (() => void) | (() => Promise<any>) | null
  type?: ButtonTypes,
  handleSubmit?: boolean
  disabled?: boolean
  autoFocus?: boolean
  stopPropagation?: boolean
}, ButtonState> {

  componentDidMount () {
    if (this.props.autoFocus) this.focus()
  }

  private _innerButton: HTMLElement | null = null

  render() {
    const { type=ButtonTypes.REGULAR } = this.props

    //TODO: render some spinner when performing async action
    return <ReactstrapButton
      {...REACTSTRAP_BUTTON_PROPS_BY_TYPE[type]}
      innerRef={innerButton => this._innerButton = innerButton}
      onClick={this._onClick}
      type={this.props.handleSubmit ? 'submit' : 'button'}
      disabled={this.props.disabled}
    >
      {this.props.children}
    </ReactstrapButton>
  }

  focus = () => {
    this._innerButton && this._innerButton.focus()
  }

  private _onClick = async (event: React.MouseEvent<any>) => {
    // onClick not needed if using `handleSubmit`
    // TODO: enforce this behaviour with assertion
    if (!!this.props.stopPropagation) {
      event.stopPropagation()
    }

    if (!this.props.onClick) {
      return
    }

    this.props.onClick()
  }
}

enum ButtonTypes {
  REGULAR,
  PRIMARY,
  LINK,
  HINT,
  DANGER,
  V2DESIGN
}

export {Button, ButtonTypes}

// use size prop to set custom styles defined in './Button.styl'
const REACTSTRAP_BUTTON_PROPS_BY_TYPE = {
  [ButtonTypes.REGULAR]: {
    color: 'secondary',
    size: null
  },
  [ButtonTypes.PRIMARY]: {
    color: 'primary',
    size: null
  },
  [ButtonTypes.LINK]: {
    color: 'link',
    size: null
  },
  [ButtonTypes.DANGER]: {
    color: 'danger',
    size: null
  },
  [ButtonTypes.HINT]: {
    color: 'link',
    size: 'custom-type-hint',
  },
  [ButtonTypes.V2DESIGN]: {
    color: 'v2design',
    size: null
  },
}
