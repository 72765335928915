import { Map } from 'immutable'

type AnyObject = Record<string,any>;

export function depthOneObjectEquality<T extends AnyObject>(
  obj1: T, obj2: T, attributesToCompare?: (keyof T)[], attributesToIgnore?: (keyof T)[]
) {
  return _traditionalDepthOneObjectEquality(obj1, obj2, attributesToCompare, attributesToIgnore)
}

function _traditionalDepthOneObjectEquality<T extends AnyObject>(
  obj1: T,
  obj2: T,
  attributesToCompare?: (keyof T)[],
  attributesToIgnore: (keyof T)[] = []
) {
  if(obj1 == obj2) return true

  let namesToProcess = attributesToCompare
  if(namesToProcess === undefined) {
    const names1 = Object.getOwnPropertyNames(obj1)
    const names2 = Object.getOwnPropertyNames(obj2)

    if(names1.length !== names2.length) return false
    namesToProcess = names1
  }

  for(let i=0; i < namesToProcess.length; ++i) {
    const name = namesToProcess[i]

    if (attributesToIgnore.indexOf(name) >= 0) {
      continue
    }

    if(obj1[name] !== obj2[name]) return false
  }

  return true
}

interface DiffEntry {
  o1: any
  o2: any
  added: any
  removed: any
  updated: any
}

type DiffResult = {[key: string]: DiffEntry}

export function depthOneObjectDiff<T extends AnyObject>(obj1: T, obj2: T): DiffResult {
  const diff: DiffResult = {}

  const names1 = Object.getOwnPropertyNames(obj1)
  const names2 = Object.getOwnPropertyNames(obj2)

  const allNames = Array.from(new Set(names1.concat(names2)))

  for(let i=0; i<allNames.length; ++i) {
    const name = allNames[i]
    if(obj1[name] !== obj2[name]) {
      const oldO = obj1[name]
      const newO = obj2[name]
      const immDiff = (oldO && newO && Map.isMap(oldO) && Map.isMap(newO)) ? newO.diffFrom(oldO) : null
      diff[name] = {
        o1: (oldO && oldO.toJS) ? oldO.toJS() : oldO,
        o2: (newO && newO.toJS) ? newO.toJS() : newO,
        added: immDiff && immDiff.added.toJS(),
        removed: immDiff && immDiff.removed.toJS(),
        updated: immDiff && immDiff.updated.toJS(),
      }
    }
  }

  return diff
}
