import * as React from 'react'
import ReactDOM from 'react-dom'
import { createStore, Store, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { RootView } from "./RootViewHMR"

import { AppStateRecord } from 'appRoot/state'
import appConfig from 'config/appConfig'

import errorMiddleware from 'appRoot/errorMiddleware'
import createBatchSubscribeMiddleware from 'appRoot/batchSubscribeMiddleware'
import rootReducer from 'appRoot/rootReducer'

// bootstrap styles needed by reactstrap
// TODO: #future Consider switching from reactstrap to react-bootstrap
// FIXME: for some reason importing it from `global.styl` results in errors in css-loader
import 'bootstrap/dist/css/bootstrap.css'

import 'appRoot/fontAwesome.less'
import 'appRoot/global.styl'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (a: any) => any

    store: Store<AppStateRecord>
  }
}

declare const module: any

if (module.hot) {
  module.hot.accept()
}

const {
  middleware: batchSubscribeMiddleware,
  enhanceStore: enhanceStoreWithBatchSubscribes
} = createBatchSubscribeMiddleware()
const store = enhanceStoreWithBatchSubscribes(
  createStore<AppStateRecord>(
    rootReducer,
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(
      applyMiddleware(errorMiddleware, batchSubscribeMiddleware, thunk)
    )
  )
)

// TODO: Rethink enabling this for low-security applications
//store.dispatch(restoreStateFromLocalStorage())
window.store = store

async function start() {
  if (appConfig.features.enableLogRocket) {
    const { default: LogRocket } = await import(/* webpackChunkName: "LogRocket" */ 'logrocket')
    LogRocket.init('mcxhpa/idepad')
  }

  ReactDOM.render(
    <Provider store={store}>
      <RootView />
    </Provider>,
    document.getElementById('reactAppMountPoint')
  )
}

start()