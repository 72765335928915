import * as React from 'react'

import styles from './Auth.styl'

import AuthWrapper from './AuthWrapper'
import AuthDialog, { AuthState } from './AuthDialog'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import { NavigationActions } from 'navigation/actions'

const actionGroups = {
  NavigationActions
}

type SignupProps = BoundActionGroups<typeof actionGroups>

class Signup extends React.Component<SignupProps> {
  render() {
    return (
      <AuthWrapper>
        <h3>Sign Up</h3>
        <AuthDialog disableLogin={true} initialAuthState={AuthState.EmailSignUp} />
        <div className={styles.alt}>
          Already have an account? <a onClick={this.props.NavigationActions.navigateToLoginPage}>Login!</a>
        </div>
      </AuthWrapper>
    )
  }
}

export default ComponentHelpers.createConnectedComponent<{}, {}, typeof actionGroups>({
  actionGroups,
  mapStateToProps: () => ({}),
  component: Signup,
})