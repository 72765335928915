import initialAppState from './state'

import connectionsReducer from 'domain/Connection/reducer'
import authReducer from 'auth/reducer'
import appReducer from './appReducer'
import colorsReducer from 'domain/Color/reducer'
import boardReducer from 'domain/Board/reducer'
import graphViewReducer from 'components/graph-view/graphViewReducer'
import evolveReducers from 'components/graph-view/evolveReducers'
import userReducer from 'domain/UserProfile/reducer'
import attributeValueReducer from 'domain/AttributeValue/reducer'
import DomainDataReducer from 'domain/DomainReducer'
import aiSearchReducer from 'domain/AiSearch/reducer'

import mergeProcessReducer from 'managers/mergeProcess/reducer'
import importProcessReducer from 'managers/importProcess/reducer'
import errorReducer from 'managers/error/reducer'

import {moduleStateReducer} from 'helpers/ModuleState'

import { AppStateRecord } from './state'

type reducerHandler = (state: AppStateRecord, action: any) => AppStateRecord

const reducers = {} as {[k: string] : reducerHandler[]}

const reducer = {
  handle: (type: string | string[], fn: reducerHandler) => {
    if(typeof fn !== 'function') {
      console.warn('Invalid action handler ', fn)
      return
    }
    let types: string[]
    if (Array.isArray(type)) {
      types = type
    } else {
      types = [type]
    }

    for(type of types) {
      if (typeof type !== 'string') {
        console.warn('Invalid action type ', type)
      }

      else {
        _addReducer(type, fn)
      }
    }
  }
}

export type Reducer = typeof reducer

export default (state = initialAppState, action) => {

  const handlers = reducers[action.type]
  if (handlers) {
    state = handlers.reduce((state, reducer) => (reducer(state, action) || state), state)
  } else {
    console.warn('No handler for action', action)
  }

  return state
}

appReducer(reducer)
connectionsReducer(reducer)
colorsReducer(reducer)
authReducer(reducer)
boardReducer(reducer)
graphViewReducer(reducer)
evolveReducers(reducer)
userReducer(reducer)
errorReducer(reducer)
mergeProcessReducer(reducer)
importProcessReducer(reducer)
attributeValueReducer(reducer)
DomainDataReducer(reducer)
moduleStateReducer(reducer)
aiSearchReducer(reducer)

function _addReducer(actionType, handler) {
  if (!reducers[actionType]) {
    reducers[actionType] = []
  }

  reducers[actionType].push(handler)
}
