import * as EvImm from 'evolving-immutable'
import { Map } from 'immutable'

import * as memoize from 'shared/helpers/memoize'

import { AppStateRecord } from 'appRoot/state'
import { Attribute } from 'shared/models/Attribute'

function _getAllRaw(state: AppStateRecord) {
  return state.get('attributes')
}

const _getAll = EvImm.startChain()
  .addStep((state) => _getAllRaw(state))
  .memoizeForValue()
  .addFilterStep(attribute => !attribute.isDeleted)
  .endChain()

function getAll (state: AppStateRecord): Map<string,Attribute> { 
  return _getAll(state)
}

function _getByField(state: AppStateRecord, fieldName: string, fieldValue: any) {
  const allAttributesMap = getAll(state)

  return allAttributesMap.find((attribute) => attribute[fieldName] === fieldValue)
}

const AttributeSelectors = {
  getAll: getAll,

  getAllSortedByName: memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (state: AppStateRecord) => ({
      allAttributes: getAll(state),
    }),

    calculateResult: ({allAttributes}) => allAttributes.sortBy((attribute => attribute.name.toLowerCase()))
  }),

  getByName (state: AppStateRecord, name: string) {
    return _getByField(state, 'name', name)
  },

  getByClientId (state: AppStateRecord, clientId: string) {
    return _getByField(state, 'clientId', clientId)
  },
}

export { AttributeSelectors }
