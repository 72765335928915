// TODO: merge all exported sruff into `./index` or TS module

export const IdeaSelectionIdeaStates = {
  NONE: Symbol('No idea selected'),
  EXISTING: Symbol('Existing (not new) idea'),
  NEW: Symbol('New idea'),
}

export interface IdeaSelectionState {
  ideaInstanceIds: Array<string>,
  newIdeaState: (typeof IdeaSelectionIdeaStates.NONE) | (typeof IdeaSelectionIdeaStates.EXISTING) | (typeof IdeaSelectionIdeaStates.NEW),
  newRelatedIdeaSourceIdeaClientId: string | undefined
}
