import { depthOneObjectEquality, depthOneObjectDiff } from './equalities'

const THE_ONLY_SLOT = 'the-only-slot'

const sameArguments = (arguments1: any[], arguments2: any[]) => {
  if (arguments1.length !== arguments2.length) return false
  for(let i=0; i<arguments1.length; ++i) {
    const argument1 = arguments1[i]
    const argument2 = arguments2[i]
    if (argument1 != argument2) return false
  }
  return true
}

export function memoizeReturnedObject<A extends any[],R>(buildObject: (...args: A) => R, historyLength = 1): typeof buildObject {
  const recentObjects: R[] = []
  const findEquivalentPastObject = (currentObject: R) => {
    for(let i=0; i<recentObjects.length; ++i) {
      const pastObject = recentObjects[i]
      if (depthOneObjectEquality(pastObject, currentObject)) return pastObject
    }
    return null
  }
  return (...currentArguments: A) => {
    const currentObject = buildObject(...currentArguments)
    const equivalentPastObject = findEquivalentPastObject(currentObject)
    if(equivalentPastObject === null) {
      recentObjects.unshift(currentObject)
      while(recentObjects.length > historyLength) {
        recentObjects.pop()
      }
      return currentObject
    } else {
      return equivalentPastObject
    }
  }
}

interface MemoizeValueForRecentPreparedArgumentsConfig<A extends any[], PA, R>{
  prepareArgument: (...args: A) => PA,
  slottingFunction?: (preparedArguments: PA) => string,
  calculateResult: (preparedArguments: PA) => R,
  historyLength?: number,
  debug?: boolean,
  debugPrefix?: string,
}

const _defaultSlottingFunction = (_preparedArgument: any) => THE_ONLY_SLOT

export function memoizeValueForRecentPreparedArguments<A extends any[], PA, R>(
  config: MemoizeValueForRecentPreparedArgumentsConfig<A, PA, R>
): (...args: A) => R {
  let {
    prepareArgument,
    slottingFunction = _defaultSlottingFunction,
    calculateResult,
    historyLength = 1, debug = false, debugPrefix = ''
  } = config

  const runTimeline = typeof performance === 'object'

  if(!Function.prototype.isPrototypeOf(slottingFunction)) {
    throw new Error('slottingFunction must be a function')
  }
  type ArgumentAndValue = {value: R, argument: PA}
  const recentArgumentsAndValuesBySlot: {[slot: string]: ArgumentAndValue[]} = {}
  const findMemoizedValue = (recentArgumentsAndValues: ArgumentAndValue[], currentArgument: PA): R | null => {
    for(let i=0; i<recentArgumentsAndValues.length; ++i) {
      const { argument: pastArgument, value: pastValue }
        = recentArgumentsAndValues[i]
      if (depthOneObjectEquality(pastArgument, currentArgument)) {
        return pastValue
      }
    }
    return null
  }
  return (...args: A) => {
    if(debug && runTimeline) {
      performance.mark(`${debugPrefix}_start`)
    }
    let currentValue: R | null
    const currentPreparedArgument = prepareArgument(...args)
    const currentSlot = slottingFunction(currentPreparedArgument)
    if(typeof currentSlot !== 'string') {
      throw new Error('Currently only string slots are supported')
    }
    let recentArgumentsAndValues = recentArgumentsAndValuesBySlot[currentSlot]
    if(recentArgumentsAndValues === undefined) {
      recentArgumentsAndValues = []
      recentArgumentsAndValuesBySlot[currentSlot] = recentArgumentsAndValues
    }
    currentValue = findMemoizedValue(recentArgumentsAndValues, currentPreparedArgument)
    if(currentValue == null) {
      if(debug) {
        console.log(debugPrefix, 'slot:', currentSlot, (!recentArgumentsAndValues[0]) && "first run")
        recentArgumentsAndValues[0] &&
          console.log(debugPrefix, 'diff', depthOneObjectDiff(
            recentArgumentsAndValues[0].argument, currentPreparedArgument
          ))
      }
      currentValue = calculateResult(currentPreparedArgument)
      while(recentArgumentsAndValues.length >= historyLength) {
        recentArgumentsAndValues.pop()
      }
      recentArgumentsAndValues.unshift({
        argument: currentPreparedArgument,
        value: currentValue,
      })
    } else {
      if(debug) {
        console.log(debugPrefix, 'slot:', currentSlot, 'no diff')
      }
    }
    if(debug && runTimeline) {
      performance.mark(`${debugPrefix}_end`)
      performance.measure(`${debugPrefix}_run`, `${debugPrefix}_start`, `${debugPrefix}_end`)
    }
    return currentValue
  }
}

export function memoizeValueForRecentArguments<A extends any[], R>(buildObject: (...args: A) => R, historyLength=1): typeof buildObject {
  const recentArgumentsAndValues: { arguments: A, value: R }[] = []
  const findMemoizedValue = (currentArguments: A) => {
    for(let i=0; i<recentArgumentsAndValues.length; ++i) {
      const { arguments: pastArguments, value: pastValue } = recentArgumentsAndValues[i]
      if (sameArguments(pastArguments, currentArguments)) return pastValue
    }
    return null
  }
  return (...currentArguments: A) => {
    let currentValue: R | null
    currentValue = findMemoizedValue(currentArguments)
    if(currentValue === null) {
      currentValue = buildObject(...currentArguments)
      recentArgumentsAndValues.unshift({
        arguments: currentArguments,
        value: currentValue,
      })
      while(recentArgumentsAndValues.length > historyLength) {
        recentArgumentsAndValues.pop()
      }
    }
    return currentValue
  }
}

export function cacheFunctionWithSingleScalarArgument<A extends string, R>(buildObject: (arg: A) => R): typeof buildObject {
  const cache: Partial<Record<A, R>> = {}

  return (singleScalarArgument: A) => {
    if (!cache.hasOwnProperty(singleScalarArgument)) {
      cache[singleScalarArgument] = buildObject(singleScalarArgument)
    }

    return cache[singleScalarArgument]!
  }
}

export const memoize = {
  memoizeValueForRecentPreparedArguments,
  memoizeValueForRecentArguments,
  cacheFunctionWithSingleScalarArgument,
}
