import * as React from 'react'
import * as Immutable from 'immutable'

import moment from 'moment'

import ColorSquare from 'components/common/ColorSquare'

import {
  Popover, PopoverHeader, PopoverBody,
} from 'reactstrap'

import IdeaSummary from './IdeaSummary'

import classnames from 'classnames'

import UserProfile, { UserProfileRecord } from 'shared/models/UserProfile'
import { IdeaRecord } from 'shared/models/Idea'
import { ConnectionRecord } from 'shared/models/Connection'
import { ColorRecord } from 'shared/models/Color'
import { ColorInstance } from 'shared/models/ColorInstance'

import {
  ChangeType, ChangelogEntry,
  ChangelogEntryIdeaCreate,
  ChangelogEntryIdeaAttributeValue,
  ChangelogEntryIdeaTitle,
  ChangelogEntryIdeaDelete,
  ChangelogEntryIdeaRestore,
  ChangelogEntryIdeaStatus,
  ChangelogEntryIdeaColorId,
  ChangelogEntryConnectionCreate,
  ChangelogEntryConnectionLabel,
  ChangelogEntryConnectionColorId,
  ChangelogEntryConnectionDelete
} from 'shared/models/Changelog'

import styles from './Changelog.styl'

import { SelectIdeaClientIdFilterConsumer } from './ChangelogView'

interface ChangelogViewRowProps {
  changelogEntry: ChangelogEntry
  allUsers: Immutable.Map<number, UserProfileRecord>
  attributesByClientId: Immutable.Map<string, { name: string }>
  ideasByClientId: Immutable.Map<string, IdeaRecord>,
  connectionsByClientId: Immutable.Map<string, ConnectionRecord>,
  colorsById: Immutable.Map<number, ColorRecord>
  colorInstanceByColorId: Immutable.Map<number, ColorInstance>,

  IdeaActions: any
}

const createContextGetters = ({ colorsById, ideasByClientId, connectionsByClientId, allUsers, attributesByClientId, colorInstanceByColorId }: ChangelogViewRowProps) => {
  function getCurrentIdeaDetails(ideaClientId: string) {
    const idea = ideasByClientId.get(ideaClientId) || null
    const colorId = idea ? idea.colorId : null
    const color = (colorId !== null && colorsById.get(colorId)) || null
    const colorInstance = (colorId !== null && colorInstanceByColorId.get(colorId)) || null
    const author = idea
      ? allUsers.get(idea.userId) || null
      : null

    return { idea, color, colorInstance, author }
  }

  return {
    getCurrentIdeaDetails,
    getCurrentConnectionDetails(connectionClientId) {
      return connectionsByClientId.get(connectionClientId) || null
    },
    getAttribute(attributeClientId: string) {
      return attributesByClientId.get(attributeClientId) || null
    },
    getColor(colorId: number | null) {
      return colorId !== null ? colorsById.get(colorId, null) : null
    },
    getColorInstance(colorId: number) {
      return colorInstanceByColorId.get(colorId) || null
    },
  }
}

type ContextGetters = ReturnType<typeof createContextGetters>

const renderColorSquare = (colorId: number | null, contextGetters: ContextGetters, includePrefixSpace = false) => {
  const color = contextGetters.getColor(colorId)
  return color && <>
    {includePrefixSpace && <>&nbsp;</>}
    <ColorSquare isNotSelectable color={color} />
  </>
}

enum PopupState {
  Hidden = "hidden",
  Preview = "preview",
  Active = "active",
}

interface IdeaShorthandProps {
  idea?: IdeaRecord | null
  color?: ColorRecord | null
  colorInstance?: ColorInstance | null

  selectIdeaClientIdFilter: (ideaClientId: string) => void
}

class IdeaShorthand extends React.PureComponent<IdeaShorthandProps, { popupState: PopupState }> {
  state = { popupState: PopupState.Hidden }

  private readonly elementRef: React.RefObject<HTMLDivElement> = React.createRef()

  render() {
    const { idea, color, colorInstance } = this.props

    const isOpen = this.state.popupState === PopupState.Preview || this.state.popupState === PopupState.Active
    return <>
      <div
        ref={this.elementRef}
        className={styles.ideaShorthandIdea}
        onClick={() => this.setState(state => ({ popupState: state.popupState === PopupState.Active ? PopupState.Hidden : PopupState.Active }))}
        onMouseEnter={() => this.setState(state => ({ popupState: state.popupState === PopupState.Hidden ? PopupState.Preview : state.popupState }))}
        onMouseLeave={() => this.setState(state => ({ popupState: state.popupState === PopupState.Preview ? PopupState.Hidden : state.popupState }))}
      >
        {idea && idea.title}{color && <>&nbsp;<ColorSquare isNotSelectable color={color} /></>}
      </div>
      {/* Popover can only be rendered when element to attach to is available */}
      {this.elementRef.current && <Popover
        placement="bottom"
        target={this.elementRef.current}
        isOpen={isOpen}
        className={styles.ideaPopover}
        innerClassName={styles.ideaPopoverInner}
      >
        <PopoverHeader>
          <span className={styles.valign} style={{ flexWrap: "nowrap" }}>
            <span className={styles.valign} style={{ flexGrow: 1 }}>
              {idea && idea.title}
              {color && <>&nbsp;<ColorSquare isNotSelectable color={color} /></>}
              {colorInstance && <>&nbsp;{colorInstance.label}</>}
            </span>
            {this.state.popupState === PopupState.Active && <div
                onClick={() => this.setState({ popupState: PopupState.Hidden })}
                style={{ cursor: "pointer", fontSize: "20px" }}
              >
                &#10006;
              </div>
            }
          </span>
        </PopoverHeader>
        <PopoverBody>
          {isOpen && idea && <IdeaSummary ideaClientId={idea.clientId} />}
          {this.state.popupState === PopupState.Active && idea &&
            <button onClick={() => this.props.selectIdeaClientIdFilter(idea.clientId)}>Select for filter</button>
          }
        </PopoverBody>
      </Popover>}
    </>
  }
}

const renderIdeaShorthand = (ideaClientId: string, contextGetters: ContextGetters) => {
  const { idea, color, colorInstance } = contextGetters.getCurrentIdeaDetails(ideaClientId)
  return <SelectIdeaClientIdFilterConsumer>
    {(selectIdeaClientIdFilter) => <IdeaShorthand
        idea={idea} color={color} colorInstance={colorInstance}
        selectIdeaClientIdFilter={selectIdeaClientIdFilter}
    />}
  </SelectIdeaClientIdFilterConsumer>
}

const renderConnectionShorthand = (connectionClientId: string, contextGetters: ContextGetters) => {
  const connection = contextGetters.getCurrentConnectionDetails(connectionClientId)
  return <>
    <div className={styles.connectionShorthandIdea}>
      { connection && connection.sourceIdeaClientId && renderIdeaShorthand(connection.sourceIdeaClientId, contextGetters) }
    </div>
    <div className={styles.connectionShorthandRelation}>
      {connection ? <>&nbsp;-{connection.labelText}->&nbsp;</> : <>&nbsp;--->&nbsp;</>}
    </div>
    <div className={styles.connectionShorthandIdea}>
      { connection && connection.targetIdeaClientId && renderIdeaShorthand(connection.targetIdeaClientId, contextGetters) }
    </div>
  </>
}

interface Renderer {
  contextTop(): React.ReactNode,
  contextBot(): React.ReactNode,
  oldField(): React.ReactNode,
  newField(): React.ReactNode,
}

const _rendererFactories: any = {
  [ChangeType.IdeaCreate]: (changelogEntry: ChangelogEntryIdeaCreate, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return "idea created"
      },
      contextBot() {
        return renderIdeaShorthand(changelogEntry.changeDescription.idea.clientId, contextGetters)
      },
      oldField() {
        return changelogEntry.changeDescription.idea.title
      },
      newField() {
        return null
      },
    }
  },
  [ChangeType.IdeaDelete]: (changelogEntry: ChangelogEntryIdeaDelete, contextGetters: ContextGetters, IdeaActions) => {
    const { idea } = contextGetters.getCurrentIdeaDetails(changelogEntry.changeDescription.ideaClientId)
    return {
      contextTop() {
        const deletedConnectionsCount = changelogEntry.changeDescription.connectionClientIds
          ? changelogEntry.changeDescription.connectionClientIds.length
          : 0
        return `idea deleted${deletedConnectionsCount > 0 ? ` with ${deletedConnectionsCount} connection(s)` : ""}`
      },
      contextBot() {
        return <>
          {renderIdeaShorthand(changelogEntry.changeDescription.ideaClientId, contextGetters)}
          <button onClick={() => IdeaActions.restoreIdea(changelogEntry.changeDescription.ideaClientId)}>Restore</button>
        </>
      },
      oldField() {
        return idea.title
      },
      newField() {
        return null
      },
    }
  },
  [ChangeType.IdeaRestore]: (changelogEntry: ChangelogEntryIdeaRestore, contextGetters: ContextGetters) => {
    const { idea } = contextGetters.getCurrentIdeaDetails(changelogEntry.changeDescription.ideaClientId)
    return {
      contextTop() {
        const deletedConnectionsCount = changelogEntry.changeDescription.connectionClientIds
          ? changelogEntry.changeDescription.connectionClientIds.length
          : 0
        return `idea restored${deletedConnectionsCount > 0 ? ` with ${deletedConnectionsCount} connection(s)` : ""}`
      },
      contextBot() {
        return renderIdeaShorthand(changelogEntry.changeDescription.ideaClientId, contextGetters)
      },
      oldField() {
        return idea.title
      },
      newField() {
        return null
      },
    }
  },
  [ChangeType.IdeaTitleChange]: (changelogEntry: ChangelogEntryIdeaTitle, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return "idea title"
      },
      contextBot() {
        return renderIdeaShorthand(changelogEntry.changeDescription.ideaClientId, contextGetters)
      },
      oldField() {
        return changelogEntry.changeDescription.oldTitle
      },
      newField() {
        return changelogEntry.changeDescription.newTitle
      },
    }
  },
  [ChangeType.IdeaStatusChange]: (changelogEntry: ChangelogEntryIdeaStatus, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return "idea status"
      },
      contextBot() {
        return renderIdeaShorthand(changelogEntry.changeDescription.ideaClientId, contextGetters)
      },
      oldField() {
        return changelogEntry.changeDescription.oldStatus
      },
      newField() {
        return changelogEntry.changeDescription.newStatus
      }
    }
  },
  [ChangeType.IdeaAttributeValueChange]: (changelogEntry: ChangelogEntryIdeaAttributeValue, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return "idea attribute value"
      },
      contextBot() {
        const attribute = contextGetters.getAttribute(changelogEntry.changeDescription.attributeClientId)

        return <>
          {renderIdeaShorthand(changelogEntry.changeDescription.ideaClientId, contextGetters)}
          : <i>{attribute && attribute.name}</i>
        </>
      },
      oldField() {
        return changelogEntry.changeDescription.oldValue
      },
      newField() {
        return changelogEntry.changeDescription.newValue
      }
    }
  },
  [ChangeType.IdeaColorIdChange]: (changelogEntry: ChangelogEntryIdeaColorId, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return "idea attribute value"
      },
      contextBot() {
        return <>
          {renderIdeaShorthand(changelogEntry.changeDescription.ideaClientId, contextGetters)}
          : <i>color</i>
        </>
      },
      oldField() {
        const colorInstance = contextGetters.getColorInstance(changelogEntry.changeDescription.oldColorId)
        return <>
          {renderColorSquare(changelogEntry.changeDescription.oldColorId, contextGetters)}
          &nbsp;{colorInstance && colorInstance.label}
        </>
      },
      newField() {
        const colorInstance = contextGetters.getColorInstance(changelogEntry.changeDescription.newColorId)
        return <>
          {renderColorSquare(changelogEntry.changeDescription.newColorId, contextGetters)}
          &nbsp;{colorInstance && colorInstance.label}
        </>
      }
    }
  },
  [ChangeType.ConnectionCreate]: (changelogEntry: ChangelogEntryConnectionCreate, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return null
      },
      contextBot() {
        return renderConnectionShorthand(changelogEntry.changeDescription.connection.clientId, contextGetters)
      },
      oldField() {
        return <i>Created relation</i>
      },
      newField() {
        return null
      }
    }
  },
  [ChangeType.ConnectionLabelChange]: (changelogEntry: ChangelogEntryConnectionLabel, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return "relation"
      },
      contextBot() {
        return <>
          {renderConnectionShorthand(changelogEntry.changeDescription.connectionClientId, contextGetters)}
          : label
        </>
      },
      oldField() {
        return changelogEntry.changeDescription.oldLabelText
      },
      newField() {
        return changelogEntry.changeDescription.newLabelText
      }
    }
  },
  [ChangeType.ConnectionColorIdChange]: (changelogEntry: ChangelogEntryConnectionColorId, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return "connction color"
      },
      contextBot() {
        return renderConnectionShorthand(changelogEntry.changeDescription.connectionClientId, contextGetters)
      },
      oldField() {
        return renderColorSquare(changelogEntry.changeDescription.oldColorId, contextGetters)
      },
      newField() {
        return renderColorSquare(changelogEntry.changeDescription.newColorId, contextGetters)
      }
    }
  },
  [ChangeType.ConnectionDelete]: (changelogEntry: ChangelogEntryConnectionDelete, contextGetters: ContextGetters) => {
    return {
      contextTop() {
        return null
      },
      contextBot() {
        return renderConnectionShorthand(changelogEntry.changeDescription.connectionClientId, contextGetters)
      },
      oldField() {
        return <i>Deleted relation</i>
      },
      newField() {
        return null
      }
    }
  },
}

const getRenderer = (changelogEntry: ChangelogEntry, contextGetters: ContextGetters, IdeaActions): Renderer => {
  const rendererFactory = _rendererFactories[changelogEntry.changeType]
  return rendererFactory && rendererFactory(changelogEntry, contextGetters, IdeaActions)
}

class UnsafeChangelogViewRow extends React.PureComponent<ChangelogViewRowProps> {
  render() {
    const contextGetters = createContextGetters(this.props)
    const rendered = getRenderer(this.props.changelogEntry, contextGetters, this.props.IdeaActions)

    const changelogEntry = this.props.changelogEntry
    const userProfile = this.props.allUsers.get(changelogEntry.userId)

    const oldField = rendered && rendered.oldField && rendered.oldField()
    const newField = rendered && rendered.newField && rendered.newField()

    const oldFieldPresent = oldField !== null && oldField !== undefined
    const newFieldPresent = newField !== null && newField !== undefined

    return <div className={classnames(styles.changeLogRow, (!oldFieldPresent || !newFieldPresent) && styles.changeLogRowOneChange)}>
      <div className={styles.timestamp}>
        {moment(this.props.changelogEntry.created_at).format("DD-MM-YY")}
        {" "}
        {moment(this.props.changelogEntry.created_at).format("HH:mm:ss")}
      </div>
      <div className={styles.user}>
        { UserProfile.getDisplayName(userProfile) }
      </div>
      <div className={styles.contextTop}>
        {rendered && rendered.contextTop && rendered.contextTop()}
      </div>
      <div className={styles.contextBot}>
        {rendered && rendered.contextBot && rendered.contextBot()}
      </div>
      {
        oldFieldPresent && newFieldPresent && <>
          <div className={styles.old}>
            {rendered && rendered.oldField && rendered.oldField()}
          </div>
          <div className={styles.arrow}>➝</div>
          <div className={styles.new}>
            {rendered && rendered.newField && rendered.newField()}
          </div>
        </>
      }
      {
        (!oldFieldPresent || !newFieldPresent) && <>
          <div className={styles.change}>
            {oldField || newField}
          </div>
        </>
      }
    </div>
  }
}

export class ChangelogViewRow extends React.PureComponent<ChangelogViewRowProps, { error: any, message: any }> {
  state = { error: null, message: null}

  componentDidCatch(error, message) {
    this.setState({ error, message })
  }

  render() {
    if(this.state.error !== null) {
      return <div className={styles.changeLogRow}>
        <div className={styles.spanAll}>
          This changelog entry cannot be displayed: {this.state.error.message}
        </div>
      </div>
    }

    return <UnsafeChangelogViewRow {...this.props} />
  }
}