import * as Immutable from 'immutable'
import apiCall from 'helpers/apiCall'

import {ModuleStateHelpers} from 'helpers/ModuleState'
import {AppStateRecord} from 'appRoot/state'
import {memoize} from 'shared/helpers/memoize'

type SettingName = string

interface CreateCompanySettingAccessorArgs<SettingRawData, SettingShape>{
  name: SettingName,
  initialValue: SettingRawData
  fromRawData: (jsonValue: SettingRawData) => SettingShape
  toRawData: (settingValue: SettingShape) => SettingRawData
}

const CompanySettingsHelpers = {
  createCompanySettingAccessor<SettingRawData, SettingShape>({name, initialValue, fromRawData, toRawData}: CreateCompanySettingAccessorArgs<SettingRawData, SettingShape>) {
    return {
      get: memoize.memoizeValueForRecentPreparedArguments({
        prepareArgument: (state: AppStateRecord) => {
          return {
            settingData: _getSetting(state, name, initialValue)
          }
        },

        calculateResult: ({settingData}) => fromRawData(settingData)
      }),

      set: (setting: SettingShape) => _setSetting(name, toRawData(setting))
    }
  }
}

const CompanySettingsActions = {
  fetchAll: () => async (dispatch) => {
    const allCompanySettings = await apiCall({path: `/companySettings`})
    dispatch(_setAll(allCompanySettings))
  },
}

export {CompanySettingsHelpers, CompanySettingsActions}

const _getSetting = (state: AppStateRecord, name: SettingName, initialValue) => {
  return _getAll(state).get(name, initialValue)
}

const _setSetting = (name: SettingName, value) => (dispatch, getState) => {
  const updatedSettings = _getAll(getState()).set(name, value)

  dispatch(_setAll(updatedSettings))

  apiCall({
    method: 'POST',
    path: '/companySettings',
    data: {name, value},
  })
}

const _getAll = (state) => _moduleState.get(state).allSettings

const _setAll = (allCompanySettigns) => _moduleState.setPart('allSettings', Immutable.Map(allCompanySettigns) as Immutable.Map<string, any>)

const _moduleState = ModuleStateHelpers.createModuleState({
  moduleName: 'CompanySettings',
  initialValue: {
    allSettings: Immutable.Map() as Immutable.Map<SettingName, any>
  }
})
