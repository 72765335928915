import { Record } from 'immutable'

// Nominal type workaround — we don't want anyone accidentally
// emulating the NotNullOrUndefined type with anything other
// than the NotNullOrUndefined value (https://basarat.gitbooks.io/typescript/docs/tips/nominalTyping.html)
enum _NotNullOrUndefinedBrand { ImpossibleOption = "ImpossibleOption" }
type NotNullOrUndefined = {} & _NotNullOrUndefinedBrand
export const NotNullOrUndefined: NotNullOrUndefined = {} as any

type DefaultValueOrForbidden<T> = {
  [K in keyof T]: T[K] | NotNullOrUndefined
}

type Nullable<T> = {
  [K in keyof T]: T[K] | null
}

export const CreateRecordClass = <T extends object>(defaultValuesOrForbidden: DefaultValueOrForbidden<T>, name?: string, throwOnNotNullOrUndefinedViolations?: boolean): Record.Class<T> => {
  const allRequiredKeys = new Set<keyof T>()
  const defaultValuesOrNull: Nullable<T> = Object.assign({}, defaultValuesOrForbidden as Nullable<T>)
  for(const [key, value] of Object.entries(defaultValuesOrForbidden)) {
    if(value === NotNullOrUndefined) {
      allRequiredKeys.add(key as keyof T)
      // Default value of is still kept as null to absolutely guarantee code using Record.Instance will never get NotNullOrUndefined
      defaultValuesOrNull[key as keyof T] = null
    }
  }

  function verify(this: Record.Instance<T>) {
    for(const requiredKey of allRequiredKeys) {
      const value = this.get(requiredKey)
      if(value === null || value === undefined) {
        const message = `The field ${requiredKey} must be provided${ name ? ` in the ${name} records` : ""}`
        if(throwOnNotNullOrUndefinedViolations) {
          throw new Error(message)
        } else {
          console.warn(message)
        }
      }
    }
  }

  const RecordClass = Record<any>(defaultValuesOrNull, name)

  const DefaultEnforcingRecord = function DefaultEnforcingRecord(this: Record.Instance<T>, values?) {
    if(values instanceof DefaultEnforcingRecord) {
      return values
    }
    if(!(this instanceof DefaultEnforcingRecord)) {
      return new (DefaultEnforcingRecord as any)(values)
    }
    const realRecord = RecordClass.call(this, values)
    if(realRecord !== undefined) {
      verify.call(realRecord)
      return realRecord
    } else {
      verify.call(this)
    }
  }

  DefaultEnforcingRecord.prototype = Object.create(RecordClass.prototype)

  return DefaultEnforcingRecord as any
}