import { Attribute } from './Attribute'

export type AttributeValues = {
  [attributeClientId: string]: number | string | boolean | null
}

export type AttributeValue = {
  ideaClientId: string
  attributeClientId: string
  value: number | string | boolean | null
}

const EMPTY_VALUE_FOR_NOT_SET_ATTRIBUTE = ''

export const AttributeValueHelpers = {
  hasAttributeFilled (attribute: string | Attribute, attributeValues: AttributeValues) {
    const value = AttributeValueHelpers.getAttributeValue(attribute, attributeValues)
    return value !== undefined && value !== null
  },

  // TODO: add explicit typing for attribute - should be either AttributeClientId or Attribute
  getAttributeValue (attribute: string | Attribute, attributeValues: AttributeValues) {
    const attributeClientId = typeof attribute === 'object' ? attribute.clientId : attribute
    return (attributeValues || {})[attributeClientId]
  },

  getFilledAttributeValue (attribute: string | Attribute, attributeValues: AttributeValues) {
    return AttributeValueHelpers.hasAttributeFilled(attribute, attributeValues)
      ? AttributeValueHelpers.getAttributeValue(attribute, attributeValues)
      : EMPTY_VALUE_FOR_NOT_SET_ATTRIBUTE
  },
}