import * as React from 'react'

import classnames from 'classnames'

import styles from './DeleteItemButton.styl'
import Icon from 'components/common/Icon'

import { EventHelpers } from 'helpers/Events'

interface DeleteItemButtonProps {
  onDelete: () => void
  confirmMessage?: React.ReactNode
  tooltip?: string
  className?: string
}

interface DeleteItemButtonState {
  isConfirming: boolean
}

export default class DeleteItemButton extends React.Component<DeleteItemButtonProps, DeleteItemButtonState> {
  constructor(props) {
    super(props)
    this.state = {
      isConfirming: false
    }
  }

  render() {
    if (this.state.isConfirming) {
      return <div
          className={styles.buttonWrapper}
          onClick={EventHelpers.stopPropagationPreventDefault}
        >
        <span className={styles.confirmMessage}>
          {this.props.confirmMessage}
        </span>
        <a className={styles.linkOption} onClick={this.handleYes}>Yes</a>
        <a className={styles.linkOption} onClick={this.handleNo}>No</a>
      </div>
    }

    return (
      <Icon
        fontAwesomeIcon='remove'
        tooltip={this.props.tooltip || 'Delete'}
        className={classnames(styles.deleteButton, this.props.className)}
        onClick={this.handleXClick}
      />
    )
  }

  private handleXClick = () => {
    if (this.props.confirmMessage) {
      this.setState({ isConfirming: true })
      return;
    }
    this.handleYes()
  }

  private handleYes = () => {
    this.props.onDelete()
    this.setState({ isConfirming: false })
  }

  private handleNo = () => {
    this.setState({ isConfirming: false })
  }
}
