import * as memoize from 'shared/helpers/memoize'
import { Map, List, Record } from 'immutable'

import {getIdeaSelectionState} from './_state'

import * as GraphViewSelectors from '../GraphViewSelector'
import * as IdeaSelectors from 'domain/Idea/selectors'
import * as ConnectionSelectors from 'domain/Connection/selectors'

import { AppStateRecord } from 'appRoot/state'
import { ConnectionRecord } from 'shared/models/Connection'
import { IdeaSelectionState } from 'components/graph-view/ideaSelection/common'

import * as EvImm from 'evolving-immutable'
import {getInGraphView} from 'components/graph-view/GraphViewState'

class LabelConnectionRecord extends Record({
  labelText: '',
  connectionList: List<ConnectionRecord>()
}) {}

// TODO: ConnectionList is expected to actually be set in the old impl. Fix that (rename or replace)
const groupConnectionsByLabel = EvImm.startChain()
  .memoizeForValue()
  .addGroupStep/*<string, ConnectionRecord, string>*/((connection: ConnectionRecord) => connection.get('labelText'))
  .addMapStep(EvImm.toSet())
  .addMapStep((connectionsSet, labelText) =>
    new LabelConnectionRecord({ labelText, connectionList: connectionsSet })
  )
  .addToSetStep()
  // .addStep(EvImm.toList())
  .endChain()

const _getGroupedRelatedConnectionsOfSelectedIdea: (state: AppStateRecord, selectedIdeaClientId: string) => Map<string,LabelConnectionRecord> = EvImm.startChain({
  logTimeline: true,
  name: '_getGroupedRelatedConnectionsOfSelectedIdea'
})
  .memoizeForValue()
  .addStep((state: AppStateRecord, selectedIdeaClientId: string) => {
    return selectedIdeaClientId
      ? ConnectionSelectors.getIdeaConnections(state, selectedIdeaClientId)
      : Map()
  })
  .memoizeForValue()
  .addStep(groupConnectionsByLabel)
  .endChain()


const getLabelGroupedRelatedIdeaConnectionPairs = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord, selectedIdeaClientId: string) => {
    return {
      selectedIdeaClientId,
      groupedConnectionsAndLabels: _getGroupedRelatedConnectionsOfSelectedIdea(state, selectedIdeaClientId),
      ideaByClientId: IdeaSelectors.getAllInCurrentBoard(state),
    }
  },
  calculateResult: ({selectedIdeaClientId, groupedConnectionsAndLabels, ideaByClientId }) => {
    const outgoingRelatedIdeas = {}
    const incomingRelatedIdeas = {}

    groupedConnectionsAndLabels.forEach((labelAndConnections) => {
      const labelText = labelAndConnections.get('labelText')
      const connections = labelAndConnections.get('connectionList')
      connections.forEach(connection => {
        const sourceIdeaClientId = connection.get('sourceIdeaClientId')
        const targetIdeaClientId = connection.get('targetIdeaClientId')
        const sourceIdea  = ideaByClientId.get(sourceIdeaClientId)
        const targetIdea  = ideaByClientId.get(targetIdeaClientId)

        if (!sourceIdea) return
        if (!targetIdea) return

        // omit self edges
        if (sourceIdeaClientId === targetIdeaClientId) return

        if (sourceIdea.get('clientId') === selectedIdeaClientId) {
          if (!outgoingRelatedIdeas[labelText]) {
            outgoingRelatedIdeas[labelText] = []
          }
          outgoingRelatedIdeas[labelText].push(targetIdea.toJS())
        } else {
          if (!incomingRelatedIdeas[labelText]) {
            incomingRelatedIdeas[labelText] = []
          }
          incomingRelatedIdeas[labelText].push(sourceIdea.toJS())
        }
      })
    })

    return {
      outgoingRelatedIdeas,
      incomingRelatedIdeas,
    }
  }
})

function getSelectedIdeaInstanceIds (state: AppStateRecord) {
  const {ideaInstanceIds} = _getRawSelectedIdeaInstanceData(state)
  return ideaInstanceIds
}

function getSelectedIdeaClientIds (state: AppStateRecord) {
  return getSelectedIdeaInstanceIds(state).map(
    id => GraphViewSelectors.getIdeaClientIdByIdeaInstanceId(state, id)
  ).filter(Boolean)
}

function _getHoveredIdeaInstanceId (state: AppStateRecord) {
  const rawIdeaInstanceId = getInGraphView(state, 'hoveredIdeaInstanceId')

  return GraphViewSelectors.actualizeIdeaInstanceId(state, rawIdeaInstanceId)
}

function getHoveredIdeaClientId (state: AppStateRecord) {
  return GraphViewSelectors.getIdeaClientIdByIdeaInstanceId(state, _getHoveredIdeaInstanceId(state))
}

const GraphViewIdeaSelectionSelectors = {
  getSelectionInfo: memoize.memoizeReturnedObject(
    _getRawSelectedIdeaInstanceData
  ),

  getSelectedIdeaInstanceIds,

  getSelectedIdeaClientIds,

  getHoveredIdeaClientId,

  getLabelGroupedRelatedIdeaConnectionPairs
}

export {GraphViewIdeaSelectionSelectors}

function _getRawSelectedIdeaInstanceData(state: AppStateRecord): IdeaSelectionState {
  const rawData = getIdeaSelectionState(state)

  return {
    ...rawData,
    ideaInstanceIds: rawData.ideaInstanceIds.map(
      id => GraphViewSelectors.actualizeIdeaInstanceId(state, id)
    ).filter(Boolean) as string[]
  }
}
