import fetch from 'isomorphic-fetch'
import { AuthActions } from 'auth/actions'

import config from 'config/appConfig'

interface APICallConfiguration {
  path: string,
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE'
  data?: object | FormData,
  apiPath?: string,
  streamResponse?: boolean
  returnTextInsteadOfJSON?: boolean
}

const apiCall = async (configuration: APICallConfiguration) => {
  const {
    path,
    method='GET',
    data: incomingData = {},
    apiPath=config.apiPath,
    streamResponse,
    returnTextInsteadOfJSON,
  } = configuration

  // TODO: remove this - apiCall should receive only plain JS objects,
  // dealing with higher-level data should be done in a centralized manner somewhere on a higher level
  // (like common logic for managing domain data)
  const anyData = incomingData as any

  const ensuredJsData = anyData.toJS
    ? anyData.toJS()
    : anyData

  const isFormData = ensuredJsData instanceof FormData

  const stringifiedData = isFormData ? ensuredJsData : JSON.stringify(ensuredJsData)

  const response = await fetch(`${apiPath}${path}`, {
    method,
    ...((method!='GET') ? {body: stringifiedData} : {}),
    credentials: 'include',
    headers: _getHeaders({ dontSetContentType: isFormData }),
  })

  if (streamResponse) {
    const reader = response.body.getReader()
    async function* asyncIterator() {
      while(true) {
        const { done, value } = await reader.read()
        if (done) break
        yield value
      }
    }
    return asyncIterator()
  }

  if (response.ok) {
    if (returnTextInsteadOfJSON) {
      return await response.text()
    } else {
      return await response.json()
    }
  } else if (response.status === 500) {
    throw (await response.json())
  } else {
    throw new Error(`${response.status} — ${path}`)
  }
}

export default apiCall

const _getHeaders = ({ dontSetContentType }) => {
  const authToken = AuthActions.getAuthToken()
  return {
    // we use AuthActions directly for simplicity
    // if more agility is needed, set AuthTokenProvider from outside instead
    ...(authToken !== null ? { Authorization: 'Bearer ' + authToken } : {}),
    ...(dontSetContentType ? {} : {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  }
}
