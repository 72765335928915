import * as React from 'react'

import icons from '../../media/icons.styl'

import styles from './LoadingScreen.styl'

import { AppStateRecord } from 'appRoot/state'

import * as AppActions from 'appRoot/actions'
import { ErrorActions } from 'managers/error/actions'
import * as ErrorSelectors from 'managers/error/selectors'
import * as BoardSelectors from 'domain/Board/selectors'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import Icon from 'components/common/Icon'
import classnames from 'classnames'

type LoadingScreenProps = {
  isLoading: boolean
  mostRecentErrors: any[]
} & BoundActionGroups<typeof actionGroups>

class LoadingScreen extends React.Component<LoadingScreenProps> {

  render() {
    if(!this.props.isLoading) {
      return null
    }
    return <div className={styles.loading}>
      <div>
        <div className={classnames(styles.loadingSpinner,icons.timer)} />
        <Icon
          fontAwesomeIcon="window-close"
          className={styles.close}
          onClick={this.props.AppActions.unsetLoading}
        />
        Loading...
        <br />
        { this.props.mostRecentErrors.length > 0
            ? <button onClick={this.props.ErrorActions.showRecentErrorsOverlay}>
                Show errors ({this.props.mostRecentErrors.length})
              </button>
            : null
        }
      </div>
    </div>
  }
}

const mapStateToProps = (state: AppStateRecord) => ({
  isLoading: BoardSelectors.isLoading(state),
  mostRecentErrors: ErrorSelectors.getMostRecentErrors(state),
})

const actionGroups = {
  ErrorActions,
  AppActions
}

export default ComponentHelpers.createConnectedComponent({
  actionGroups,
  mapStateToProps,
  component: LoadingScreen,
})