import React from 'react'

export class GraphIcon extends React.Component<{width: number, height: number}> {
    render() {
      return (
        <svg width={this.props.width} height={this.props.height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.7354 11.2092L8.70435 8.03794L10.3885 6.95929L12.4196 10.1305L10.7354 11.2092Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M15.8124 19.1372L13.7814 15.966L15.4656 14.8873L17.4966 18.0586L15.8124 19.1372Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M14.9216 10.7637L18.0929 8.73268L19.1084 10.3183L15.9372 12.3493L14.9216 10.7637Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M6.99354 15.8415L10.1648 13.8104L11.1803 15.3961L8.00905 17.4271L6.99354 15.8415Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M6.51457 5.97301C7.01239 6.7503 8.04607 6.97686 8.82336 6.47904C9.60065 5.98122 9.82721 4.94754 9.32939 4.17025C8.83158 3.39296 7.7979 3.1664 7.02061 3.66422C6.24332 4.16204 6.01676 5.19572 6.51457 5.97301ZM4.83038 7.05165C5.92391 8.7591 8.19456 9.25677 9.90201 8.16324C11.6095 7.0697 12.1071 4.79906 11.0136 3.09161C9.92006 1.38416 7.64941 0.886486 5.94196 1.98002C4.23451 3.07356 3.73684 5.3442 4.83038 7.05165Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.6703 21.8298C17.1682 22.607 18.2018 22.8336 18.9791 22.3358C19.7564 21.838 19.983 20.8043 19.4852 20.027C18.9873 19.2497 17.9537 19.0232 17.1764 19.521C16.3991 20.0188 16.1725 21.0525 16.6703 21.8298ZM14.9861 22.9084C16.0797 24.6159 18.3503 25.1135 20.0578 24.02C21.7652 22.9265 22.2629 20.6558 21.1694 18.9484C20.0758 17.2409 17.8052 16.7432 16.0977 17.8368C14.3903 18.9303 13.8926 21.201 14.9861 22.9084Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M11.5925 13.9017C12.0903 14.679 13.1239 14.9056 13.9012 14.4078C14.6785 13.9099 14.9051 12.8763 14.4073 12.099C13.9095 11.3217 12.8758 11.0951 12.0985 11.5929C11.3212 12.0907 11.0946 13.1244 11.5925 13.9017ZM9.90826 14.9804C11.0018 16.6878 13.2724 17.1855 14.9799 16.0919C16.6873 14.9984 17.185 12.7278 16.0915 11.0203C14.9979 9.31287 12.7273 8.8152 11.0198 9.90873C9.31239 11.0023 8.81472 13.2729 9.90826 14.9804Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M20.0263 6.515C19.249 7.01282 19.0224 8.0465 19.5202 8.82379C20.018 9.60108 21.0517 9.82764 21.829 9.32982C22.6063 8.832 22.8329 7.79832 22.335 7.02103C21.8372 6.24374 20.8036 6.01718 20.0263 6.515ZM18.9476 4.8308C17.2402 5.92434 16.7425 8.19498 17.836 9.90243C18.9296 11.6099 21.2002 12.1076 22.9077 11.014C24.6151 9.92048 25.1128 7.64984 24.0192 5.94239C22.9257 4.23494 20.6551 3.73727 18.9476 4.8308Z" fill="#212529"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M4.1703 16.6699C3.39301 17.1677 3.16645 18.2014 3.66427 18.9787C4.16209 19.756 5.19577 19.9825 5.97306 19.4847C6.75035 18.9869 6.97691 17.9532 6.47909 17.1759C5.98127 16.3987 4.9476 16.1721 4.1703 16.6699ZM3.09166 14.9857C1.38421 16.0792 0.886536 18.3499 1.98007 20.0573C3.07361 21.7648 5.34425 22.2625 7.0517 21.1689C8.75915 20.0754 9.25682 17.8047 8.16329 16.0973C7.06975 14.3898 4.79911 13.8922 3.09166 14.9857Z" fill="#212529"/>
        </svg>
      )
    }
  }