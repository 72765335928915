import immutable from 'immutable'

const emptySet = immutable.Set()

const addToMultiIndex = (mapToSet,[id,element]) => {
  return mapToSet.update(id, emptySet, (oldSet) => oldSet.add(element));
}

const addManyToMultiIndex = <V>(mapToSet: immutable.Map<string, immutable.Set<V>>, idElementPairs: [string,V][]) => {
  /*
  let newMapToSet = mapToSet;
  idElementPairs.forEach((idElementPair) => {
    newMapToSet = addToMultiIndex(newMapToSet,idElementPair);
  });
  return newMapToSet;
  */
  const idToNewElements: { [key: string]: immutable.Set<V> } = {};
  idElementPairs.forEach(([id,element]) => {
    if(idToNewElements[id] === undefined) {
      idToNewElements[id] = mapToSet.get(id) || emptySet;
    };
    idToNewElements[id] = idToNewElements[id].add(element);
  })

  return mapToSet.merge(
    idToNewElements
  );
}

const GraphPrototype = {
  getVertex(id) {
    return this.structure.getIn(['vertexById',id]);
  },
  getAllVertices() {
    return this.structure.get('vertexById').valueSeq().toArray();
  },
  forEachVertex(op) {
    this.structure.get('vertexById').forEach((vertex) => {
      op(vertex);
    });
  },
  getEdge(id) {
    return this.structure.getIn(['edgeById',id]);
  },
  getAllEdges() {
    return this.structure.get('edgeById').valueSeq().toArray();
  },
  forEachEdge(op) {
    this.structure.get('edgeById').forEach((edge) => {
      op(edge);
    });
  },
  getOutEdges(id) {
    return this.structure.get('edgesBySourceId').get(id,emptySet);
  },
  getNeighboursVertices(id) {
    return this.getOutEdges(id)
      .map((edge) =>
        this.getVertex(edge.targetId == id ? edge.sourceId : edge.targetId)
      )
      .filter(vertex => vertex !== undefined)
  },
  getInEdges(id) {
    return this.structure.get('edgesByTargetId').get(id,emptySet);
  },
  addVertex(vertex) {
    const newStructure = this.structure
      .update('vertexById', (vertexById) => vertexById.set(vertex.id, vertex))
    return wrapBareGraph(newStructure);
  },
  deleteVertex(id) {
    const vertex = this.getVertex(id);
    if(!vertex) throw new Error('No vertex with id ' + id);
    const edgesToRemove = emptySet
      .union(this.structure.getIn(['edgesBySourceId',id],emptySet))
      .union(this.structure.getIn(['edgesByTargetId',id],emptySet));

    let newStructure = this.structure;
    newStructure = newStructure
      .update('vertexById', (vertexById) => vertexById.delete(id))
      .update('edgesBySourceId', (edgesBySourceId) =>
        edgesBySourceId.delete(id)
      )
      .update('edgesByTargetId', (edgesByTargetId) =>
        edgesByTargetId.delete(id)
      )
      .update('edgesBySourceId', (edgesBySourceId) => {
        edgesToRemove.forEach((edge: any) => {
          edgesBySourceId = edgesBySourceId
            .update(edge.sourceId, emptySet, (edgeSet) => edgeSet.delete(edge))
            .update(edge.targetId, emptySet, (edgeSet) => edgeSet.delete(edge));
        })
        return edgesBySourceId;
      })
      .update('edgesByTargetId', (edgesByTargetId) => {
        edgesToRemove.forEach((edge: any) => {
          edgesByTargetId = edgesByTargetId
            .update(edge.sourceId, emptySet, (edgeSet) => edgeSet.delete(edge))
            .update(edge.targetId, emptySet, (edgeSet) => edgeSet.delete(edge));
        })
        return edgesByTargetId;
      })
      .update('edgeById', (edgeById) => {
        edgesToRemove.forEach((edge: any) => {
          edgeById = edgeById.delete(edge.id);
        })
        return edgeById;
      })
    return wrapBareGraph(newStructure);
  },
  addManyVertices(vertices) {
    let newStructure = this.structure;
    newStructure = newStructure
      .update('vertexById', (vertexById) => vertexById.merge(
        immutable.Map(vertices.map((vertex) => [vertex.id, vertex]))
      ))
    return wrapBareGraph(newStructure);
  },
  transformVertex(id,transform) {
    const oldVertex = this.getVertex(id);
    if(oldVertex === undefined) return this;
    const newVertex = transform(oldVertex);
    if(newVertex === undefined) return this;

    let newStructure = this.structure;
    newStructure = newStructure
      .update('vertexById', (vertexById) => vertexById.set(newVertex.id, newVertex))
    return wrapBareGraph(newStructure);
  },
  transformAllVertices(transform) {
    let newStructure = this.structure;
    newStructure = newStructure
      .update('vertexById', (vertexById) => vertexById.map(transform))
    return wrapBareGraph(newStructure);
  },
  addEdge(edge) {
    let newStructure = this.structure;
    newStructure = newStructure
      .update('edgeById', (edgeById) => edgeById.set(edge.id, edge))
      .update('edgesBySourceId', (edgesBySourceId) =>
        addToMultiIndex(edgesBySourceId,[edge.sourceId,edge])
      )
      .update('edgesByTargetId', (edgesByTargetId) =>
        addToMultiIndex(edgesByTargetId,[edge.targetId,edge])
      )
    if(!edge.directed) {
      newStructure = newStructure
        .update('edgesBySourceId', (edgesBySourceId) =>
          addToMultiIndex(edgesBySourceId,[edge.targetId,edge])
        )
        .update('edgesByTargetId', (edgesByTargetId) =>
          addToMultiIndex(edgesByTargetId,[edge.sourceId,edge])
        )
    }
    return wrapBareGraph(newStructure);
  },
  addManyEdges(edges) {
    const undirectedEdges = edges
      .filter((edge) => !edge.directed)

    let newStructure = this.structure;
    newStructure = newStructure
      .update('edgeById', (edgeById) => edgeById.merge(
        immutable.Map(edges.map((edge) => [edge.id, edge]))
      ))
      .update('edgesBySourceId', (edgesBySourceId) => {
        const bySourceId = edges.map((edge) => [edge.sourceId,edge])
        const byTargetIdUndirected = undirectedEdges
          .map((edge) => [edge.targetId,edge])

        return addManyToMultiIndex(
          edgesBySourceId,
          bySourceId.concat(byTargetIdUndirected)
        )
      })
      .update('edgesByTargetId', (edgesByTargetId) => {
        const byTargetId = edges.map((edge) => [edge.targetId,edge])
        const bySourceIdUndirected = undirectedEdges
          .map((edge) => [edge.sourceId,edge])

        return addManyToMultiIndex(
          edgesByTargetId,
          byTargetId.concat(bySourceIdUndirected)
        )
      })
    return wrapBareGraph(newStructure);
  },
  transformEdge(id,transform) {
    const oldEdge = this.getEdge(id);
    const newEdge = transform(oldEdge);
    if(newEdge === undefined) return this;

    let newStructure = this.structure;
    newStructure = newStructure
      .update('edgeById', (edgeById) => edgeById.set(newEdge.id, newEdge))
    return wrapBareGraph(newStructure);
  },
  transformAllEdges(transform) {
    let newStructure = this.structure;
    newStructure = newStructure
      .update('edgeById', (edgeById) => edgeById.map(transform))
    newStructure = newStructure
      .update('edgesBySourceId', (edgesBySourceId) => edgesBySourceId.map(
        (edges) => edges.map((edge) => newStructure.getIn(['edgeById', edge.id]))
      ))
      .update('edgesByTargetId', (edgesByTargetId) => edgesByTargetId.map(
        (edges) => edges.map((edge) => newStructure.getIn(['edgeById', edge.id]))
      ))
    return wrapBareGraph(newStructure);
  },
  serialize() {
    return {
      vertices: this.getAllVertices(),
      edges: this.getAllEdges()
    };
  }
}

const wrapBareGraph = (structure) => {
  return Object.assign(
      Object.create(GraphPrototype), { structure }
    )
}

const emptyGraph = wrapBareGraph(immutable.Map({
  vertexById: immutable.Map(),
  edgeById: immutable.Map(),
  edgesBySourceId: immutable.Map(),
  edgesByTargetId: immutable.Map()
}))

const loadGraph = (serializedGraph) => {
  return emptyGraph
    .addManyVertices(serializedGraph.vertices)
    .addManyEdges(serializedGraph.edges);
}

export default {
  empty: emptyGraph,
  load: loadGraph
}
