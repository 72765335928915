import * as React from 'react'
import {connect} from 'react-redux'

import * as Immutable from 'immutable'
import {AttributeSelectors} from 'domain/Attribute/selectors'
import SelectSingle from 'components/common/SelectSingle'

import { Attribute } from 'shared/models/Attribute'

interface AttributePickerExternalProps {
  value: string
  onChange: (string) => void
}

class AttributePicker extends React.Component<{
  // TODO: add and use Attribute type here (define in src/domain/Attribute (maybe extend smth from 'shared')
  allAttributes: Immutable.Map<string, Attribute>
} & AttributePickerExternalProps, {}> {
  render() {
    const currentAttributeClientId = this.props.value
    const currentAttribute = this.props.allAttributes.get(currentAttributeClientId)
    return <SelectSingle
      selectedOption={{
        value: currentAttributeClientId,
        contents: currentAttribute ? currentAttribute.name : '<Not set>'
      }}
      options={this._getAttributeOptions()}
      onChange={this.props.onChange}
    />
  }

  _getAttributeOptions () {
    return this.props.allAttributes.valueSeq().toArray().map((attribute) => {
      return {
        value: attribute.clientId,
        contents: attribute.name
      }
    })
  }
}

const AttributePickerContainer = connect(
  (state, _ownProps: AttributePickerExternalProps) => {
    return {
      allAttributes: AttributeSelectors.getAllSortedByName(state as any)
    }
  },

  (_dispatch, _ownProps) => {
    return {}
  }
)(AttributePicker)

export default AttributePickerContainer
