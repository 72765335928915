import * as React from 'react'
import classNames from 'classnames'
import Color from 'color'

import styles from './ColorBox.styl'

import { ColorRecord } from 'shared/models/Color'

interface ColorSquareProps {
  color: ColorRecord
  isSelected?: boolean
  isNotSelectable?: boolean
  isUsed?: boolean

  onKeyUp?: (event: React.KeyboardEvent<any>) => void
  selectColor?: (newSelectionState: boolean, color: ColorRecord) => void
}

class ColorSquare extends React.Component<ColorSquareProps, { isHovered: boolean }> {
  constructor(props, context) {
    super(props, context)
    this.state = {isHovered: false}
  }

  render() {
    const { color, isNotSelectable, isSelected, isUsed } = this.props;
    const { isHovered } = this.state;

    const backgroundColor = Color('#' + color.backgroundColor)
    const borderColor = (!isNotSelectable && (isHovered || isSelected))
      ? backgroundColor.isLight() ? backgroundColor.darken(0.6) : backgroundColor.lighten(0.6)
      : backgroundColor

    let borderStyle
    if (isNotSelectable) {
      borderStyle = "solid"
    } else if (isHovered) {
      borderStyle = "dashed"
    } else if (isSelected) {
      borderStyle = "solid"
    } else {
      borderStyle = "solid"
    }

    return (
      <div
        className={classNames({
          [styles.square] : true,
          [styles.isUsed] : isUsed,
          [styles.isNotSelectable] : isNotSelectable
        })}
        style={{
          borderStyle,
          borderColor: borderColor.string(),
          backgroundColor: backgroundColor.string()
        }}
        onKeyUp={ this.handleKeyUp }
        onMouseOver={ this.handleMouseOver }
        onMouseLeave={ this.handleMouseLeave }
        onClick={ this.handleOnClick }
      />
    )
  }

  private handleMouseOver = () => {
    !this.state.isHovered && this.setState({isHovered: true})
  }

  private handleMouseLeave = () => {
    this.state.isHovered && this.setState({isHovered: false})
  }

  private handleKeyUp = (e) => {
    this.props.onKeyUp && this.props.onKeyUp(e)
  }

  private handleOnClick = () => {
    const {color, isSelected} = this.props

    const newSelectionState = !isSelected
    this.props.selectColor && this.props.selectColor(newSelectionState, color)
  }
}

export default ColorSquare
