import { IdeaInstanceRecord } from 'shared/models/IdeaInstance'
import Rectangle, { RectangleType, isEmptyRectangle, intersectRectangles } from 'shared/helpers/Rectangle'
import Point, { PointClass } from 'shared/helpers/Point'

import { GraphVertexConstants } from 'shared/models/graph/GraphVertexConstants'

interface ClientCoordsBelongToDomElementConfig {
  element: Element
  clientX: number
  clientY: number
}

type GetNextInstanceFn = (
  instances: Array<IdeaInstanceRecord>,
  current: IdeaInstanceRecord
) => IdeaInstanceRecord | null

function getCenterPointOfInstance(instance: IdeaInstanceRecord) {
  return Point.fromXY(instance.simulationPosition)
}

function getRectangleOfInstance(instance: IdeaInstanceRecord) {
  const center = Point.fromXY(instance.simulationPosition)
  const halfDiagonal = Point(
    GraphVertexConstants.vertexWidth,
    GraphVertexConstants.vertexHeight
  ).mult(0.5)
  return Rectangle.rectangleFromPoints(
    center.add(halfDiagonal),
    center.sub(halfDiagonal),
  )
}

function getDistanceSquare(instanceA: IdeaInstanceRecord, instanceB: IdeaInstanceRecord) {
  const pointA = getCenterPointOfInstance(instanceA)
  const pointB = getCenterPointOfInstance(instanceB)
  return pointA.distSquare(pointB)
}

function _getNextInstance(
  instances: Array<IdeaInstanceRecord>,
  current: IdeaInstanceRecord,
  filterFn: (difference: PointClass) => boolean,
  nextFn: GetNextInstanceFn
) {
  if (instances.length <= 0) return null

  if (instances.length === 1) return instances[0]

  const filteredInstances = instances.filter(instance => {
    const difference = getCenterPointOfInstance(instance).sub(getCenterPointOfInstance(current))
    return filterFn(difference)
  })
  if (filteredInstances.length > 0) {
    const [nearest] = filteredInstances.reduce(
      ([nearest, distance], instance) => {
        if (!nearest) {
          return [instance, getDistanceSquare(instance, current)]
        } else {
          const newDistance = getDistanceSquare(instance, current)
          return newDistance < distance ? [instance, newDistance] : [nearest, distance]
        }
      }, [null, Infinity]
    )
    return nearest
  } else {
    return nextFn(instances, current);
  }
}

/**
 * This function determines whether the point lies in the horizontal cone
 * determined by the y = x and y = -x lines. The said cone look like this:
 * +++++ \   |   / ++++++
 * ++++++ \  |  / +++++++
 * +++++++ \ | / ++++++++
 * ++++++++ \|/ +++++++++
 * ——————————————————————
 * ++++++++ /|\ +++++++++
 * +++++++ / | \ ++++++++
 * ++++++ /  |  \ +++++++
 * +++++ /   |   \ ++++++
 *
 */
// function xDominant(point: PointClass) {
//   return Math.abs(point.x) > Math.abs(point.y)
// }

export function getLeftInstance(
  instances: Array<IdeaInstanceRecord>,
  current: IdeaInstanceRecord
) {
  return _getNextInstance(
    instances,
    current,
    difference => difference.x < 0,// && xDominant(difference),
    getTopInstance
  )
}

export function getTopInstance(
  instances: Array<IdeaInstanceRecord>,
  current: IdeaInstanceRecord
) {
  return _getNextInstance(
    instances,
    current,
    difference => difference.y < 0,// && !xDominant(difference),
    getRightInstance
  )
}

export function getRightInstance(
  instances: Array<IdeaInstanceRecord>,
  current: IdeaInstanceRecord
) {
  return _getNextInstance(
    instances,
    current,
    difference => difference.x > 0,// && xDominant(difference),
    getBottomInstance
  )
}

export function getBottomInstance(
  instances: Array<IdeaInstanceRecord>,
  current: IdeaInstanceRecord
) {
  return _getNextInstance(
    instances,
    current,
    difference => difference.y > 0,// && !xDominant(difference),
    getLeftInstance
  )
}

export function clientCoordsBelongToDomElement ({element, clientX, clientY}: ClientCoordsBelongToDomElementConfig) {
  const elementClientRect = element.getBoundingClientRect()

  const xInside = elementClientRect.left <= clientX && clientX <= elementClientRect.right
  const yInside = elementClientRect.top <= clientY && clientY <= elementClientRect.bottom

  return xInside && yInside
}

export function getInstancesInsideRectangle(
  instances: IdeaInstanceRecord[],
  rectangle: RectangleType
) {
  return instances
    .filter(instance => {
      const intersection = intersectRectangles(rectangle, getRectangleOfInstance(instance))
      return intersection && !isEmptyRectangle(intersection)
    })
}
