import * as React from 'react'

import {Input} from 'components/common/Forms'

type SelectValue = string | number

interface Option {
  value: SelectValue,
  contents: React.ReactChild
}
//TODO: maybe base on AutocompleteSearchBar instead
class SelectSingle extends React.Component<{
  selectedOption: Option
  options: Option[]
  onChange: (value: SelectValue) => void
}, {}> {
  render() {
    const currentValue = this.props.selectedOption.value

    const optionsToRender = this.props.options
    const currentOptionExistsAmongGivenOptions = optionsToRender.some((optionData) => optionData.value === currentValue)

    // Force show current value as the first item if it's not present in the options list
    // Typically needed for the case whe no value is selected
    if (!currentOptionExistsAmongGivenOptions) {
      optionsToRender.unshift({
        value: currentValue,
        contents: this.props.selectedOption.contents
      })
    }
    const renderedOptions = this.props.options.map((optionData, optionIndex) => <option
      key={optionIndex}
      value={optionData.value}
    >
      {optionData.contents}
    </option>)

    return <Input
      type="select"
      value={currentValue}
      onChange={(event) => {
        const newValue = event.target.value
        if (newValue !== currentValue) {
          this.props.onChange(newValue)
        }
      }}
    >
      {renderedOptions}
    </Input>  }
}

export default SelectSingle
