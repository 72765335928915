import apiCall from 'helpers/apiCall'

import pick from 'lodash/pick'
import keyBy from 'lodash/keyBy'

import GenericProfile, { GenericProfileRecord, ProfileBoardMembershipSource, GenericProfileDefaultValues } from 'shared/models/ProfileBoardMembership'

import { pushAllObjects } from 'domain/GenericActions'

import { UserProfileSelectors } from 'domain/UserProfile/selectors'
import { ProfileBoardMembershipSelectors } from './selectors'

const genericProfileProps = Object.keys(GenericProfileDefaultValues)

const addProfileBoardMembershipForUser = (boardClientId: string, genericProfileData: GenericProfileRecord) => async (dispatch, getState) => {
  const profileBoardMembership: ProfileBoardMembershipSource = await apiCall({
    path: "/profileBoardMemberships/create",
    method: "POST",
    data: {
      boardClientId,
      userId: genericProfileData.userId,
    }
  })

  if(profileBoardMembership !== null) {
    const userProfile = UserProfileSelectors.getById(getState(), profileBoardMembership.userId)
    const genericProfile = GenericProfile({
      ...pick(userProfile, genericProfileProps) as GenericProfileRecord,
      clientId: profileBoardMembership.clientId,
      type: "userProfile" as "userProfile",
      isDeleted: profileBoardMembership.isDeleted,
      created_at: profileBoardMembership.created_at,
    })
    dispatch(pushAllObjects({ peopleInCurrentBoard: [genericProfile] }))
  } else {
    throw new Error("Creating failed")
  }
}

const addProfileBoardMembershipForUnclaimedProfile = (boardClientId: string, genericProfileData: GenericProfileRecord) => async (dispatch) => {
  const { unclaimedProfile, profileBoardMembership } = await apiCall({
    path: "/profileBoardMemberships/unclaimedProfiles/create",
    method: "POST",
    data: {
      boardClientId,
      unclaimedProfileData: pick(genericProfileData, ['name', 'email', 'bio', 'website']),
      clientId: genericProfileData.clientId,
    }
  })

  if(unclaimedProfile !== null) {
    dispatch(pushAllObjects({
      peopleInCurrentBoard: [
        CreateGenericProfileFromUnclaimedProfile(unclaimedProfile, profileBoardMembership)
      ]
    }))
  } else {
    throw new Error("Creating failed")
  }
}

const CreateGenericProfileFromUnclaimedProfile = (unclaimedProfile, profileBoardMembership) => {
  return GenericProfile({
    ...unclaimedProfile,
    clientId: profileBoardMembership.clientId,
    unclaimedProfileClientId: profileBoardMembership.unclaimedProfileClientId,
    type: "unclaimedProfile" as "unclaimedProfile",
    isDeleted: profileBoardMembership.isDeleted,
  })
}

const ProfileBoardMembershipActions = {
  getPeopleForBoard: (boardClientId) => async (dispatch, getState) => {
    const {
      contributors,
      profileBoardMemberships,
      unclaimedProfiles
    } = await apiCall({
      path: `/profileBoardMemberships/board/${boardClientId}/people`,
      method: 'GET',
    })

    const contributorGenericProfiles = (contributors as { userId: number, created_at: string }[])
      .map(contributor => {
        const contributorUserProfile = UserProfileSelectors.getById(getState(), contributor.userId)
        return GenericProfile({
          ...pick(contributorUserProfile, genericProfileProps) as GenericProfileRecord,
          clientId: contributorUserProfile.username,
          type: "userProfile" as "userProfile",
          created_at: contributor.created_at,
          isContributor: true,
        })
      })
      .filter(contributor => (contributor.email || contributor.name || contributor.bio || contributor.website))

    const existingUserMemberGenericProfiles = (profileBoardMemberships as ProfileBoardMembershipSource[])
      .filter(profileBoardMembership => profileBoardMembership.userId !== null)
      .map(profileBoardMembership => {
        const userProfile = UserProfileSelectors.getById(getState(), profileBoardMembership.userId)
        return GenericProfile({
          ...pick(userProfile, genericProfileProps) as GenericProfileRecord,
          clientId: profileBoardMembership.clientId,
          type: "userProfile" as "userProfile",
          isDeleted: profileBoardMembership.isDeleted,
          created_at: profileBoardMembership.created_at
        })
      })

    const unclaimedProfilesByClientId = keyBy(unclaimedProfiles, "clientId")

    const unclaimedGenericProfiles = (profileBoardMemberships as ProfileBoardMembershipSource[])
      .filter(profileBoardMembership => profileBoardMembership.unclaimedProfileClientId !== null)
      .map(profileBoardMembership => {
        const unclaimedProfile = unclaimedProfilesByClientId[profileBoardMembership.unclaimedProfileClientId]
        return CreateGenericProfileFromUnclaimedProfile(unclaimedProfile, profileBoardMembership)
      })

    const peopleInCurrentBoard = [
      ...contributorGenericProfiles,
      ...existingUserMemberGenericProfiles,
      ...unclaimedGenericProfiles
    ]

    dispatch(pushAllObjects({ peopleInCurrentBoard }))
  },

  addProfileBoardMembership: (boardClientId: string, genericProfileData: GenericProfileRecord) => (dispatch) => {
    if (genericProfileData.type === "userProfile") {
      dispatch(addProfileBoardMembershipForUser(boardClientId, genericProfileData))
    } else {
      dispatch(addProfileBoardMembershipForUnclaimedProfile(boardClientId, genericProfileData))
    }
  },

  deleteProfileBoardMembership: (genericProfileData) => async (dispatch) => {
    const deletedProfile = await apiCall({
      path: `/profileBoardMemberships/delete/${genericProfileData.clientId}`,
      method: "DELETE",
    })

    if(deletedProfile !== null) {
      dispatch(pushAllObjects({ peopleInCurrentBoard: [ deletedProfile ] }))
    } else {
      throw new Error("Deleting failed")
    }
  },

  updateUnclaimedProfile: (clientId, unclaimedProfileClientId, genericProfileData) => async (dispatch, getState) => {
    const { unclaimedProfile } = await apiCall({
      path: `/profileBoardMemberships/unclaimedProfiles/update/${unclaimedProfileClientId}`,
      method: "PATCH",
      data:  pick(genericProfileData, ['name', 'email', 'bio', 'website']),
    })

    const profileBoardMembership = ProfileBoardMembershipSelectors.getAll(getState())
      .find(genericProfile => genericProfile.clientId === clientId)

    if(unclaimedProfile !== null && profileBoardMembership !== undefined) {
      dispatch(pushAllObjects({
        peopleInCurrentBoard: [
          CreateGenericProfileFromUnclaimedProfile(unclaimedProfile, profileBoardMembership)
        ]
      }))
    } else {
      throw new Error("Update failed")
    }
  },

  replaceUnclaimedProfileWithUserProfile: (clientId, userId) => async (dispatch, getState) => {
    await apiCall({
      path: `/profileBoardMemberships/unclaimedProfiles/replaceWithUserProfile/${clientId}`,
      method: "PATCH",
      data:  { userId },
    })

    const profileBoardMembership = ProfileBoardMembershipSelectors.getAll(getState())
      .find(genericProfile => genericProfile.clientId === clientId)

    if(profileBoardMembership !== undefined) {
      const userProfile = UserProfileSelectors.getById(getState(), profileBoardMembership.userId)
      dispatch(pushAllObjects({
        peopleInCurrentBoard: [
          GenericProfile({
            ...pick(userProfile, genericProfileProps) as GenericProfileRecord,
            clientId: profileBoardMembership.clientId,
            type: "userProfile" as "userProfile",
            isDeleted: profileBoardMembership.isDeleted,
            created_at: profileBoardMembership.created_at.toString()
          })
        ]
      }))
    } else {
      throw new Error("Update failed")
    }
  },
}

export default ProfileBoardMembershipActions

