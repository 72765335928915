import * as React from "react"
import { GenericProfileRecord } from "shared/models/ProfileBoardMembership"
import styles from "./UserTableRow.styl"
import UserProfileSearchBar from "components/common/searchBars/UserProfileSearchBar"
import classnames from "classnames"
import Icon from "components/common/Icon"
import UniversalTextInput from "components/common/UniversalTextInput"
import DeleteItemButton from "components/common/DeleteItemButton"

import Avatar from "components/common/Avatar"

import withMobileDetector from 'components/common/MobileDetector'
import { KeyboardHelpers } from "helpers/Keyboard";
import * as BrowserHelpers from "helpers/browser";

interface EditableUserProfile {
  name: string;
  website: string;
  email: string;
  bio: string;
}

interface Column {
  label: string;
  propName: keyof EditableUserProfile;
  isAutocomplete: boolean;
}

interface UserTableRowProps {
  genericProfile: GenericProfileRecord;
  columns: Column[];
  onAddUser: (userData: any, autofocusCell?) => void;
  onDeleteUser: (userData: any) => void;
  onUpdateUser: (userData: any) => void;
  replaceUnclaimedProfileWithUserProfile: (
    clientId: string,
    userId: number
  ) => void;
  autofocusCell?: keyof EditableUserProfile;
  scrollToProfileBoardMember?: (clientId) => void;
  navigateToUserProfile?: (userId) => void;
  isCreateNew?: boolean;
  isEditable?: boolean;
}

interface State {
  editedColumns: EditableUserProfile;
  highlightedAutocompleteSuggestion: any;
  autocompleteSuggestionProp: string | null;
  isBioExpanded: boolean;
}

class UserTableRow extends React.Component<UserTableRowProps & { isOnMobile: boolean }, State> {
  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  private MAX_CHARS = 200;

  getStateFromProps(props) {
    return {
      editedColumns: {
        name: props.genericProfile.name,
        website: props.genericProfile.website,
        email:
          props.isEditable || props.genericProfile.isEmailPublic
            ? props.genericProfile.email
            : "Private",
        bio: props.genericProfile.bio
      },
      highlightedAutocompleteSuggestion: null,
      autocompleteSuggestionProp: null,
      isBioExpanded: false
    };
  }

  handleBlur = () => {
    if (
      this.state.highlightedAutocompleteSuggestion < 0 ||
      this.state.highlightedAutocompleteSuggestion === null
    ) {
      this.saveChanges();
    }

    this.setState({
      highlightedAutocompleteSuggestion: null,
      autocompleteSuggestionProp: null
    });
  };

  private setAutocompleteSuggestionPreview(suggestion, propName) {
    this.setState({
      highlightedAutocompleteSuggestion: suggestion || null,
      autocompleteSuggestionProp: suggestion ? propName : null
    });
  }

  private handleCellClick(
    event: React.MouseEvent<any>,
    propName: keyof EditableUserProfile
  ) {
    if (this.props.isCreateNew) {
      this.props.onAddUser(this.props.genericProfile, propName);
    } else if (KeyboardHelpers.isCtrlOrMacCmd(event)) {
      if (propName === "website") {
        const fullUrl = /https?:\/\//.test(this.state.editedColumns.website)
          ? this.state.editedColumns.website
          : "http://" + this.state.editedColumns.website;
        window.open(fullUrl, "_blank");
      } else if (
        propName === "name" &&
        this.props.genericProfile.userId !== null
      ) {
        this.props.navigateToUserProfile &&
          this.props.navigateToUserProfile(this.props.genericProfile.userId)
      }
    } else if (
      this.props.isEditable &&
      propName === "bio" &&
      !this.state.isBioExpanded
    ) {
      this.setState({ isBioExpanded: true })
    }
  }

  private isBlank() {
    return (
      !this.state.editedColumns.name &&
      !this.state.editedColumns.email &&
      !this.state.editedColumns.bio &&
      !this.state.editedColumns.website
    );
  }

  private getToolTip(propName: string): string | undefined {
    if (
      propName === "email" &&
      !this.props.genericProfile.isEmailPublic &&
      this.props.isEditable
    ) {
      return "Only you can see your email. \nYou can make it public on your profile page.";
    } else if (
      propName === "website" ||
      (propName === "name" && this.props.genericProfile.userId !== null)
    ) {
      const modifier = BrowserHelpers.isMac() ? 'cmd' : 'ctrl'
      return `Use ${modifier}+click to follow link`;
    }
    return undefined;
  }

  private onBackspaceOrDelete = () => {
    if (!this.isBlank()) return;
    this.props.onDeleteUser(this.props.genericProfile);
  };

  render() {
    return <div
      className={classnames(styles.userTableRow, !this.props.isEditable && styles.editDisabled)}
      onBlur={ this.handleBlur }
    >
      { this.renderProfileIcon() }
      { this.props.columns.map(column => this.renderCell(column)) }
      { this.renderRowIcons() }
    </div>
  }

  private renderCell({ propName, isAutocomplete }) {
    const canHaveHyperlink =
      propName === "website" ||
      (propName === "name" && this.props.genericProfile.userId !== null);
    const isPrivateField =
      propName === "email" && !this.props.genericProfile.isEmailPublic;

    const text = this.state.highlightedAutocompleteSuggestion
      ? this.state.highlightedAutocompleteSuggestion.userProfile[propName]
      : (this.state.editedColumns[propName] || '')

    return (
      <div
        className={classnames(
          styles.userTableCell,
          !this.props.isEditable && styles.editDisabled,
          (canHaveHyperlink && text !== "") && styles.hyperlink,
          isPrivateField && !this.props.isEditable && styles.privateField,
          this.state.highlightedAutocompleteSuggestion && styles.preview,
          styles[propName + "Area"]
        )}
        key={propName}
        onClick={event => this.handleCellClick(event, propName)}
        title={this.getToolTip(propName)}
      >
        { !isAutocomplete
            ? this.renderPlainCell(propName, text)
            : this.state.highlightedAutocompleteSuggestion !== null && this.state.autocompleteSuggestionProp !== propName
              ? this.renderPlainCell(propName, text)
              : this.renderAutocompleteCell(propName)
        }
      </div>
    );
  }

  private renderPlainCell(propName: keyof EditableUserProfile, text: string) {
    const includeShowMore = propName === 'bio' && !this.state.highlightedAutocompleteSuggestion && text.length > this.MAX_CHARS

    return <>
      <UniversalTextInput
        className={styles[propName + "PlainCell"]}
        placeholder={this.props.isOnMobile ? propName : undefined}
        text={includeShowMore && !this.state.isBioExpanded ? text.substr(0, 200) + "..." : text}
        multiline={true}
        disabled={!this.props.isEditable || (includeShowMore && !this.state.isBioExpanded)}
        allowDefaultActions={['tab', 'up', 'down']}
        onChange={this.setEditedColumnValue(propName)}
        autofocus={ this.props.autofocusCell && this.props.autofocusCell === propName }
        onDelete={this.onBackspaceOrDelete}
        onBackspace={this.onBackspaceOrDelete}
      />
      { includeShowMore && <div className={styles.showMoreLink} onClick = {() => this.setState({ isBioExpanded: !this.state.isBioExpanded })}>
          { this.state.isBioExpanded ? 'show less' : 'show more' }
      </div>}
    </>
  }

  private renderAutocompleteCell(propName: keyof EditableUserProfile) {
    return (
      <UserProfileSearchBar
        onUserProfileSuggestionSelected={
          this.handleAutocompleteSuggestionSelection
        }
        maxNumberOfResultsToDisplay={5}
        placeholder={this.props.isCreateNew && propName === 'name' ? '+ Add yourself' : (this.props.isOnMobile ? propName : undefined)}
        omitInputStyling={true}
        bare={true}
        searchField={propName}
        allowDefaultActions={['tab', 'up', 'down']}
        onHighlightedSuggestionChange={suggestion => this.setAutocompleteSuggestionPreview(suggestion, propName)}
        onMouseOut={(_event) => this.setAutocompleteSuggestionPreview(null, null)}
        initialValue={this.state.highlightedAutocompleteSuggestion ? this.state.highlightedAutocompleteSuggestion.userProfile[propName] : (this.state.editedColumns[propName] || '')}
        disabled={!this.props.isEditable}
        useInlineDropdown={false}
        hasNoInitialSelection={true}
        onQueryChange={this.setEditedColumnValue(propName)}
        autofocus={
          this.props.autofocusCell && this.props.autofocusCell === propName
        }
        onDelete={this.onBackspaceOrDelete}
        onBackspace={this.onBackspaceOrDelete}
      />
    );
  }

  private setEditedColumnValue = <T extends keyof EditableUserProfile>(
    propName: T
  ) => (newValue: EditableUserProfile[T]) => {
    this.setState({
      editedColumns: { ...this.state.editedColumns, [propName]: newValue }
    });
  };

  private renderProfileIcon = () => {
    if (!this.props.genericProfile.userId) {
      return <Avatar
        className={styles.avatarIcon}
        href={null}
      />
    }

    return (
      <Avatar
        className={styles.avatarIcon}
        href={this.props.genericProfile.avatar}
        onClick={() =>
          this.props.navigateToUserProfile &&
          this.props.navigateToUserProfile(this.props.genericProfile.userId)
        }
        tooltip={"Go to " + this.props.genericProfile.name + "'s profile"}
      />
    );
  };

  private renderRowIcons = () => {
    return <div className={classnames(styles.rowIcons)}>
      { this.props.isEditable ? null : <Icon className={styles.rowIcon} fontAwesomeIcon='lock' tooltip="You don't have permisison to edit this profile"/> }
      { !this.props.isCreateNew && this.props.genericProfile.isContributor
          ? <Icon
            className={classnames(styles.rowIcon, styles.deleteIcon)}
            stackedIconClassName={classnames(styles.rowIcon, styles.deleteIcon, 'text-danger')}
            fontAwesomeIcon='remove'
            stackedIcon='ban'
            tooltip="Can't delete contributor"
          />
          : !this.props.isCreateNew ? this.renderDeleteButton() : null
      }
    </div>
  }

  private renderDeleteButton = () => {
    if (this.isBlank()) {
      return (
        <Icon
          fontAwesomeIcon="remove"
          tooltip="Delete"
          className={classnames(styles.rowIcon, styles.deleteIcon)}
          onClick={() => this.props.onDeleteUser(this.props.genericProfile)}
        />
      );
    }

    const person =
      this.props.genericProfile.name ||
      this.props.genericProfile.email ||
      "this person";
    const deleteMessage = `Are you sure you want to remove ${person} from this board?`;

    return (
      <DeleteItemButton
        className={classnames(styles.rowIcon, styles.deleteIcon)}
        onDelete={() => {
          this.props.onDeleteUser(this.props.genericProfile);
        }}
        confirmMessage={deleteMessage}
      />
    );
  };

  private handleAutocompleteSuggestionSelection = async userSuggestion => {
    if (!userSuggestion.userProfile) return;

    if (userSuggestion.currentBoardMembershipClientId === null) {
      this.setState(
        this.getStateFromProps({
          genericProfile: userSuggestion.userProfile
        })
      );
      await this.props.replaceUnclaimedProfileWithUserProfile(
        this.props.genericProfile.clientId,
        userSuggestion.userId
      );
    } else {
      this.props.scrollToProfileBoardMember &&
        this.props.scrollToProfileBoardMember(
          userSuggestion.currentBoardMembershipClientId
        );
    }
  };

  async saveChanges() {
    const hasChanges = this.props.columns
      .map(column => column.propName)
      .some(
        propName =>
          this.state.editedColumns[propName] !==
          this.props.genericProfile[propName]
      );
    if (!hasChanges) return;

    this.props.onUpdateUser({
      ...this.props.genericProfile.toObject(),
      ...this.state.editedColumns
    });
  }
}

export type UserTableRowInstance = UserTableRow

export default withMobileDetector<UserTableRowProps>(UserTableRow)
