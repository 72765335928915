import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

import { generateClientId } from 'shared/helpers/Id'

const IdeaLikeDefaultValues = {
  clientId: NotNullOrUndefined,
  ideaClientId: NotNullOrUndefined,
  userId: NotNullOrUndefined,
  isDeleted: false,
  createdAt: NotNullOrUndefined,
  updatedAt: NotNullOrUndefined,
  type: "ideaLike" as "ideaLike"
}

type IdeaLikeRawType = {
  clientId: string,
  ideaClientId: string,
  userId: number,
  isDeleted: boolean,
  createdAt: Date,
  updatedAt: Date,
  type: "ideaLike"
}

type IdeaLikeSourceObjectType = Partial<IdeaLikeRawType> & {
  created_at?: string,
  updated_at?: string,
}

class IdeaLikeRecord extends CreateRecordClass<IdeaLikeRawType>(IdeaLikeDefaultValues) {}

type IdeaLikeRecordT = IdeaLikeRecord
export { IdeaLikeRecordT as IdeaLikeRecord }

const ideaLikeAttributesOverwriteObject = { clientId: '', status: '', createdAt: new Date(), updatedAt: new Date() }
const createIdeaLike = (stub: IdeaLikeSourceObjectType) => {
  ideaLikeAttributesOverwriteObject.clientId = stub.clientId || generateClientId()
  ideaLikeAttributesOverwriteObject.createdAt = new Date(stub.createdAt || stub.created_at || Date.now() as any)
  ideaLikeAttributesOverwriteObject.updatedAt = new Date(stub.updatedAt || stub.updated_at || Date.now() as any)
  return new IdeaLikeRecord(Object.assign({}, stub, ideaLikeAttributesOverwriteObject))
}

const IdeaLike = Object.assign(
  (stub: IdeaLikeSourceObjectType | IdeaLikeRecord = {}): IdeaLikeRecord => {
    if(!IdeaLike.isIdeaLike(stub)) {
      stub = stub as IdeaLikeSourceObjectType
      return createIdeaLike(stub)
    }
    return new IdeaLikeRecord(stub)
  },
  {
    isIdeaLike: (obj): obj is IdeaLikeRecord => (obj instanceof IdeaLikeRecord),
    createIdeaLike,
  }
)

export default IdeaLike
