import * as React from 'react'
import { Map } from 'immutable'

import { ErrorHelpers } from 'shared/helpers/Error'

import LayoutShell from '../layout/LayoutShell'

import Node from './Node'
import styles from './BoardsList.styl'

import { BoardSummary } from 'shared/models/BoardSummary'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import { BoardActions } from 'domain/Board/actions'
import * as BoardSelectors from 'domain/Board/selectors'

const actionGroups = {
  BoardActions
}

interface StateProps {
  boardSummariesByRepresentedIdeaClientId: Map<string, BoardSummary>
}

export type BoardShapedObject = (BoardSummary & {childBoardClientIds: string[]})

class BoardsList extends React.Component<StateProps & BoundActionGroups<typeof actionGroups>> {
  componentDidMount() {
    this.props.BoardActions.refetchAllBoardSummaries()
  }

  private getBoards = () => {
    const sortedBoards = this.props.boardSummariesByRepresentedIdeaClientId.valueSeq().toArray()
      .sort((boardA, boardB) => (new Date(boardA.createdAt)).getTime() - (new Date(boardB.createdAt)).getTime())

    return sortedBoards
      .map(thisBoard => ({
        ...thisBoard,
        childBoardClientIds:
          sortedBoards
            .filter((board: BoardSummary) => board.parentBoardClientId === thisBoard.clientId && !board.isDeleted)
            .map(board => board.clientId)
      }))
  }

  private getRootLevelBoards = () =>
    this.getBoards().filter(board => !board.parentBoardClientId)

  private createGetBoardByClientId = () => {
    const boards = this.getBoards()
    return (clientId: string) => {
      return ErrorHelpers.castToNotNullOrThrow(
        boards.find(board => board.clientId === clientId) || null,
        "getBoardByClientId can only be called with a clientId of an existing board"
      )
    }
  }

  render() {
    const rootLevelBoards = this.getRootLevelBoards()
    const getBoardByClientId = this.createGetBoardByClientId()
    return (
      <LayoutShell className={styles.boardsList}>
        <div className={styles.boardsListBody}>
          <div className={styles.title}>
            All Boards
          </div>
          {rootLevelBoards.map(board =>
            <Node
              key={board.clientId}
              board={board}
              getBoardByClientId={getBoardByClientId}
              expanded
            />
          )}
        </div>
      </LayoutShell>
    )
  }
}

export default ComponentHelpers.createConnectedComponent<{}, StateProps, typeof actionGroups>({
  actionGroups,
  mapStateToProps: (state) => {
    return {
      boardSummariesByRepresentedIdeaClientId: BoardSelectors.getAllBoardSummariesByRepresentedIdeaClientId(state)
    }
  },
  component: BoardsList,
})
