import { Reducer } from 'appRoot/rootReducer';

export interface AiSearchState {
  attributeId: string | null;
  query: string;
  wasQueried: boolean;
  isLoading: boolean;
  newIdeaClientIds: string[];
  loadedIdeaClientIds: string[];
  loadedConnectionClientIds: string[];
  aiResponse: string;
  createdNodeClientIds: string[];
  addNodesInQueryTermToGraph: boolean;
  relevancyMap: Record<string, {label: string, ideaClientId: string}[]>,
  unconfirmedClientIds: string[]
}

export const UPDATE_STATE = 'aiSearch/UPDATE_STATE';

export default function aiSearchReducer(reducer: Reducer) {
  reducer.handle(UPDATE_STATE, (state, action) => {
    return state.update('aiSearch', aiSearch => ({
      ...aiSearch,
      ...action.payload
    }))
  })
} 