type CollapseDirection = true | false | 'DONT_COLLAPSE'

const COLLAPSE_RANGE_TO_START: CollapseDirection = true
const COLLAPSE_RANGE_TO_END: CollapseDirection = false
const DONT_COLLAPSE: CollapseDirection = 'DONT_COLLAPSE'

type EditableElement = HTMLElement
type ContentEditableElement = HTMLElement

function getInnerText(el: any) {
  return el.innerText || el
}

/**
 * Given an index for text inside a contentEditable, find the childNode that contains
 * that index. If an offset is right between two nodes, we return info regarding the
 * first one.
 */
function findNodePositionForOffset(
  editableElement: ContentEditableElement,
  offset: number,
) {
  if (editableElement.childNodes.length === 0) {
    return { index: -1, nodeOffset: -1 }
  }

  let offsetLeft = offset
  let i
  for (i = 0; i < editableElement.childNodes.length; ++i) {
    const nodeText = getInnerText(editableElement.childNodes[i])
    if (offsetLeft <= nodeText.length) {
      // Our offset falls inside this node.
      return { index: i, nodeOffset: offsetLeft }
    }
    offsetLeft -= nodeText.length
  }

  // Our offset is greater than all the content.
  const lastNode = editableElement.childNodes.item(editableElement.childNodes.length - 1)

  return {
    index: i - 1,
    nodeOffset: getInnerText(lastNode).length,
  }
}

/**
 * Given a selection in a contentEditable, find the anchor's index within the
 * contentEditable's inner text.
 */
function findOffsetForContentEditableSelection(
  editableElement: ContentEditableElement,
  selection: Selection,
): number {
  if (editableElement.childNodes.length === 0) {
    return 0
  }

  let contentOffset = 0
  for (var i = 0; i < editableElement.childNodes.length; ++i) {
    // TODO explain this
    if (selection.anchorNode === editableElement.childNodes[i]
    || editableElement.childNodes[i].contains(selection.anchorNode)) {
      return contentOffset + selection.anchorOffset
    }

    const item = editableElement.childNodes[i]
    contentOffset += getInnerText(item).length
  }

  throw new Error('selection.anchorNode not found inside editableElement')
}

function setCaretToOffsetInContentEditable(
  editableElement: ContentEditableElement,
  offset: number,
) {
  const { index, nodeOffset } = findNodePositionForOffset(editableElement, offset)
  if (index === -1) {
    // Argument to setCaretToOffsetInContentEditable is empty. Call focus instead.
    editableElement.focus()
    return
  }

  const range = document.createRange()
  range.collapse(true)
  try {
    range.setStart(editableElement.childNodes[index], nodeOffset)
  } catch (e) {
    console.warn(editableElement.childNodes, nodeOffset)
    throw e
  }
  var selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
}


// Create a range within an element, and set the window selection to that range.
function _selectRangeInElement(editableElement: EditableElement, collapseDirection: CollapseDirection) {
  const range = document.createRange()
  range.selectNodeContents(editableElement)

  if (collapseDirection !== DONT_COLLAPSE) {
    range.collapse(collapseDirection as boolean)
  }

  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
}

function _setCaretInElement(editableElement: EditableElement, caretOffset: number) {
  const editableElementTextNode = editableElement.childNodes.item(0) as Text
  const selection = window.getSelection()

  if (!editableElementTextNode || !selection) return
  const offset = Math.min(caretOffset, editableElementTextNode.length)

  selection.removeAllRanges()

  const range = document.createRange()

  range.setStart(editableElementTextNode, offset)
  range.setEnd(editableElementTextNode, offset)

  range.collapse(false)
  selection.addRange(range)
}

const FocusAndSelectionHelpers = {
  isElementFocused(element: EditableElement) {
    return document.activeElement === element
  },

  elementHasFocusWithin(editableElement: EditableElement) {
    return editableElement.contains(document.activeElement)
  },

  ensureElementIsFocused(editableElement: EditableElement) {
    const shouldFocus = !FocusAndSelectionHelpers.isElementFocused(editableElement)

    if (!shouldFocus) return

    editableElement.focus()

    const selection = window.getSelection()
    if (!selection || !editableElement.contains(selection.focusNode)) {
      FocusAndSelectionHelpers.moveCaretToEnd(editableElement)
    }
  },

  moveCaretToEnd(editableElement: EditableElement) {
    _selectRangeInElement(editableElement, COLLAPSE_RANGE_TO_END)
  },

  moveCaretToStart(editableElement: EditableElement) {
    _selectRangeInElement(editableElement, COLLAPSE_RANGE_TO_START)
  },

  selectTextInElement(editableElement: EditableElement) {
    _selectRangeInElement(editableElement, DONT_COLLAPSE)
  },

  setCaretInElement(editableElement: EditableElement, caretOffset: number) {
    _setCaretInElement(editableElement, caretOffset)
  },

  //

  getCaretAtStartOrEnd(editableElement: EditableElement) {
    let offset: number | null
    if (editableElement instanceof HTMLInputElement
      || editableElement instanceof HTMLTextAreaElement) {
      // Handle <input />, <textarea /> etc
      // Will return false if editableElement isn't text/number
      offset = editableElement.selectionEnd
    } else {
      // Handle contentEditable
      const selection = window.getSelection()
      if (!selection || !editableElement.contains(selection.anchorNode)) {
        return null
      }
      offset = findOffsetForContentEditableSelection(editableElement, selection)
    }

    return {
      atStart: offset === 0,
      atEnd: offset === editableElement.innerText.length,
    }
  },

  getCaretPosition(editableElement: EditableElement) {
    let offset: number | null
    if (editableElement instanceof HTMLInputElement
      || editableElement instanceof HTMLTextAreaElement) {
      // Handle <input />, <textarea /> etc
      // Will return false if editableElement isn't text/number
      offset = editableElement.selectionEnd
    } else {
      // Handle contentEditable
      const selection = window.getSelection()
      if (!selection || !editableElement.contains(selection.anchorNode)) {
        return null
      }
      offset = findOffsetForContentEditableSelection(editableElement, selection)
    }

    const linesTillHere = editableElement.innerText.slice(0, offset).split('\n')
    const precedingLines = linesTillHere.slice(0, linesTillHere.length - 1)

    const sumOfPrecedingLines = precedingLines
      .map(line => line.length + 1) // Add one for newline character.
      .reduce((a, b) => a + b, 0)

    return {
      offset: offset,
      line: precedingLines.length,
      lineOffset: offset - sumOfPrecedingLines,
      currentLine: linesTillHere[linesTillHere.length - 1],
      numLines: (editableElement.innerText.match(/\n/) || []).length,
    }
  },

  setCaretPosition(editableElement: EditableElement, line: number, lineOffset?: number): void {
    const lines = editableElement.innerText.split('\n')
    const offset = lines
      .slice(0, line)
      .map(line => line.length)
      .reduce((a, b) => a + b + 1, 0) + (lineOffset || 0)

    if (editableElement instanceof HTMLInputElement
      || editableElement instanceof HTMLTextAreaElement) {
      // Handle <input />, <textarea /> etc
      throw new Error('Not implemented.')
    } else {
      // Handle contentEditable
      const selection = window.getSelection()
      if (!selection || !editableElement.contains(selection.anchorNode)) {
        return
      }

      setCaretToOffsetInContentEditable(editableElement, offset)
    }
  },

  //

  isCaretAtStartOfElement(editableElement: EditableElement): boolean {
    // Handle <input />, <textarea /> etc
    if (editableElement instanceof HTMLInputElement
    || editableElement instanceof HTMLTextAreaElement) {
      // Will return false if editableElement isn't text/number
      return editableElement.selectionEnd === 0
    }

    // Handle contentEditable
    const selection = window.getSelection()
    if (!selection || !editableElement.contains(selection.anchorNode)) {
      return false
    }

    if (selection.anchorNode === editableElement.childNodes.item(0)) {
      return selection.anchorOffset === 0
    }
    return false
  },

  isCaretAtEndOfElement(editableElement: EditableElement): boolean {
    // Handle <input />, <textarea /> etc
    if (editableElement instanceof HTMLInputElement
    || editableElement instanceof HTMLTextAreaElement) {
      // Will return false if editableElement isn't text/number
      return editableElement.selectionEnd === editableElement.value.length
    }

    // Handle contentEditable
    const selection = window.getSelection()
    if (!selection || !editableElement.contains(selection.anchorNode)) {
      return false
    }

    const { childNodes } = editableElement
    const lastNode = childNodes[childNodes.length - 1] as Text

    if (selection.anchorNode === lastNode) {
      return selection.anchorOffset === lastNode.length
    }
    return false
  },
}

export default FocusAndSelectionHelpers
