import * as React from 'react'

interface ImageWithFallbackProps {
  imageSrc: string
  className?: string
  fallbackChildren: React.ReactNode
}

export default class ImageWithFallback extends React.Component<ImageWithFallbackProps, { hasError: boolean }> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  render() {
    return <div className={this.props.className}>
      { this.state.hasError
          ? this.props.fallbackChildren
          : <img
              onError={this.handleError}
              src={this.props.imageSrc}
            />
      }
    </div>
  }

  private handleError = () => this.setState({"hasError": true})
}
