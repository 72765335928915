import { Record } from 'immutable'

import { IMPORT_PROCESS_STATE_GLOBAL_STATE_KEY } from './constants'

const initialImportProcessState = Record({
  isImportProcessOngoing: false,
  schemaFile: null as File | null,
  dataFile: null as File | null,
  importProgressData: null,
})({})

export type ImportProcessState = typeof initialImportProcessState

const initialState = {
  [IMPORT_PROCESS_STATE_GLOBAL_STATE_KEY]: initialImportProcessState,
}

export default initialState