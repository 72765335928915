import * as React from 'react'
import classnames from 'classnames'
import Header from './Header'
import HeaderMobile from './Header/HeaderMobile'

import CheckInternetConnection from './CheckInternet'
import LoadingScreen from './LoadingScreen'

import { MobileDetector } from 'components/common/MobileDetector'
//import TopBanner from './TopBanner'

import styles from './LayoutShell.styl'
import TopBanner from "components/layout/TopBanner";
import {XCircleIcon, XIcon} from "lucide-react";

interface LayoutShellProps {
  topSearch?: React.ReactElement<any> | null
  className?: string
  hideNavigation?: boolean
  hideAuth?: boolean
  ignoreLoading?: boolean
}

type TopBarProps = {};

type TopBarState = {
    show: boolean;
};


class TopBar extends React.Component<TopBarProps, TopBarState> {

    constructor(props: Readonly<TopBarProps>) {
        super(props);
        this.state = {
            show: this.localStorageState.get(),
        };
    }

     localStorageState = {
        get: () => (localStorage.getItem("showBanner") || "true") === "true",
        set: (value: boolean) => localStorage.setItem("showBanner", `${value}`)
    }

    hideBar = () => {
        this.localStorageState.set(false),
        this.setState({
            show: false
        })
    }

    render() {
        if(!this.state.show) return <></>
        return (  <div className={styles.topBanner}>
            <span>Ideapad, a product of <a href="https://ideaflow.io" target="_blank">ideaflow.io</a></span>
            <XIcon onClick={this.hideBar} size={18}/>
        </div>)
    }
}

export default class LayoutShell extends React.Component<LayoutShellProps> {
  private renderHeader = (isOnMobile: boolean) => {
    return isOnMobile
      ? <HeaderMobile hideAuth={this.props.hideAuth} />
      : <Header
          search={this.props.topSearch}
          hideNavigation={this.props.hideNavigation}
          hideAuth={this.props.hideAuth}
        />
  }

  render() {
    return (
      <div className={classnames(styles.outer, this.props.className)}>
          <TopBar/>
        {/* <TopBanner storedUIStateKey="TopBanner" messageKey={"newIdeaPad_11_17"}>
          Welcome to the new version of IdeaFlow. You can see the old version at &nbsp;
          <a href="https://old.ideapad.io" target="_blank">old.ideapad.io</a>
        </TopBanner> */}
        <div className={styles.headerWrapper}>
          <MobileDetector>
            {this.renderHeader}
          </MobileDetector>
        </div>
        <div className={styles.inner}>
          { (!this.props.ignoreLoading) && <LoadingScreen /> }
          <CheckInternetConnection />
          <div className={styles.content}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}