import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

import { generateClientId } from 'shared/helpers/Id'

const CommentDefaultValues = {
  clientId: NotNullOrUndefined,
  ideaClientId: NotNullOrUndefined,
  userId: NotNullOrUndefined,
  comment: '',
  type: "comment" as "comment",
  isDeleted: false,
  createdAt: NotNullOrUndefined,
  updatedAt: NotNullOrUndefined,
  likeCount: 0,
}

type CommentRawType = {
  clientId: string
  ideaClientId: string
  userId: number
  comment: string
  type: "comment"
  isDeleted: boolean
  createdAt: Date
  updatedAt: Date
  likeCount: number
}

export type CommentSourceObjectType = Partial<CommentRawType> & {
  created_at?: string,
  updated_at?: string,
}

class CommentRecord extends CreateRecordClass<CommentRawType>(CommentDefaultValues, "Comment") {}

type CommentRecordT = CommentRecord
export { CommentRecordT as CommentRecord }

const commentAttributesOverwriteObject = { clientId: '', status: '', createdAt: new Date(), updatedAt: new Date() }
const createComment = (stub: CommentSourceObjectType) => {
  commentAttributesOverwriteObject.clientId = stub.clientId || generateClientId()
  commentAttributesOverwriteObject.createdAt = new Date(stub.createdAt || stub.created_at || Date.now() as any)
  commentAttributesOverwriteObject.updatedAt = new Date(stub.updatedAt || stub.updated_at || Date.now() as any)
  return new CommentRecord(Object.assign({}, stub, commentAttributesOverwriteObject))
}

const Comment = Object.assign(
  (stub: CommentSourceObjectType | CommentRecord = {}): CommentRecord => {
    if(!Comment.isComment(stub)) {
      stub = stub as CommentSourceObjectType
      return createComment(stub)
    }
    return new CommentRecord(stub)
  },
  {
    isComment: (obj): obj is CommentRecord => (obj instanceof CommentRecord),
    createComment,
  }
)

export default Comment
