import React, {useEffect, useState} from 'react';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";


type AggregateItem = {
    attributeClientId: string;
    availableInIdeas: number;
    attributeValueTypes: {
        string: number,
        stringConvertibleToNumber: number,
        number: number,
    };
    values: { [attributeValue: string]: number; }
};

type AttributeName = string;

type Aggregate = Record<AttributeName, AggregateItem>;

function AttributeList(props: {boardClientId: string}) {
    const slug = props.boardClientId

    const [detailsVisible, setDetailsVisible] = useState<Record<string, boolean>>({});
    const [isProcessingAttribute, setIsProcessingAttribute] = useState(false);
    const [refetchKey, setRefetchKey] = useState(0);

    const toggleDetails = (attribute: string) => {
        setDetailsVisible((prev) => ({
            ...prev,
            [attribute]: !prev[attribute]
        }));
    };

    const [aggregate, setAggregate] = useState<Aggregate | null>(null);

    useEffect(() => {
        const response = fetch("/api/v1/attributes/" + slug);
        response.then(async (response) => {
            if(response.ok){
                setAggregate((await response.json()) as Aggregate)
            }
        })
    }, [refetchKey])


    if(aggregate === null){
        return <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%,-50%)"}}>
            <img src={"https://i.imgur.com/QoQLJyu.gif"}/>
        </div>
    }

    return (
        <div>
            <button onClick={() => downloadAllAttributes(aggregate, slug)} style={{ cursor: "pointer", backgroundColor: "#dee2e7",
                width: "fit-content", outline: 0, border: 0,
                height: "fit-content", padding: "5px",
                margin: "5px", marginBottom: "15px"
            }}>
                Download All Data
            </button>
            <button onClick={() => copyAttributesToClipboard(aggregate)} style={{ cursor: "pointer", backgroundColor: "#dee2e7",
                width: "fit-content", outline: 0, border: 0,
                height: "fit-content", padding: "5px",
                margin: "5px", marginBottom: "15px"
            }}>
                Copy Attributes
            </button>
            {Object.entries(aggregate).map(([attribute, details]) => (
                <div key={attribute} style={{ margin: "10px" }}>
                    <span>Name: <strong>{attribute}</strong></span>
                    <span style={{ marginLeft: "10px" }}>[{details.availableInIdeas} Ideas Available] ({details.attributeValueTypes.string} strings, {details.attributeValueTypes.stringConvertibleToNumber} strings convertible to numbers, {details.attributeValueTypes.number} numbers)</span>

                     <div style={{display: "flex", flexDirection: "row", gap: "5px"}}>
                         <button
                             onClick={() => toggleDetails(attribute)}
                             style={{ cursor: "pointer", backgroundColor: "#dee2e7",
                                 width: "fit-content", outline: 0, border: 0,
                                 height: "fit-content", padding: "5px"
                             }}
                         >
                             Show Details
                         </button>
                         <button
                             onClick={() => downloadSingleAttribute(attribute, details)}
                             style={{ cursor: "pointer", backgroundColor: "#dee2e7",
                                 width: "fit-content", outline: 0, border: 0,
                                 height: "fit-content", padding: "5px"
                             }}
                         >
                             Download `{attribute}`
                         </button>
                         {details.attributeValueTypes.stringConvertibleToNumber > 0 && <button
                            disabled={isProcessingAttribute}
                            onClick={async () => {
                                setIsProcessingAttribute(true);
                                await fetch("/api/v1/attributes/convertToNumber/" + slug + "/" + details.attributeClientId)
                                setIsProcessingAttribute(false)
                                setRefetchKey(k => k+1)
                            }}
                            style={{ cursor: "pointer", backgroundColor: "#dee2e7",
                                width: "fit-content", outline: 0, border: 0,
                                height: "fit-content", padding: "5px"
                            }}
                         >
                            Convert strings to numbers (delete incompatible)
                        </button>}
                     </div>
                    {detailsVisible[attribute] && (
                        <table cellPadding="5" style={{ width: "100%", borderCollapse: "collapse", marginTop: "5px" }}>
                            <thead>
                            <tr>
                                <th>Attribute Value</th>
                                <th>Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.entries(details.values)
                                .map(([value, count]) => (
                                    <tr key={value}>
                                        <td>{value}</td>
                                        <td>{count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                </div>
            ))}
        </div>
    );
}

const downloadSingleAttribute = (attribute: string, details: AggregateItem) => {
    const rows = Object.entries(details)
        .filter(([key]) => key !== "availableInIdeas")
        .map(([value, count]) => ({ "Attribute Value": value, Count: count }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, attribute);

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(blob, `${attribute}.xlsx`);
};

// 📌 Utility function to download all attributes as a multi-sheet Excel file
const downloadAllAttributes = (data: Aggregate, name: string) => {
    const workbook = XLSX.utils.book_new();

    Object.entries(data).forEach(([attribute, details]) => {
        const rows = Object.entries(details)
            .filter(([key]) => key !== "availableInIdeas")
            .map(([value, count]) => ({ "Attribute Value": value, Count: count }));

        const worksheet = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet, attribute);
    });

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(blob, `${name}.xlsx`);
};

const copyAttributesToClipboard = (data: Aggregate) => {
    let csvContent = Object.entries(data)
        .map(([attribute, details]) => `${attribute}\t${details.availableInIdeas}`)
        .join("\n");

    navigator.clipboard.writeText(csvContent).then(() => {
        alert("Attributes copied");
    }).catch((err) => {
        console.error("Failed to copy: ", err);
    });
};

export default AttributeList;