const GraphVertexConstants = {
  vertexWidth: 116,
  vertexHeight: 72,
  vertexExpandedWidth: 400,
  newVertexSpacing: {
    xOffset: 100,
    ySpacing: 20
  }
}

export {GraphVertexConstants}
