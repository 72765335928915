import { pushColors } from './Color/actions'
import { pushCurrentBoardConnections } from './Connection/actions'

import * as DomainConstants from './DomainConstants'

import {BoardSummary} from 'shared/models/BoardSummary'
import {IdeaSourceObjectType, IdeaRecord} from 'shared/models/Idea'
import {ConnectionSourceObjectType, ConnectionRecord} from 'shared/models/Connection'
import {ColorSourceObjectType, ColorRecord} from 'shared/models/Color'
import {GraphLayoutSourceObjectType, GraphLayoutRecord} from 'shared/models/GraphLayout'
import {DocumentSourceObjectType, DocumentRecord} from 'shared/models/Document'
import {Attribute, AttributeStats} from 'shared/models/Attribute'
import {ChangeLogActions} from 'domain/ChangeLog'
import {UserProfileSourceObjectType, UserProfileRecord} from 'shared/models/UserProfile'
import { GenericProfileObjectType, GenericProfileRecord } from 'shared/models/ProfileBoardMembership'

import { BoardActions } from 'domain/Board/actions'
import { OutboundPermissionsPayload, OutboundOwnersPayload, PermissionResourceType } from 'shared/models/Permission'

interface AllDomainObjects {
  boardSummaries?: BoardSummary[]
  boardsPermissions?: OutboundPermissionsPayload<PermissionResourceType.boards>[]
  boardsOwners?: OutboundOwnersPayload[],
  ideas?: (IdeaSourceObjectType | IdeaRecord)[]
  ideasPermissions?: OutboundPermissionsPayload<PermissionResourceType.ideas>[]
  ideasOwners?: OutboundOwnersPayload[],
  connections?: (ConnectionSourceObjectType | ConnectionRecord)[]
  colors?: (ColorSourceObjectType | ColorRecord)[]
  graphLayouts?: (GraphLayoutSourceObjectType | GraphLayoutRecord)[]
  documents?: (DocumentSourceObjectType | DocumentRecord)[]
  attributes?: Attribute[]
  attributesStatsForCurrentBoard?: AttributeStats
  attributeValues?: (DocumentSourceObjectType | DocumentRecord)[]
  changelog?,
  comments?,
  ideaLikes?,
  commentLikes?
  userProfiles?: (UserProfileSourceObjectType | UserProfileRecord)[]
  peopleInCurrentBoard?: (GenericProfileObjectType | GenericProfileRecord)[]
  listViewLayout?,
  selectedIdeas?,
  boardMemberships?: any[]
}

export const pushAllObjects = (response: AllDomainObjects) => (dispatch) => {
  const {
    boardSummaries, boardsPermissions, boardsOwners,
    ideas, ideasPermissions, ideasOwners, connections,
    colors, attributes, attributesStatsForCurrentBoard, attributeValues,
    graphLayouts, documents, changelog,
    comments, ideaLikes, commentLikes,
    userProfiles, peopleInCurrentBoard,
    listViewLayout,
    boardMemberships
  } = response

  // TODO: turn `pushDomainData` into a base action for all specific `push*` actions,
  // TODO: gather all domain data in a single state branch
  const domainData = {
    ideasCache: ideas,
    boardSummaries,
    boardsPermissions,
    boardsOwners,
    ideasPermissions,
    ideasOwners,
    attributes,
    attributeValues,
    comments,
    ideaLikes,
    commentLikes,
    graphLayouts,
    documents,
    userProfiles,
    peopleInCurrentBoard,
  }
  dispatch({ type: DomainConstants.PUSH_DOMAIN_DATA, data: domainData })

  if (colors && colors.length > 0) dispatch(pushColors(colors))
  if (connections && connections.length > 0) dispatch(pushCurrentBoardConnections(connections))
  if (listViewLayout) dispatch(setCurrentListViewLayout(listViewLayout))

  if (attributesStatsForCurrentBoard) dispatch(setAttributesStatsForCurrentBoard(attributesStatsForCurrentBoard))

  if(changelog) dispatch(ChangeLogActions.reset(changelog))

  // HACK: TODO: Figure out how to properly handle board memberships when lazy loading ideas
  if(boardMemberships && boardMemberships.length > 0) dispatch(BoardActions.pushBoardMemberships(boardMemberships))
}

export const resetAllBoardData = () => ({
  type: DomainConstants.RESET_CURRENT_BOARD_DATA
})

export const setCurrentListViewLayout = (listViewLayout) => ({
  type: DomainConstants.SET_CURRENT_LIST_VIEW_LAYOUT,
  listViewLayout
})

export const setAttributesStatsForCurrentBoard = (attributesStatsForCurrentBoard) => ({ 
  type: DomainConstants.SET_ATTRIBUTES_STATS_FOR_CURRENT_BOARD,
  attributesStatsForCurrentBoard
})