import * as React from 'react'
import * as BrowserHelpers from './browser'

const KeyboardHelpers = {
  isBackspace: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Backspace),

  isDelete: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Delete),

  isUp: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Up),

  isDown: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Down),

  isEnter: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Enter),

  isTab: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Tab),

  isEscape: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Escape),

  isSpace: (keyboardEvent: React.KeyboardEvent<any>) =>_is(keyboardEvent, keyCodes.Space),

  isCtrlOrMacCmd: (event: React.KeyboardEvent<any> | React.MouseEvent<any>) =>
    event.ctrlKey || (BrowserHelpers.isMac() && event.metaKey),

  isOnTextInputs: ({ target }: React.KeyboardEvent<any>) => {
    const el = target as HTMLElement
    return ["input", "textarea"].includes(el.tagName.toLowerCase()) ||
      el.isContentEditable
  }
}

function _is (keyboardEvent: React.KeyboardEvent<any>, keyCode: number) {
  return keyboardEvent.keyCode === keyCode
}

export { KeyboardHelpers }

const keyCodes = {
  Backspace: 8,
  Delete: 46,
  Up: 38,
  Down: 40,
  Enter: 13,
  Tab: 9,
  Escape: 27,
  Space: 32,
}
