import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import { BoardActions } from 'domain/Board/actions'

type NormalPageOuterProps = {
  Component: React.ComponentClass<any>,
} & RouteComponentProps<{}>

type NormalPageInnerProps =
  NormalPageOuterProps & BoundActionGroups<{ BoardActions: typeof BoardActions }>

// Wrapper for non-board pages.
class _NormalPageWrapper extends React.Component<NormalPageInnerProps> {
  componentWillMount() {
    this.props.BoardActions.clearBoardState()
  }

  render() {
    return (
      <this.props.Component />
    )
  }
}

const NormalPageWrapperActionGroups = {
  BoardActions,
}
const NormalPageWrapper = ComponentHelpers.createConnectedComponent<
  NormalPageOuterProps,
  NormalPageOuterProps,
  typeof NormalPageWrapperActionGroups
>({
  actionGroups: NormalPageWrapperActionGroups,
  mapStateToProps: (_state, ownProps) => ownProps,
  component: _NormalPageWrapper,
})

export default NormalPageWrapper