export const ForbiddenBoardSlugs = [
  "admin",
  "login",
  "logout",
  "register",
  "boards",
  "board",
  "users",
  "user",
  "newsfeed",
  "graph",
  "list",
  "contributors",
  "changelog",
  "api",
  "facebook",
  "thought",
  "thoughtstream",

  // The following three are reserved for the list view sorting mode
  "new",
  "top",
  "status",

  // The following two are reserved for the graph view default modes
  "all",
  "empty"
]

export const ForbiddenDocumentSlugs = ForbiddenBoardSlugs