import {IdeaSelectionIdeaStates, IdeaSelectionState} from './common'
import {ModuleStateHelpers} from 'helpers/ModuleState'

const ideaSelectionModuleState = ModuleStateHelpers.createModuleState({
  moduleName: 'ideaSelection',
  initialValue: {
    ideaSelection: {
      ideaInstanceIds: [],
      newIdeaState: IdeaSelectionIdeaStates.NONE,
      newRelatedIdeaSourceIdeaClientId: undefined
    } as IdeaSelectionState
  }
})

const getIdeaSelectionState = (state) => ideaSelectionModuleState.get(state).ideaSelection
const setIdeaSelectionState = (ideaSelectionState: IdeaSelectionState) => ideaSelectionModuleState.setPart('ideaSelection', ideaSelectionState)

export {getIdeaSelectionState, setIdeaSelectionState}
