import * as React from 'react'

interface Config {
  matchClass: string
  prefix?: string
  suffix?: string
}

// Return a list of <span /> representing matches and plain text, with the
// config.matchClass class name applied to the matches.
export const getTextWithMatchesHighlighted = (
  text: string,
  matches: string[] | null,
  config: Config
) => {
  const chunks = []

  const pushChunk = (content: string | undefined, optionalClassName: string | null) => {
    if (!content || !content.length) {
      return
    }

    // Add breaks between lines.
    const lines = content.split('\n')
    lines.forEach((line, index) => {
      if (index > 0) {
        chunks.push(<br key={chunks.length} />)
      }

      chunks.push(
        <span key={chunks.length} className={optionalClassName}>{line}</span>
      )
    })
  }

  if (config.prefix && config.prefix.length) {
    pushChunk(config.prefix, null)
  }

  let currentTitlePartIndex = 0

  if (matches) {
    matches.forEach((match) => {
      const matchStartIndex = text.indexOf(match, currentTitlePartIndex)
      const titlePartBeforeMatch = text.substring(currentTitlePartIndex, matchStartIndex)

      pushChunk(titlePartBeforeMatch, null)
      pushChunk(match, config.matchClass)

      currentTitlePartIndex = matchStartIndex + match.length
    })
  }

  const titlePartAfterMatches = text.substring(currentTitlePartIndex)

  pushChunk(titlePartAfterMatches, null)
  pushChunk(config.suffix, null)

  return chunks
}
