import * as React from 'react'
import * as ReactDOM from 'react-dom'

import classnames from 'classnames'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import ProfileBoardMembershipActions from 'domain/ProfileBoardMembership/actions'
import { UserProfileActions } from 'domain/UserProfile/actions'
import { NavigationActions } from 'navigation/actions'
import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'
import { GenericProfileRecord, GenericProfileDefaultValues } from 'shared/models/ProfileBoardMembership'
import styles from './UserTable.styl'
import rowStyles from './UserTableRow.styl'

import { generateClientId } from 'shared/helpers/Id'

import UserTableRow, { UserTableRowInstance } from './UserTableRow'

import pick from 'lodash/pick'

interface UserTableProps {
  genericProfiles: GenericProfileRecord[]
  currentBoardClientId: string
}

interface StateProps extends UserTableProps { 
  currentUserId: number 
}

const actionGroups = {
  UserProfileActions, ProfileBoardMembershipActions, NavigationActions
}

interface State {
  nextClientId: string
  autofocusCell: "name" | "email" | "website" | "bio" | null
}

const columns = [{
    label: "Person",
    propName: "name" as "name",
    isAutocomplete: true,
  }, {
    label: "Email",
    propName: "email" as "email",
    isAutocomplete: true,
  }, {
    label: "Interests",
    propName: "bio" as "bio",
    isAutocomplete: false,
  }, {
    label: "Website",
    propName: "website" as "website",
    isAutocomplete: false,
  }]

class UserTable extends React.Component<StateProps & BoundActionGroups<typeof actionGroups>, State> {
  constructor(props) {
    super(props)

    this.state = { 
      nextClientId: generateClientId(),
      autofocusCell: null,
    }
  }

  private handleAddUser = async (genericProfileData: GenericProfileRecord, autofocusCell = null) => {
    const clientIdToAdd = this.state.nextClientId
    this.setState({ 
      nextClientId: generateClientId(),
      autofocusCell: autofocusCell,
    })
    
    await this.props.ProfileBoardMembershipActions.addProfileBoardMembership(this.props.currentBoardClientId, genericProfileData)
    this.scrollToProfileBoardMember(clientIdToAdd)
  }
 
  private handleUpdateUser = async (genericProfileData) => {
    if (genericProfileData.type === "userProfile") {
      await this.props.UserProfileActions.updateUser(
        genericProfileData.userId,
        pick(genericProfileData, ['name', 'email', 'bio', 'website'])
      )
      await this.props.UserProfileActions.refetchAll()
      await this.props.ProfileBoardMembershipActions.getPeopleForBoard(this.props.currentBoardClientId)
      this.setState({ autofocusCell: null })
    } else {
      await this.props.ProfileBoardMembershipActions.updateUnclaimedProfile(
        genericProfileData.clientId,
        genericProfileData.unclaimedProfileClientId,
        genericProfileData
      )
      this.setState({ autofocusCell: null })
    }
  }

  private handleReplaceUnclaimedProfileWithUserProfile = async (clientId: string, userId: number) => {
    await this.props.ProfileBoardMembershipActions.replaceUnclaimedProfileWithUserProfile(clientId, userId)
    await this.props.UserProfileActions.refetchAll()
    await this.props.ProfileBoardMembershipActions.getPeopleForBoard(this.props.currentBoardClientId)
    this.setState({ autofocusCell: null })
  }

  private handleDeleteUser = async (genericProfileData) => {
    await this.props.ProfileBoardMembershipActions.deleteProfileBoardMembership(genericProfileData)
  }

  private scrollToProfileBoardMember = (clientId) => {
    const instance = this.userTableRowInstanceByClientId[clientId]
    if(!!instance) {
      (ReactDOM.findDOMNode(instance) as Element)
        .scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      console.warn("Attempting to scroll to the nonexisting table row")
    }
  }

  private userTableRowInstanceByClientId: { [clientId: string]: UserTableRowInstance | null } = {}

  private getUserProfileProps(genericProfile, isAutocomplete, isNew) {
    const profileProps = {
      onAddUser: this.handleAddUser,
      onDeleteUser: this.handleDeleteUser,
      onUpdateUser: this.handleUpdateUser,
      replaceUnclaimedProfileWithUserProfile: this.handleReplaceUnclaimedProfileWithUserProfile,
      scrollToProfileBoardMember: this.scrollToProfileBoardMember,
      navigateToUserProfile: this.props.NavigationActions.navigateToUserProfile,
      columns: columns,
      key: genericProfile.clientId,
      genericProfile: genericProfile as GenericProfileRecord,
      isEditable: genericProfile.userId === null || genericProfile.userId === this.props.currentUserId,
      ref: (instance: UserTableRowInstance) => this.userTableRowInstanceByClientId[genericProfile.clientId] = instance,
    }

    if (isNew) {
      return { ...profileProps, isCreateNew: true }
    } else if (isAutocomplete) {
      return { ...profileProps, autofocusCell: this.state.autofocusCell }
    } else {
      return profileProps
    }
  }

  render() {  
    const createNewProfile = {...GenericProfileDefaultValues, clientId: this.state.nextClientId }

    return <div className={styles.userTable}>
      <div className={styles.stickyUserTableRows}>
        <div className={classnames(rowStyles.userTableRow, rowStyles.userTableHeaderRow)}>
          <div className={classnames(rowStyles.emptyIcon, rowStyles.avatarIcon)}>.</div>
          {columns.map((column) => {
            return <div
              key={column.propName}
              className={classnames(rowStyles.userTableCell, rowStyles.userTableHeaderCell)}
            >{ column.label }</div>
          })}
          <div className={classnames(rowStyles.emptyIcon, rowStyles.rowIcons)}>.</div>
        </div>
        <UserTableRow {...this.getUserProfileProps(createNewProfile, false, true)}/>
      </div>

      {
        this.props.genericProfiles.map((genericProfile, index) => {
          return <UserTableRow {...this.getUserProfileProps(genericProfile, index === 0, false)}/>
        })
      }
      
    </div>
  }
}

export default ComponentHelpers.createConnectedComponent<UserTableProps, StateProps, typeof actionGroups> ({
  actionGroups,
  mapStateToProps: (_state, outerProps: UserTableProps): StateProps => {
    return {
      ...outerProps,
      currentUserId: CurrentUserSelectors.getId(_state)
    }
  },
  component: UserTable
})

