import * as React from 'react'

export default abstract class DelayedComponent<P> extends React.Component<P, { Component: any }> {
  constructor(props) {
    super(props)
    this.getComponent().then(Component => this.setState({ Component }))

    this.state = { Component: null }
  }

  abstract getComponent()

  render() {
    if(this.state.Component === null) {
      return null
    }
    return <this.state.Component {...this.props} />
  }
}