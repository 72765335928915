import { IdeaSourceObjectType } from "shared/models/Idea"
import { ConnectionSourceObjectType } from "shared/models/Connection"

export enum ChangeType {
  IdeaCreate = 'idea-create',
  IdeaDelete = 'idea-delete',
  IdeaRestore = 'idea-restore',
  IdeaTitleChange = 'idea-title-change',
  IdeaStatusChange = 'idea-status-change',
  IdeaAttributeValueChange = 'idea-attribute-value-change',
  IdeaColorIdChange = 'idea-colorid-change',

  ConnectionCreate = 'connection-create',
  ConnectionDelete = 'connection-delete',
  ConnectionLabelChange = 'connection-label-change',
  ConnectionColorIdChange = 'connection-colorid-change',
}

interface ChangelogEntryBase {
  changeType: ChangeType
  created_at: Date,
  updated_at: Date,
  userId: number,
  changeDescription: { [key: string]: any },
  clientId: string,
}

export interface ChangelogEntryIdeaCreate extends ChangelogEntryBase {
  changeType: ChangeType.IdeaCreate,
  changeDescription: { idea: IdeaSourceObjectType },
}

export interface ChangelogEntryIdeaDelete extends ChangelogEntryBase {
  changeType: ChangeType.IdeaDelete,
  changeDescription: {
    ideaClientId: string,
    connectionClientIds?: string[],
    boardClientIds?: string[]
  },
}

export interface ChangelogEntryIdeaRestore extends ChangelogEntryBase {
  changeType: ChangeType.IdeaRestore,
  changeDescription: { ideaClientId: string, connectionClientIds?: string[] },
}

export interface ChangelogEntryIdeaTitle extends ChangelogEntryBase {
  changeType: ChangeType.IdeaTitleChange,
  changeDescription: { ideaClientId: string, oldTitle: string, newTitle: string },
}

export interface ChangelogEntryIdeaStatus extends ChangelogEntryBase {
  changeType: ChangeType.IdeaStatusChange,
  changeDescription: { ideaClientId: string, oldStatus: string, newStatus: string },
}

export interface ChangelogEntryIdeaColorId extends ChangelogEntryBase {
  changeType: ChangeType.IdeaColorIdChange,
  changeDescription: { ideaClientId: string, oldColorId: number, newColorId: number },
}

export interface ChangelogEntryIdeaAttributeValue extends ChangelogEntryBase {
  changeType: ChangeType.IdeaAttributeValueChange,
  changeDescription: { oldValue: any, newValue: any, attributeClientId: string, ideaClientId: string, },
}

export interface ChangelogEntryConnectionCreate extends ChangelogEntryBase {
  changeType: ChangeType.ConnectionCreate,
  changeDescription: { connection: ConnectionSourceObjectType },
}

export interface ChangelogEntryConnectionDelete extends ChangelogEntryBase {
  changeType: ChangeType.ConnectionDelete,
  changeDescription: { connectionClientId: string, connection: ConnectionSourceObjectType },
}

export interface ChangelogEntryConnectionLabel extends ChangelogEntryBase {
  changeType: ChangeType.ConnectionLabelChange,
  changeDescription: { connectionClientId: string, oldLabelText: string, newLabelText: string },
}

export interface ChangelogEntryConnectionColorId extends ChangelogEntryBase {
  changeType: ChangeType.ConnectionColorIdChange,
  changeDescription: { connectionClientId: string, oldColorId: number, newColorId: number },
}

export type ChangelogEntry =
  ChangelogEntryIdeaCreate | ChangelogEntryIdeaDelete | ChangelogEntryIdeaTitle |
  ChangelogEntryIdeaStatus | ChangelogEntryIdeaAttributeValue | ChangelogEntryIdeaColorId |
  ChangelogEntryConnectionCreate | ChangelogEntryConnectionDelete | ChangelogEntryConnectionLabel |
  ChangelogEntryConnectionColorId
