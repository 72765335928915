import AdminUsers, { AdminUsersStateProps } from './AdminUsers'
import { ComponentHelpers } from 'helpers/Component'

import { UserProfileActions, UserProfileSelectors } from 'domain/UserProfile'
import { AttributeActions } from 'domain/Attribute/actions'
import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'
import { TeamCodesSelectors, TeamCodesActions } from 'managers/TeamCodes'

const actionGroups = {
  UserProfileActions,
  AttributeActions,
  TeamCodesActions,
}

const emptyArrayPromise = Promise.resolve([])

// FIXME: somehow if opening admin page directly, header is not re-rendered after successful login
// Also if the admin page is opened directly no attribute can be selected in "Set team code attribute"
export default ComponentHelpers.createConnectedComponent<{}, AdminUsersStateProps, typeof actionGroups>({
  actionGroups,

  mapStateToProps: (state): AdminUsersStateProps => {
    const teamCodesAreActive = TeamCodesSelectors.teamCodesAreActive(state)
    
    return {
      users: UserProfileSelectors.getAll(state).valueSeq().toArray(),
      canManageTeamCodes: CurrentUserSelectors.canManageTeamCodes(state),
      canAdminUsers: CurrentUserSelectors.canAdminUsers(state),
      teamCodeAttributeClientId: TeamCodesSelectors.getTeamCodeAttributeClientId(state),
      teamCodesAreActive,
      allTeamCodes: teamCodesAreActive ? TeamCodesSelectors.getAllTeamCodes(state): emptyArrayPromise,
      userHasTeamCode: (userId, teamCode) => TeamCodesSelectors.userHasTeamCode(state, userId, teamCode)
    }
  },

  component: AdminUsers
})
