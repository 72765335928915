import * as React from 'react'
import styles from './input.styl'
import classnames from 'classnames'
import Icon from 'components/common/Icon'

import { ErrorHelpers } from 'shared/helpers/Error'

interface InputProps {
  focusOnMount?: boolean
  copyToClipboard?: boolean
  size?: 'small' | 'normal'
  onFocus?: () => void
  onBlur?: () => void
  onClick?: () => void
  onCopyToClipboard?: () => void
  onEnterKey?: (event: any) => void
  onKeyUp?: (event: any) => void

  type?: 'email' | 'password' | 'text' | 'submit'
  name?: string
  style?: object
  value?: any
  readOnly?: boolean
  defaultValue?: any
  placeholder?: any
  className?: string
}

interface InputState {
  focused: boolean
}

export default class Input extends React.Component<InputProps, InputState> {
  state = {
    focused: false,
  }

  private readonly inputRef: React.RefObject<HTMLInputElement> = React.createRef()

  handleFocus = () => {
    this.setState({ focused: true, })

    this.props.onFocus && this.props.onFocus()
  }

  handleBlur = () => {
    this.setState({ focused: false, })

    this.props.onBlur && this.props.onBlur()
  }

  private handleClick = () => {
    if (this.props.copyToClipboard) {
      this.selectEntireInput()
    }

    this.props.onClick && this.props.onClick()
  }

  private handleCopyToClipboardClick = () => {
    if (!this.inputRef.current) return
    this.inputRef.current.focus()
    this.copyToClipboard()

    this.props.onCopyToClipboard && this.props.onCopyToClipboard()
  }

  clearInput = () => {
    if(!this.inputRef.current) return
    this.inputRef.current.value = ''
  }

  copyToClipboard = () => {
    if(!this.inputRef.current) return
    this.selectEntireInput()
    document.execCommand('copy')
  }

  selectEntireInput = () => {
    this.inputRef.current.setSelectionRange(0, this.inputRef.current.value.length)
  }

  private handleKeyUp = (e) => {
    (e.keyCode === 27) && this.clearInput()

    if (e.keyCode === 13) {
      this.props.onEnterKey && this.props.onEnterKey(e)
      this.clearInput()
    }

    this.props.onKeyUp && this.props.onKeyUp(e)
  }

  private handleKeyDown = (e: React.KeyboardEvent<any>) => {
    e.stopPropagation()
    // https://stackoverflow.com/a/24421834/396050
    e.nativeEvent.stopImmediatePropagation()
  }

  componentDidMount() {
    if(this.props.focusOnMount) {
      const input = ErrorHelpers.castToNotNullOrThrow(this.inputRef.current, "input was not rendered")
      input.focus()
    }
  }

  render() {
    const isSmall = this.props.size === 'small'
    const { type, name, style, value, readOnly, defaultValue, placeholder, className } = this.props

    return <>
      <input
        className={classnames(styles.input, {
          [styles.isSmall]: isSmall,
          [styles.isLarge]: !isSmall
        }, className)}
        ref={this.inputRef}
        type="text"
        onKeyUp={this.handleKeyUp}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onClick={this.handleClick}
        {...{ type, name, style, value, readOnly, defaultValue, placeholder }}
      />
      {/* TODO: Rendering this button should not be the concern of this low level component */}
      { this.props.copyToClipboard &&
        <span className={styles.copyToClipboard} onClick={this.handleCopyToClipboardClick}>
          <Icon
            fontAwesomeIcon="clipboard"
            tooltip="Copy to clipboard"
          />
        </span>}
    </>
  }
}
