import apiCall from 'helpers/apiCall'

import * as types from './constants'

import { ConnectionRecord, ConnectionSourceObjectType } from 'shared/models/Connection'

import * as ConnectionSelectors from 'domain/Connection/selectors'


export function pushCurrentBoardConnections(connections: (ConnectionRecord | ConnectionSourceObjectType)[]) {
  return { type: types.PUSH_CURRENT_BOARD_CONNECTIONS, connections }
}

export const updateConnectionColorId = (connectionClientId: string, newColorId: number) => {
  return updateConnection("updateColorId", connectionClientId, { colorId: newColorId })
}

export const updateConnectionLabel = (connectionClientId: string, newLabelText: string) => {
  return updateConnection("updateLabel", connectionClientId, { labelText: newLabelText })
}

const updateConnection = (apiEndpoint, connectionClientId: string, connectionPatch: ConnectionSourceObjectType) => async (dispatch, getState) => {
  const currentConnection = ConnectionSelectors.getByClientId(getState(), connectionClientId)

  const updatedConnection = currentConnection.merge(connectionPatch)

  dispatch(pushCurrentBoardConnections([updatedConnection]))
  const data = await apiCall({
    path: `/connections/${apiEndpoint}/${connectionClientId}`,
    method: 'POST',
    data: connectionPatch
  })
  dispatch(pushCurrentBoardConnections([data.connection]))
}

export const deleteConnection = (clientId: string) => async (dispatch) => {
  dispatch(pushCurrentBoardConnections([{ clientId, isDeleted: true }]))
  await apiCall({
    path: `/connections/${clientId}`,
    method: 'DELETE',
  })
}

export const deleteConnectionBetweenIdeas = (sourceIdeaClientId: string, targetIdeaClientId: string) => async (dispatch, getState) => {
  var connectionToDelete = ConnectionSelectors.getIdeaConnections(getState(), sourceIdeaClientId)
    .find((connection) => connection.get('targetIdeaClientId') === targetIdeaClientId)

  connectionToDelete ? null : connectionToDelete = ConnectionSelectors.getIdeaConnections(getState(), targetIdeaClientId)
    .find((connection) => connection.get('targetIdeaClientId') === sourceIdeaClientId)

  const connectionClientIdToDelete = connectionToDelete && connectionToDelete.get('clientId')
  if (!connectionClientIdToDelete) return

  dispatch(pushCurrentBoardConnections([{ clientId: connectionClientIdToDelete, isDeleted: true }]))
  await apiCall({
    path: `/connections/${connectionClientIdToDelete}`,
    method: 'DELETE',
  })
}

export const markAllIdeaConnectionsAsDeleted = (ideaClientId: string) => (dispatch, getState) => {
  const relatedConnections = ConnectionSelectors.getIdeaConnections(getState(), ideaClientId)
  dispatch(pushCurrentBoardConnections(
    relatedConnections.valueSeq().toArray().map(connection => connection.set("isDeleted", true))
  ))
}

export const ConnectionActions = {
  markAllIdeaConnectionsAsDeleted,
  deleteConnectionBetweenIdeas,
  deleteConnection,
  updateConnection,
  pushCurrentBoardConnections,
}
