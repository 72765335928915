export const types = {
  SET_HIGHLIGHTED_IDEAS_IN_GRAPH_VIEW: 'SET_HIGHLIGHTED_IDEAS_IN_GRAPH_VIEW' as 'SET_HIGHLIGHTED_IDEAS_IN_GRAPH_VIEW',
  SET_PATH_IN_GRAPH_VIEW: 'SET_PATH_IN_GRAPH_VIEW' as 'SET_PATH_IN_GRAPH_VIEW',

  SET_GRAPH_MODAL: 'SET_GRAPH_MODAL' as 'SET_GRAPH_MODAL',
  SET_SHORTEST_PATH_DAG: 'SET_SHORTEST_PATH_DAG' as 'SET_SHORTEST_PATH_DAG',

  ACTIVATE_DOCUMENT_IN_GRAPH_VIEW: 'ACTIVATE_DOCUMENT_IN_GRAPH_VIEW' as 'ACTIVATE_DOCUMENT_IN_GRAPH_VIEW',
  REPLACE_COLOR_RULES_RESULTS: 'REPLACE_COLOR_RULES_RESULTS' as 'REPLACE_COLOR_RULES_RESULTS',

  COLOR_IDEA_INSTANCE_IN_GRAPH_VIEW: 'COLOR_IDEA_INSTANCE_IN_GRAPH_VIEW' as 'COLOR_IDEA_INSTANCE_IN_GRAPH_VIEW',

  HOVER_IDEA_INSTANCE_IN_GRAPH_VIEW: 'HOVER_IDEA_INSTANCE_IN_GRAPH_VIEW' as 'HOVER_IDEA_INSTANCE_IN_GRAPH_VIEW',
  UNHOVER_IDEA_INSTANCE_IN_GRAPH_VIEW: 'UNHOVER_IDEA_INSTANCE_IN_GRAPH_VIEW' as 'UNHOVER_IDEA_INSTANCE_IN_GRAPH_VIEW',

  SELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW: 'SELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW' as 'SELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW',
  UNSELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW: 'UNSELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW' as 'UNSELECT_CONNECTION_INSTANCE_IN_GRAPH_VIEW',
  UNSELECT_ALL_CONNECTION_INSTANCES_IN_GRAPH_VIEW: 'UNSELECT_ALL_CONNECTION_INSTANCES_IN_GRAPH_VIEW' as 'UNSELECT_ALL_CONNECTION_INSTANCES_IN_GRAPH_VIEW',

  HOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW: 'HOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW' as 'HOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW',
  UNHOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW: 'UNHOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW' as 'UNHOVER_CONNECTION_INSTANCE_IN_GRAPH_VIEW',
  HOVER_SIDEBAR_IDEA: 'HOVER_SIDEBAR_IDEA' as 'HOVER_SIDEBAR_IDEA',
  UNHOVER_SIDEBAR_IDEA: 'UNHOVER_SIDEBAR_IDEA' as 'UNHOVER_SIDEBAR_IDEA',

  REPORT_CANVAS_CLIENT_RECTANGLES: 'REPORT_CANVAS_CLIENT_RECTANGLES' as 'REPORT_CANVAS_CLIENT_RECTANGLES',

  SET_IS_EXPANDING_THE_ACTIVE_GRAPH_LAYOUT: 'SET_IS_EXPANDING_THE_ACTIVE_GRAPH_LAYOUT' as 'SET_IS_EXPANDING_THE_ACTIVE_GRAPH_LAYOUT',

  SET_EVOLVE_THE_ACTIVE_GRAPH_LAYOUT: 'SET_EVOLVE_THE_ACTIVE_GRAPH_LAYOUT' as 'SET_EVOLVE_THE_ACTIVE_GRAPH_LAYOUT',
  SET_SIMULATION_STATE: 'SET_SIMULATION_STATE' as 'SET_SIMULATION_STATE',

  START_DRAGGING_IN_GRAPH_VIEW: 'START_DRAGGING_IN_GRAPH_VIEW' as 'START_DRAGGING_IN_GRAPH_VIEW',
  END_DRAGGING_IN_GRAPH_VIEW: 'END_DRAGGING_IN_GRAPH_VIEW' as 'END_DRAGGING_IN_GRAPH_VIEW',

  START_CONNECTING_IN_GRAPH_VIEW: 'START_CONNECTING_IN_GRAPH_VIEW' as 'START_CONNECTING_IN_GRAPH_VIEW',
  CONTINUE_CONNECTING_IN_GRAPH_VIEW: 'CONTINUE_CONNECTING_IN_GRAPH_VIEW' as 'CONTINUE_CONNECTING_IN_GRAPH_VIEW',
  CANCEL_CONNECTING_IN_GRAPH_VIEW: 'CANCEL_CONNECTING_IN_GRAPH_VIEW' as 'CANCEL_CONNECTING_IN_GRAPH_VIEW',

  START_MERGING_IN_GRAPH_VIEW: 'START_MERGING_IN_GRAPH_VIEW' as 'START_MERGING_IN_GRAPH_VIEW',
  CONTINUE_MERGING_IN_GRAPH_VIEW: 'CONTINUE_MERGING_IN_GRAPH_VIEW' as 'CONTINUE_MERGING_IN_GRAPH_VIEW',
  CANCEL_MERGING_IN_GRAPH_VIEW: 'CANCEL_MERGING_IN_GRAPH_VIEW' as 'CANCEL_MERGING_IN_GRAPH_VIEW',

  START_PANNING_IN_GRAPH_VIEW: 'START_PANNING_IN_GRAPH_VIEW' as 'START_PANNING_IN_GRAPH_VIEW',
  END_PANNING_IN_GRAPH_VIEW: 'END_PANNING_IN_GRAPH_VIEW' as 'END_PANNING_IN_GRAPH_VIEW',

  START_RECT_SELECTING_IN_GRAPH_VIEW: 'START_RECT_SELECTING_IN_GRAPH_VIEW' as 'START_RECT_SELECTING_IN_GRAPH_VIEW',
  CONTINUE_RECT_SELECTING_IN_GRAPH_VIEW: 'CONTINUE_RECT_SELECTING_IN_GRAPH_VIEW' as 'CONTINUE_RECT_SELECTING_IN_GRAPH_VIEW',
  END_RECT_SELECTING_IN_GRAPH_VIEW: 'END_RECT_SELECTING_IN_GRAPH_VIEW' as 'END_RECT_SELECTING_IN_GRAPH_VIEW',
  CANCEL_RECT_SELECTING_IN_GRAPH_VIEW: 'CANCEL_RECT_SELECTING_IN_GRAPH_VIEW' as 'CANCEL_RECT_SELECTING_IN_GRAPH_VIEW',

  PUSH_FIXED_POINTS_PAN_ZOOM_STATE: 'PUSH_FIXED_POINTS_PAN_ZOOM_STATE' as 'PUSH_FIXED_POINTS_PAN_ZOOM_STATE',

  SET_SHOW_EDGE_LABELS: 'SET_SHOW_EDGE_LABELS' as 'SET_SHOW_EDGE_LABELS',

  DESELECT_ALL_IN_GRAPH_VIEW: 'DESELECT_ALL_IN_GRAPH_VIEW' as 'DESELECT_ALL_IN_GRAPH_VIEW',

  SET_DYNAMIC_IMAGE_TRANSFORM: 'SET_DYNAMIC_IMAGE_TRANSFORM' as 'SET_DYNAMIC_IMAGE_TRANSFORM',
  REMOVE_DYNAMIC_IMAGE_TRANSFORM: 'REMOVE_DYNAMIC_IMAGE_TRANSFORM' as 'REMOVE_DYNAMIC_IMAGE_TRANSFORM',

  SET_CURRENT_NEW_IDEA: 'SET_CURRENT_NEW_IDEA' as 'SET_CURRENT_NEW_IDEA',
  GRAPH_VERTEX_SEARCH_BAR: 'GRAPH_VERTEX_SEARCH_BAR' as 'GRAPH_VERTEX_SEARCH_BAR',

  SET_USING_TRANSIENT_DOCUMENT: 'SET_USING_TRANSIENT_DOCUMENT' as 'SET_USING_TRANSIENT_DOCUMENT',

  SELECT_SIDEBAR_MODE: 'SELECT_SIDEBAR_MODE' as 'SELECT_SIDEBAR_MODE',

  MOUSE_ENTER: 'MOUSE_ENTER' as 'MOUSE_ENTER',
  MOUSE_LEAVE: 'MOUSE_LEAVE' as 'MOUSE_LEAVE',
}
