import * as React from 'react'
import {UncontrolledTooltip} from 'reactstrap'
import IconMenu from 'components/common/IconMenu'

import classnames from 'classnames'

import styles from './styles.styl'
export interface HoverMenuItem {
  fontAwesomeIcon: string
  //use only strings now as we don't want to display a rich tooltip inside another rich tooltip that implements HoverMenu
  tooltip: string
  className?: string
  action: () => void
}

interface HoverMenuItemProps {
  placement: 'inline' | 'tooltip'
  menuItems: HoverMenuItem[]
}

class HoverMenu extends React.Component<HoverMenuItemProps> {
  _tooltipTargetElementId: string

  constructor(props) {
    super(props)

    this._tooltipTargetElementId = `HoverMenu_wrapper_${_hoverMenuIdCounter++}`
  }

  render() {
    const menuItems = this.props.menuItems
    const renderedIconMenu = menuItems && menuItems.length && <IconMenu items={menuItems}/>
    return <div>
      <div
        className={styles.contentsWrapper}
        id={this._tooltipTargetElementId}
      >
        {this.props.children}
        {this.props.placement === 'inline' && <span
          className={classnames(styles.inlineMenuWrapper)}
        >
          {renderedIconMenu}
        </span>
        }
      </div>
      {
        this.props.placement === 'tooltip' && <UncontrolledTooltip
          placement="right"
          target={this._tooltipTargetElementId}
          autohide={false}
          delay={{
            show: 200,
            hide: 200
          }}
        >
          {renderedIconMenu}
        </UncontrolledTooltip>
      }
    </div>
  }
}

let _hoverMenuIdCounter = 0

export default HoverMenu
