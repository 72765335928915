import * as React from 'react'
import { Map } from 'immutable'

import { UserProfileSelectors } from 'domain/UserProfile'
import { UserProfileRecord } from 'shared/models/UserProfile'
import { ComponentHelpers } from 'helpers/Component'
import UserProfileBox from 'components/common/UserProfileBox'
import {CurrentUserSelectors} from 'domain/CurrentUser/selectors'

import { Label } from 'components/common/Forms'
import Checkbox from 'components/common/Checkbox'

import styles from './UserGrid.styl'

type StateProps = {
  allUserProfiles: Map<number, UserProfileRecord>
  currentUserId: number
}

class UserGrid extends React.Component<StateProps, { showAnonymousUsers: boolean }> {
  state = { showAnonymousUsers: false }
  render() {
    const sortedUserProfiles = this.props.allUserProfiles
      .filter(userProfile => !!userProfile && (userProfile.get("status") !== "anon" || this.state.showAnonymousUsers))
      .sortBy(userProfile => userProfile.createdAt)
      .reverse()
      .valueSeq().toArray()

    return <div className={styles.userGridWrapper}>
      <Label onChange={() => this.setState(state => ({ showAnonymousUsers: !state.showAnonymousUsers }))}>
        <span>Show anonymous users:</span>
        <Checkbox
          className={styles.showAnonymousUsersCheckbox}
          checked={this.state.showAnonymousUsers}
        />
      </Label>
      <div className={styles.userGrid}>
        {sortedUserProfiles.map(userProfile => this.renderUser(userProfile))}
      </div>
    </div>
  }

  renderUser(userProfile: UserProfileRecord) {
    return <UserProfileBox
      key={userProfile.userId}
      user={userProfile}
      className={styles.box}
      isUserProfileForCurrentUser={this.props.currentUserId === userProfile.userId}
    />
  }
}

export default ComponentHelpers.createConnectedComponent<{}, StateProps, {}>({
  actionGroups: {},
  mapStateToProps: (state): StateProps => {
    const allUserProfiles = UserProfileSelectors.getAll(state)
    const currentUserId = CurrentUserSelectors.getId(state)

    return {
      allUserProfiles,
      currentUserId,
    }
  },
  component: UserGrid,
})
