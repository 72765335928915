import * as React from 'react'

import he from 'he'
import { castToString } from 'helpers/casting'

import FocusAndSelectionHelpers from 'helpers/FocusAndSelection'
import {KeyboardHelpers} from 'helpers/Keyboard'
import styles from './UniversalTextInput.styl'
import classnames from 'classnames'

import { ErrorHelpers } from 'shared/helpers/Error'

export interface FocusAndBlurHandlers {
  focus: () => void
  blur: () => void
  isFocused: () => boolean
}

type KeyboardAction = "enter" | "escape" | "tab" | "up" | "down" | "delete" | "backspace" | "control" | "alt" | "shift"

interface UniversalTextInputProps {
  exposeFocusAndBlurHandler?: (config: FocusAndBlurHandlers) => void

  keepTrackOfIsOverflowed?: boolean
  tagName?: 'span' | 'div'
  className?: string
  style?: React.CSSProperties
  caretPosition?: number | null
  disabled?: boolean
  multiline?: boolean
  text: string | undefined
  triggerChangeOnSpecialKeysOnly?: boolean
  placeholder?: string

  onEscape?: (event: React.KeyboardEvent<any>) => void
  onBackspace?: (event: React.KeyboardEvent<any>) => void
  onDelete?: (event: React.KeyboardEvent<any>) => void
  onEnter?: (event: React.KeyboardEvent<any>) => void
  onTab?: (event: React.KeyboardEvent<any>) => void
  onArrowUp?: (event: React.KeyboardEvent<any>) => void
  onArrowDown?: (event: React.KeyboardEvent<any>) => void
  onKeyDown?: (event: React.KeyboardEvent<any>) => void
  onChange?: (value: string) => void

  allowDefaultActions?: KeyboardAction[]
  allowedPropagations?: KeyboardAction[]
  autofocus?: boolean

  onFocus?: (event: React.FocusEvent<HTMLElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void

  onMouseMove?: (event: React.MouseEvent<any>) => void
  onMouseDown?: (event: React.MouseEvent<any>) => void
  onMouseUp?: (event: React.MouseEvent<any>) => void
  onMouseEnter?: (event: React.MouseEvent<any>) => void
  onMouseLeave?: (event: React.MouseEvent<any>) => void
}

interface KeyTriggerConfig {
  test: (event: React.KeyboardEvent) => boolean,
  callback: (event: React.KeyboardEvent) => void,
  defaultCanBePrevented: boolean,
}

export default class UniversalTextInput extends React.Component<UniversalTextInputProps> {
  static defaultProps = {
    allowDefaultActions: [],
    allowedPropagations: ['shift', 'alt', 'control'],
    caretPosition: null,
  }

  constructor(props: UniversalTextInputProps) {
    super(props)

    if (this.props.exposeFocusAndBlurHandler) {
      this.props.exposeFocusAndBlurHandler({
        focus: this.focus,
        blur: this.blur,
        isFocused: this.isFocused
      })
    }
  }

  shouldComponentUpdate() {
    // FIXME: this is a quick workaround: if input is focused, we assume that props update is caused by the user typing,
    // so skip updating to avoid various bugs like cursor jumping
    // this can be improved by skipping only when `text` prop is the only one changed,
    // but even then it will incorrectly skip the case when suddenly a new value arrives
    return !this.isFocused()
  }

  componentDidMount() {
    this.props.autofocus === true ? this.focus() : null
  }

  containsSelection(selection: Selection): boolean {
    if (this._inputElement == null) {
      return false
    }
    return this._inputElement.contains(selection.anchorNode)
  }

  getValue = () => {
    return this._getCurrentValue() || ''
  }

  setValue = (value) => {
    this.getInputElement().innerHTML = he.encode(castToString(value))
  }

  focus = () => {
    FocusAndSelectionHelpers.ensureElementIsFocused(this.getInputElement())
  }

  blur = () => {
    this.getInputElement().blur()
  }

  selectText() {
    FocusAndSelectionHelpers.selectTextInElement(this.getInputElement())
  }

  getCaretAtStartOrEnd() {
    return FocusAndSelectionHelpers.getCaretAtStartOrEnd(this.getInputElement())
  }

  getCaretPosition() {
    return FocusAndSelectionHelpers.getCaretPosition(this.getInputElement())
  }

  setCaretPosition(lineIndex: number, lineOffset: number) {
    FocusAndSelectionHelpers.setCaretPosition(this.getInputElement(), lineIndex, lineOffset)
  }

  isFocused() {
    return FocusAndSelectionHelpers.isElementFocused(this.getInputElement())
  }

  private _inputElement: HTMLDivElement | HTMLSpanElement | null = null

  private getInputElement() {
    return ErrorHelpers.castToNotNullOrThrow(this._inputElement, "Input element was not rendered")
  }

  render() {
    return React.createElement(
      this.props.tagName || 'div',
      {
        className: classnames(this.props.className, styles.universalTextInput, !this.props.multiline && styles.universalTextInputSingleLine),
        style: this.props.style,
        contentEditable: !this.props.disabled,
        suppressContentEditableWarning: true,
        dangerouslySetInnerHTML: { __html: he.encode(castToString(this.props.text)) },
        ref: element => this._inputElement = element,
        onKeyDown: this._onKeyDown,
        onKeyUp: this._onKeyUp,
        onInput: this._onInput,
        onFocus: this._onFocus,
        onBlur: this._onBlur,
        onMouseMove: this._onMouseMove,
        onMouseDown: this._onMouseDown,
        onMouseUp: this._onMouseUp,
        onMouseEnter: this._onMouseEnter,
        onMouseLeave: this._onMouseLeave,
        "data-placeholder": this.props.placeholder,
      } as any
    )
  }

  private _getCurrentValue() {
    return this.getInputElement().innerText
  }

  private _onKeyDown = (e: React.KeyboardEvent<any>) => {
    let allowDefaultActions = this.props.allowDefaultActions || []

    const triggers: { [index in KeyboardAction]: KeyTriggerConfig } = {
      backspace: {
        test: KeyboardHelpers.isBackspace,
        callback: this.props.onBackspace,
        defaultCanBePrevented: false,
      },
      delete: {
        test: KeyboardHelpers.isDelete,
        callback: this.props.onDelete,
        defaultCanBePrevented: false,
      },
      escape: {
        test: KeyboardHelpers.isEscape,
        callback: this.props.onEscape,
        defaultCanBePrevented: true,
      },
      enter: {
        test: KeyboardHelpers.isEnter,
        callback: this.props.onEnter,
        defaultCanBePrevented: true,
      },
      tab: {
        test: KeyboardHelpers.isTab,
        callback: this.props.onTab,
        defaultCanBePrevented: true,
      },
      up: {
        test: KeyboardHelpers.isUp,
        callback: this.props.onArrowUp,
        defaultCanBePrevented: true,
      },
      down: {
        test: KeyboardHelpers.isDown,
        callback: this.props.onArrowDown,
        defaultCanBePrevented: true,
      },
      control: {
        test: event => event.ctrlKey,
        callback: () => {},
        defaultCanBePrevented: false,
      },
      alt: {
        test: event => event.altKey,
        callback: () => {},
        defaultCanBePrevented: false,
      },
      shift: {
        test: event => event.shiftKey,
        callback: () => {},
        defaultCanBePrevented: false,
      }
    }

    let stopPropagation = true
    if (KeyboardHelpers.isEnter(e) && e.shiftKey) {
      if (!this.props.multiline) {
        e.preventDefault()
      }
      e.stopPropagation()
      // https://stackoverflow.com/a/24421834/396050
      e.nativeEvent.stopImmediatePropagation()
      return
    }

    let action: KeyboardAction
    for (action in triggers) {
      const { test, callback, defaultCanBePrevented } = triggers[action]
      if (test(e)) {
        if (defaultCanBePrevented && !allowDefaultActions.includes(action)) {
          e.preventDefault()
        }

        // Prevent by default all events in `triggers` from propagating.
        if (this.props.allowedPropagations!.includes(action)) {
          stopPropagation = false
        }

        if (callback) {
          callback(e)
        }

        break
      }
    }

    if (stopPropagation) {
      e.stopPropagation()
      // https://stackoverflow.com/a/24421834/396050
      e.nativeEvent.stopImmediatePropagation()
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  private _onKeyUp = (e) => {
    // TODO: for multiline inputs, consider adding shift/ctrl either for either newline or for onChange triggering
    if(
      KeyboardHelpers.isEnter(e) && !this.props.multiline ||
      KeyboardHelpers.isTab(e)
    ) {
      this._onChange()
    }
  }

  private _onInput = () => {
    //FIXME: remove this flag and never trigger changes here - only on special keys in _onKeyUp (maybe _onBlur as well)
    if(!this.props.triggerChangeOnSpecialKeysOnly) {
      this._onChange()
    }
  }

  private _normalizeContent = () => {
    if(this._inputElement && this._inputElement.textContent === '') {
      this._inputElement.innerText = ''
    }
  }

  private _onChange = () => {
    this._normalizeContent()
    this.props.onChange && this.props.onChange(this._getCurrentValue())
  }

  private _onFocus = (e) => {
    this.props.onFocus && this.props.onFocus(e)
  }

  private _onBlur = (e) => {
    this.props.onBlur && this.props.onBlur(e)
  }

  private _onMouseMove = (e) => {
    this.props.onMouseMove && this.props.onMouseMove(e)
  }

  private _onMouseDown = (e) => {
    this.props.onMouseDown && this.props.onMouseDown(e)
  }

  private _onMouseUp = (e) => {
    this.props.onMouseUp && this.props.onMouseUp(e)
  }

  private _onMouseEnter = (e) => {
    this.props.onMouseEnter && this.props.onMouseEnter(e)
  }

  private _onMouseLeave = (e) => {
    this.props.onMouseLeave && this.props.onMouseLeave(e)
  }
}
