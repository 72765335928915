import * as React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import Routes from 'navigation/routes'
import {GlobalModalsHost} from 'helpers/Modal'
import {MouseHelpers} from 'helpers/mouse'

import styles from './App.styl'

import { AuthActions } from 'auth/actions'

interface AppProps {
  verifyExistingAuth: () => Promise<boolean>
}

class App extends React.Component<AppProps, {initialized: boolean}> {
  constructor (props) {
    super(props)

    this.state = {initialized: false}
  }

  componentWillMount () {
    this._init()
  }

  render () {
    // TODO: show global spinner here while loading global data
    return this.state.initialized && (
      <div className={styles.appContainer}>
        <Routes>
          <GlobalModalsHost />
        </Routes>
      </div>
    )
  }

  async _init () {
    MouseHelpers.init()
    try {
      await this.props.verifyExistingAuth()
    }
    catch (e) {
      // this is a catch-all block to ensure that this action finishes - needed to ensure that top-level UI always renders
      // TODO: remove it - there should be no need to catch errors that we don't know how to recover from
      // - make sure that errors UI rendered always, not depending on anything
    }
    this.setState({initialized: true})
  }
}

export default connect(
  null,
  (dispatch) => ({
    verifyExistingAuth: bindActionCreators(AuthActions as any, dispatch).verifyExistingAuth
  })
)(App)

