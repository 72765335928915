//TODO: split this into specific single-responsibility reducers
import {
  SET_IMPORT_PROGRESS_DATA, SET_LOADING, UNSET_LOADING
} from './constants'

// TODO: Separate the error registration/reporting to separate actions/reducers

export default (reducer) => {
  reducer.handle(SET_LOADING,  (state, action) => {
    return state.set('isLoading', true);
  })

  reducer.handle(UNSET_LOADING,  (state, action) => {
    return state.set('isLoading', false);
  })
}
