import { Map, Set } from 'immutable'
import { Dispatch } from 'redux'

import apiCall from 'helpers/apiCall'
import { createBatchOnIdle } from 'helpers/batchOnIdle'
import { ErrorHelpers } from 'shared/helpers/Error'

import * as GenericActions from '../GenericActions'
import * as AppActions from 'appRoot/actions'

import * as types from './constants'

import Idea, { IdeaSourceObjectType, IdeaRecord } from 'shared/models/Idea'
import Connection, { ConnectionSourceObjectType } from 'shared/models/Connection'
import { ColorInstance } from 'shared/models/ColorInstance'
import { SortField } from 'shared/models/ListView'
import { PermissionResourceType, StandardPrivacyActor, Permission } from 'shared/models/Permission'
import { BoardMembership } from 'shared/models/BoardMembership'

import { IdeaActions, markIdeaAsBeingCreated } from 'domain/Idea/actions'
import * as PermissionActions from 'domain/Permission/actions'
import * as IdeaSelectors from 'domain/Idea/selectors'
import * as ConnectionSelectors from 'domain/Connection/selectors'
import * as ConnectionActions from 'domain/Connection/actions'
import * as ListViewSelectors from 'components/list-view/ListViewSelectors'

import { NavigationActions } from 'navigation/actions'
import { AllViewTypes } from 'config/appConfig'

import * as BoardSelectors from './selectors'

import { AppStateRecord } from 'appRoot/state'

import { arrayMove } from 'react-sortable-hoc'

const setBoardIsFound = (isFound) => ({ type: types.BOARD_SET_IS_FOUND, isFound })


const _createIdeasAndConnectionsOnCurrentBoard = (ideaStubs: IdeaSourceObjectType[], connectionStubs: ConnectionSourceObjectType[]=[]) => (dispatch, getState) => {
  const boardClientId = BoardSelectors.getCurrentBoardClientId(getState())
  //Todo: Remove this later, sometimes the array is gone? #bug
  console.log(ideaStubs.map(c => c), connectionStubs.map(c => c));
  const ideas = ideaStubs.map(ideaStub => {
    // TODO: create real idea records only in one place on the lowest level
    return Idea(ideaStub)
  })
  const removeCreateMarks = ideas.map(idea => {
    return markIdeaAsBeingCreated(idea.clientId)
  })

  const connections = connectionStubs.map(connectionStub => {
    if (!connectionStub.sourceIdeaClientId || !connectionStub.targetIdeaClientId) {
      throw new Error('sourceIdeaClientId or targetIdeaClientId not specified')
    }

    return Connection(connectionStub)
  })

  const ideaClientIds = ideas.map(idea => idea.clientId)
  const connectionClientIds = connections.map(connection => connection.clientId)
  const result = { ideaClientIds, connectionClientIds }

  dispatch(IdeaActions.pushCurrentBoardIdeas(ideas))
  dispatch(BoardActions.addToBoardIdeaClientIds(ideaClientIds))
  dispatch(ConnectionActions.pushCurrentBoardConnections(connections))

  apiCall({
    path: `/boards/createIdeasAndConnections`,
    method: 'POST',
    data: {
      boardClientId,
      ideas: ideas.map(idea => idea.toJS()),
      connections: connections.map(connection => connection.toJS()),
    },
  }).then((data) => {
    console.log("Finished createIdeasAndConnections calls");
    dispatch(acceptCreateIdeasAndConnectionsData(ideas, connections, removeCreateMarks, data))
  })

  return result
}

// This needs to be a separate action in order for the batchSubscribeMiddleware to be able
// to prevent redux-triggered rerendering from happening in-between dispatches
// see acceptBoardData below
const acceptCreateIdeasAndConnectionsData = (ideas, connections, removeCreateMarks, data) => (dispatch, getState) => {
  // Accept server versions of only the ideas that were not updated
  // in the time it took the server to respond
  const serverIdeasToUpdate = ideas
    .map(idea => ({
      originalIdea: idea,
      currentIdea: IdeaSelectors.getByClientId(getState(), idea.clientId),
      serverIdea: data.ideas.find(serverIdea => serverIdea.clientId === idea.clientId)
    }))
    .filter(({ originalIdea, currentIdea }) => originalIdea === currentIdea)
    .map(({ serverIdea }) => serverIdea)

  for (const removeCreateMark of removeCreateMarks) {
    removeCreateMark()
  }

  const serverConnectionsToUpdate = connections
    .map(connection => ({
      originalConnection: connection,
      currentConnection: ConnectionSelectors.getByClientId(getState(), connection.clientId),
      serverConnection: data.connections.find(serverConnection => serverConnection.clientId === connection.clientId)
    }))
    .filter(({ originalConnection, currentConnection }) => originalConnection === currentConnection)
    .map(({ serverConnection }) => serverConnection)

  if(serverIdeasToUpdate.length !== data.ideas.length || serverConnectionsToUpdate.length !== data.connections.length) {
    console.warn(`Rejecting server response for ${data.ideas.length - serverIdeasToUpdate.length} ideas and ${data.connections.length - serverConnectionsToUpdate.length} connections that were modified`)
  }

  dispatch(IdeaActions.pushCurrentBoardIdeas(serverIdeasToUpdate))
  dispatch(ConnectionActions.pushCurrentBoardConnections(serverConnectionsToUpdate))

  dispatch(BoardActions.incrementCurrentBoardSummaryIdeaCount(serverIdeasToUpdate.length))

  // Push new permissions and owners to app state as well
  dispatch(PermissionActions.pushPermissions(PermissionResourceType.ideas, data.ideasPermissions))
  dispatch(PermissionActions.pushOwners(PermissionResourceType.ideas, data.ideasOwners))
}



// FIXME: clear previous board data first to make it less stateful
// - the stare should be the same without regards to what was fetched before
const fetchCurrentBoardData = ({
  onlyFetchViewTypeData=false,
} = {}, {
  cutoffDate=null,
  recentIdeaLimit=null,
  sortField=null,
  filter="",
  includeAll=false
}: AdditionalBoardFetchParameters = {}) => async (dispatch, getState) => {
  const slug = BoardSelectors.getCurrentBoardSlug(getState())
  const viewType = (getState() as AppStateRecord).get('viewType')

  console.log("Fetching", {
    slug, viewType,
    onlyFetchViewTypeData, cutoffDate, recentIdeaLimit, sortField, includeAll
  })

  dispatch(AppActions.setLoading())

  let allViewTypesDataPromise
  if(!onlyFetchViewTypeData) {
    allViewTypesDataPromise = apiCall({
      path: `/boards/allViewTypes/${slug}`,
      method: "POST",
      data: { includeAll },
    })
  }

  const currentViewTypeDataPromise = apiCall({
    path: `/boards/${viewType}/${slug}`,
    method: "POST",
    data: {
      filter,
      ...(cutoffDate ? { cutoffDate } : {}),
      ...(recentIdeaLimit ? { recentIdeaLimit } : {}),
      ...(sortField ? { sortField } : {}),
    }
  })

  // If we await on the promises after both are created the requests will happen in parallel!
  let allViewTypesData, currentViewTypeData
  try {
    allViewTypesData = await allViewTypesDataPromise
    currentViewTypeData = await currentViewTypeDataPromise
  } catch (err) {
    dispatch(setBoardIsFound(false))
    dispatch(AppActions.unsetLoading())
    return
  }

  dispatch(acceptBoardData({ allViewTypesData, currentViewTypeData }))
}

// This needs to be a separate action in order for the batchSubscribeMiddleware to be able
// to prevent redux-triggered rerendering from happening in-between dispatches
const acceptBoardData = ({ allViewTypesData, currentViewTypeData }) => async (dispatch) => {
  dispatch(setBoardIsFound(true))
  if(!!allViewTypesData) {
    dispatch(GenericActions.resetAllBoardData())
    dispatch(GenericActions.pushAllObjects(allViewTypesData))
  }
  dispatch(GenericActions.pushAllObjects(currentViewTypeData))

  const boardIdeaClientIds = [
    ...(allViewTypesData && allViewTypesData.boardIdeaClientIds || []),
    ...(currentViewTypeData.boardIdeaClientIds || [])
  ]
  dispatch(BoardActions.markIdeaClientIdsAsVisible(boardIdeaClientIds))
  dispatch(setBoardIdeaClientIds(boardIdeaClientIds))

  dispatch(AppActions.unsetLoading())
}

const setBoardIdeaClientIds = (boardIdeaClientIds: string[]) => (dispatch) => {
  dispatch({
    type: types.BOARD_SET_BOARD_IDEA_CLIENT_IDS,
    boardIdeaClientIds: Map(boardIdeaClientIds.map(ideaClientId => [ideaClientId, true])),
  })
}

const markIdeaClientIdsAsVisibleBatch = createBatchOnIdle((ideaClientIdsArrays: string[][]) => async (dispatch, getState) => {
  const ideaClientIds = Set(Array.prototype.concat.call([], ...ideaClientIdsArrays) as string[])
    .valueSeq().toArray()
  const visibilityByIdeaClientId = (getState() as AppStateRecord).get('visibilityByIdeaClientId')
  const newIdeaClientIds = ideaClientIds.filter(ideaClientId => !visibilityByIdeaClientId.has(ideaClientId))

  if(newIdeaClientIds.length === 0) return

  dispatch(setVisibilityByIdeaClientId(visibilityByIdeaClientId.concat(...newIdeaClientIds)))

  const entities = await apiCall({
    method: "POST",
    path: "/ideas/getRelatedEntities",
    data: { ideaClientIds: newIdeaClientIds }
  })
  dispatch(GenericActions.pushAllObjects(entities))
})

const markIdeaClientIdsAsVisible = (ideaClientIds: string[]) => async (dispatch) => {
  markIdeaClientIdsAsVisibleBatch.setDispatch(dispatch)
  markIdeaClientIdsAsVisibleBatch.batchOnIdle(ideaClientIds)
}

const setVisibilityByIdeaClientId = (visibilityByIdeaClientId) => (dispatch) => {
  dispatch({
    type: types.SET_VISIBILITY_BY_IDEA_CLIENT_ID,
    visibilityByIdeaClientId,
  })
}

const updateColorInstances = (updater: (cis: ColorInstance[]) => ColorInstance[]) => async (dispatch, getState) => {
  const boardSummary = ErrorHelpers.castToNotNullOrThrow(
    BoardSelectors.getCurrentBoardSummary(getState()),
    "Cannot change color instances if no board is selected"
  )

  const newColorInstances = updater(boardSummary.colorInstances)

  const patchedBoardSummary = {
    ...boardSummary,
    colorInstances: newColorInstances,
  }

  dispatch(GenericActions.pushAllObjects({ boardSummaries: [patchedBoardSummary] }))

  await apiCall({
    path: "/boards/updateBoardColorInstances",
    method: "POST",
    data: { boardClientId: boardSummary.clientId, colorInstances: newColorInstances }
  })
}

export type AdditionalBoardFetchParameters = {
  cutoffDate?: Date | null
  recentIdeaLimit?: number | null
  sortField?: SortField | null
  filter?: string
  includeAll?: boolean
}

export const BoardActions = {
  replacePermissions: (
    boardClientId: string,
    newPermissions: Permission<PermissionResourceType.boards>[]
  ) => {
    return PermissionActions.replacePermissions(PermissionResourceType.boards, boardClientId, newPermissions)
  },

  replaceOwners: (
    boardClientId: string,
    newOwners: StandardPrivacyActor[]
  ) => {
    return PermissionActions.replaceOwners(PermissionResourceType.boards, boardClientId, newOwners)
  },

  setViewType: (viewType: AllViewTypes) => (dispatch) => {
    dispatch({type: types.BOARD_SET_VIEW_TYPE, viewType})
  },

  setSearchInAllBoards: (value: boolean) => (dispatch) => {
    dispatch({
      type: types.SET_SEARCH_IN_ALL_BOARDS_FLAG,
      searchInAllBoards: value
    })
  },

  setBoardSlugAndViewType: (slug: string, viewType: AllViewTypes, additionalData?: AdditionalBoardFetchParameters) => async (dispatch, getState) => {
    if(!dispatch(NavigationActions.normalizeTheBoardSlugAndViewType(slug, viewType))) {
      return { slugChanged: false, viewTypeChanged: false }
    }
    const previousSlug = BoardSelectors.getCurrentBoardSlug(getState())
    const previousViewType = getState().get('viewType')

    const slugChanged = slug !== previousSlug
    const viewTypeChanged = viewType !== previousViewType
    if (slugChanged || viewTypeChanged || additionalData !== undefined) {
      dispatch({type: types.BOARD_SET_SLUG, slug})
      dispatch(BoardActions.setViewType(viewType))

      await dispatch(fetchCurrentBoardData({
        onlyFetchViewTypeData: !slugChanged,
      }, additionalData))
    }

    return { slugChanged, viewTypeChanged }
  },

  refetchCurrentBoardData: () => fetchCurrentBoardData({ onlyFetchViewTypeData: false }),

  refetchAllBoardSummaries: () => async (dispatch) => {
    const data = await apiCall({
      path: "/boards/all",
    })
    dispatch(GenericActions.pushAllObjects(data))
  },

  fetchAndEnterBoardOfIdea: (ideaClientId: string) => async (dispatch, getState) => {
    await dispatch(BoardActions.refetchAllBoardSummaries())
    const boardSummary = BoardSelectors.getAllBoardSummariesByRepresentedIdeaClientId(
      getState()
    ).get(ideaClientId)
    if (boardSummary) {
      dispatch(NavigationActions.enterTheBoard(boardSummary.clientId, true))
    } else {
      dispatch(BoardActions.makeIdeaABoardAndEnter(ideaClientId, true))
    }
  },

  markIdeaClientIdsAsVisible,

  addToBoardIdeaClientIds: (ideaClientIds: string[]) => (dispatch, getState) => {
    if (ideaClientIds.length === 0) return
    const boardIdeaClientIds = IdeaSelectors.getBoardIdeaClientIds(getState())
    dispatch({
      type: types.BOARD_SET_BOARD_IDEA_CLIENT_IDS,
      boardIdeaClientIds: boardIdeaClientIds.merge(Map(
        ideaClientIds.map(ideaClientId => [ideaClientId, true])
      ) as any)
    })
  },

  clearBoardState: () => async (dispatch) => {
    dispatch({type: types.BOARD_SET_SLUG, slug: null})
    dispatch({type: types.SET_VISIBILITY_BY_IDEA_CLIENT_ID, visibilityByIdeaClientId: Set<string>() })
  },

  createIdeasAndConnection: (ideaStubs: IdeaSourceObjectType[], connectionStubs: ConnectionSourceObjectType[]) => (dispatch): {connectionClientIds: string[], ideaClientIds: string[]} => {
    const {connectionClientIds, ideaClientIds} = dispatch(_createIdeasAndConnectionsOnCurrentBoard(ideaStubs, connectionStubs))
    return {connectionClientIds, ideaClientIds};
  },

  connectIdeas: ({sourceIdeaClientId, targetIdeaClientId}) => (dispatch) => {
    const connection = Connection({ sourceIdeaClientId, targetIdeaClientId })

    const { connectionClientIds } = dispatch(_createIdeasAndConnectionsOnCurrentBoard([], [connection]))

    return connectionClientIds[0]
  },

  createIdeaOnCurrentBoard: (ideaData) => (dispatch: Dispatch<any>) => {
    const { ideaClientIds } = dispatch(_createIdeasAndConnectionsOnCurrentBoard([ideaData], []))

    return ideaClientIds[0]
  },

  createIdeasOnCurrentBoard: (ideas) => (dispatch: Dispatch<any>) => {
    const { ideaClientIds } = dispatch(_createIdeasAndConnectionsOnCurrentBoard(ideas, []))
    return ideaClientIds
  },

  insertIdeaOnCurrentBoardAtIndex: (index: number) => (dispatch, getState) => {
    const { ideaClientIds } = dispatch(_createIdeasAndConnectionsOnCurrentBoard([{ title: '' }], []))

    const listLayout = ListViewSelectors.getCurrentListViewLayout(getState())

    const newListViewLayout = [
      ...listLayout.slice(0,index),
      ideaClientIds[0],
      ...listLayout.slice(index)
    ]

    console.log("newListViewLayout", newListViewLayout)

    dispatch(BoardActions.upsertListViewLayout(
      BoardSelectors.getCurrentBoardClientId(getState()),
      newListViewLayout
    ))
    dispatch(GenericActions.pushAllObjects({
      listViewLayout: newListViewLayout
    }))

    return ideaClientIds[0]
  },

  createIdeasFromTextOnCurrentBoard: (text: string, generateConnections?: (ideaStubs: IdeaRecord[]) => ConnectionSourceObjectType[]) => (dispatch: Dispatch<any>) => {
    const splitText = text
      .split('\n')
      .filter((idea: string) => {
        return !(/^\s+$/.test(idea)) && idea.length >= 1;
      })

    let ideasToCreate = [text]
    if (splitText.length > 1) {
      if (confirm("Do you want to create a separate idea for each line?")) {
        ideasToCreate = splitText
      }
    }

    const ideas = ideasToCreate.map(title => Idea({ title }))
    const connections = generateConnections ? generateConnections(ideas) : []
    const result = dispatch(_createIdeasAndConnectionsOnCurrentBoard(ideas, connections))
    return result.ideaClientIds
  },

  createPeerIdeaOnCurrentBoard: (ideaStub, sourceIdeaClientId) => (dispatch) => {
    const idea = Idea(ideaStub)

    const connection = Connection({
      sourceIdeaClientId: sourceIdeaClientId,
      targetIdeaClientId: idea.clientId
    })

    dispatch(_createIdeasAndConnectionsOnCurrentBoard([idea], [connection]))

    return {
      ideaClientId: idea.clientId,
      connectionClientId: connection.clientId
    }
  },

  makeIdeaABoardAndEnter: (ideaClientId, replace = false) => async (dispatch) => {
    const data = await apiCall({
      path: `/ideas/makeBoard/${ideaClientId}`,
      method: 'POST',
    })

    dispatch(GenericActions.pushAllObjects(data))
    dispatch(NavigationActions.enterTheBoardAttachedToAnIdea(ideaClientId, replace))
  },

  addColorInstance: (colorId, label) => async (dispatch) => {
    const updater = (colorInstances: ColorInstance[]) => {
      const newColorInstance = {
        colorId,
        label,
      }
      return [...colorInstances, newColorInstance]
    }
    await dispatch(updateColorInstances(updater))
  },

  updateColorInstance: (colorId, label) => async (dispatch) => {
    const updater = (colorInstances: ColorInstance[]) => {
      const newColorInstance = {
        colorId,
        label,
      }
      return colorInstances
        .map(colorInstance => colorInstance.colorId === colorId ? newColorInstance : colorInstance)
    }
    await dispatch(updateColorInstances(updater))
  },

  reorderColorInstance: ({ oldIndex, newIndex }) => async (dispatch) => {
    const updater = (colorInstances: ColorInstance[]) => {
      return arrayMove(colorInstances, oldIndex, newIndex)
    }
    await dispatch(updateColorInstances(updater))
  },

  upsertHomepage: (boardClientId, draftBlob) => async () => {
    ErrorHelpers.assert(!!boardClientId, 'boardClientId not specified')
    await apiCall({
      path: `/boards/homepage/${boardClientId}`,
      method: "POST",
      data: { draftBlob }
    })
  },

  upsertListViewLayout: (boardClientId, listViewLayout) => async () => {
    ErrorHelpers.assert(!!boardClientId, 'boardClientId not specified')
    await apiCall({
      path: `/boards/listViewLayout/${boardClientId}`,
      method: "POST",
      data: { listViewLayout }
    })
  },

  pushBoardMemberships: (boardMemberships: BoardMembership[]) => (dispatch, getState) => {
    const boardClientId = BoardSelectors.getCurrentBoardClientId(getState())
    const boardIdeaClientIds: string[] = []
    for (const boardMembership of boardMemberships) {
      if (boardMembership.boardClientId === boardClientId)
      boardIdeaClientIds.push(boardMembership.ideaClientId)
    }
    dispatch(BoardActions.addToBoardIdeaClientIds(boardIdeaClientIds))

    const boardMembershipByBoardByIdea: { [key: string]: { [key: string]: BoardMembership } } = {}
    for (const boardMembership of boardMemberships) {
      boardMembershipByBoardByIdea[boardMembership.ideaClientId] = boardMembershipByBoardByIdea[boardMembership.ideaClientId] || {}
      boardMembershipByBoardByIdea[boardMembership.ideaClientId][boardMembership.boardClientId] = boardMembership
    }
    const boardMembershipsByIdeaClientId = IdeaSelectors.getBoardMembershipsByIdeaClientId(getState()).withMutations(boardMemberships => {
      for (const [ideaClientId, newBoardMembershipByBoard] of Object.entries(boardMembershipByBoardByIdea)) {
        boardMemberships.update(ideaClientId, boardMembershipByBoard =>
          (boardMembershipByBoard || Map<string, BoardMembership>())
            .merge(Map<string, BoardMembership>(Object.entries(newBoardMembershipByBoard)))
        )
      }
    })

    dispatch({
      type: types.BOARD_SET_BOARDMEMBERSHIPS,
      boardMembershipsByIdeaClientId: boardMembershipsByIdeaClientId
    })
  },

  incrementCurrentBoardSummaryIdeaCount: (ideaDelta: number) => (dispatch, getState) => {
    dispatch({ type: types.INCREMENT_CURRENT_BOARD_SUMMARY_IDEA_COUNT, ideaDelta, })
  }
}
