import * as React from 'react'

import Dropdown, { OverflowSide } from '../Dropdown'
export { OverflowSide } from '../Dropdown'

import UniversalTextInput from 'components/common/UniversalTextInput'
import Icon from 'components/common/Icon'

import Infinite from 'react-infinite'
import ReactDOM from 'react-dom'
import classnames from 'classnames'

import debounce from 'helpers/debounce'
import { KeyboardHelpers } from 'helpers/Keyboard'
import { getTextWithMatchesHighlighted } from 'helpers/format'
import styles from './AutocompleteSearchBar.styl'

enum SearchQueryUpdateActions {
  CLEAR = Symbol('CLEAR') as any,
  USE_SELECTED_SUGGESTION = Symbol('USE_SELECTED_SUGGESTION') as any,
  KEEP_QUERY = Symbol('KEEP_QUERY') as any,
}

// this fixed height is set by several styles of the component
const SCROLL_CONTAINER_ELEMENT_HEIGHT = 30

// react-infinite is slow with large number of elements
// and those elements need to be created too
// therefore we impose a upper limit on number of search results
const MAX_NUMBER_OF_SUGGESTIONS = 1000
const INFINITE_LOAD_STEP_SIZE = 20

export interface BasicSuggestion {
  title: string,
  textMatches: any[]
  clientId?: string
  belongsToBoard?: boolean
}

interface Header {
  label: string
  isHeader: true
}

interface GetSearchResults<Suggestion> {
  (params: { searchQuery: string, searchResults: any[] }):
    { label: string, group: string, searchResults: Suggestion[] }[]
}

export interface AutocompleteSearchBarProps<Suggestion> {
  omitInputStyling: boolean
  bare?: boolean
  maxNumberOfResultsToDisplay: number
  useInlineDropdown?: boolean
  overflowSide?: OverflowSide
  allowMultipleLines: boolean
  placeholder: string
  searchInAllBoards?: boolean

  handleSuggestionSelected: (suggestion: string | Suggestion, allSearchResults: Suggestion[]) => void
  handleInputTextSelected?: (text: string) => void
  handleOnChange: (searchQuery: string) => void
  handleAddRelatedSuggestions?: (ideaClientIds: string[]) => void
  handleOnHighlightedSuggestionChange?: (suggestion: Suggestion) => void
  exposeFocusHandler?: (config: { focus: () => void, isFocused: () => boolean }) => void

  getSearchQueryUpdateActionOnSuggestionSelection?: (suggestion: string | Suggestion) => SearchQueryUpdateActions
  getSuggestionTextParts?: (suggestion: Suggestion) => { prefix?: string, suffix?: string }

  SearchBarActions: { performSearch: (searchQuery: string) => { fastResultsPromise: Promise<any[]>, slowResultsPromise: Promise<any[]> } }
  getSearchResults: GetSearchResults<Suggestion>

  initialValue?: string,
  disabled?: boolean,
  autofocus?: boolean,
  className?: string,
  hasNoInitialSelection?: boolean,
  onMouseOut?: (event) => void,

  allowDefaultActions?: ("enter" | "escape" | "tab" | "up" | "down")[]
  onEscape?: (event: React.KeyboardEvent<any>) => void
  onBackspace?: (event: React.KeyboardEvent<any>) => void
  onDelete?: (event: React.KeyboardEvent<any>) => void
  onEnter?: (event: React.KeyboardEvent<any>) => void
  onTab?: (event: React.KeyboardEvent<any>) => void
  onArrowUp?: (event: React.KeyboardEvent<any>) => void
  onArrowDown?: (event: React.KeyboardEvent<any>) => void
}

interface AutocompleteSearchBarState {
  selectedSuggestionIndex: number
  queryInputIsFocused: boolean
  currentResults: any[]
  currentSearchQuery: string
  infiniteLoadLastIndex: number
  isSearching: boolean
}

export default class AutocompleteSearchBar<Suggestion extends BasicSuggestion>
  extends React.Component<AutocompleteSearchBarProps<Suggestion>, AutocompleteSearchBarState>
{
  static SearchQueryUpdateActions = SearchQueryUpdateActions

  private _latestSearchPromises: { fastResultsPromise: Promise<any[]>, slowResultsPromise: Promise<any[]> }

  constructor(props: AutocompleteSearchBarProps<Suggestion>) {
    super(props)
    if (props.exposeFocusHandler) {
      props.exposeFocusHandler({
        focus: this._focus,
        isFocused: this._isFocused
      })
    }

    this.state = {
      queryInputIsFocused: false,
      currentResults: [],
      currentSearchQuery: "",
      infiniteLoadLastIndex: INFINITE_LOAD_STEP_SIZE,
      selectedSuggestionIndex: this.props.hasNoInitialSelection ? -1 : 0,
      isSearching: false
    }
  }

  componentWillUnmount() {
    // Flush the throttled onChange handlers to make sure no change is lost
    this.handleOnChangeDebounced.flush()
  }

  private _handleInputBlur = () => {
    this.handleOnChangeDebounced.flush()
    this.setState({ queryInputIsFocused: false })
  }

  private _handleInputFocus = () => {
    this.setState({ queryInputIsFocused: true })

    // Force refresh of search results on focus change:
    //   this is necessary for the first focus of search bar, as there are no search results yet,
    //   but it could be beneficial for any subsequent focus,
    //   so we do it on every focus for simplicity and reliability
    this._handleInputChange()
  }

  private _isFocused = () => {
    return this.state.queryInputIsFocused
  }

  private handleEscape = (event) => {
    // Flush all the defered changes before calling the onEscape handler to make sure it has up-to-date data
    this.handleOnChangeDebounced.flush()
    if (this.props.onEscape) {
      this.props.onEscape(event)
      return;
    }
    this._setSearchInputValue('')
    this.inputRef.current && this.inputRef.current.blur()
  }

  private _shouldShowDropdown () {
    return (
      this._isFocused() &&
      this.getFlatSearchResults().length > 0
    )
  }

  private _setSelectedSuggestionIndex = (selectedSuggestionIndex) => {
    this.setState({ selectedSuggestionIndex })
    const selectedSuggestion = selectedSuggestionIndex < 0
      ? null
      : this.getFlatSearchResults()[selectedSuggestionIndex]
    this.props.handleOnHighlightedSuggestionChange && this.props.handleOnHighlightedSuggestionChange(selectedSuggestion)
  }

  private handleUpArrow = (event) => {
    // TODO: Whether preventDefault should be called should be the concern of the UniversalTextInput
    !this.props.allowMultipleLines && event.preventDefault()

    let selectedSuggestionIndex = Math.max(this.state.selectedSuggestionIndex - 1, 0)
    if (KeyboardHelpers.isCtrlOrMacCmd(event)) {
      // Ctrl+ArrowUp moves to the first option. If on macOS, Cmd+ArrowUp also works
      event.preventDefault()
      selectedSuggestionIndex = 0
    }
    if (selectedSuggestionIndex !== this.state.selectedSuggestionIndex) {
      event.preventDefault()
      this._setSelectedSuggestionIndex(selectedSuggestionIndex)
    }

    const index = this.findIndexInSearchResultsWithHeaders(selectedSuggestionIndex)
    this.scrollContainerUpIfNecessary(index)

    this.props.onArrowUp && this.props.onArrowUp(event)
  }

  private handleDownArrow = (event) => {
    !this.props.allowMultipleLines && event.preventDefault()

    const flatSearchResultsLength = this.getFlatSearchResults({limit: false}).length
    let selectedSuggestionIndex = Math.min(
      this.state.selectedSuggestionIndex + 1,
      flatSearchResultsLength - 1
    )
    if (KeyboardHelpers.isCtrlOrMacCmd(event)) {
      // Ctrl+ArrowDown moves to the last option. If on macOS, Cmd+ArrowDown also works
      event.preventDefault()
      selectedSuggestionIndex = flatSearchResultsLength - 1
    }
    if (selectedSuggestionIndex !== this.state.selectedSuggestionIndex) {
      event.preventDefault()
      this._setSelectedSuggestionIndex(selectedSuggestionIndex)
    }

    const index = this.findIndexInSearchResultsWithHeaders(selectedSuggestionIndex)
    const newInfiniteLoadLastIndex = Math.min(
      index + 1,
      MAX_NUMBER_OF_SUGGESTIONS,
    )
    if (this.state.infiniteLoadLastIndex < newInfiniteLoadLastIndex) {
      this.setState({
        infiniteLoadLastIndex: newInfiniteLoadLastIndex
      }, () => {
        this.scrollContainerDownIfNecessary(index)
      })
    } else {
      this.scrollContainerDownIfNecessary(index)
    }

    this.props.onArrowDown && this.props.onArrowDown(event)
  }

  private scrollContainerUpIfNecessary(itemIndex) {
    const containerTop = this.suggestionScrollContainer.scrollTop
    const suggestionTop = itemIndex * SCROLL_CONTAINER_ELEMENT_HEIGHT

    // if suggestion at least partially beyond top of container
    if (containerTop > suggestionTop) {
      // snap top of result to top of container
      this.suggestionScrollContainer.scrollTop = itemIndex * SCROLL_CONTAINER_ELEMENT_HEIGHT
    }
  }

  private scrollContainerDownIfNecessary(itemIndex) {
    const scrollContainerHeight = this.computeScrollContainerHeight()

    const containerBottom = this.suggestionScrollContainer.scrollTop + scrollContainerHeight
    const suggestionTop = itemIndex * SCROLL_CONTAINER_ELEMENT_HEIGHT
    const suggestionBottom = suggestionTop + SCROLL_CONTAINER_ELEMENT_HEIGHT

    // if suggestion is at least partially beyond bottom of container
    if (containerBottom < suggestionBottom) {
      // snap bottom of container to bottom of result
      this.suggestionScrollContainer.scrollTop = suggestionBottom - scrollContainerHeight
    }
  }

  private _handleSuggestionIndexSelected(suggestionIndex) {
    const currentSearchQuery = this._getSearchQuery()

    // we want to wait for current search to end to match query and results,
    // but skip any possible future result updates
    const currentQueryPromises = Promise.all([
      this._latestSearchPromises.fastResultsPromise,
      this._latestSearchPromises.slowResultsPromise
    ])

    currentQueryPromises.then(() => {
      const searchResults = this.getFlatSearchResults({ limit: false })
      const noResults = searchResults.length === 0

      const selectedSuggestion = noResults || suggestionIndex < 0
        ? currentSearchQuery
        : searchResults[suggestionIndex]

      this._handleSuggestionSelected(selectedSuggestion, searchResults)
    })
  }

  private _handleSuggestionSelected(suggestion: string | Suggestion, allSearchResults: Suggestion[]) {
    if (typeof suggestion === 'string' && suggestion.trim().length === 0) return

    const searchInputUpdateAction = this.props.getSearchQueryUpdateActionOnSuggestionSelection
      ? this.props.getSearchQueryUpdateActionOnSuggestionSelection(suggestion)
      : SearchQueryUpdateActions.USE_SELECTED_SUGGESTION

      if (searchInputUpdateAction === SearchQueryUpdateActions.CLEAR) {
        this._setSearchInputValue('')
      } else if (searchInputUpdateAction === SearchQueryUpdateActions.USE_SELECTED_SUGGESTION) {
        typeof suggestion === 'string'
          ? this._setSearchInputValue(suggestion)
          : this._setSearchInputValue(suggestion.title || suggestion)
      } // do nothing for SearchQueryUpdateActions.KEEP_QUERY

    // selection is finished, no need to display dropdown and keep focus now
    this.inputRef.current && this.inputRef.current.blur()

    this.props.handleSuggestionSelected(suggestion, allSearchResults)
  }

  private _handleMouseOut = (event) => {
    this.props.onMouseOut && this.props.onMouseOut(event)
  }

  // FIXME: this is a workaround for keys like Enter and Tab fired before corresponding debounced handler `handleOnChangeDebounced` gets fired
  // - this results in absent/obsolete search query
  // update is needed as right now we want to keep query and results in sync when processing suggestion selection,
  // even if actual render doesn't have time to render
  _forceOnChangeAndHandleSuggestionSelected () {
    // WORKAROUND: store suggestion index before it possible gets reset by input change
    const currentSelectedSuggestionIndex = this.state.selectedSuggestionIndex
    this.handleOnChangeDebounced.flush()
    this._handleSuggestionIndexSelected(currentSelectedSuggestionIndex)
  }

  private _handleEnterKey = (event) => {
    const { handleInputTextSelected } = this.props
    if (handleInputTextSelected && KeyboardHelpers.isCtrlOrMacCmd(event)) {
      // On Ctrl+Enter, always create a new node. If on macOS, Cmd+Enter also works
      handleInputTextSelected(this.state.currentSearchQuery)
      this.inputRef.current && this.inputRef.current.blur()
    } else {
      this._forceOnChangeAndHandleSuggestionSelected()
    }
  }

  _setSearchInputValue (newValue) {
    if(this.inputRef.current === null) return
    this.inputRef.current.setValue(newValue)
    this._handleInputChange()
  }

  private _handleInputChange = () => {
    const currentSearchQuery = this._getSearchQuery()
    this.props.handleOnChange(currentSearchQuery)

    const newSelectedSuggestionIndex = this.state.selectedSuggestionIndex < 0 ? -1 : 0
    this._setSelectedSuggestionIndex(newSelectedSuggestionIndex)

    // prepare for both sync and async nature of `getSearchResults`
    const searchPromises = this.props.SearchBarActions.performSearch(currentSearchQuery)
    this._latestSearchPromises = searchPromises

    const { fastResultsPromise, slowResultsPromise } = this._latestSearchPromises

    const resolveSearchPromise = (currentResults) => {
      // another fetch attempt could be made recently, making these results obsolete
      const currentResultsAreActual = this._latestSearchPromises === searchPromises
      if (currentResultsAreActual) {
        this.setState({ currentResults: currentResults.filter((r => {
            if(this.props.searchInAllBoards === false && r.ideaClientId && r.belongsToBoard === false){
              return false;
            }
            return true;
          })), currentSearchQuery })
      }
    }

    fastResultsPromise.then(resolveSearchPromise)
    slowResultsPromise.then(resolveSearchPromise)

    this.setState({ isSearching: true })
    Promise.all([fastResultsPromise, slowResultsPromise]).then(() => {
      this.setState({isSearching: false})
    })
  }
  private handleOnChangeDebounced = debounce(this._handleInputChange, 500)

  private _getFlatSearchResults({
    excludeGroup=null, limit=true, includeHeaders=false
  } = {
    excludeGroup: null as string | null, limit: true as boolean, includeHeaders: false as boolean
  }): (Suggestion | Header)[] {
    const groups = this.props.getSearchResults({
      searchQuery: this.state.currentSearchQuery,
      searchResults: this.state.currentResults,
    })
    const maxSize = limit ? this.state.infiniteLoadLastIndex: Infinity
    const searchResults: (Suggestion | Header)[] = []
    let groupIndex = 0
    while (searchResults.length < maxSize && groupIndex < groups.length) {
      const group = groups[groupIndex]
      if (excludeGroup === null || group.group !== excludeGroup) {
        if (includeHeaders && group.label !== null) {
          searchResults.push({ isHeader: true, label: group.label })
        }
        searchResults.push(...group.searchResults.slice(0, maxSize))
      }
      groupIndex++
    }
    return searchResults.slice(0, maxSize)
  }

  private getFlatSearchResultsWithHeaders(): (Suggestion | Header)[] {
    return this._getFlatSearchResults({
      excludeGroup: null, limit: true, includeHeaders: true
    })
  }

  private findIndexInSearchResultsWithHeaders(suggestionIndex: number) {
    const groups = this.props.getSearchResults({
      searchQuery: this.state.currentSearchQuery,
      searchResults: this.state.currentResults,
    })

    let groupIndex = 0
    let accumulatedSuggestionsCount = 0
    while (accumulatedSuggestionsCount <= suggestionIndex) {
      const group = groups[groupIndex]
      accumulatedSuggestionsCount += group.searchResults.length
      groupIndex++
    }

    return suggestionIndex + groupIndex
  }

  private getFlatSearchResults({ excludeGroup=null, limit=true } = {
    excludeGroup: null as string | null, limit: true as boolean
  }): Suggestion[] {
    return this._getFlatSearchResults({
      excludeGroup, limit, includeHeaders: false
    }) as Suggestion[]
  }

  private _getSearchQuery () {
    return this.inputRef.current ? this.inputRef.current.getValue() : ''
  }

  computeScrollContainerHeight() {
    const maxHeight = SCROLL_CONTAINER_ELEMENT_HEIGHT * this.props.maxNumberOfResultsToDisplay
    const totalHeight = SCROLL_CONTAINER_ELEMENT_HEIGHT * this.getFlatSearchResultsWithHeaders().length
    return Math.min(maxHeight, totalHeight)
  }

  private readonly inputRef: React.RefObject<UniversalTextInput> = React.createRef()

  render() {
    return (
      <div
        className={classnames(styles.container, this.props.omitInputStyling ? styles.unstyled : styles.regular)}
        onMouseOut={this._handleMouseOut}
      >
        <Dropdown
          show={this._shouldShowDropdown()}
          contentsRenderer={this._renderDropdown}
          useInlineDropdown={this.props.useInlineDropdown}
          overflowSide={this.props.overflowSide}
        >
          <div
            className={classnames(
              styles.searchInputContainer,
              this.props.bare && styles.bareContainer,
              this.props.className && this.props.className
            )}
          >
            <UniversalTextInput
              ref={this.inputRef}
              multiline={this.props.allowMultipleLines}
              className={classnames(
                styles.searchInput,
                this.props.bare && styles.bareInput,
                this.props.allowMultipleLines && styles.multiline,
              )}
              placeholder={this.props.placeholder}
              onBlur={this._handleInputBlur}
              onFocus={this._handleInputFocus}
              onChange={this.handleOnChangeDebounced}
              onEnter={this._handleEnterKey}
              onArrowDown={this.handleDownArrow}
              onArrowUp={this.handleUpArrow}
              onEscape={this.handleEscape}
              onBackspace={this.props.onBackspace}
              onDelete={this.props.onDelete}
              onTab={this.props.onTab}
              text={this.props.initialValue}
              disabled={this.props.disabled}
              autofocus={this.props.autofocus}
              allowDefaultActions={this.props.allowDefaultActions}
            />
            { this.state.isSearching && <Icon className={styles.loadingIcon} fontAwesomeIcon="spinner" spin /> }
          </div>
        </Dropdown>
      </div>
    )
  }

  private suggestionScrollContainer: Infinite

  private _renderDropdown = () => {
    // TODO: extract reusable component that contains this logic of events muting
    // (to be used instead of <Infinite>, etc.)
    // onClick - prevent default action to prevent false click on node
    // onMouseUp - prevent default action to prevent click event on scroll that would result in
    // stop default actions to prevent false clicks , like clicks on scroll bar - anyway only clicks on suggestions matter
    const numRelatedSuggestions = this.getFlatSearchResults({
      excludeGroup: "newIdea", limit: false
    }).length
    return <div
      className={classnames(styles.autocompleteWrapper)}
      onWheel={this._muteEvent}
      onClick={this._muteEventAndPreventDefault}
      onMouseUp={this._muteEventAndPreventDefault}
    >
      <Infinite
        ref={suggestionScrollContainer =>
          this.suggestionScrollContainer = ReactDOM.findDOMNode(suggestionScrollContainer)
        }
        containerHeight={this.computeScrollContainerHeight()}
        elementHeight={SCROLL_CONTAINER_ELEMENT_HEIGHT}
        infiniteLoadBeginEdgeOffset={200}
        onInfiniteLoad={this._handleInfiniteLoad}
      >
        {this._renderSuggestions()}
      </Infinite>
      {/* Remove the following code - it's too specific to one one case of AutocompleteSearchBar */}
      { this.props.handleAddRelatedSuggestions && (numRelatedSuggestions > 0) &&
        <div
          onMouseDown={this._onAddRelatedSuggestions}
          onMouseUp={this._onAddRelatedSuggestionsMouseUp}
          className={styles.controls}
        >
          <button className={styles.addRelatedButton}>Add All Results ({numRelatedSuggestions})</button>
        </div>
      }
    </div>
  }

  private _muteEvent (event) {
    //don't propagate scroll events to not mess with parents' scrolling
    event.stopPropagation()
  }

  private _muteEventAndPreventDefault = (event) => {
    this._muteEvent(event)

    event.preventDefault()
  }

  private _focus = () => {
    this.inputRef.current && this.inputRef.current.focus()
  }

  private _onAddRelatedSuggestions = (event) => {
    const relatedSuggestionIds = this.getFlatSearchResults({
      excludeGroup: "newIdea", limit: false
    }).map(suggestion => suggestion.clientId)
    this.props.handleAddRelatedSuggestions && this.props.handleAddRelatedSuggestions(relatedSuggestionIds)
    event.stopPropagation()
    event.preventDefault()
  }

  private _onAddRelatedSuggestionsMouseUp = () => {
    this.inputRef.current && this.inputRef.current.blur()
  }

  private _handleInfiniteLoad = () => {
    this.setState(state => ({
      infiniteLoadLastIndex: Math.min(
        state.infiniteLoadLastIndex + INFINITE_LOAD_STEP_SIZE,
        MAX_NUMBER_OF_SUGGESTIONS
      )
    }))
  }

  private _renderSuggestions() {
    const { selectedSuggestionIndex } = this.state
    // TODO: find a more elegant solution
    let suggestionIndex = -1
    return this.getFlatSearchResultsWithHeaders().map((suggestionOrHeader, index) => {
      if ("isHeader" in suggestionOrHeader && suggestionOrHeader.isHeader === true) {
        const header = suggestionOrHeader as Header
        return <div
          key={index}
          className={classnames(styles.searchResult, styles.header)}
        >
          {header.label}
        </div>
      }
      const suggestion = suggestionOrHeader as Suggestion
      suggestionIndex++
      let currentSuggestionIndex = suggestionIndex
      const isSelected = selectedSuggestionIndex === suggestionIndex
      return <div
        key={index}
        className={classnames(styles.searchResult, isSelected && styles.selectedSearchResult)}
        onMouseOver={() => this.handleMouseOverSuggestion(currentSuggestionIndex)}
        onClick={(event) => this._handleClickSuggestion(event, currentSuggestionIndex)}
        onMouseDown={this.handleOnMouseDownSuggestion}
      >
        <div className={styles.boardSuggestionWrapper}>
          <span>{ this._getSuggestionText(suggestion) }</span>
          {!suggestion.belongsToBoard && suggestion.clientId && <span className={styles.boardBadge}>External</span>}
        </div>
      </div>
    })
  }

  private handleOnMouseDownSuggestion = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  private _getSuggestionText (suggestion: Suggestion) {
    const optionalParts = this.props.getSuggestionTextParts && this.props.getSuggestionTextParts(suggestion)

    return getTextWithMatchesHighlighted(
      suggestion.title,
      suggestion.textMatches,
      {
        ...optionalParts,
        matchClass: styles.match,
      }
    )
  }

  private handleMouseOverSuggestion(suggestionIndex) {
    this._setSelectedSuggestionIndex(suggestionIndex)
  }

  private _handleClickSuggestion (event, suggestionIndex) {
    event.stopPropagation()
    event.preventDefault()
    this._handleSuggestionIndexSelected(suggestionIndex)
  }
}
