import {UserConstants} from './constants'
import {Map} from 'immutable'

import { AppStateRecord } from 'appRoot/state'

import { UserProfileRecord } from 'shared/models/UserProfile'

// TODO: Integrate this with GenericActions.ts#pushAllObjects
export default (reducer) => {
  reducer.handle(UserConstants.RESET,  (state: AppStateRecord) => {
    return state.set('userProfiles', Map<number, UserProfileRecord>())
  })
}

// create tuples array first to enable us of userIds as numbers in Map
// creating a JS object instead with ids as keys would convert them to strings, so they would not match userIds in other entities
