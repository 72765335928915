import apiCall from 'helpers/apiCall'

import { generateClientId } from 'shared/helpers/Id'

import { Attribute } from 'shared/models/Attribute'

import {AttributeSelectors} from './selectors'
import { AnyAction } from 'redux';
import { Dispatch } from 'redux';


import { pushAllObjects } from 'domain/GenericActions'
import {AppStateRecord} from "appRoot/state";
const AttributeActions = {
  pushAttributes: (attributes: Attribute[]) => (dispatch) => {
    return dispatch(pushAllObjects({ attributes }))
  },

  refetchAll: () => async (dispatch) => {
    const { attributes } = await apiCall({
      path: '/attributes/',
      method: 'GET',
    })

    dispatch(AttributeActions.pushAttributes(attributes))
  },
  
  create: (name: string) => async (dispatch, getState) => {
    // TODO: incorporate inside shared/models/Attribute
    const newAttribute = {
      clientId: generateClientId(),
      name,
      isRestricted: false,
      isGuardedByTeamCode: false,
      isPinned: false
    }

    dispatch(AttributeActions.pushAttributes([newAttribute]))

    const data = await apiCall({
      path: `/attributes/`,
      method: 'POST',
      data: newAttribute,
    })

    dispatch(AttributeActions.pushAttributes(data.attributes))

    return AttributeSelectors.getByClientId(getState(), newAttribute.clientId)
  },

  updateAttribute: (clientId: string, update: Partial<Attribute>) => async (dispatch) => {
    // TODO: add optimistic update
    const data = await apiCall({
      path: `/attributes/${clientId}`,
      method: 'PATCH',
      data: update,
    })

    dispatch(AttributeActions.pushAttributes(data.attributes))
  },

  delete: (clientId: string) => async (dispatch) => {
    // TODO: add optimistic update
    const data = await apiCall({
      path: `/attributes/${clientId}`,
      method: 'DELETE',
    })

    dispatch(AttributeActions.pushAttributes(data.attributes))
  }
}

export {AttributeActions}
