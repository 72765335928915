import { REGISTER_AN_ERROR, MAX_NUMBER_OF_REGISTERED_ERRORS, SET_IS_ERROR_OVERLAY_VISIBLE } from './constants'

export default (reducer) => {
  reducer.handle(REGISTER_AN_ERROR, (state, action) => {
    return state.update(
      'mostRecentErrors',
      mostRecentErrors => ([action.error, ...mostRecentErrors]).slice(0,MAX_NUMBER_OF_REGISTERED_ERRORS)
    );
  })

  reducer.handle(SET_IS_ERROR_OVERLAY_VISIBLE, (state, action) => {
    return state.set('isErrorOverlayVisible', action.isErrorOverlayVisible)
  })
}