import { Map } from 'immutable'

import * as EvImm from 'evolving-immutable'
import * as memoize from 'shared/helpers/memoize'

import * as IdeaSelectors from 'domain/Idea/selectors'
import {CommentSelectors} from 'domain/Comment/selectors'

import { IdeaLikeRecord } from 'shared/models/IdeaLike'
import { CommentLikeRecord } from 'shared/models/CommentLike'
import { GenericLike } from 'shared/models/GenericLike'

import {CurrentUserModuleState, UNAUTHORIZED_USER_PROFILE} from './_moduleState'

import { AppStateRecord } from 'appRoot/state'
import { UserProfileRecord } from 'shared/models/UserProfile';

const CurrentUserSelectors = {
  getId (state: AppStateRecord) {
    return _getFieldInCurrentUserProfile(state, 'userId')
  },

  getUsername (state: AppStateRecord) {
    return _getFieldInCurrentUserProfile(state, 'username')
  },

  getEmail (state: AppStateRecord) {
    return _getFieldInCurrentUserProfile(state, 'email')
  },

  getCurrentUserProfile(state: AppStateRecord) {
    // FIXME: HACK: TODO: Remove the UNAUTHORIZED_USER_PROFILE as a default value for the userProfile
    const userProfile = CurrentUserModuleState.getPart(state, 'userProfile')
    return userProfile === UNAUTHORIZED_USER_PROFILE
      ? null
      : userProfile
  },

  getTeamCodes (state: AppStateRecord) {
    return _getFieldInCurrentUserProfile(state, 'teamCodes') || []
  },

  // TODO: change all the following to regular group permissions system implemented separately from user data
  canEditRestrictedAttributes (state: AppStateRecord) {
    return _hasPermission(state, 'canEditRestrictedAttributes')
  },

  canAdminUsers (state: AppStateRecord) {
    return _hasPermission(state, 'canAdminUsers')
  },

  canAdminIdeas (state: AppStateRecord) {
    return _hasPermission(state, 'canAdminIdeas')
  },

  canAdminComments (state: AppStateRecord) {
    return _hasPermission(state, 'canAdminComments')
  },

  canImport (state: AppStateRecord) {
    return _isSuperUser(state)
  },

  canManageDB (state: AppStateRecord) {
    return _isSuperUser(state)
  },

  canMerge (state: AppStateRecord) {
    return _isSuperUser(state)
  },

  canDebug (state: AppStateRecord) {
    return _isSuperUser(state)
  },

  canAdminAttributes (state: AppStateRecord) {
    return CurrentUserSelectors.canEditRestrictedAttributes(state)
  },

  canManageTeamCodes (state: AppStateRecord) {
    return _hasPermission(state, 'canManageTeamCodes')
  },

  getEditCapabilityByIdeaClientId: _createEditCapabilityByEntityClientIdSelector({
    getEntities: (state: AppStateRecord) => IdeaSelectors.getExistingIdeasCache(state),
    getAdmin: (state: AppStateRecord) => CurrentUserSelectors.canAdminIdeas(state),
    computeEditCapability: ({ entity, canAdminEntities, currentUserId }) => {
      const canDelete = canAdminEntities || (entity.get('userId') === currentUserId && entity.get('likeCount') === 0)
      const canEditText = canAdminEntities
      // For now we allow everyone to edit statuses
      // TODO: #osdiabPrivacy Develop a proper permissions system
      const canEditStatus = true//canAdminEntities
      return { canEditText, canEditStatus, canDelete }
    }
  }),

  getEditCapabilityByCommentClientId: _createEditCapabilityByEntityClientIdSelector({
    getEntities: (state) => CommentSelectors.getAllComments(state),
    getAdmin: (state) => CurrentUserSelectors.canAdminComments(state),
    computeEditCapability: ({ entity, canAdminEntities, currentUserId }) => {
      const canDelete = canAdminEntities || entity.get('userId') === currentUserId
      const canEditText = canAdminEntities || entity.get('userId') === currentUserId
      return { canEditText, canDelete }
    }
  }),

  getCurrentUserIdeaLikesByIdeaClientIds: _createCurrentUserLikesSelector({
    likesExtractor: state => state.get('ideaLikes'),
    likeIndexFn: (_likeId, like: IdeaLikeRecord) => like.ideaClientId
  }),

  getCurrentUserCommentLikesByCommentClientIds: _createCurrentUserLikesSelector({
    likesExtractor: (state: AppStateRecord) => state.get('commentLikes'),
    likeIndexFn: (_likeId, like: CommentLikeRecord) => like.commentClientId
  })
}

type Selector = (state: AppStateRecord) => any

const _theOnlySlot = Symbol("one slot")
function _createEditCapabilityByEntityClientIdSelector({ getEntities, getAdmin, computeEditCapability }): Selector {
  return EvImm.startChain()
    .memoizeForValue()
    .mapOneToMany({
      entities: (state: AppStateRecord) => getEntities(state),
      globalData: EvImm.startChain()
        .addStep((state: AppStateRecord) => ({
          currentUserId: CurrentUserSelectors.getId(state),
          canAdminEntities: getAdmin(state)
        }))
        .memoizeForObject()
        .addStep(({ currentUserId, canAdminEntities }) => Map([[_theOnlySlot, { currentUserId, canAdminEntities }]]))
        .endChain()
    })
    .memoizeForObject()
    .addLeftJoinStep({
      mapLeftToSetOfRightKeys: () => [_theOnlySlot],
      attachLeftWithMapOfRight: (entity, globalDataSet) => ({ entity, ...(globalDataSet.get(_theOnlySlot)) }),
      extractLeftMap: ({ entities }) => entities,
      extractRightMap: ({ globalData }) => globalData,
    })
    .addMapStep(({ entity, canAdminEntities, currentUserId }) => computeEditCapability({ entity, canAdminEntities, currentUserId }))
    .endChain()
}

interface CreateCurrentUserLikesSelectorParameters<T>{
  likesExtractor: (state: AppStateRecord) => Map<string, T>
  likeIndexFn: (likeId: string, like: T) => string
}

function _createCurrentUserLikesSelector<T extends GenericLike>({ likesExtractor, likeIndexFn }: CreateCurrentUserLikesSelectorParameters<T>): (state: AppStateRecord) => Map<string, T> {
  return memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (state: AppStateRecord) => ({
      currentUserId: CurrentUserSelectors.getId(state),
      allLikes: likesExtractor(state)
    }),
    calculateResult: ({currentUserId, allLikes}) => {
      const usersLikes = allLikes
        .filter(like => !like.isDeleted && like.userId === currentUserId)

      const usersLikesByIdeaClientIds = usersLikes.mapKeys((likeId, like) => likeIndexFn(likeId, like))
      return usersLikesByIdeaClientIds
    }
  })
}

function _getFieldInCurrentUserProfile<T extends keyof UserProfileRecord>(state: AppStateRecord, fieldName: T): UserProfileRecord[T] {
  return CurrentUserModuleState.getPart(state, 'userProfile')[fieldName]
}

function _isSuperUser(state: AppStateRecord): boolean {
  return _getFieldInCurrentUserProfile(state, 'isSuperUser')
}

function _hasPermission(state: AppStateRecord, permissionName): boolean {
  return _isSuperUser(state) || !!_getFieldInCurrentUserProfile(state, permissionName)
}

export {CurrentUserSelectors}
