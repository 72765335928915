import * as Immutable from 'immutable'
import Connection, { ConnectionRecord } from "shared/models/Connection"

const ConnectionStateFactory = {
  createInitialConnectionState () {
    return new ConnectionState(Immutable.Map(), Immutable.Map(), Immutable.Map())
  }
}

export {ConnectionStateFactory}

type ConnectionsByClientIdMap = Immutable.Map<string, ConnectionRecord>

class ConnectionState {
  private _connectionsFullCache: Immutable.Map<string, ConnectionRecord>
  private _connectionsByConnectionClientId: Immutable.Map<string, ConnectionRecord>
  private _connectionsByIdeaClientId: Immutable.Map<string, ConnectionsByClientIdMap>

  constructor (connectionsFullCache, connectionsByConnectionClientId, connectionsByIdeaClientId) {
    this._connectionsFullCache = connectionsFullCache
    this._connectionsByConnectionClientId = connectionsByConnectionClientId
    this._connectionsByIdeaClientId = connectionsByIdeaClientId
  }

  getFullCache () {
    return this._connectionsFullCache
  }

  getAllByClientId () {
    return this._connectionsByConnectionClientId
  }

  getAllByIdeaClientId () {
    return this._connectionsByIdeaClientId
  }

  getByIdeaClientId (clientId: string): ConnectionsByClientIdMap {
    const connections = this._connectionsByIdeaClientId.get(clientId)
    return connections
      ? connections.asImmutable()
      : Immutable.Map()
  }

  mutate (mutator) {
    let newFullCache, newByConnection, newByIdea

    newFullCache = this._connectionsFullCache.withMutations((mutableConnectionsFullCache) => {
      newByConnection = this._connectionsByConnectionClientId.withMutations((mutableConnectionsByConnectionClientId) => {
        newByIdea = this._connectionsByIdeaClientId.withMutations((mutableConnectionsByIdeaClientId) => {
          const consumeConnection = (connectionData) => {
            const connection = Connection(connectionData)
            const possibleExistingConnection = mutableConnectionsFullCache.get(connection.clientId)
            if (possibleExistingConnection) {
              _applyConnection(
                mutableConnectionsFullCache,
                mutableConnectionsByConnectionClientId,
                mutableConnectionsByIdeaClientId,
                possibleExistingConnection,
                'REMOVE'
              )
            }

            _applyConnection(
              mutableConnectionsFullCache,
              mutableConnectionsByConnectionClientId,
              mutableConnectionsByIdeaClientId,
              connection,
            )
          }

          mutator(consumeConnection)
        })
      })
    })

    return new ConnectionState(newFullCache, newByConnection, newByIdea)
  }
}

function _applyConnection(mutableConnectionsFullCache, mutableConnectionsByConnectionClientId, mutableConnectionsByIdeaClientId, connection, action?) {
  const { clientId, isDeleted } = connection

  // mutableConnectionsFullCache retains all connections, including the deleted ones
  mutableConnectionsFullCache.set(clientId, connection)

  if (!action) {
    action = !isDeleted ? "ADD" : "DELETE"
  }

  const updateConnectionMap = action === "ADD"
    ? connectionMap => connectionMap.set(clientId, connection)
    : connectionMap => connectionMap.delete(clientId)

  updateConnectionMap(mutableConnectionsByConnectionClientId)

  const updateInvolvedIdeaClientId = ideaClientId => {
    const currentIdeaConnections = mutableConnectionsByIdeaClientId.get(ideaClientId) || Immutable.Map().asMutable()
    mutableConnectionsByIdeaClientId.set(ideaClientId, updateConnectionMap(currentIdeaConnections))
  }
  connection.sourceIdeaClientId && updateInvolvedIdeaClientId(connection.sourceIdeaClientId)
  connection.targetIdeaClientId && updateInvolvedIdeaClientId(connection.targetIdeaClientId)
}
