import { AppStateRecord } from "appRoot/state"

import {
  BOARD_SET_SLUG,
  BOARD_SET_VIEW_TYPE,
  BOARD_SET_LIST_VIEW_LIMIT,
  BOARD_SET_IS_FOUND,
  BOARD_SET_BOARD_IDEA_CLIENT_IDS,
  SET_VISIBILITY_BY_IDEA_CLIENT_ID,
  BOARD_SET_BOARDMEMBERSHIPS, SET_SEARCH_IN_ALL_BOARDS_FLAG,
  INCREMENT_CURRENT_BOARD_SUMMARY_IDEA_COUNT,
} from './constants'

export default (reducer) => {
  reducer.handle(
    BOARD_SET_SLUG,
    (state: AppStateRecord, action) => state.set('currentBoardSlug', action.slug)
  )
  reducer.handle(
    BOARD_SET_VIEW_TYPE,
    (state: AppStateRecord, action) => state.set("viewType", action.viewType)
  )
  reducer.handle(
    BOARD_SET_LIST_VIEW_LIMIT,
    (state: AppStateRecord, action) => state.set("listViewLimit", action.listViewLimit)
  )
  reducer.handle(
    BOARD_SET_IS_FOUND,
    (state: AppStateRecord, action) => state.set('currentBoardIsFound', action.isFound)
  )
  reducer.handle(
    BOARD_SET_BOARD_IDEA_CLIENT_IDS,
    (state: AppStateRecord, action) => state.set('boardIdeaClientIds', action.boardIdeaClientIds)
  )
  reducer.handle(
    BOARD_SET_BOARDMEMBERSHIPS,
    (state: AppStateRecord, action) => state.set('boardMembershipsByIdeaClientId', action.boardMembershipsByIdeaClientId)
  )
  reducer.handle(
    SET_VISIBILITY_BY_IDEA_CLIENT_ID,
    (state: AppStateRecord, action) => state.set('visibilityByIdeaClientId', action.visibilityByIdeaClientId)
  )
  reducer.handle(
   SET_SEARCH_IN_ALL_BOARDS_FLAG,
      (state: AppStateRecord, action) => state.set('searchInAllBoards', action.searchInAllBoards)
  )
  reducer.handle(
    INCREMENT_CURRENT_BOARD_SUMMARY_IDEA_COUNT,
    (state: AppStateRecord, action) => {
      // Get the current board's client ID
      const currentBoardSlug = state.get('currentBoardSlug')
      const currentBoard = state.get('boardSummaries').find(b => b.slug === currentBoardSlug)
      
      if (!currentBoard) return state;
      // Calculate how many new ideas were added
      const newIdeaCount = action.ideaDelta
      
      // Update both the board idea IDs and the board summary
      return state
        .update('boardSummaries', summaries => 
          summaries.update(currentBoard.clientId, summary => ({
            ...summary,
            ideaCount: summary.ideaCount + newIdeaCount
          }))
        )
    }
   )
}
