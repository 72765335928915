import {CompanySettingsHelpers} from 'domain/CompanySettings'

import { TEAM_CODE_ATTRIBUTE_CLIENT_ID_SETTING_NAME, TeamCode } from 'shared/TeamCodes'

import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'
import { UserProfileSelectors } from 'domain/UserProfile/selectors'
import { AttributeValueSelectors } from 'domain/AttributeValue/selectors'

import { memoize } from 'shared/helpers/memoize'

import { AttributeValues, AttributeValueHelpers } from 'shared/models/AttributeValue'

import { AppStateRecord } from 'appRoot/state'

import { Set } from 'immutable'

const teamCodeSettingAccessor = CompanySettingsHelpers.createCompanySettingAccessor<string, TeamCode>({
  name: TEAM_CODE_ATTRIBUTE_CLIENT_ID_SETTING_NAME,
  initialValue: null,

  // TODO: turn teamCodes into entities to not use their names everywhere directly
  fromRawData: (teamCodeString) => teamCodeString,
  toRawData: (teamCode) => teamCode
})

function getTeamCodeAttributeClientId (state: AppStateRecord): string {
  return teamCodeSettingAccessor.get(state)
}

const TeamCodesSelectors = {

  getTeamCodeAttributeClientId,

  teamCodesAreActive (state: AppStateRecord) {
    return !!TeamCodesSelectors.getTeamCodeAttributeClientId(state)
  },

  getIdeaTeamCode (state: AppStateRecord, ideaAttributes: AttributeValues | null) {
    if(ideaAttributes === null) {
      return null
    }

    if (!TeamCodesSelectors.teamCodesAreActive(state)) {
      return null
    }

    const teamCodeAttributeClientId = TeamCodesSelectors.getTeamCodeAttributeClientId(state)

    return AttributeValueHelpers.getAttributeValue(teamCodeAttributeClientId, ideaAttributes)
  },

  userHasTeamCode(state: AppStateRecord, userId, teamCode) {
    // TODO: Make sure UserProfileSelectors are the single source of truth for userProfile objects
    // Right now, it's possible that the user profile of the current user is not reachable through
    // those selectors
    const userProfile = userId === CurrentUserSelectors.getId(state)
      ? CurrentUserSelectors.getCurrentUserProfile(state)
      : UserProfileSelectors.getById(state, userId)
    if(userProfile === null) {
      return false
    } else {
      return userProfile.teamCodes.includes(teamCode)
    }
  },

  // TODO: #osdiabPrivacy move to some future PermissionsChecker module
  currentUserHasAccessToIdea (state: AppStateRecord, ideaAttributes: AttributeValues | null) {
    if(ideaAttributes === null) {
      return false
    }

    if (!TeamCodesSelectors.teamCodesAreActive(state)) {
      return true
    }

    const ideaTeamCode = TeamCodesSelectors.getIdeaTeamCode(state, ideaAttributes)

    // idea should be explicitly assigned to some team code to become accessible by that team members
    if(ideaTeamCode === undefined || ideaTeamCode === null) {
      return false
    }

    return TeamCodesSelectors.userHasTeamCode(state, CurrentUserSelectors.getId(state), ideaTeamCode)
  },

  // TODO: extract attr values extraction logic to something like IdeaAttributes module
  getAllTeamCodes: memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (state: AppStateRecord) => {
      const teamCodeAttributeClientId = getTeamCodeAttributeClientId(state)

      return {teamCodeAttributeClientId}
    },

    calculateResult: async ({teamCodeAttributeClientId}) => {
      const { values } = await AttributeValueSelectors.getAllAvailableAttributeValues(teamCodeAttributeClientId)
      return Set(values)
        // sort by names implying that they are string right now
        .sortBy(teamCode => teamCode)
        .toArray()
    }
  })
}

const TeamCodesActions = {
  setTeamCodeAttribute: (attributeClientId: string) => teamCodeSettingAccessor.set(attributeClientId),
}

export {TeamCodesSelectors, TeamCodesActions}
