import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

import { generateClientId } from 'shared/helpers/Id'

import { ColorRule } from './ColorRule'

// TODO: Move EditorState to the shared/ directory
import { SerializedEditorState } from '../../src/components/common/DeclarativeEditor/EditorState'

export type RawPathData = { connectionClientId: string }[]
export type EquivalentPathPair = {
  clientId: string
  first: RawPathData
  second: RawPathData
  colorId?: number
}

const DocumentDefaultValues = {
  clientId: NotNullOrUndefined,
  name: 'Snapshot',
  boardClientId: '',
  graphLayoutClientId: '',
  graphLayoutClientIdHistory: [] as string[],
  colorRulesInOrder: [] as ColorRule[],
  pairsOfEquivalentPaths: [] as EquivalentPathPair[],
  editorState: NotNullOrUndefined,
  isDeleted: false,
  createdAt: NotNullOrUndefined,
  updatedAt: NotNullOrUndefined,
  authorId: NotNullOrUndefined,
  slugs: [],

  // Frontend only
  isSaved: false,
  originalDocumentUpdatedAt: null,
}

export type DocumentRawType = {
  clientId: string
  name: string
  boardClientId: string
  graphLayoutClientId: string
  graphLayoutClientIdHistory: string[]
  colorRulesInOrder: ColorRule[]
  pairsOfEquivalentPaths: EquivalentPathPair[]
  editorState: SerializedEditorState
  isDeleted: boolean
  createdAt: Date
  updatedAt: Date
  authorId: number
  slugs: string[]

  // Frontend only
  isSaved: boolean
  originalDocumentUpdatedAt: Date | null
}

export type DocumentSourceObjectType = Partial<DocumentRawType> & {
  created_at?: string,
  updated_at?: string,
}

class DocumentRecord extends CreateRecordClass<DocumentRawType>(DocumentDefaultValues, "Document") {}

type DocumentRecordT = DocumentRecord
export { DocumentRecordT as DocumentRecord }

const Document = Object.assign(
  (stub: DocumentSourceObjectType | DocumentRecord): DocumentRecord => {
    if(!Document.isDocument(stub)) {
      stub = stub as DocumentSourceObjectType
      stub = {
        ...stub,
        clientId: stub.clientId || generateClientId(),
        createdAt: new Date(stub.createdAt || stub.created_at || Date.now() as any),
        updatedAt: new Date(stub.updatedAt || stub.updated_at || Date.now() as any),
      }
    }
    return new DocumentRecord(stub)
  },
  {
    isDocument: (obj): obj is DocumentRecord => (obj instanceof DocumentRecord),
  }
)

export default Document
