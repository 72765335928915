const debounce = (fn, timeInMilliseconds, usePromise=false) => {
  let resolvePromise: ((value: any) => void) | null = null
  let timeoutId: number | undefined
  let nextArgs: any[] | null = null
  const cancel = () => {
    if(timeoutId !== undefined) {
      window.clearTimeout(timeoutId)
      timeoutId = undefined
      nextArgs = null
    }
  }
  const executeNow = () => {
    if(nextArgs !== null) {
      const result = fn.apply(this, nextArgs)
      nextArgs = null
      if(resolvePromise) {
        resolvePromise(result)
      }
    }
  }
  const scheduleNextCall = () => {
    timeoutId = window.setTimeout(executeNow, timeInMilliseconds)
  }
  const run = (...args) => {
    cancel()
    nextArgs = args
    scheduleNextCall()

    if(usePromise) {
      return new Promise((res, _rej) => { resolvePromise = res })
    } else {
      return null
    }
  }
  const flush = () => {
    executeNow()
    cancel()
  }
  const isScheduled = () => {
    return nextArgs !== null
  }

  return Object.assign(run, { cancel, flush, isScheduled })
}

export const debouncePromise = <A,R>(fn: (a:A) => Promise<R>, timeInMilliseconds: number): (a:A) => Promise<R> => {
  return debounce(fn, timeInMilliseconds, true) as any
}

export default debounce