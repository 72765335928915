/**
 * Error handler
 * - catches fallthrough errors
 * - used as upstream middleware
 */

import { ErrorActions } from 'managers/error/actions'

const _handleError = (next) => (err) => {
  next(ErrorActions.registerAnError(err))
  throw err
}

const errorMiddleware = _store => next => action => {
  let returnValue
  try {
    returnValue = next(action)
  } catch (err) {
    _handleError(next)(err)
  }

  if(Promise.prototype.isPrototypeOf(returnValue)) {
    returnValue.catch(_handleError(next))
  }

  return returnValue
}

export default errorMiddleware
