import * as React from 'react'
import { Map } from 'immutable'
import LayoutShell from 'components/layout/LayoutShell'

import ColorSquare from 'components/common/ColorSquare'

import Avatar from 'components/common/Avatar'

import {
  Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input,
} from 'reactstrap'

import classnames from 'classnames'

import {
  ChangeType, ChangelogEntry,
  ChangelogEntryIdeaTitle
} from 'shared/models/Changelog'

import { UserProfileRecord } from 'shared/models/UserProfile'

import { ChangelogViewRow } from './ChangelogRow'

import styles from './Changelog.styl'

import { BoundActionGroups } from 'helpers/Component'
import { IdeaActions } from 'domain/actions'

export type SelectIdeaClientIdFilter = (ideaClientId: string) => void
const { Provider: SelectIdeaClientIdFilterProvider, Consumer: SelectIdeaClientIdFilterConsumer } = React.createContext<SelectIdeaClientIdFilter | null>(null)
export { SelectIdeaClientIdFilterConsumer }

const ChangeTypes = [
  { changeType: ChangeType.IdeaCreate, description: "idea created" },
  { changeType: ChangeType.IdeaDelete, description: "idea deleted" },
  { changeType: ChangeType.IdeaTitleChange, description: "idea title changed" },
  { changeType: ChangeType.IdeaStatusChange, description: "idea status changed" },
  { changeType: ChangeType.IdeaAttributeValueChange, description: "idea attribute value changed" },
  { changeType: ChangeType.IdeaColorIdChange, description: "idea color changed" },
  { changeType: ChangeType.ConnectionCreate, description: "connection created" },
  { changeType: ChangeType.ConnectionDelete, description: "connection deleted" },
  { changeType: ChangeType.ConnectionLabelChange, description: "connection label changed" },
  { changeType: ChangeType.ConnectionColorIdChange, description: "connection color changed" },
]

enum FilterMode {
  All = "all",
  IdeaTitle = "idea-title",
  IdeaClientId = "idea-client-id",
  User = "user",
  Color = "color",
  Type = "type",
}

type ChangelogViewProps = {
  changelog: ChangelogEntry[]
  allUsers: Map<number, UserProfileRecord>
  attributesByClientId: any
  ideasByClientId: any
  connectionsByClientId: any
  colorsById: any
  colorInstanceByColorId: any
  currentBoardSummary: any
  dataFetcher: (additionalData: { cutoffDate: Date }) => void
} & BoundActionGroups<{ IdeaActions: typeof IdeaActions }>

function formatDay(date: Date) {
  const month = date.getMonth()+1
  function printNumber(number: number) {
    return number.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })
  }
  return `${date.getFullYear()}-${printNumber(month)}-${printNumber(date.getDate())}`
}

type ChangelogViewState = {
  filterMode: FilterMode,
  selecting: boolean,
  filterContent: any,
  cutoffDate: Date
}

class ChangelogView extends React.PureComponent<ChangelogViewProps, ChangelogViewState> {
  constructor(props: ChangelogViewProps) {
    super(props)
    this.state = {
      filterMode: FilterMode.All,
      selecting: false,
      filterContent: null,
      cutoffDate: new Date(Date.now() - 1000*60*60*24*31)
    }
  }

  componentDidMount() {
    this.props.dataFetcher({ cutoffDate: this.state.cutoffDate })
  }

  componentDidUpdate(prevProps: ChangelogViewProps, prevState: ChangelogViewState) {
    if (this.props.dataFetcher !== prevProps.dataFetcher || this.state.cutoffDate !== prevState.cutoffDate) {
      this.props.dataFetcher({ cutoffDate: this.state.cutoffDate })
    }
  }

  render() {
    const collapsedFilteredChangelog = this.getCollapsedFilteredChangelog()

    const selectedFilterColor = this.state.filterMode === FilterMode.Color && this.props.colorsById.get(this.state.filterContent) || null
    const selectedFilterUser = this.state.filterMode === FilterMode.User && this.props.allUsers.get(this.state.filterContent) || null
    const selectedFilterIdea = this.state.filterMode === FilterMode.IdeaClientId && this.props.ideasByClientId.get(this.state.filterContent) || null
    const selectedFilterIdeaColor = selectedFilterIdea && this.props.colorsById.get(selectedFilterIdea.colorId)

    return <LayoutShell>
      <div className={styles.changelog}>
        <div className={styles.changeLogTitle}>
          Recent Changes to {(this.props.currentBoardSummary && this.props.currentBoardSummary.title) || "this board"}:
          Filter <ButtonGroup>
            <Button
              color={this.state.filterMode === FilterMode.All ? "primary" : "secondary"}
              onClick={() => this.setState({ filterMode: FilterMode.All })}
            >
              All
            </Button>
            <ButtonDropdown
              onClick={() => this.setState(state => ({ filterMode: FilterMode.IdeaTitle, filterContent: state.filterMode !== FilterMode.IdeaTitle ? "" : state.filterContent }))}
              isOpen={this.state.filterMode === FilterMode.IdeaTitle && this.state.selecting}
              toggle={() => this.setState(state => ({ selecting: !state.selecting }))}
            >
              <DropdownToggle caret color={this.state.filterMode === FilterMode.IdeaTitle ? "primary" : "secondary"}>
                <span className={styles.valign}>
                  Idea title
                  {this.state.filterMode === FilterMode.IdeaTitle && <>:&nbsp;{this.state.filterContent}</>}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <Input
                    innerRef={input => input && input.focus()}
                    style={{ minWidth: 400 }}
                    type="text" placeholder="Search for an idea"
                    onChange={(event) => this.setState({ filterContent: event.target.value })}
                  />
                </DropdownItem>
                {(this.state.filterContent + "").length > 2 && this.state.filterMode === FilterMode.IdeaTitle && this.props.ideasByClientId.valueSeq().toArray()
                  .filter(idea => idea.title.match(new RegExp(this.state.filterContent + "", "i")))
                  .slice(0, 50)
                  .map(idea => {
                    const color = this.props.colorsById.get(idea.colorId)
                    return <DropdownItem
                      key={idea.clientId}
                      onClick={() => this.setState({ filterContent: idea.title })}
                    >
                      <span className={styles.valign}>
                        {idea.title}
                        {color && <>&nbsp;<ColorSquare isNotSelectable color={color} /></>}
                      </span>
                    </DropdownItem>
                  })
                }
              </DropdownMenu>
            </ButtonDropdown>
            <ButtonDropdown
              onClick={() => this.setState(state => ({ filterMode: FilterMode.IdeaClientId, filterContent: state.filterMode !== FilterMode.IdeaClientId ? null : state.filterContent }))}
              isOpen={this.state.filterMode === FilterMode.IdeaClientId && this.state.selecting}
              toggle={() => this.setState(state => ({ selecting: !state.selecting }))}
            >
              <DropdownToggle caret color={this.state.filterMode === FilterMode.IdeaClientId ? "primary" : "secondary"}>
                <span className={styles.valign}>
                  Idea id
                  {selectedFilterIdea && <>:&nbsp;
                    <span className={styles.valign}>
                        {selectedFilterIdea.title}
                        {selectedFilterIdeaColor && <>&nbsp;<ColorSquare isNotSelectable color={selectedFilterIdeaColor} /></>}
                      </span>
                  </>}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <Input
                    innerRef={input => input && input.focus()}
                    style={{ minWidth: 400 }}
                    type="text" placeholder="Search for an idea"
                    onChange={(event) => this.setState({ filterContent: event.target.value })}
                  />
                </DropdownItem>
                {(this.state.filterContent + "").length > 2 && this.state.filterMode === FilterMode.IdeaClientId && this.props.ideasByClientId.valueSeq().toArray()
                  .filter(idea => idea.title.match(new RegExp(this.state.filterContent + "", "i")))
                  .slice(0, 50)
                  .map(idea => {
                    const color = this.props.colorsById.get(idea.colorId)
                    return <DropdownItem
                      key={idea.clientId}
                      onClick={() => this.setState({ filterContent: idea.clientId })}
                    >
                      <span className={styles.valign}>
                        {idea.title}
                        {color && <>&nbsp;<ColorSquare isNotSelectable color={color} /></>}
                      </span>
                    </DropdownItem>
                  })
                }
              </DropdownMenu>
            </ButtonDropdown>
            <ButtonDropdown
              onClick={() => this.setState(state => ({ filterMode: FilterMode.User, filterContent: state.filterMode !== FilterMode.User ? "" : state.filterContent }))}
              isOpen={this.state.filterMode === FilterMode.User && this.state.selecting}
              toggle={() => this.setState(state => ({ selecting: !state.selecting }))}
            >
              <DropdownToggle caret color={this.state.filterMode === FilterMode.User ? "primary" : "secondary"}>
                <span className={styles.valign}>
                  User
                  {selectedFilterUser && <>:&nbsp;<Avatar
                    href={selectedFilterUser.avatar} />
                  </>}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <Input
                    innerRef={input => input && input.focus()}
                    style={{ minWidth: 400 }}
                    type="text" placeholder="Search for a user"
                    onChange={(event) => this.setState({ filterContent: event.target.value })}
                  />
                </DropdownItem>
                {this.state.filterMode === FilterMode.User && this.props.allUsers.valueSeq().toArray()
                  .map(userProfile => { console.log(userProfile); return userProfile; })
                  .filter(userProfile =>
                    userProfile.userId === this.state.filterContent ||
                    userProfile.getDisplayName().match(new RegExp(this.state.filterContent + "", "i"))
                  )
                  .slice(0, 50)
                  .map(userProfile => {
                    return <DropdownItem
                      key={userProfile.userId}
                      onClick={() => this.setState({ filterContent: userProfile.userId })}
                    >
                      <span className={styles.valign}>
                        <Avatar
                          href={userProfile.avatar}
                        />
                        &nbsp;
                        {userProfile.getDisplayName()}
                      </span>
                    </DropdownItem>
                  })
                }
              </DropdownMenu>
            </ButtonDropdown>
            <ButtonDropdown
              onClick={() => this.setState(state => ({ filterMode: FilterMode.Color, filterContent: state.filterMode !== FilterMode.Color ? null : state.filterContent }))}
              isOpen={this.state.filterMode === FilterMode.Color && this.state.selecting}
              toggle={() => this.setState(state => ({ selecting: !state.selecting }))}
            >
              <DropdownToggle caret color={this.state.filterMode === FilterMode.Color ? "primary" : "secondary"}>
                <span className={styles.valign}>
                  Color
                  {selectedFilterColor && <>:&nbsp;<ColorSquare isNotSelectable color={selectedFilterColor} /></>}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Select a color</DropdownItem>
                {this.props.colorInstanceByColorId.toList().toArray().map(colorInstance => {
                  const color = this.props.colorsById.get(colorInstance.colorId)
                  return <DropdownItem
                    key={colorInstance.colorId}
                    onClick={() => this.setState({ filterContent: colorInstance.colorId })}
                  >
                    {color && <><ColorSquare isNotSelectable color={color} />&nbsp;</>}
                    {colorInstance.label}
                  </DropdownItem>
                })}
              </DropdownMenu>
            </ButtonDropdown>
            <ButtonDropdown
              onClick={() => this.setState(state => ({ filterMode: FilterMode.Type, filterContent: state.filterMode !== FilterMode.Type ? null : state.filterContent }))}
              isOpen={this.state.filterMode === FilterMode.Type && this.state.selecting}
              toggle={() => this.setState(state => ({ selecting: !state.selecting }))}
            >
              <DropdownToggle caret color={this.state.filterMode === FilterMode.Type ? "primary" : "secondary"}>
                <span className={styles.valign}>
                  Change type
                  {(() => {
                    const changeType = ChangeTypes.find(({ changeType }) => this.state.filterContent === changeType)
                    return this.state.filterMode === FilterMode.Type && changeType && <>:&nbsp;{changeType.description}</>
                  })()}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Select a change type</DropdownItem>
                {ChangeTypes.map(({ changeType, description }) => {
                  return <DropdownItem
                    key={changeType}
                    onClick={() => this.setState({ filterContent: changeType })}
                  >
                    {description}
                  </DropdownItem>
                })}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonGroup>
          {" "}Cutoff date:&nbsp;
          <input
            value={formatDay(this.state.cutoffDate)}
            onChange={(e) => this.setState({ cutoffDate: new Date(e.target.value) })}
            type="date" max={formatDay(new Date())}
          />
        </div>
        <div className={classnames(styles.changeLogRow, styles.changeLogRowHeader)}>
          <div className={styles.dayHeader}>
            Timestamp
          </div>
          <div className={styles.userHeader}>
            Author
          </div>
          <div className={styles.contextHeader}>
            Element
          </div>
          <div className={styles.oldHeader}>
            Change
          </div>
          <div className={styles.newHeader}>
          </div>
        </div>
        <SelectIdeaClientIdFilterProvider value={this.selectIdeaClientIdFilter}>
          {collapsedFilteredChangelog.map(changelogEntry =>
            <ChangelogViewRow
              key={changelogEntry.clientId}
              changelogEntry={changelogEntry}
              allUsers={this.props.allUsers}
              attributesByClientId={this.props.attributesByClientId}
              ideasByClientId={this.props.ideasByClientId}
              connectionsByClientId={this.props.connectionsByClientId}
              colorsById={this.props.colorsById}
              colorInstanceByColorId={this.props.colorInstanceByColorId}
              IdeaActions={this.props.IdeaActions}
            />
          )}
        </SelectIdeaClientIdFilterProvider>
      </div>
    </LayoutShell>
}

  private selectIdeaClientIdFilter = (ideaClientId: string) => {
    this.setState({ filterMode: FilterMode.IdeaClientId, filterContent: ideaClientId })
  }

  private getCollapsedFilteredChangelog() {
    const collapsedChangelog: ChangelogEntry[] = []
    let lastEntry: ChangelogEntry | null = null
    this.props.changelog.forEach((changelogEntry: ChangelogEntry) => {
      const canCollapse = lastEntry && lastEntry.changeType === changelogEntry.changeType && (lastEntry.changeDescription as any).ideaClientId === (changelogEntry.changeDescription as any).ideaClientId && lastEntry.userId === changelogEntry.userId
      if(canCollapse && lastEntry && lastEntry.changeType === 'idea-title-change') {
        changelogEntry = changelogEntry as ChangelogEntryIdeaTitle
        // We are going backwards in time
        lastEntry.changeDescription.oldTitle = changelogEntry.changeDescription.oldTitle
      } else {
        collapsedChangelog.push(changelogEntry)
        lastEntry = changelogEntry
      }
    })

    const createIdeaFilter = (ideaPredicate) => changelogEntry => {
      const { ideaClientId, connectionClientId, idea, connection } = changelogEntry.changeDescription as any

      const realIdea = idea || this.props.ideasByClientId.get(ideaClientId) || null
      const realConnection = connection || this.props.connectionsByClientId.get(connectionClientId) || null
      const realSource = (realConnection && this.props.ideasByClientId.get(realConnection.sourceIdeaClientId)) || null
      const realTarget = (realConnection && this.props.ideasByClientId.get(realConnection.targetIdeaClientId)) || null

      return (realIdea && ideaPredicate(realIdea))
        || (realSource && ideaPredicate(realSource))
        || (realTarget && ideaPredicate(realTarget))
    }

    if (this.state.filterMode === FilterMode.All) {
      return collapsedChangelog
    } else if (this.state.filterMode === FilterMode.Color) {
      const colorId = this.state.filterContent
      const filterFn = createIdeaFilter(idea => idea.colorId === colorId)
      return collapsedChangelog.filter(filterFn)
    } else if (this.state.filterMode === FilterMode.IdeaTitle) {
      const title = this.state.filterContent
      const matchesTitle = (text: string | undefined) => (text || "").match(new RegExp(title, "i"))
      const filterFn = createIdeaFilter(idea => matchesTitle(idea.title))
      return collapsedChangelog
        .filter(changelogEntry => filterFn(changelogEntry) ||
          changelogEntry.changeType === ChangeType.IdeaTitleChange
            && (
              matchesTitle(changelogEntry.changeDescription.newTitle) ||
              matchesTitle(changelogEntry.changeDescription.oldTitle)
            )
        )
    } else if (this.state.filterMode === FilterMode.IdeaClientId) {
      const filterFn = createIdeaFilter(idea => idea.clientId === this.state.filterContent)
      return collapsedChangelog.filter(filterFn)
    } else if (this.state.filterMode === FilterMode.User) {
      return collapsedChangelog
        .filter(changelogEntry => changelogEntry.userId === this.state.filterContent)
    } else if (this.state.filterMode === FilterMode.Type) {
      return collapsedChangelog
        .filter(changelogEntry => changelogEntry.changeType === this.state.filterContent)
    }

    throw new Error("Unknown filter mode")
  }
}

export default ChangelogView