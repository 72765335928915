import React from 'react'
import classnames from 'classnames'

import {
  Modal as ReactstrapModal,
  ModalHeader as ReactstrapModalHeader,
  ModalBody as ReactstrapModalBody,
  ModalFooter as ReactstrapModalFooter,
} from 'reactstrap'

import {Button, ButtonTypes} from 'components/common/Button'
import {ErrorHelpers} from "shared/helpers/Error";
import styles from './Modal.styl'

interface AlertArgs {
  type: ModalTypes,
  title: string,
  size?: ModalSizes
  contents: React.ReactNode,
  buttonCaption: string
}

interface ConfirmArgs {
  type: ModalTypes,
  title: string,
  size?: ModalSizes
  message: React.ReactNode,
  okButtonCaption: string,
  cancelButtonCaption: string
}

interface ModalContents {
  type: ModalTypes
  size: ModalSizes
  header?: React.ReactNode
  contents: React.ReactNode
  onModalDisplayed: null | (() => void)
  wrapClassName?: string
  modalClassName?: string
  contentClassName?: string
  escapeHandler?: () => void
}

const ModalHelpers = {
  // this is the preferred method to create components able to display modals
  // modals created by these components respect their lifecycle,
  // so are destroyed automatically if a component gets unmounted
  // - this is needed in rare cases when a modal is not closed explicitly by the calling code,
  // like when navigating to other route by a link in the modal
  addModalsToComponent <ComponentProps>(component: React.ComponentClass<ComponentProps & { modals: _Modals }>) {
    return class ComponentWithModals extends React.Component<ComponentProps> {
      _modals: _Modals

      render() {
        return React.createElement(_ModalHost, {
          exposeModals: (_modals: _Modals) => {
            this._modals = _modals
          },

          renderContents: () => React.createElement(
            component,
            Object.assign({}, this.props, {
                modals: this._modals,
              },
            ))
        })
      }
    }
  },

  // use this from inside UI-less code like actions
  // and make sure to close custom modals correctly if they need that
  get globalModals(): _Modals {
    ErrorHelpers.assert(!!_globalModals, `Global modals are not initialized yet! Render <GlobalModalsHost/> in your top-level component first`)
    return _globalModals as _Modals
  }
}

class GlobalModalsHost extends React.Component<{}> {
  render () {
    return React.createElement(_ModalHost, {
      exposeModals: (modals: _Modals) => {
        _globalModals = modals
      },

      renderContents: null
    })
  }
}

export type ModalsClass = _Modals

class _Modals {
  private _displayModalInModalHost: (config: ModalContents) => () => void

  constructor({displayModalInModalHost}: { displayModalInModalHost: (config: ModalContents) => () => void }) {
    this._displayModalInModalHost = displayModalInModalHost
  }

  alert({type, title, contents, buttonCaption, size = ModalSizes.NORMAL}: AlertArgs) {
    let finalize
    const promise = new Promise(alertPromiseResolver => {
      finalize = (closeModal) => {
        closeModal()
        alertPromiseResolver()
      }
    })

    this.showModalWithButtons({
      type, size, title, contents,
      buttons: [{
        type: ButtonTypes.PRIMARY,
        caption: buttonCaption,
        action: finalize,
        autoFocus: true,
      }],
      escapeHandler: finalize
    })

    return promise
  }

  confirm({type, title, message, okButtonCaption, cancelButtonCaption, size = ModalSizes.NORMAL}: ConfirmArgs) {
    let finalizeWith
    const modalPromise = new Promise<boolean>((modalPromiseResolver) => {
      finalizeWith = (modalResult) => (closeModal) => {
        closeModal()
        modalPromiseResolver(modalResult)
      }
    })

    this.showModalWithOkCancel({
      type,
      size,
      title,
      contents: message,
      okButton: { caption: okButtonCaption, action: finalizeWith(true) },
      cancelButton: { caption: cancelButtonCaption, action: finalizeWith(false) },
    })

    return modalPromise
  }

  showModalWithOkCancel({type, title, contents, okButton, cancelButton, size}) {
    this.showModalWithButtons({
      type,
      size,
      title,
      contents,
      buttons: [
        {
          type: ButtonTypes.PRIMARY,
          caption: okButton.caption,
          action: okButton.action,
          autoFocus: true,
        },
        {
          type: ButtonTypes.REGULAR,
          caption: cancelButton.caption,
          action: cancelButton.action,
        },
      ],
      escapeHandler: cancelButton.action,
    })
  }

  showModalWithButtons({type, title, contents, buttons, size, escapeHandler = null}) {
    let closeModal: () => void
    const bindToCloseModal = (fn) => () => fn && fn(closeModal)

    const renderedButtons = buttons.map(button => React.createElement(Button, {
        autoFocus: button.autoFocus,
        type: button.type,
        onClick: bindToCloseModal(button.action),
      },
      button.caption
    ))

    const contentsWithFooter = React.createElement(React.Fragment, null,
      React.createElement(ReactstrapModalBody, null, contents),
      React.createElement(ReactstrapModalFooter, null, ...renderedButtons)
    )

    closeModal = this.showModal({
      type,
      size,
      header: title,
      contents: contentsWithFooter,
      escapeHandler: bindToCloseModal(escapeHandler),
      onModalDisplayed: null,
    })
  }

  showModal(modalConfig: ModalContents) {
    const closeModal = this._displayModalInModalHost(modalConfig)
    return closeModal
  }
}

export type Modals = _Modals

enum ModalTypes {
  INFO,
  WARNING,
  ERROR,
}

enum ModalSizes {
  NORMAL,
  LARGE,
  FULLSCREEN,
}

export {ModalHelpers, GlobalModalsHost, ModalTypes, ModalSizes}

interface ModalHostState {
  modalShown: boolean,
  modalContents: ModalContents | null
}

class _ModalHost extends React.Component<{
  exposeModals: (modals: _Modals) => void
  renderContents: (() => React.ReactNode) | null
}, ModalHostState> {
  constructor(props) {
    super(props)

    this.state = this._getClosedModalState()
    this.props.exposeModals(new _Modals({displayModalInModalHost: this._displayModal}))
  }

  render() {
    return React.createElement(React.Fragment, {},
      this.props.renderContents && this.props.renderContents(),
      this.state.modalShown && this._renderModal()
    )
  }

  private onModalShow = () => {
    const modalContents = ErrorHelpers.castToNotNullOrThrow(
      this.state.modalContents, "modalContents cannot be null when a modal is shown"
    )
    const onModalDisplayed = modalContents.onModalDisplayed

    // TODO: Use FocusAndSelectionHelpers.elementHasFocusWithin to verify if some element inside of the modal has focus
    // If no such elements exists focus the modal itself. Unfortunatelly using autoFocus Modal prop will result in loss
    // of focus on buttons inside of the modal

    if (onModalDisplayed) {
      onModalDisplayed()
    }
  }

  _displayModal = (modalContents: ModalContents) => {
    this.setState({
      modalShown: true,
      modalContents,
    })

    // Modal can only be closed once
    let wasClosed = false
    const closeModal = () => {
      if (!wasClosed) {
        this.setState(this._getClosedModalState())
        wasClosed = true
      } else {
        console.warn("The modal was already closed, refusing to close again to not iterfere with other modals")
      }
    }
    return closeModal
  }

  _getClosedModalState() {
    return {modalShown: false, modalContents: null}
  }

  _renderModal() {
    const {
      size,
      header,
      contents,
      escapeHandler,
      wrapClassName,
      modalClassName,
      contentClassName,
    } = ErrorHelpers.castToNotNullOrThrow(
      this.state.modalContents,
      `_renderModal can only be called when a modal is available`
    )

    // TODO: convert type to specific className that sets some predefined styling

    return React.createElement(ReactstrapModal, {
      isOpen: true,
      autoFocus: false,
      size: reactstrapModalSizes[size],
      toggle: escapeHandler,
      backdrop: true,
      onOpened: this.onModalShow,
      wrapClassName: classnames(wrapClassName),
      modalClassName: classnames(styles.modalWrapper, modalClassName),
      contentClassName: classnames(styles.modalContent, contentClassName),

      // handle Escape key
      keyboard: true
    },
    header && React.createElement(ReactstrapModalHeader, {
        toggle: escapeHandler
      },
      header
    ),
    contents
    )
  }
}

let _globalModals: _Modals | null = null

const reactstrapModalSizes = {
  [ModalSizes.NORMAL]: null,
  [ModalSizes.LARGE]: 'lg',
  [ModalSizes.FULLSCREEN]: 'full',
}
