import Cookie from 'js-cookie'

import config from 'config/appConfig'

import apiCall from 'helpers/apiCall'

import * as types from './constants'

import { StoredUIState } from 'managers/StoredUIState'

import {CurrentUserActions} from 'domain/CurrentUser/actions'
import {CompanySettingsActions} from 'domain/CompanySettings'
import {CurrentUserModuleState, UNAUTHORIZED_USER_PROFILE} from 'domain/CurrentUser/_moduleState'

import { NavigationActions } from 'navigation/actions'
import { UserProfileActions } from 'domain/UserProfile/actions'

import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'
import * as BoardSelectors from 'domain/Board/selectors'

const regularSignup = (userData) => async (dispatch) => {
  await dispatch(_signup({userData, anonymous: false}))
}

const _anonymousSignup = () => async (dispatch) => {
  await dispatch(_signup({userData: undefined, anonymous: true}))
}

const _signup = ({userData, anonymous}) => async (dispatch) => {
  const data = await apiCall({
    path: `/auth/${anonymous ? 'anonymousSignup' : 'signup'}/`,
    method: 'POST',
    data: userData,
  })

  _storeAuthToken(data.token)
  await dispatch(_onLoginSuccess())
}

const changePassword = ({ currentPassword, newPassword }) => async () => {
  const { done, error } = await apiCall({
    path: `/auth/changePassword/`,
    method: 'POST',
    data: { currentPassword, newPassword },
  })

  if(!done) throw new Error(error)
}

const changeUsername = ({ newUsername }) => async (dispatch, getState) => {
  const oldUsername = CurrentUserSelectors.getUsername(getState())
  const { done, error } = await apiCall({
    path: `/auth/changeUsername/`,
    method: 'POST',
    data: { newUsername },
  })
  if(!done) throw new Error(error)

  // Refetch all the user profile data that might have been modified
  await dispatch(UserProfileActions.refetchAll())
  await dispatch(CurrentUserActions.loadCurrentUser())

  // If the user was already on their profile page, navigate to the modified URL
  const currentBoardSlug = BoardSelectors.getCurrentBoardSlug(getState())
  console.log({ currentBoardSlug, oldUsername })
  if(currentBoardSlug === oldUsername) {
    dispatch(NavigationActions.navigateToCurrentUserProfile())
  }
}

const login = ({ email, password }) => async (dispatch) => {
  let token, isPasswordTemporary

  try {
    ({ token, isPasswordTemporary} = await apiCall({
      path: '/auth/login/',
      method: 'POST',
      data: {email, password},
    }))
  }

  // TODO: add custom error processing to apiCall
  // so that it could use some default server error processing only if no custom one is set
  catch(error) {
    dispatch({ type: types.LOGIN_FAILED, error: 'Invalid login credentials!' })
    return { loginSuccess: false, isPasswordTemporary: false }
  }

  _storeAuthToken(token)
  await dispatch(_onLoginSuccess())

  return { loginSuccess: true, isPasswordTemporary }
}

const finalizeTheAuthOperations = () => (_dispatch, getState) => {
  NavigationActions.navigate(getState().get('loginRedirect') || '/')
}

const verifyExistingAuth = () => async (dispatch) => {
  const tmpJWT = Cookie.get("tmpJWT")
  if(tmpJWT) {
    _storeAuthToken(tmpJWT)
    Cookie.remove("tmpJWT")
  }

  let authIsValid = false

  const currentAuthToken = getAuthToken()

  if (!currentAuthToken) {
    // we expect anonymous signup to work always
    if (config.features.allowAnonymousUsers) {
      await dispatch(_anonymousSignup())
      authIsValid = true
    }
  }

  else {
    const { loggedIn } = await apiCall({
      path: '/auth/validateAuth/',
      method: 'POST',
      data: { token: currentAuthToken },
    })

    authIsValid = loggedIn
  }

  if (authIsValid) {
    await apiCall({
      "path": "/aiSearch/unconfirmed",
      method: "DELETE"
    })
    await dispatch(_onLoginSuccess())
  } else {
    dispatch({ type: types.LOGIN_REQUIRED, loginRedirect: window.location.pathname })
    NavigationActions.navigate('/login')
  }
}

// TODO: to remove unneeded dependencies, consider subscribing on auth events from outside
const _onLoginSuccess = () => async (dispatch) => {
  // Fetch all the following in parallel
  // TODO: Right now no components get rendered until all of this fetching finishes
  // However if some component were to be rendered during this fetching, some selectors
  // could be broken or depend on the certain fetch order. Test that, make sure no selectors
  // have any assumptions about load order
  const uiStatePromise = StoredUIState.init()
  const currentUserPromise = dispatch(CurrentUserActions.loadCurrentUser())
  const currentUserLikesPromise = dispatch(CurrentUserActions.fetchCurrentUserLikes())
  const companySettingsPromise = dispatch(CompanySettingsActions.fetchAll())

  await Promise.all([
    uiStatePromise,
    currentUserPromise,
    currentUserLikesPromise,
    companySettingsPromise,
  ])

  dispatch({ type: types.LOGIN_VERIFIED })
}

const logout = () => async (dispatch) => {
  await apiCall({
    path: '/auth/logout',
    method: 'POST',
  })

  _deleteAuthToken()

  dispatch({ type: types.LOGOUT })
  dispatch(CurrentUserModuleState.setPart('userProfile', UNAUTHORIZED_USER_PROFILE))
  window.location.reload(true)
}

const getAuthToken = () => localStorage.getItem('jwt')

const _storeAuthToken = (token) => localStorage.setItem('jwt', token)
const _deleteAuthToken = () => localStorage.removeItem('jwt')

export const AuthActions = {
  regularSignup,
  changePassword,
  changeUsername,
  login,
  verifyExistingAuth,
  logout,
  finalizeTheAuthOperations,
  getAuthToken, // TODO: This is not really an action, move it
}