import Point from './Point'

function Polygon() {
  if(arguments.length == 1) {
    this.points = arguments[0].slice();
  } else {
    this.points = Array.prototype.slice.call(arguments);
  }

  for(var i=0; i<this.points.length; ++i) {
    if(!Point.isPoint(this.points[i])) {
      this.points[i] = Point(this.points[i]);
    }
  }
}

Polygon.prototype.Clone = function() {
  var newPoints = [];
  for(var i=0; i<this.points.length; i++) {
    newPoints.push(this.points[i].clone());
  }

  return new Polygon(newPoints);
}

Polygon.prototype.GetArea = function() {
  var area = 0;

  var j = this.points.length - 1;
  for(var i=0; i<this.points.length; ++i) {
    area += (this.points[i].x + this.points[j].x) * (this.points[i].y - this.points[j].y);
    j = i;
  }

  return area/2;
}

Polygon.prototype.GetPolygonWithRayDent = function(c,a,mark,numberOfDents) {
  return this.GetPolygonWithLineDent(c,a,mark,numberOfDents,true);
}

Polygon.prototype.GetPolygonWithLineDent = function(c,a,mark,numberOfDents,ray) {
  if(numberOfDents === undefined)
    numberOfDents = 1;
  var allPoints = [];
  var dentsMade = 0;
  var j = this.points.length - 1;
  var i = 0;
  while(i != this.points.length) {
    var intersection = this.IntersectionPoint(c,a,this.points[i],this.points[j]);
    var onSegment = this.IsPointOnSegment(this.points[i],this.points[j],intersection);
    var onRay = intersection.div(c,a) > -0.001;

    if(onSegment && (!ray || onRay) && dentsMade < numberOfDents) {
      var point = intersection.clone();
      point.mark = mark;
      allPoints.push(point)
      dentsMade += 1;
    };
    allPoints.push(this.points[i].clone());

    j = i;
    i++;
  }

  return new Polygon(allPoints);
}

Polygon.prototype.SplitOnMarked = function(mark) {
  var dentsIds = this.GetMarkedPointsIds(mark);
  var clone = this.Clone();

  if(dentsIds.length < 2) {
    return [clone];
  }

  var cutoffPolygons = []
  for(var i=0; i<dentsIds.length-1; ++i) {
    cutoffPolygons.push(new Polygon(clone.points.slice(dentsIds[i],dentsIds[i+1]+1)));
  }

  var doublePoints = clone.points.slice().concat(clone.points.slice());
  cutoffPolygons.push(new Polygon(doublePoints.slice(dentsIds[dentsIds.length-1],dentsIds[0]+this.points.length+1)))

  return cutoffPolygons;
}

Polygon.prototype.ClearMarked = function() {
  for(var i=0; i<this.points.length; ++i) {
    delete this.points[i].mark;
  }
}

Polygon.prototype.GetMarkedPointsIds = function(mark) {
  var dentsIds = [];
  for(var i=0; i<this.points.length; ++i) {
    if(this.points[i].mark == mark) {
      dentsIds.push(i);
    }
  }
  return dentsIds;
}

Polygon.prototype.GetMarkedPoints = function(mark) {
  var dents = [];
  for(var i=0; i<this.points.length; ++i) {
    if(this.points[i].mark == mark) {
      dents.push(this.points[i]);
    }
  }
  return dents;
}

Polygon.prototype.CutRay = function(c,a) {
  var newPoly = this.GetPolygonWithRayDent(c,a,1,Infinity);
  newPoly = newPoly.ReduceWithPrecision(0.001);

  return newPoly.SplitOnMarked(1);
}

Polygon.prototype.CutLine = function(a, b) {
  if(!Point.isPoint(a) || !Point.isPoint(b)) {
    throw new Error('Invalid line')
  }

  var dentedPolygon = this.GetPolygonWithLineDent(a,b,1,Infinity);

  var cutoffPolygons = dentedPolygon.SplitOnMarked(1);

  for(var i=0; i<cutoffPolygons.length; ++i) {
    cutoffPolygons[i].ReduceWithPrecision(0.001);

    if(cutoffPolygons[i].points.length < 3) {
      cutoffPolygons.splice(i,1);
      i--;
    }
  }

  return cutoffPolygons;
}

Polygon.prototype.CutWedge = function(c,a,b) {
  if(!Point.isPoint(a) || !Point.isPoint(b) || !Point.isPoint(c)) {
    throw new Error('Invalid wedge')
  }

  var polygon = this;
  polygon = polygon.GetPolygonWithRayDent(c,a,0);
  polygon = polygon.GetPolygonWithRayDent(c,b,1);

  var position1 = undefined;
  var position2 = undefined;
  for(var i=0; i<polygon.points.length; ++i) {
    if(polygon.points[i].mark == 0) {
      position1 = i;
    } else if(polygon.points[i].mark == 1) {
      position2 = i;
    }
  }

  if(position1 < position2) {
    var arcPoints = polygon.points.slice(position1, position2+1);
  } else {
    var doublePoints = polygon.points.slice().concat(polygon.points);
    var arcPoints = doublePoints.slice(position1, position2+polygon.points.length+1);
  }
  arcPoints.push(c.clone());

  for(var i=0; i<arcPoints.length; ++i) {
    arcPoints[i] = arcPoints[i].clone();
    delete arcPoints[i].mark;
  }

  return (new Polygon(arcPoints)).ReduceWithPrecision(0.001);
}

Polygon.prototype.IntersectionPoint = function(a,b,c,d) {
  var A1 = b.y - a.y;
  var B1 = a.x - b.x;
  var C1 = A1 * a.x + B1 * a.y;
  var A2 = d.y - c.y;
  var B2 = c.x - d.x;
  var C2 = A2 * c.x + B2 * c.y;

  var det = A1*B2 - A2*B1;
  return Point((B2*C1 - B1*C2)/det, (A1*C2 - A2*C1)/det);
}

Polygon.prototype.IsPointOnSegment = function(a,b,pt) {
  var segmentLength = a.dist(b);
  var dist1 = a.dist(pt);
  var dist2 = pt.dist(b);

  var epsilon = 0.001;
  if(dist1 + dist2 < segmentLength + epsilon && dist1 + dist2 > segmentLength - epsilon) {
    return true;
  } else {
    return false;
  }
}

Polygon.prototype.ReduceWithPrecision = function(precision) {
  var done = false;
  while(!done) {
    done = true;
    var j = this.points.length-1;
    var i = 0;
    while(i<this.points.length) {
      if(this.points[i].dist(this.points[j]) < precision) {
        this.points.splice(i,1);
        done = false;
        break;
      } else {
        j = i;
        i++
      }
    }
  }
  return this;
}

export default Polygon
