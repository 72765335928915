import * as React from "react"

import {
  StandardPrivacyActor, PermissionResourceType, OneResourceType, Permission
} from "shared/models/Permission"

import { CustomStyles, EditedPermission } from "components/Permissions/PermissionsUI"

import PermissionsEditor, { PermissionsEditorInstance } from "./PermissionsEditor"

import { ModalHeader, ModalFooter, ModalBody, Button, ButtonGroup } from "reactstrap"
import { SimpleModal } from "helpers/SimpleModal"

import style from "./PermissionsEditorModal.styl"

interface PermissionsEditorModalProps<ResourceType extends PermissionResourceType> {
  resourceType: OneResourceType<ResourceType>
  entityReadableName?: string
  permissions: Permission<ResourceType>[]
  owners: StandardPrivacyActor[]
  permissionsExplicitlySet: boolean
  customStyles?: CustomStyles
  canReplacePermissionsAndOwners: boolean

  onSubmitChanges: (permissions: Permission<ResourceType>[], owners: StandardPrivacyActor[], ) => void
}
interface PermissionsEditorModalState {
  isOpen: boolean
}

export default class PermissionsEditorModal<ResourceType extends PermissionResourceType>
  extends React.Component<PermissionsEditorModalProps<ResourceType>, PermissionsEditorModalState>
{
  constructor(props: PermissionsEditorModalProps<ResourceType>) {
    super(props)
    this.state = { isOpen: false }
  }

  private permissionsEditor = React.createRef<PermissionsEditorInstance<ResourceType>>()

  render() {
    const { canReplacePermissionsAndOwners } = this.props
    let headerText = canReplacePermissionsAndOwners
      ? "Update Permissions and Owners"
      : "View Permissions and Owners"
    if ("entityReadableName" in this.props) {
      headerText += `: ${this.props.entityReadableName}`
    }
    const editedPermissions: EditedPermission<ResourceType>[] = this.props.permissions
      .map(permission => ({
        permission,
        preexisting: true
      }))
    return <SimpleModal
      isOpen={this.state.isOpen}
      close={this.close}
      className={style.permissionsModal}
    >
      <ModalHeader toggle={this.close}>
        {headerText}
      </ModalHeader>
      <ModalBody>
        <PermissionsEditor<ResourceType>
          innerRef={this.permissionsEditor}
          resourceType={this.props.resourceType}
          canReplacePermissionsAndOwners={canReplacePermissionsAndOwners}
          owners={this.props.owners}
          permissions={editedPermissions}
          permissionsExplicitlySet={this.props.permissionsExplicitlySet}
          customStyles={this.props.customStyles}
        />
      </ModalBody>
      <ModalFooter>
        { canReplacePermissionsAndOwners
          ? <ButtonGroup>
              <Button type="button" color="primary" autoFocus onClick={this.submitAndExit}>Update Permissions and Owners</Button>
              <Button type="button" color="secondary" onClick={this.close}>Cancel</Button>
            </ButtonGroup>
          : <Button type="button" color="primary" autoFocus onClick={this.close}>Return</Button>}
      </ModalFooter>
    </SimpleModal>
  }

  private submitAndExit = () => {
    const editorInstance = this.permissionsEditor.current!

    const newOwners = editorInstance.getEditedOwners()
    // filter out the permissions that have no user chosen
    const newPermissions = editorInstance.getEditedPermissions()
      .filter(p => !!p.permission.actor)

    if (
      newOwners.length === 0 ||
      newPermissions.length === 0
    ) {
      // This shouldn't happen due to validation, but just in case
      alert("New owners and permissions both must not be empty")
      return
    }

    this.props.onSubmitChanges(
      newPermissions.map(p => p.permission),
      newOwners
    )
    this.close()
  }

  open = () => {
    this.setState({ isOpen: true })
  }

  private close = () => {
    console.log("CLOSE")
    this.setState({ isOpen: false })
  }
}