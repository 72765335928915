import * as React from 'react'
import classnames from 'classnames'

import appConfig, { AllViewTypes } from 'config/appConfig'

import { NavigationActions } from 'navigation/actions'
import { BoardActions } from 'domain/Board/actions'
import {ComponentHelpers, BoundActionGroups} from 'helpers/Component'

import { BoardShapedObject } from './index'

import { PermissionResourceType, Permission, StandardPrivacyActor } from 'shared/models/Permission'
import * as PermissionsSelectors from 'domain/Permission/selectors'
import PermissionsEditorModal from 'components/Permissions/PermissionsEditorModal'

import Icon from 'components/common/Icon'

import styles from './Board.styl'

interface BoardProps {
  board: BoardShapedObject
  toggleChildren: () => void
  isExpanded: boolean
}

interface BoardStateProps {
  permissions: Permission<PermissionResourceType.boards>[]
  owners: StandardPrivacyActor[]
  currentUserIsOwner: boolean
}

const actionGroups = {
  NavigationActions,
  BoardActions
}

class Board extends React.Component<BoardProps & BoardStateProps & BoundActionGroups<typeof actionGroups>> {
  // TODO: Load board summaries into the redux store, and use enterTheBoardAndViewType instead.
  _goToGraphView = () =>
    this.props.NavigationActions.enterTheBoardSlugAndViewType(this.props.board.slug, AllViewTypes.GRAPH)

  _goToListView = () =>
    this.props.NavigationActions.enterTheBoardSlugAndViewType(this.props.board.slug, AllViewTypes.LIST)

  _goToContributorsView = () =>
    this.props.NavigationActions.enterTheBoardSlugAndViewType(this.props.board.slug, AllViewTypes.CONTRIBUTORS)

  private permissionModal = React.createRef<PermissionsEditorModal<PermissionResourceType.boards>>()

  render() {
    return (
      <div className={styles.board}>
        <div>
          <span onClick={this._goToGraphView} className={styles.title}>{this.props.board.title}</span>
          <span className={styles.ideaCount}>{this.props.board.descendentIdeaCount} Ideas</span>
          <span className={styles.linkContainer}>
            { appConfig.features.views.includes(AllViewTypes.GRAPH)
                ? <span onClick={this._goToGraphView} className={styles.link}>Graph View</span>
                : null
            }
            {
              appConfig.features.views.includes(AllViewTypes.LIST)
                ? <span onClick={this._goToListView} className={styles.link}>List View</span>
                : null
            }
            {
              appConfig.features.views.includes(AllViewTypes.CONTRIBUTORS)
                ? <span onClick={this._goToContributorsView} className={styles.link}>Contributors</span>
                : null
            }
          </span>
          {this.props.owners && this.props.permissions && <span style={{ float: "right" }}>
            <Icon
              onClick={() => this.permissionModal.current!.open()}
              fontAwesomeIcon="eye"
              tooltip={
                this.props.currentUserIsOwner ? "Update Permissions and Owners" : `View Permissions and Owners`
              }
            />
            <PermissionsEditorModal
              resourceType={PermissionResourceType.boards}
              ref={this.permissionModal}
              canReplacePermissionsAndOwners={this.props.currentUserIsOwner}
              owners={this.props.owners}
              permissions={this.props.permissions}
              permissionsExplicitlySet={this.props.board.permissionsExplicitlySet}
              onSubmitChanges={this.handleSubmitChanges}
            />
          </span>}
        </div>
        {this.props.board.childBoardClientIds && this.props.board.childBoardClientIds.length > 0 &&
          <span
            className={classnames(styles.childrenToggle, this.props.isExpanded && styles.expanded)}
            onClick={this.props.toggleChildren}
          >
            {this.props.board.childBoardClientIds.length} Sub-Boards
          </span>
        }
      </div>
    )
  }

  private handleSubmitChanges = (permissions: Permission<PermissionResourceType.boards>[], owners: StandardPrivacyActor[]) => {
    this.props.BoardActions.replacePermissions(this.props.board.clientId, permissions)
    this.props.BoardActions.replaceOwners(this.props.board.clientId, owners)
  }
}

export default ComponentHelpers.createConnectedComponent<BoardProps, BoardProps, typeof actionGroups>({
  actionGroups,
  mapStateToProps: (state, outerProps: BoardProps) => {

    return {
      ...outerProps,

      permissions: PermissionsSelectors.getPermissionsForBoard(state, outerProps.board.clientId),
      owners: PermissionsSelectors.getOwnersForBoard(state, outerProps.board.clientId),
      currentUserIsOwner: PermissionsSelectors.getCurrentUserIsBoardOwner(state, outerProps.board.clientId),
    }
  },
  component: Board,
})
