import { AppStateRecord } from 'appRoot/state'

import { GraphLayoutRecord } from 'shared/models/GraphLayout'

type Predicate = (graphLayout: GraphLayoutRecord, graphLayoutClientId: string) => boolean

const GraphLayoutSelectors = {
  getByClientId (state: AppStateRecord, graphLayoutClientId: string) {
    return _getAllAsMapByClientIds(state).get(graphLayoutClientId)
  },

  findOne (state: AppStateRecord, predicate: Predicate) {
    return _getAllAsMapByClientIds(state).find(predicate)
  },
}

export { GraphLayoutSelectors }

function _getAllAsMapByClientIds (state: AppStateRecord) {
  return state.get('graphLayouts')
}
