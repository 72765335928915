import * as React from "react"

import Select from "react-select"

import { IdeaPermissionAction } from "shared/models/Permission/Idea"
import { BoardPermissionAction } from "shared/models/Permission/Board"
import { Permission, PermissionResourceType, OneResourceType } from "shared/models/Permission"

import { SelectablePermissionOption, CustomStyles, EditedPermission } from "./PermissionsUI"

import DeleteItemButton from "components/common/DeleteItemButton"
import UserProfileSearchBar from "components/common/searchBars/UserProfileSearchBar"

import PermissionActor from "./PermissionActor"

import styles from "./PermissionEntryEditable.styl"

/**
 * Gets the permission option for a given user blob.
 *
 * Support non-user actors when checking aginst owners
 */
function optionForIdeaPermission(
  actions: Permission<PermissionResourceType.ideas>['actions']
): SelectablePermissionOption {
  if (!actions[IdeaPermissionAction.READ]) {
    return SelectablePermissionOption.HIDDEN
  }

  if (
    actions[IdeaPermissionAction.DELETE] &&
    actions[IdeaPermissionAction.UPDATE] &&
    actions[IdeaPermissionAction.CREATE_BOARD]
  ) {
    return SelectablePermissionOption.CAN_UPDATE
  }

  return SelectablePermissionOption.CAN_READ;
}

function optionForBoardPermission(
  actions: Permission<PermissionResourceType.boards>['actions']
): SelectablePermissionOption {
  if (!actions[BoardPermissionAction.VIEW_IDEAS]) {
    return SelectablePermissionOption.HIDDEN
  }

  if (
    actions[BoardPermissionAction.DELETE] &&
    actions[BoardPermissionAction.UPDATE_METADATA] &&
    actions[BoardPermissionAction.CREATE_IDEA]
  ) {
    return SelectablePermissionOption.CAN_UPDATE
  }

  return SelectablePermissionOption.CAN_READ;
}

function optionForPermission<ResourceType extends PermissionResourceType>(
  resourceType: OneResourceType<ResourceType>,
  actions: Permission<ResourceType>['actions'],
): SelectablePermissionOption {
  if (resourceType === PermissionResourceType.ideas) {
    return optionForIdeaPermission(
      actions as Permission<PermissionResourceType.ideas>['actions'],
    )
  } else {
    return optionForBoardPermission(
      actions as Permission<PermissionResourceType.boards>['actions'],
    )
  }
}

/**
 * Get the proper permission actions table for a given option.
 */
function ideaPermissionForOption(
  basePermissions: Permission<PermissionResourceType.ideas>['actions'],
  opt: SelectablePermissionOption,
): Permission<PermissionResourceType.ideas>['actions'] {
  switch (opt) {
    case SelectablePermissionOption.CAN_READ:
      return {
        ...basePermissions,
        [IdeaPermissionAction.READ]: true,
        [IdeaPermissionAction.CREATE_BOARD]: false,
        [IdeaPermissionAction.DELETE]: false,
        [IdeaPermissionAction.UPDATE]: false
      }
    case SelectablePermissionOption.CAN_UPDATE:
      return {
        ...basePermissions,
        [IdeaPermissionAction.READ]: true,
        [IdeaPermissionAction.CREATE_BOARD]: true,
        [IdeaPermissionAction.DELETE]: true,
        [IdeaPermissionAction.UPDATE]: true
      }
    case SelectablePermissionOption.HIDDEN:
      return {
        [IdeaPermissionAction.READ]: false,
        [IdeaPermissionAction.CREATE_BOARD]: false,
        [IdeaPermissionAction.DELETE]: false,
        [IdeaPermissionAction.UPDATE]: false,
        [IdeaPermissionAction.ADD_COMMENT]: false,
        [IdeaPermissionAction.DISCOVER]: false,
      }
  }
}

function boardPermissionForOption(
  basePermissions: Permission<PermissionResourceType.boards>['actions'],
  opt: SelectablePermissionOption,
): Permission<PermissionResourceType.boards>['actions'] {
  switch (opt) {
    case SelectablePermissionOption.CAN_READ:
      return {
        ...basePermissions,
        [BoardPermissionAction.VIEW_IDEAS]: true,
        [BoardPermissionAction.CREATE_IDEA]: false,
        [BoardPermissionAction.UPDATE_METADATA]: false,
        [BoardPermissionAction.DELETE]: false,
      }
    case SelectablePermissionOption.CAN_UPDATE:
      return {
        ...basePermissions,
        [BoardPermissionAction.VIEW_IDEAS]: true,
        [BoardPermissionAction.CREATE_IDEA]: true,
        [BoardPermissionAction.UPDATE_METADATA]: true,
        [BoardPermissionAction.DELETE]: true,
      }
    case SelectablePermissionOption.HIDDEN:
      return {
        [BoardPermissionAction.VIEW_IDEAS]: false,
        [BoardPermissionAction.CREATE_IDEA]: false,
        [BoardPermissionAction.UPDATE_METADATA]: false,
        [BoardPermissionAction.DELETE]: false,
        [BoardPermissionAction.DISCOVER]: false,
      }
  }
}

function permissionForOption<ResourceType extends PermissionResourceType>(
  resourceType: OneResourceType<ResourceType>,
  basePermissions: Permission<ResourceType>['actions'],
  opt: SelectablePermissionOption,
): Permission<ResourceType>['actions'] {
  if (resourceType === PermissionResourceType.ideas) {
    return ideaPermissionForOption(
      basePermissions as Permission<PermissionResourceType.ideas>['actions'],
      opt
    ) as Permission<ResourceType>['actions']
  } else {
    return boardPermissionForOption(
      basePermissions as Permission<PermissionResourceType.boards>['actions'],
      opt
    ) as Permission<ResourceType>['actions']
  }
}

export function getDefaultPermissionEntry<ResourceType extends PermissionResourceType>(
  resourceType: OneResourceType<ResourceType>,
  basePermissions: Permission<ResourceType>['actions']
) {
  return permissionForOption(
    resourceType,
    basePermissions,
    SelectablePermissionOption.CAN_UPDATE,
  )
}

const selectablePermissionOptionsForDropdown =
  Object.values(SelectablePermissionOption) as SelectablePermissionOption[]

interface PermissionActionsSelectorProps<ResourceType extends PermissionResourceType> {
  resourceType: OneResourceType<ResourceType>
  basePermission: Permission<ResourceType>['actions'],
  value: Permission<ResourceType>['actions']
  onChangeValue: (value: Permission<ResourceType>['actions']) => void
  canReplacePermissions: boolean
}

function PermissionActionsSelector<ResourceType extends PermissionResourceType>({
  resourceType,
  basePermission,
  canReplacePermissions,
  value,
  onChangeValue,
}: PermissionActionsSelectorProps<ResourceType>) {
  const uiValue = optionForPermission(resourceType, value)
  return canReplacePermissions
    ? <Select
        value={[uiValue]}
        onChange={(v: SelectablePermissionOption) => onChangeValue(
          permissionForOption(resourceType, basePermission, v)
        )}
        formatOptionLabel={option => option}
        getOptionLabel={option => option}
        getOptionValue={option => option}
        isOptionSelected={(option, value) => value.includes(option)}
        options={selectablePermissionOptionsForDropdown}
        isClearable={false}
        isSearchable={false}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 })}}
        className={styles.permissionSelect}
      />
    : <>
      <span className={styles.permissionSelect}>{uiValue}</span>
    </>
}

interface PermissionEntryEditableProps<ResourceType extends PermissionResourceType> {
  resourceType: OneResourceType<ResourceType>
  basePermission: Permission<ResourceType>['actions'],
  editedPermission: EditedPermission<ResourceType>
  canReplacePermissions: boolean
  canRemovePermission: boolean

  customStyles?: CustomStyles

  handleChangePermissionValue: (value: Permission<ResourceType>['actions']) => void
  handleRemovePermission: () => void
  handleSelectNewUser: (userId: number) => void
}

export default class PermissionEntryEditable<ResourceType extends PermissionResourceType>
  extends React.Component<PermissionEntryEditableProps<ResourceType>>
{
  render() {
    const {
      resourceType, basePermission,
      canReplacePermissions, canRemovePermission,
      customStyles = {}
    } = this.props
    const { permission, preexisting } = this.props.editedPermission
    return <>
      { preexisting ?
        <PermissionActor
          actor={permission.actor}
          customStyles={this.props.customStyles}
        />
      :
        // Newly added permissions row
        <span className={styles.permissionActor}>
          <UserProfileSearchBar
            onUserProfileSuggestionSelected={({ userId }) => this.props.handleSelectNewUser(userId)}
            omitInputStyling={false}
            maxNumberOfResultsToDisplay={5}
            placeholder={"Name of user"}
            onHighlightedSuggestionChange={() => {}}
            initialValue={null}
            disabled={false}
            autofocus={true}
            hasNoInitialSelection={true}
            useInlineDropdown={false}
          />
        </span>
      }
      <span className={styles.spacer} />
      <span className={styles.permissionDetails}>
        <PermissionActionsSelector
          resourceType={resourceType}
          basePermission={basePermission}
          value={permission.actions}
          onChangeValue={this.props.handleChangePermissionValue}
          canReplacePermissions={canReplacePermissions}
        />
        {
          canReplacePermissions &&
          canRemovePermission && (
            <DeleteItemButton
              className={customStyles.deleteButton}
              onDelete={this.props.handleRemovePermission}
            />
        )}
      </span>
    </>
  }
}