import * as React from "react"

import UserProfile from 'shared/models/UserProfile'

import { AppStateRecord } from "appRoot/state"
import { UserProfileSelectors } from "domain/UserProfile"

import { PrivacyActor, ActorType } from "shared/models/Permission"

import { ComponentHelpers } from "helpers/Component"

import Icon from "components/common/Icon"

import styles from "./PermissionActor.styl"

import { CustomStyles } from "./PermissionsUI"

/**
 * Visual display of an actor in the permissions changing UI.
 * TODO: support non-user actors
 */
type PermissionActorProps = {
  actor: PrivacyActor
  customStyles?: CustomStyles
}
type PermissionActorInnerProps = PermissionActorProps & {
  userProfilesByUserId: ReturnType<typeof UserProfileSelectors.getAll>
}

export default ComponentHelpers.createConnectedComponent({
  actionGroups: { },
  mapStateToProps: (state: AppStateRecord, outerProps: PermissionActorProps) => ({
    userProfilesByUserId: UserProfileSelectors.getAll(state),
    ...outerProps,
  }),
  component: class PermissionActor extends React.PureComponent<PermissionActorInnerProps> {
    render() {
      const { actor, userProfilesByUserId, customStyles = {} } = this.props
      return actor.type === ActorType.PUBLIC_ACTOR
        ? (
          <span className={styles.permissionActor}>
            <Icon fontAwesomeIcon="globe" />
            Public
          </span>
        ) : (
          <span className={styles.permissionActor}>
            <Icon className={customStyles.permissionActorIcon} fontAwesomeIcon="user" />
            {/* TODO: make it a real user name */}
            {UserProfile.getDisplayName(userProfilesByUserId.get(actor.identifier))}
          </span>
        )
    }
  }
})