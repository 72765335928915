import * as React from 'react'
import classnames from 'classnames'

import styles from './CheckInternet.styl'

import apiCall from 'helpers/apiCall'

type State = {
  isOnline: boolean
}

export default class CheckInternetConnection extends React.PureComponent<{},State> {

  private intervalHandle: number

  constructor(props) {
    super(props)
    this.state = { isOnline: true }
  }

  componentDidMount() {
    // check for internet connectivity every 5s
    this.intervalHandle = window.setInterval(this.checkInternetConnection, 5000) as number
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalHandle)
  }

  private checkInternetConnection = async () => {
    try {
      await apiCall({
        path: '/ping',
        method: 'GET',
        data: {},
      })
      this.setState({ isOnline: true })
    } catch (error) {
      console.error('Error received while pinging server: ', error)
      this.setState({ isOnline: false })
    }
  }

  render() {
    return <div
      className={classnames(
        styles.overlay,
        this.state.isOnline && styles.hidden
      )}
      >
        <div className={styles.errorBox}>
          <div className={styles.explanation}>
            Oops! We can't connect to the server.
          </div>
          <div className={styles.explanation}>
            We'll keep trying to reconnect.
          </div>
        </div>
      </div>
  }
}