import { OrderedSet, Map, Set } from 'immutable'

import * as ConnectionSelectors from 'domain/Connection/selectors'
import * as memoize from 'shared/helpers/memoize'

import { IdeaRecord } from 'shared/models/Idea'
import { AppStateRecord } from 'appRoot/state'

import * as EvImm from 'evolving-immutable'

import {SelectorHelpers} from 'helpers/Selector'

import intersection from 'lodash/intersection'

import sortBy from 'lodash/sortBy'

import * as IdeaSelectors from 'domain/Idea/selectors'

import { stemmer } from 'porter-stemmer'
import { english as englishStopwords } from 'stopwords'

import apiCall from 'helpers/apiCall'

import { ConnectionRecord } from 'shared/models/Connection'
import { BoardMembership } from 'shared/models/BoardMembership';

const stopwordsHash = {}
for(const stopword of englishStopwords) {
  stopwordsHash[stopword] = true
}

export const getFullIdeasCache = (state: AppStateRecord) => state.get("ideasCache")

export const getExistingIdeasCache = EvImm.startChain()
  .memoizeForValue()
  .addStep((state: AppStateRecord) => state.get("ideasCache"))
  .memoizeForValue()
  .addFilterStep((idea: IdeaRecord) => !idea.isDeleted)
  .endChain()

export const getBoardMembershipsByIdeaClientId = EvImm.startChain()
  .memoizeForValue()
  .addStep((state: AppStateRecord) => state.get("boardMembershipsByIdeaClientId"))
  .addMapStep(
    EvImm.startChain()
      .addFilterStep((boardMembership: BoardMembership) => !boardMembership.isDeleted)
      .endChain()
  )
  .memoizeForValue()
  .endChain()

const emptyMap = Map<string, BoardMembership>()
export function getBoardMembershipsForIdea (state: AppStateRecord, ideaClientId: string) {
  return getBoardMembershipsByIdeaClientId(state).get(ideaClientId, emptyMap) as Map<string, BoardMembership>
}

export const getBoardIdeaClientIds = (state: AppStateRecord) => state.get("boardIdeaClientIds")

export const getAllInCurrentBoard: (state: AppStateRecord) => Map<string, IdeaRecord> = EvImm.startChain()
  .memoizeForValue()
  .addStep((state: AppStateRecord) => ({
    ideasCache: getExistingIdeasCache(state),
    boardIdeaClientIds: getBoardIdeaClientIds(state)
  }))
  .memoizeForObject()
  .addZipStep({
    extractLeftMap: ({ ideasCache }) => ideasCache,
    extractRightMap: ({ boardIdeaClientIds }) => boardIdeaClientIds,
    attach: (idea, ideaClientId: string | undefined) => ({ idea, isIncludedOnBoard: ideaClientId !== undefined })
  })
  .addFilterStep(({ idea, isIncludedOnBoard }) => isIncludedOnBoard && idea)
  .addMapStep(({ idea }) => idea)
  .endChain()

export const getByClientId = (state: AppStateRecord, ideaClientId: string | null): IdeaRecord | null => ideaClientId === null
  ? null
  : getIdeaGetter(state)(ideaClientId)

// WARNING: This doesn't guarantee correct cache eviction!!!
// TODO: Use EvImm library once it's ready for this
export const getRelatedIdeaClientIds = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord, ideaClientId: string) => ({
    connections: ConnectionSelectors.getIdeaConnectionsGetter(state)(ideaClientId),
    ideaClientId
  }),
  slottingFunction: ({ ideaClientId }) => ideaClientId,
  calculateResult: ({ connections, ideaClientId }) => {
    return _connectionToEndpointSet(connections, ideaClientId)
  }
})

export const getIncomingIdeaClientIds = (state: AppStateRecord, ideaClientId: string) => {
  const connections = ConnectionSelectors.getIdeaConnectionsGetter(state)(ideaClientId)
  return _incomingEndpointSet(connections).remove(ideaClientId)
}

const _getRelatedIdeasByIdeaClientId = EvImm.startChain()
  .mapOneToMany({
    connectionsByIdeaClientId: ConnectionSelectors.getAllByIdeaClientId,
    ideasByIdeaClientId: getExistingIdeasCache
  })
  .addLeftJoinStep({
    mapLeftToSetOfRightKeys: (connections: Map<string, ConnectionRecord>) => [
      ...connections.toSet().map(connection => connection.sourceIdeaClientId),
      ...connections.toSet().map(connection => connection.targetIdeaClientId)
    ],
    attachLeftWithMapOfRight: (_connections, ideas) => Map(ideas.entries()),
    extractLeftMap: ({ connectionsByIdeaClientId }) => connectionsByIdeaClientId,
    extractRightMap: ({ ideasByIdeaClientId }) => ideasByIdeaClientId,
  })
  .endChain()

// We need EvImm to correctly memoize this - keeping ideaGetter in the arguments
// forces the denormalization to rerun too often, EvImm.startChain(...).addLeftJoinStep(...)
// can handle this properly but not lazily (ie the left join will be fully computed, not just for ideas
// we need to compute it for)
export const getRelatedIdeas = memoize.memoizeValueForRecentPreparedArguments({
  debug: false,
  debugPrefix: "getRelatedIdeas",
  prepareArgument: (state: AppStateRecord, ideaClientId: string) => ({
    relatedIdeaClientIds: getRelatedIdeaClientIdsGetter(state)(ideaClientId),
    relatedIdeas: _getRelatedIdeasByIdeaClientId(state).get(ideaClientId),
    ideaClientId
  }),
  slottingFunction: ({ ideaClientId }) => ideaClientId,
  calculateResult: ({ relatedIdeaClientIds, relatedIdeas }) => {
    return relatedIdeaClientIds
      .map(relatedIdeaClientId => relatedIdeas.get(relatedIdeaClientId))
      .filter((idea: IdeaRecord | null): idea is IdeaRecord  => !!idea)
  }
})

export const getIdeaGetter = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    allIdeas: getExistingIdeasCache(state),
  }),

  calculateResult: ({allIdeas}) => (ideaClientId: string): IdeaRecord | null =>
    allIdeas.get(ideaClientId) || null
})

export const getRelatedIdeaClientIdsGetter = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    ideaConnectionsGetter: ConnectionSelectors.getIdeaConnectionsGetter(state),
  }),

  calculateResult: ({ideaConnectionsGetter}) => memoize.cacheFunctionWithSingleScalarArgument((ideaClientId: string) => {
    const connections = ideaConnectionsGetter(ideaClientId)
    return _connectionToEndpointSet(connections, ideaClientId)
  })
})

const getAllConceptsInCurrentBoard = EvImm.startChain()
  .memoizeForValue()
  .addStep((state: AppStateRecord) => getAllInCurrentBoard(state))
  .memoizeForValue()
  .addMapStep((idea: IdeaRecord) => memoize.memoizeValueForRecentArguments(() => {
    const title: string = idea.title.toLowerCase()
      .replace(/[^\w]/g, ' ') // dump non-words
      .replace(/\s+/g, ' ') // dump multiple white-space
      .trim();

    const wordlist = title.split(' ')

    const concepts: string[] = []
    for(const word of wordlist) {
      if (word in stopwordsHash) continue
      const stem: string = stemmer(word, null)
      concepts.push(stem)
    }
    return concepts
  }))
  .endChain()

export const getTopRelatedIdeaSuggestionsGetter = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    allIdeaConcepts: getAllConceptsInCurrentBoard(state),
    allIdeas: getAllInCurrentBoard(state),
    relatedIdeaClientIdsGetter: getRelatedIdeaClientIdsGetter(state),
  }),

  calculateResult: ({allIdeaConcepts, allIdeas, relatedIdeaClientIdsGetter}) => memoize.cacheFunctionWithSingleScalarArgument((sourceIdeaClientId: string) => {
    const maxNumSuggestions = 10
    const sourceIdeaConcepts = allIdeaConcepts.get(sourceIdeaClientId)()
    const existingRelatedIdeas = (relatedIdeaClientIdsGetter(sourceIdeaClientId))
    let topSuggestions: { ideaClientId: string, similarityScore: number }[] = []

    allIdeaConcepts.forEach((getConcepts, ideaClientId) => {
      const concepts = getConcepts()
      if (ideaClientId === sourceIdeaClientId || existingRelatedIdeas.has(ideaClientId)) return

      const similarityScore = intersection(concepts, sourceIdeaConcepts).length

      if (similarityScore > 0) {
        if (topSuggestions.length < maxNumSuggestions) {
          topSuggestions.push({ ideaClientId, similarityScore })
        } else if (similarityScore > topSuggestions[0].similarityScore) {
          topSuggestions[0] = { ideaClientId, similarityScore }
          topSuggestions = sortBy(topSuggestions, ['similarityScore'])
        }
      }
    })

    return topSuggestions.reverse()
      .map(suggestion => allIdeas.get(suggestion.ideaClientId)) as IdeaRecord[]
  })
})

const _connectionToEndpointSet = (connectionsOfIdea: Map<string,ConnectionRecord>, ideaClientId: string) => {
  const ideaIdsSet = Set<string>()
  return ideaIdsSet.withMutations((mutatedIdsSet => {
    connectionsOfIdea.forEach(connection =>
      mutatedIdsSet.add(
        connection.sourceIdeaClientId !== ideaClientId
          ? connection.sourceIdeaClientId
          : connection.targetIdeaClientId
      ))
  }))
}

const _incomingEndpointSet = (connectionsOfIdea: Map<string,ConnectionRecord>) => {
  const ideaIdsSet = Set<string>()
  return ideaIdsSet.withMutations((mutatedIdsSet => {
    connectionsOfIdea.forEach(connection =>
      mutatedIdsSet.add(connection.sourceIdeaClientId)
      )
  }))
}

export const getRelatedIdeaClientIdsForMultipleIdeas = (state: AppStateRecord, ideaClientIds: Iterable<string>) => {
  const relatedIdeaClientIds: string[] = []

  for (const ideaClientId of ideaClientIds) {
    relatedIdeaClientIds.push(...getRelatedIdeaClientIds(state, ideaClientId).toArray())
  }

  return relatedIdeaClientIds
}

export const getSortedFilteredRelatedIdeas = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord, ideaClientId: string) => ({
    ideaClientId,
    relatedIdeas: getRelatedIdeas(state, ideaClientId)
  }),
  slottingFunction: ({ideaClientId}) => ideaClientId,
  calculateResult: ({relatedIdeas}) => {
    return sortIdeaLikeEntitiesByTitle(relatedIdeas.toSet()) as OrderedSet<IdeaRecord>
  }
})

export const getIdeasByColorId: (state: AppStateRecord) => Map<number, Set<IdeaRecord>> = EvImm.startChain()
  .memoizeForValue()
  .addStep(state => getAllInCurrentBoard(state))
  .memoizeForValue()
  .addGroupStep(idea => idea.get('colorId'))
  .endChain()

export const ideaSeemsToBeEmpty = (state, ideaClientId) => {
  const idea = getByClientId(state, ideaClientId)
  if(idea === null) return true

  const titleIsEmpty = idea.get('title') === ''
  const noOutcomingConnections = ConnectionSelectors.getOutcomingConnections(state, ideaClientId).size === 0

  return (titleIsEmpty && noOutcomingConnections)
}

interface IdeaLikeEntity {
  title: string
}

export const sortIdeaLikeEntitiesByTitle = (ideaLikeEntitiesCollection) => {
  return SelectorHelpers.sortCollectionAlphabetically(ideaLikeEntitiesCollection, (ideaLikeEntity: IdeaLikeEntity) => ideaLikeEntity.title)
}

export function actualizeIdeaClientId(state: AppStateRecord, ideaClientId: string) {
  // idea can be deleted already by some previous action, even if its id is stored somewhere,
  // so in that case we should use `undefined` instead
  const ideaExists = ideaClientId && getByClientId(state, ideaClientId)

  return ideaExists
    ? ideaClientId
    : null
}

export const fetchMostRelatedIdeas = async (allIdeas, ideaGetter) => {
  const ideas = allIdeas.valueSeq().map(idea => ({
    description: idea.title,
    _id: idea.clientId,
  }))

  try {
    const data = await apiCall({
      method: 'POST',
      path: '/semanticSearch/getMostRelated',
      data: {
        ideas,
        limit: 100,
      }
    })

    return data.map(relatedPair => ({
      score: relatedPair.score,
      ideaOne: ideaGetter(relatedPair.idea_id_one),
      ideaTwo: ideaGetter(relatedPair.idea_id_two),
    }))
  } catch (err) {
    console.log(err)
    return []
  }
}

export const getMostRelatedIdeas = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    allIdeas: IdeaSelectors.getAllInCurrentBoard(state),
    ideaGetter: getIdeaGetter(state)
  }),
  calculateResult: ({ allIdeas, ideaGetter }) =>
    fetchMostRelatedIdeas(allIdeas, ideaGetter)
})
