import Point, { PointClass, CoordinatePair } from './Point'

export interface RectangleType {
  topLeftCorner: PointClass
  bottomRightCorner: PointClass
  width: number
  height: number
  aspect: number
}

function rectangleFromPoints(...points: PointClass[]): RectangleType | null {
  if (points.length === 0) return null

  const min = (coordinate: "x" | "y") => points.reduce((min,el) => el[coordinate] < min ? el[coordinate] : min, Infinity);
  const max = (coordinate: "x" | "y") => points.reduce((min,el) => el[coordinate] > min ? el[coordinate] : min, -Infinity);

  const topLeftCorner = Point(min('x'),min('y'));
  //const topRightCorner = Point(max('x'),min('y'));
  const bottomRightCorner = Point(max('x'),max('y'));
  //const bottomLeftCorner = Point(min('x'),max('y'));
  const width = bottomRightCorner.x - topLeftCorner.x;
  const height = bottomRightCorner.y - topLeftCorner.y;

  return {
    topLeftCorner,
    bottomRightCorner,
    width,
    height,
    aspect: Math.abs(width/height)
  }
}

function rectangleFromClientRect(bcr: { top: number, left: number, width: number, height: number }): RectangleType {
  return {
    topLeftCorner: Point(bcr.left, bcr.top),
    bottomRightCorner: Point(bcr.left + bcr.width, bcr.top + bcr.height),
    width: bcr.width,
    height: bcr.height,
    aspect: Math.abs(bcr.width/bcr.height)
  }
}

function rectangleInFrameOfReference(rectangle: RectangleType, frameOfReference: RectangleType) {
  const shift = frameOfReference.topLeftCorner
  return {
    ...rectangle,
    topLeftCorner: rectangle.topLeftCorner.sub(shift),
    bottomRightCorner: rectangle.bottomRightCorner.sub(shift)
  }
}

export function rectangleContains({ topLeftCorner, bottomRightCorner }: RectangleType, { x, y }: CoordinatePair) {
  return (
    topLeftCorner.x <= x && x <= bottomRightCorner.x &&
    topLeftCorner.y <= y && y <= bottomRightCorner.y
  )
}

function intersectSegments(start1: number, end1: number, start2: number, end2: number) {
  const start = Math.max(start1, start2)
  const end = Math.min(end1, end2)
  return start < end ? { start, end } : null
}

export function intersectRectangles(rect1: RectangleType, rect2: RectangleType): RectangleType | null {
  const xRange = intersectSegments(
    rect1.topLeftCorner.x, rect1.bottomRightCorner.x,
    rect2.topLeftCorner.x, rect2.bottomRightCorner.x
  )
  const yRange = intersectSegments(
    rect1.topLeftCorner.y, rect1.bottomRightCorner.y,
    rect2.topLeftCorner.y, rect2.bottomRightCorner.y
  )
  return xRange && yRange && rectangleFromPoints(
    Point(xRange.start, yRange.start),
    Point(xRange.end, yRange.end),
  )
}

const epsilon = 1e-20
export function isEmptyRectangle(rect: RectangleType): boolean {
  return rect.width < epsilon || rect.height < epsilon
}

export default {
  rectangleFromPoints,
  rectangleFromClientRect,
  rectangleInFrameOfReference
}
