import * as React from 'react'

import AuthWrapper from './AuthWrapper'
import AuthDialog from './AuthDialog'

import appConfig from 'config/appConfig'

import styles from './Auth.styl'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import { NavigationActions } from 'navigation/actions'

const actionGroups = {
  NavigationActions,
}

type LoginProps = BoundActionGroups<typeof actionGroups>

interface LoginState {
}

class Login extends React.Component<LoginProps, LoginState> {

  render() {
    return (
      <AuthWrapper>
        <h3>Login</h3>
        <AuthDialog disableSignup={true} />
        { appConfig.features.enableUserRegistration && <div className={styles.alt}>
          Don't have an account? <a onClick={this.props.NavigationActions.navigateToSignupPage}>Signup!</a>
        </div> }
      </AuthWrapper>
    )
  }
}

export default ComponentHelpers.createConnectedComponent<{}, {}, typeof actionGroups>({
  actionGroups,
  mapStateToProps: () => ({}),
  component: Login,
})
