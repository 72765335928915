import { Set, Map } from 'immutable'

import * as memoize from 'shared/helpers/memoize'
import * as EvImm from 'evolving-immutable'

import { SelectorHelpers } from 'helpers/Selector'

// TODO: Remove the backward dependancy
import * as GraphViewSelectors from 'components/graph-view/GraphViewSelector'

import * as IdeaSelectors from 'domain/Idea/selectors'

import { IdeaInstanceSelectors } from 'domain/IdeaInstance/selectors'

import { AppStateRecord } from 'appRoot/state'
import { ColorInstance } from 'shared/models/ColorInstance'
import { BoardSummary } from 'shared/models/BoardSummary'
import { ColorRecord } from 'shared/models/Color'
import { IdeaRecord } from 'shared/models/Idea'

import appConfig from 'config/appConfig'

export const getPathFromRootBoardToCurrentBoard = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    return {
      currentBoardSummary: getCurrentBoardSummary(state),
      allBoardSummaries: getAllBoardSummariesByBoardClientIds(state)
    }
  },

  calculateResult: ({currentBoardSummary, allBoardSummaries}) => {
    if (!currentBoardSummary) {
      return null
    }

    const path: BoardSummary[] = []

    let boardSummaryIteratee: BoardSummary | undefined = currentBoardSummary
    while (!!boardSummaryIteratee) {
      path.unshift(boardSummaryIteratee)
      boardSummaryIteratee = allBoardSummaries.get(boardSummaryIteratee.parentBoardClientId)
    }

    // Ignore the root board at the start of the path
    if(path[0].slug === appConfig.defaultBoardSlug) {
      return path.slice(1)
    } else {
      return path
    }
  }
})

export function isLoading(state: AppStateRecord) {
  return state.get('isLoading')
}

export function isBoardMissing(state: AppStateRecord) {
  const isFound = state.get('currentBoardIsFound')
  const isLoading = state.get('isLoading')
  return !isLoading && !isFound
}

export const getCommentsByIdeaClientId = memoize.memoizeValueForRecentArguments(
  (commentsCollection) => SelectorHelpers.groupBy(commentsCollection, 'ideaClientId')
)

export const getAllBoardSummariesByBoardClientIds = (state: AppStateRecord) =>
  state.get('boardSummaries')

const _getAllBoardSummariesBySlug: (state: AppStateRecord) => Map<string, BoardSummary> = EvImm.startChain()
  .addStep(state => getAllBoardSummariesByBoardClientIds(state))
  .addReindexMapStep((boardSummary: BoardSummary) => boardSummary.slug)
  .endChain()

export const getBoardSummaryByClientId = (state: AppStateRecord, boardClientId: string) =>
  getAllBoardSummariesByBoardClientIds(state).get(boardClientId, null)

const _getAttachedBoardClientIdByIdeaClientId: (state: AppStateRecord) => Map<string, string> = EvImm.startChain()
  .memoizeForValue()
  .addStep(state => IdeaSelectors.getExistingIdeasCache(state))
  .memoizeForValue()
  .addFilterStep((idea: IdeaRecord) => !!idea.get("attachedBoardClientId"))
  .addMapStep((idea: IdeaRecord) => idea.get("attachedBoardClientId"))
  .endChain()

export const getAllBoardSummariesByRepresentedIdeaClientId = EvImm.startChain()
  .memoizeForValue()
  .addStep(state => ({
    boardSummaries: state.get("boardSummaries"),
    attachedBoardClientIdByIdeaClientId: _getAttachedBoardClientIdByIdeaClientId(state),
  }))
  .memoizeForObject()
  .addLeftJoinStep({
    mapLeftToSetOfRightKeys: (boardClientId: string) =>
      [boardClientId],
    attachLeftWithMapOfRight: (boardClientId: string, boardSummariesMap) =>
      boardSummariesMap.get(boardClientId),
    extractLeftMap: ({ attachedBoardClientIdByIdeaClientId }) => attachedBoardClientIdByIdeaClientId,
    extractRightMap: ({ boardSummaries }) => boardSummaries,
  })
  .endChain() as (state: AppStateRecord) => Map<string, BoardSummary>

export const getCurrentBoardSlug = (state: AppStateRecord) => state.get('currentBoardSlug')

export const getCurrentBoardSummary = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    return {
      currentBoardSlug: getCurrentBoardSlug(state),
      allBoardSummariesBySlug: _getAllBoardSummariesBySlug(state),
    }
  },
  calculateResult: ({ currentBoardSlug, allBoardSummariesBySlug }) => {
    return (
      currentBoardSlug
        ? allBoardSummariesBySlug.get<BoardSummary | null>(currentBoardSlug, null)
        : null
    )
  }
})

export const getCurrentBoardClientId = (state: AppStateRecord) => {
  const currentBoardSummary = getCurrentBoardSummary(state)

  if (!currentBoardSummary) {
    return null
  }

  return currentBoardSummary.clientId
}

export const getBoardSettings = (state: AppStateRecord) => {
  return {
    searchInAllBoards: state.searchInAllBoards
  }
}

export const getLikesByIdeaClientId = memoize.memoizeValueForRecentArguments(
  (likesCollection) => SelectorHelpers.groupBy(likesCollection, 'ideaClientId')
)

//TODO: use ideas instead of instances - remove colors from idea instances
const getColorIdsFromIdeaInstancesFromActiveGraphLayout = memoize.memoizeReturnedObject(
  (state: AppStateRecord): Iterable<number> => {
    const activeGraphLayout = GraphViewSelectors.getActiveGraphLayout(state)
    const activeGraphLayoutClientId = GraphViewSelectors.getActiveGraphLayoutClientId(state)
    return activeGraphLayout
      ? IdeaInstanceSelectors.getColorIdsFromIdeaInstancesFromGraphLayout(state, activeGraphLayoutClientId)
      : MEMOIZABLE_EMPTY_ITERABLE
  }
)

export const colorsUsedByIdeas = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    ideasByColorId: IdeaSelectors.getIdeasByColorId(state),
    colorsById: state.get('colors'),
  }),
  calculateResult: ({
    ideasByColorId,
    colorsById,
  }) => Set(ideasByColorId.filter((ideas, id) =>
    typeof id === 'number' && colorsById.get(id) && ideas.size > 0
  ).keySeq())
})

const MEMOIZABLE_EMPTY_ARRAY = [] as never[]

export const getColorInstanceByColorId = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    const boardSummary = getCurrentBoardSummary(state)
    return {
      colorInstances: boardSummary ? boardSummary.colorInstances : MEMOIZABLE_EMPTY_ARRAY,
    }
  },
  calculateResult: ({ colorInstances }) => {
    return Set(colorInstances)
      .groupBy(colorInstance => colorInstance.colorId)
      .map(colorInstances => colorInstances.first())
      .toMap() as Map<number, ColorInstance> // TODO: remove this cast once immutable typings are updated
  }
})

// TODO: this is layout-level selector - moved it to ./GraphLayout
export const getUsedColors = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    const colorInstanceByColorId = getColorInstanceByColorId(state)
    const colorIdsFromIdeaInstances = getColorIdsFromIdeaInstancesFromActiveGraphLayout(state)

    return {
      colorInstanceByColorId,
      colorsById: state.get('colors'),
      colorsUsedByIdeas: colorsUsedByIdeas(state),
      colorIdsFromIdeaInstances,
    }
  },

  calculateResult: ({
    colorInstanceByColorId,
    colorsById,
    colorsUsedByIdeas,
    colorIdsFromIdeaInstances,
  }) => {
    let activeColorsSet = Set<number>()
    // Colors with instances in the active graph layout (labeled)
    colorInstanceByColorId.entrySeq().forEach(([id, colorInstance]) => {
      if(colorInstance.label) {
        activeColorsSet = activeColorsSet.add(id)
      }
    })

    // All colors present on at least on idea in the store
    activeColorsSet = activeColorsSet.union(colorsUsedByIdeas)

    // All colors present on at least one idea instance
    for(const colorId of colorIdsFromIdeaInstances) {
      activeColorsSet = activeColorsSet.add(+colorId)
    }

    return activeColorsSet
      .map(id => colorsById.get(id))
      .filter(
        (color: ColorRecord): color is ColorRecord => !!color
      )
    }
})

const MEMOIZABLE_EMPTY_ITERABLE: Iterable<any> = []
