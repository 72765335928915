import { Map } from 'immutable'
import apiCall from 'helpers/apiCall'
import { ErrorHelpers } from 'shared/helpers/Error'

import * as IdeaSelectors from 'domain/Idea/selectors'
import { AttributeValueSelectors } from 'domain/AttributeValue/selectors'

import { IdeaRecord, IdeaSourceObjectType } from 'shared/models/Idea'

import * as ConnectionActions from '../Connection/actions'
import { ModalHelpers, ModalTypes } from 'helpers/Modal'

import * as Immutable from 'immutable'
import { AppStateRecord } from 'appRoot/state'

import { AttributeValueActions } from 'domain/AttributeValue/actions'

import * as GenericActions from 'domain/GenericActions'
import * as PermissionActions from 'domain/Permission/actions'
import { StandardPrivacyActor, PermissionResourceType, Permission } from 'shared/models/Permission'
import { BoardActions } from 'domain/Board/actions';

import zipObject from 'lodash/zipObject'
import * as BoardSelectors from 'domain/Board/selectors'
import { memoize } from 'shared/helpers/memoize';

export const IdeasServerState: { [key: string]: { isBeingCreated?: Promise<void>, isBeingUpdated?: Promise<void> } } = {}

function markIdea(mark: keyof typeof IdeasServerState[any], ideaClientId) {
  let resolve: () => void
  const promise = new Promise<void>(res => resolve = res)
  IdeasServerState[ideaClientId] = { ...IdeasServerState[ideaClientId], [mark]: promise }
  return () => {
    delete IdeasServerState[ideaClientId][mark]
    resolve()
  }
}

export function markIdeaAsBeingCreated(ideaClientId) {
  return markIdea("isBeingCreated", ideaClientId)
}

function markIdeaAsBeingUpdated(ideaClientId) {
  return markIdea("isBeingUpdated", ideaClientId)
}

const _updateIdea = (clientId, ideaUpdater: (idea: IdeaRecord) => IdeaRecord) => (dispatch, getState) => {
  const idea = IdeaSelectors.getByClientId(getState(), clientId)
  const updatedIdea = ideaUpdater(idea)
  dispatch(IdeaActions.pushCurrentBoardIdeas([updatedIdea]))
  return updatedIdea
}

const _patchIdea = (clientId: string, ideaPatch: Partial<IdeaSourceObjectType>) => (dispatch) => {
  return dispatch(_updateIdea(clientId, (idea) => idea.merge(Immutable.Map(ideaPatch))))
}

const _verifyClientId = (state, clientId) => {
  const actualClientId = IdeaSelectors.actualizeIdeaClientId(state, clientId)

  // there is some chance that idea gets deleted before this action fires due to debounced nature of some event handling,
  // like title update in GraphVertex
  // so in this case we just do nothing
  if (!actualClientId) {
    return false
  }

  return true
}

const _updateIdeaProperty = (propertyName: keyof IdeaSourceObjectType, apiEndpoint) => (ideaClientId: string, propertyValue) => async (dispatch, getState) => {
  //FIXME: remove this WTF - such check should happen on the level that can generate stale events (UI level), not in domain actions
  if (!_verifyClientId(getState(), ideaClientId)) {
    return
  }

  while (IdeasServerState[ideaClientId] && IdeasServerState[ideaClientId].isBeingCreated) {
    await IdeasServerState[ideaClientId].isBeingCreated
  }
  while (IdeasServerState[ideaClientId] && IdeasServerState[ideaClientId].isBeingUpdated) {
    await IdeasServerState[ideaClientId].isBeingUpdated
  }
  const removeUpdateMark = markIdeaAsBeingUpdated(ideaClientId)

  const patch = {
    [propertyName]: propertyValue,
    updatedAt: new Date(Date.now()),
  }

  const updatedIdea = dispatch(_patchIdea(ideaClientId, patch))
  const data = await apiCall({
    path: `/ideas/${apiEndpoint}/${ideaClientId}`,
    method: 'POST',
    data: { [propertyName]: propertyValue }
  })

  const currentIdea = IdeaSelectors.getByClientId(getState(), updatedIdea.clientId)
  // Accept server version of the idea only if no change to the updated idea was made
  // in the time it took the server to respond
  if (updatedIdea === currentIdea) {
    dispatch(IdeaActions.pushCurrentBoardIdeas(data.ideas))
  } else {
    console.warn("Rejecting server response - idea was modified", updatedIdea, currentIdea)
  }

  removeUpdateMark()
}

const IdeaActions = {
  replacePermissions: (
    ideaClientId: string,
    newPermissions: Permission<PermissionResourceType.ideas>[]
  ) => {
    return PermissionActions.replacePermissions(PermissionResourceType.ideas, ideaClientId, newPermissions)
  },

  replaceOwners: (
    ideaClientId: string,
    newOwners: StandardPrivacyActor[]
  ) => {
    return PermissionActions.replaceOwners(PermissionResourceType.ideas, ideaClientId, newOwners)
  },

  fetchIdeasByClientIds: (ideaClientIds: string[]) => async dispatch => {
    const entities = await apiCall({
      path: `/ideas/getIdeas`,
      method: 'POST',
      data: { ideaClientIds }
    })

    dispatch(GenericActions.pushAllObjects(entities))
  },

  pushCurrentBoardIdeas: (ideas: (IdeaRecord | IdeaSourceObjectType)[]) => (dispatch) => {
    dispatch(GenericActions.pushAllObjects({ ideas }))
  },

  updateIdeaTitle: (ideaClientId: string, newTitle: string) => (dispatch) => {
    dispatch(_updateIdeaProperty("title", "updateTitle")(ideaClientId, newTitle))
  },

  updateIdeaStatus: (ideaClientId: string, newStatus) => (dispatch) => {
    dispatch(_updateIdeaProperty("status", "updateStatus")(ideaClientId, newStatus))
  },

  updateIdeaColorId: (ideaClientId: string, newColorId) => (dispatch) => {
    dispatch(_updateIdeaProperty("colorId", "updateColorId")(ideaClientId, newColorId))
  },

  setAttributeValue: (ideaClientId: string, attributeClientId, newValue) => async (dispatch) => {
    await dispatch(_updateAttributeValue(ideaClientId, attributeClientId, newValue))
  },

  // TODO: Figure out if we actually want to use this (it's not utilized right now)
  deleteAttributeValue: (ideaClientId: string, attributeClientId) => async (dispatch) => {
    dispatch(_updateAttributeValue(ideaClientId, attributeClientId, undefined))
  },

  deleteIdeasWithConfirmation: (clientIds: string[], deletionConfirmationLevel: symbol) => (dispatch, getState) => {
    const ideaWillBeDeletedPromise = _confirmIdeasDeletion(clientIds, deletionConfirmationLevel, getState())

    async function next() {
      const willBeDeleted = await ideaWillBeDeletedPromise
      if (!willBeDeleted) {
        return
      }

      dispatch(BoardActions.incrementCurrentBoardSummaryIdeaCount(-clientIds.length));

      // TODO: as boards are also ideas now, deleting a board idea should also result in deletion of its ideas
      clientIds.forEach(clientId => {
        dispatch(_patchIdea(clientId, { isDeleted: true }))
        dispatch(ConnectionActions.markAllIdeaConnectionsAsDeleted(clientId))
      })

      const entities = await apiCall({
        path: '/ideas',
        method: 'DELETE',
        data: {
          clientIds
        }
      })

      dispatch(GenericActions.pushAllObjects(entities))
    }

    // Don't wait for the api call results, resolve to { ideaWillBeDeletedPromise } immediately
    next()

    return { ideaWillBeDeletedPromise }
  },

  restoreIdea: (ideaClientId: string) => async (dispatch) => {
    const entities = await apiCall({
      path: `/ideas/restore/${ideaClientId}`,
      method: 'POST'
    })

    dispatch(GenericActions.pushAllObjects(entities))
  },

  incrementLikeCount: (ideaClientId: string, delta = +1) => (dispatch) => {
    return dispatch(_updateIdea(
      ideaClientId,
      (idea) => idea.update('likeCount', likeCount => likeCount + delta)
    ))
  },

  incrementCommentCount: (ideaClientId: string, delta = +1) => (dispatch) => {
    return dispatch(_updateIdea(
      ideaClientId,
      (idea) => idea.update('commentCount', commentCount => commentCount + delta)
    ))
  },

  removeFromBoard: (ideaClientId: string, boardClientId: string) => async (dispatch) => {
    const data = await apiCall({
      path: `/ideas/removeFromBoard/${ideaClientId}/${boardClientId}`,
      method: 'POST'
    })
    dispatch(GenericActions.pushAllObjects(data))
  },

  getSemanticMatches: memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (searchQuery) => ({searchQuery: searchQuery || ''}),
    calculateResult: ({ searchQuery }) => memoize.memoizeValueForRecentPreparedArguments({
      prepareArgument: (dispatch, getState) => ({
        dispatch,
        searchQuery,
        boardClientId: BoardSelectors.getCurrentBoardClientId(getState()),
      }),
      calculateResult: ({ dispatch, searchQuery, boardClientId }) => {
        return dispatch(_calculateSemanticMatches({ searchQuery, boardClientId }))
      }
    }),
  }),
  fetchSemanticSuggestions,
}

const _updateAttributeValue = (ideaClientId: string, attributeClientId, newValueOrUndefined) => async (dispatch, getState) => {
  if (!_verifyClientId(getState(), ideaClientId)) {
    return
  }

  await dispatch(AttributeValueActions.updateAttributeValueForIdea(
    ideaClientId, attributeClientId, newValueOrUndefined
  ))
}

const IdeaDeletionConfirmationLevels = {
  DontConfirm: Symbol('No prompt'),
  ConfirmAlways: Symbol('Prompt always'),
  Standard: Symbol('Prompt if has attributes')
}

export { IdeaActions, IdeaDeletionConfirmationLevels }

const _confirmIdeasDeletion = async (
  clientIds: string[],
  deletionConfirmationLevel: symbol,
  state: AppStateRecord,
): Promise<boolean> => {
  const ideaCount = clientIds.length

  let shouldConfirm = false
  let confirmationQuestion = ''
  switch (deletionConfirmationLevel) {
    case IdeaDeletionConfirmationLevels.ConfirmAlways:
      shouldConfirm = true
      if (ideaCount === 1) {
        const idea = ErrorHelpers.castToNotNullOrThrow(
          IdeaSelectors.getByClientId(state, clientIds[0]) || null,
          "Cannot delete an idea that doesn't exist"
        )
        const ideaTitle = idea.get('title')
        const ideaTitleWithSpace = ideaTitle ? ` '${ideaTitle}'` : ''
        confirmationQuestion = `Delete idea${ideaTitleWithSpace}?`
      } else {
        confirmationQuestion = `Delete ${ideaCount} ideas?`
      }
      break

    case IdeaDeletionConfirmationLevels.Standard:
      // Inaccessible (due to teamCode permissions) attributes should trigger deletion confirmation too
      shouldConfirm = (await Promise.all(clientIds.map(
        async clientId => AttributeValueSelectors.ideaHasAttributes(clientId)
      ))).every(x => x === true)

      if (shouldConfirm) {
        confirmationQuestion = `Ideas have attributes. Do you want to delete it anyway?`
      }

      break
    default:
      console.warn("deletionConfirmationLevel must be one of property values of IdeaDeletionConfirmationLevels")
      break
  }

  return !shouldConfirm || await ModalHelpers.globalModals.confirm({
    type: ModalTypes.WARNING,
    title: 'Deleting idea',
    message: confirmationQuestion,
    okButtonCaption: 'Delete',
    cancelButtonCaption: 'Cancel'
  })
}

//Adding a single key cache because IdeaSearchBar/actions.ts dispatches a search
//even on focus change, so if the query hasn't changed, the API call is a waste.
const semanticSearchCache = {
  query: null,
  data: null
};
// For a given query and set of ideas, get the semantically similar ideas.
// The data is an array of matches, where each match has the form:
// [full text that was matched, score, specific similar words in the text, idea clientId]
function fetchSemanticSuggestions(query: string, boardClientId: string, threshold: number = 0.0) {
  return async (dispatch) => {
    if ((query || '').length === 0) {
      semanticSearchCache.query = null
      return []
    }

    try {

      if(query === semanticSearchCache.query){
        const { results } = semanticSearchCache.data
        return results as { score: number, ideaClientId: string }[]
      }

      // First try to hit the cache, without passing ideas.
      let data = await apiCall({
        method: 'POST',
        path: '/semanticSearch',
        data: {
          query,
          cache_key: boardClientId,
          threshold,
        }
      })

      if (data.status === 'error') {
        console.warn("semantic search update failed")
        return []
      }
      const { results, ideas } = data
      semanticSearchCache.query = query;
      semanticSearchCache.data = data;
      dispatch(GenericActions.pushAllObjects({ ideas }))
      dispatch(BoardActions.addToBoardIdeaClientIds(ideas.map(idea => idea.clientId)))

      return results as { score: number, ideaClientId: string }[]
    } catch (err) {
      console.log(err)
      return []
    }
  }
}

// Returns an object mapping idea client-ids to match score.
function _calculateSemanticMatches({
  searchQuery,
  boardClientId
}) {
  return async (dispatch): Promise<Map<string, number>> => {
    if (searchQuery.length === 0) {
      return Map({})
    }

    const semanticMatches = await dispatch(fetchSemanticSuggestions(searchQuery, boardClientId))
    const ideaClientIds = semanticMatches.map(suggestion => suggestion.ideaClientId)
    const scores = semanticMatches.map(suggestion => suggestion.score) as number[]
    return Map(zipObject(ideaClientIds, scores)) as Map<string, number>
  }
}
