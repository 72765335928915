import apiCall from 'helpers/apiCall'

import * as types from './constants'
import { AttributeValueSelectors } from './selectors'

import { AttributeValues } from 'shared/models/AttributeValue'

const _pushAttributeValues = (ideaClientId: string, attributeValues: AttributeValues | null) => ({
  type: types.PUSH_ATTRIBUTE_VALUES_INTO_CACHE,
  ideaClientId,
  attributeValues,
})

const fetchAttributeValuesForIdea = (ideaClientId: string) => async (dispatch) => {
  const attributeValues = await apiCall({
    path: `/attributeValues/attributeValuesForIdea/${ideaClientId}`
  })

  dispatch(_pushAttributeValues(ideaClientId, attributeValues))
}

const fetchAttributeValuesForIdeaIfMissing = (ideaClientId: string) => async (dispatch, getState) => {
  const attributeValues = AttributeValueSelectors.getAttributeValuesOfIdea(getState(), ideaClientId)

  if(attributeValues === null) {
    await dispatch(fetchAttributeValuesForIdea(ideaClientId))
  }
}

const updateAttributeValueForIdea = (ideaClientId: string, attributeClientId: string, newValueOrUndefined) => async (dispatch, getState) => {
  // TODO: Create a separate API endpoint for deleting attribute values
  await apiCall({
    path: `/attributeValues/updateAttributeValue/${ideaClientId}/${attributeClientId}`,
    method: 'POST',
    data: { newValueOrUndefined, attributeClientId }
  })

  const oldAttributeValues = AttributeValueSelectors.getAttributeValuesOfIdea(getState(), ideaClientId)

  if(oldAttributeValues !== null) {
    const newAttributeValues = {
      ...oldAttributeValues
    }
    if(newValueOrUndefined === undefined) {
      delete newAttributeValues[attributeClientId]
    } else {
      newAttributeValues[attributeClientId] = newValueOrUndefined
    }

    dispatch(_pushAttributeValues(ideaClientId, newAttributeValues))
  }
}

const invalidateCachedAttributeValuesForIdea = (ideaClientId: string) => async (dispatch) => {
  dispatch(_pushAttributeValues(ideaClientId, null))
}

const invalidateAllCachedAttributeValues = () => ({
  type: types.RESET_ATTRIBUTE_VALUES_CACHE,
})

export const AttributeValueActions = {
  fetchAttributeValuesForIdea,
  fetchAttributeValuesForIdeaIfMissing,
  updateAttributeValueForIdea,
  invalidateCachedAttributeValuesForIdea,
  invalidateAllCachedAttributeValues,
}