import * as React from 'react'
import classnames from 'classnames'
import qs from 'querystring'

import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'
import { UserProfileRecord } from 'shared/models/UserProfile'
import { BoardSummary } from 'shared/models/BoardSummary'
import { NavigationActions } from 'navigation/actions'
import { UserProfileActions } from 'domain/UserProfile/actions'
import { BoardActions } from 'domain/Board/actions'
import ProfileBoardMembershipActions from 'domain/ProfileBoardMembership/actions'
import * as BoardSelectors from 'domain/Board/selectors'
import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'
import LayoutShell from '../layout/LayoutShell'
import Input from 'components/common/Input'

import styles from './JoinView.styl'
import { AllViewTypes } from 'config/appConfig'

interface StateProps {
  currentUserId: number
  currentUserProfile: UserProfileRecord | null
  currentBoardClientId: string | null
  currentBoardSummary: BoardSummary | null
}

const actionGroups = {
  ProfileBoardMembershipActions,
  NavigationActions,
  UserProfileActions,
  BoardActions
}

class JoinView extends React.Component<StateProps & BoundActionGroups<typeof actionGroups>> {

  private signupForm: HTMLFormElement | null = null

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    if (this.props.currentBoardClientId) {
      this.props.ProfileBoardMembershipActions.getPeopleForBoard(this.props.currentBoardClientId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentBoardClientId && nextProps.currentBoardClientId !== this.props.currentBoardClientId) {
      this.props.ProfileBoardMembershipActions.getPeopleForBoard(nextProps.currentBoardClientId)
    }
  }

  isAnonUserOrLoggedOut() {
    const { currentUserProfile } = this.props
    return currentUserProfile && !currentUserProfile.isAnonymousUser()
  }

  handleFacebookLogin(e) {
    e.preventDefault();
    const query = qs.stringify({ redirect: window.location.pathname })
    window.location.assign(`/api/v1/auth/facebook?${query}`)
  }

  handleSignupComplete(e) {
    e.preventDefault()
    const { bio, idea1, idea2, idea3 } = this.signupForm
    this.props.UserProfileActions.updateUser(this.props.currentUserId, { bio: bio.value })

    for (const idea of [idea1, idea2, idea3]) {
      const text = idea.value
      if (text) this.props.BoardActions.createIdeasFromTextOnCurrentBoard(text)
    }

    this.props.NavigationActions.enterTheViewType(AllViewTypes.LIST)
  }

  renderFacebookButtonOrProfile() {
    const { currentUserProfile } = this.props
    if (this.isAnonUserOrLoggedOut()) {
      return <p>
        You signed up as: {currentUserProfile.getDisplayName()}
      </p>
    } else {
      return <>
        <h5>Sign up to Ideaflow to connect with others who have related ideas</h5>
        <button onClick={this.handleFacebookLogin} className={classnames(styles.facebookButton)}>
          Continue with Facebook
        </button>
      </>
    }
  }

  private renderForm() {
    const { currentUserProfile } = this.props
    return <form className={styles.loginForm} ref={signupForm => this.signupForm = signupForm} onSubmit={(e) => this.handleSignupComplete(e)}>
      <h5>List your interests</h5>
      <Input className={styles.interestInput} size="normal" type="text" name="bio" focusOnMount={false}
        placeholder="Examples: AI, cryptocurrency, government"
        defaultValue={this.isAnonUserOrLoggedOut() ? currentUserProfile.bio : null}
      />

      <h5>List 3 project ideas</h5>
      <Input className={styles.ideaInput} size="normal" type="text" name="idea1" focusOnMount={false}
        placeholder="Project Idea #1"
      />
      <Input className={styles.ideaInput} size="normal" type="text" name="idea2" focusOnMount={false}
        placeholder="Project Idea #2"
      />
      <Input className={styles.ideaInput} size="normal" type="text" name="idea3" focusOnMount={false}
        placeholder="Project Idea #3"
      />

      <button className={classnames(styles.submitButton)} disabled={!this.isAnonUserOrLoggedOut()}>
        Submit
      </button>
    </form>
  }

  render() {
    const { currentBoardSummary } = this.props
    return (
      <LayoutShell className={styles.joinViewWrapper} hideNavigation={true}>
        <div className={styles.joinView}>
          <div className={styles.joinForm}>
            <h1>{(currentBoardSummary && currentBoardSummary.title) || "Welcome to IdeaFlow"}</h1>
            {this.renderFacebookButtonOrProfile()}
            {this.isAnonUserOrLoggedOut() && this.renderForm()}
          </div>
        </div>
      </LayoutShell>
    )
  }
}

export default ComponentHelpers.createConnectedComponent<{}, StateProps, typeof actionGroups> ({
  actionGroups,
  mapStateToProps: (state): StateProps => {
    return {
      currentUserId: CurrentUserSelectors.getId(state),
      currentUserProfile: CurrentUserSelectors.getCurrentUserProfile(state),
      currentBoardClientId: BoardSelectors.getCurrentBoardClientId(state),
      currentBoardSummary: BoardSelectors.getCurrentBoardSummary(state)
    }
  },
  component: JoinView
})
