import {
  PUSH_CURRENT_BOARD_CONNECTIONS,
} from './constants'

import { AppStateRecord } from 'appRoot/state'

export default (reducer) => {
  reducer.handle(PUSH_CURRENT_BOARD_CONNECTIONS, (state: AppStateRecord, action) => state.update('connectionsCache', (connectionsCache =>
    connectionsCache.mutate(consumeConnection => {
      for (const connectionData of action.connections) {
        consumeConnection(connectionData)
      }
    }))))
}
