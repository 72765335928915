import * as React from 'react'

import { Dispatch } from 'redux'
import { connect, connectAdvanced, Selector } from 'react-redux'

import mapValues from 'lodash/mapValues'

import * as EqualityHelpers from 'shared/helpers/equalities'
import config from 'config/appConfig'
import {StoredUIState} from 'managers/StoredUIState'
import {ActionCreatorsMapObject, bindActionCreators} from 'redux'
import {AppStateRecord} from 'appRoot/state'
import {ErrorHelpers} from 'shared/helpers/Error'

import { wrapCancellable, CancellablePromise } from "shared/helpers/cancellablePromise"

interface CreatePropsPromiseResolverConfiguration<P,T> {
  getPromise: (props: P) => Promise<T> | null
  updateState: (resolvedValue: T, isResolved: boolean) => void
  unresolvedValue: T
}

const ComponentHelpers = {
  //use this function in component definition to create `shouldComponentUpdate` method
  createShouldComponentUpdateShallow<P,S>(componentName, { debug=false }={ debug: false as boolean}) {
    return function (nextProps: P, nextState: S) {
      if (this.state !== nextState) {
        if (config.log.shouldComponentUpdateReasoning || debug) {
          console.log(`${componentName} - re-rendering due to state depth-0 change.`, this.state, nextState)
        }
        return true
      }

      else {
        if (!ComponentHelpers.compareComponentProps(this.props, nextProps)) {
          if (config.log.shouldComponentUpdateReasoning || debug) {
            performance && performance.mark(`${componentName} prop change`)
            console.log(`${componentName} - re-rendering due to props depth-1 change. Props diff:`, EqualityHelpers.depthOneObjectDiff(this.props, nextProps))
          }

          return true
        }
      }

      if (config.log.shouldComponentUpdateReasoning || debug) {
        console.log(`${componentName} - skipping re-rendering.`)
      }
      return false
    }
  },

  createPropsPromiseResolver<P,T>({ getPromise, updateState, unresolvedValue }: CreatePropsPromiseResolverConfiguration<P,T>) {
    let current: CancellablePromise<T> | null = null
    async function propsPromiseResolver(nextProps: P) {
      const nextPromise = getPromise(nextProps)
      if(nextPromise === null || (current !== null && nextPromise === current.originalPromise)) {
        return
      }
      if (current !== null) current.cancel()

      current = wrapCancellable(nextPromise)
      updateState(unresolvedValue, false)

      try {
        const resolvedValue = await current.promise

        // The cancellable promise will reject if it's cancelled, making sure state is not updated
        // if the promise was superseeded by a newer one
        updateState(resolvedValue, true)
      } catch (err) {
        console.warn(err)
      }
    }
    propsPromiseResolver.cancel = () => {
      if (current !== null) current.cancel()
    }

    return propsPromiseResolver
  },

  compareComponentProps (currentProps, nextProps) {
    return EqualityHelpers.depthOneObjectEquality(currentProps, nextProps, undefined, PROPS_IGNORED_WHEN_COMPARING)
  },

  createConnectedComponent,

  createComponentWithStoredUIState
}

type ActionGroupsDictionary = {
  [groupName: string]: ActionCreatorsMapObject
}

type PropsWithChildren<P> = Readonly<{ children?: React.ReactNode }> & Readonly<P>
type StateToPropsMapper<OuterProps, StateProps> = (state: AppStateRecord, outerProps: PropsWithChildren<OuterProps>) => StateProps

type ConnectableComponentClass<
  StateProps,
  ActionGroups extends ActionGroupsDictionary,
  WrappedComponent extends React.Component<any>
> =
  React.ClassType<FullInnerProps<StateProps, ActionGroups>, WrappedComponent, React.ComponentClass<FullInnerProps<StateProps, ActionGroups>>>
  //(new (props: FullInnerProps<StateProps, ActionGroups>, context?: any) => WrappedComponent)
type ConnectableComponent<StateProps, ActionGroups extends ActionGroupsDictionary> = React.Component<FullInnerProps<StateProps, ActionGroups>>

// Replace the below with React.ClassType<OuterProps, ConnectedComponent<OuterProps,WrappedComponent>, React.ComponentClass<OuterProps>>
// once TypeScript starts treating the JSX syntax the same way as React.createElement (take a look at https://github.com/errendir/typescript-jsx-issue)
export type ConnectedComponentClass<
    OuterProps, WrappedComponent extends React.Component<any>
  > = React.ClassType<OuterProps, WrappedComponent, React.ComponentClass<OuterProps>>

type ConnectDecorator<
  OuterProps, StateProps,
  ActionGroups extends ActionGroupsDictionary,
  WrappedComponent extends React.Component<any>
> =
  (component: ConnectableComponentClass<StateProps, ActionGroups, WrappedComponent>) => ConnectedComponentClass<WrappedOuterProps<OuterProps>, WrappedComponent>
type WrappedOuterProps<OuterProps> = OuterProps

interface CreateConnectedComponentArgs<OuterProps, StateProps, ActionGroups extends ActionGroupsDictionary, WrappedComponent extends ConnectableComponent<StateProps, ActionGroups>> {
  mapStateToProps?: StateToPropsMapper<OuterProps, StateProps>,

  //use factory if you want to create some container's state that could be reused in `mapStateToProps`
  mapStateToPropsFactory?: (dispatch?) => StateToPropsMapper<OuterProps, StateProps>,
  actionGroups: ActionGroups,
  component: ConnectableComponentClass<StateProps, ActionGroups, WrappedComponent>,
}

declare module "redux" {
  type ThunkedAction = (dispatch?: any, getState?: any) => any
  export interface Dispatch<S> {
    <A extends (Action | ThunkedAction)>(action: A): A extends (dispatch?: any, getState?: any) => infer R ? R : A;
  }
}

type SkipDispatch<T> =
    T extends (...args: infer A) => (dispatch?: any, getState?: any) => infer R
      ? (...args: A) => R
  : T extends (...args: infer A) => infer R
      ? (...args: A) => R
  : never

// This makes sure the return type of each function in each action group is correct
type BoundActions<Actions extends ActionCreatorsMapObject> = {
  [ActionName in keyof Actions]: SkipDispatch<Actions[ActionName]>
}

export type BoundActionGroups<ActionGroups extends ActionGroupsDictionary> = {
  [ActionGroupName in keyof ActionGroups]: BoundActions<ActionGroups[ActionGroupName]>
}

type FullInnerProps<StateProps, ActionGroups extends ActionGroupsDictionary> = StateProps & BoundActionGroups<ActionGroups>

function createConnectedComponent<
  OuterProps, StateProps, ActionGroups extends ActionGroupsDictionary,
  WrappedComponent extends ConnectableComponent<StateProps, ActionGroups> = React.Component<any>
> ({
  mapStateToProps,
  mapStateToPropsFactory,
  actionGroups,
  component
}: CreateConnectedComponentArgs<OuterProps, StateProps, ActionGroups, WrappedComponent>):
  ConnectedComponentClass<OuterProps, WrappedComponent>
{
  const staticPropsMapperIsPassed = !!mapStateToProps
  const propsMapperFactoryIsPassed = !!mapStateToPropsFactory

  ErrorHelpers.assert(
    staticPropsMapperIsPassed !== propsMapperFactoryIsPassed,
    'strictly one of options should be passed - either `mapStateToProps` or `mapStateToPropsFactory`',
    {mapStateToProps, mapStateToPropsFactory}
  )

  const mapDispatchToProps: (dispatch: Dispatch<any>) => BoundActionGroups<ActionGroups> = (dispatch: Dispatch<any>) =>
    mapValues(actionGroups, (actionGroup) =>
      bindActionCreators(actionGroup, dispatch)
    ) as BoundActionGroups<ActionGroups>

  const mergeProps = (
    stateProps: StateProps,
    boundActionGroups: BoundActionGroups<ActionGroups>,
    _ownProps: OuterProps
  ): FullInnerProps<StateProps, ActionGroups> => Object.assign({}, stateProps, boundActionGroups) as FullInnerProps<StateProps, ActionGroups>

  let connectDecorator: ConnectDecorator<OuterProps, StateProps, ActionGroups, WrappedComponent>

  //with static props mapper we can use just connect
  if (staticPropsMapperIsPassed) {
    connectDecorator = connect<StateProps, BoundActionGroups<ActionGroups>, OuterProps, FullInnerProps<StateProps, ActionGroups>, AppStateRecord>(
      mapStateToProps as StateToPropsMapper<OuterProps, StateProps>,
      mapDispatchToProps,
      // define `mergeProps` to remove implicit passing of outer props to component
      // - everything should be passed via props created by `mapStateToProps`
      mergeProps,
      ({ forwardRef: true } as any) // For some reason react-redux typings are not up to date with documentation
    ) as any
  }
  // otherwise we need lower-level connectAdvanced that enables use of factories
  else {

    //selectorFactory is a strange name, but it's used by react-redux, so we will stick to it here
    const selectorFactory = (dispatch: Dispatch<any>) => {
      const boundActionGroups = mapDispatchToProps(dispatch)
      const propsMapper = (mapStateToPropsFactory as (dispatch?) => StateToPropsMapper<OuterProps, StateProps>)(dispatch)

      let currentStateProps: StateProps | null = null
      let currentFullInnerProps

      return ((newState: AppStateRecord, newOuterProps: OuterProps): FullInnerProps<StateProps, ActionGroups> => {
        const newStateProps = propsMapper(newState, newOuterProps)

        // skip changing component props instance if its contents were not changed to avoid re-rendering
        // this is our responsibility when using connectAdvanced
        if (!currentStateProps || !ComponentHelpers.compareComponentProps(currentStateProps, newStateProps)) {
          currentStateProps = newStateProps
          currentFullInnerProps = mergeProps(currentStateProps, boundActionGroups, null)
        }

        return currentFullInnerProps
      }) as Selector<AppStateRecord, FullInnerProps<StateProps, ActionGroups>, OuterProps>
    }

    connectDecorator = connectAdvanced(
      selectorFactory,

      // don't use component refs as they break approaches like HOC
      // instead, a component can accept a callback prop to expose some handle to manage it
      {forwardRef: false}
    ) as any
  }

  return connectDecorator(component)
}

interface _CreateComponentWithStoredUIStateArgs<ExternalProps, UIState>{
  component: React.ComponentClass<ExternalProps & StoredUIStateProps<UIState>>
  defaultUIState: UIState
}

type _CreateComponentWithStoredUIStateReturn<ExternalProps> = React.ComponentClass<ExternalProps & {storedUIStateKey : string | null}>

export interface StoredUIStateProps<UIState>{
  storedUIState: UIState
  setStoredUIState: (newUIState: UIState) => void
}

function createComponentWithStoredUIState<ExternalProps, UIState>(
  {component, defaultUIState}: _CreateComponentWithStoredUIStateArgs<ExternalProps, UIState>
): _CreateComponentWithStoredUIStateReturn<ExternalProps> {
  const displayName = `ComponentWithStoredUIState(${component.displayName || component.name})`
  return class ComponentWithStoredUIState extends React.Component<ExternalProps & {storedUIStateKey : string | null}, {
    _uiState: UIState
  }> {
    constructor (props) {
      super(props)

      this.state = {_uiState: this._useStoredUIState()
        ? StoredUIState.get(this.props.storedUIStateKey, defaultUIState, displayName) as UIState
        : defaultUIState
      }
    }

    static displayName = displayName

    _useStoredUIState = () => !!this.props.storedUIStateKey

    render () {
      const componentProps = {
        ...(this.props as object),  // this is a workaround for TS bug
                                    // that should be fixed by https://github.com/Microsoft/TypeScript/pull/13288
        storedUIState: this.state._uiState,
        setStoredUIState: this._setStoredUIState
      } as ExternalProps & StoredUIStateProps<UIState>

      return React.createElement(component, componentProps, this.props.children)
    }

    _setStoredUIState = (newState: UIState) => {
      this.setState({_uiState: newState})

      if (this._useStoredUIState()) {
        StoredUIState.set(this.props.storedUIStateKey, newState, displayName)
      }
    }
  }
}

// skip React-specific properties, or React will raise warning
const PROPS_IGNORED_WHEN_COMPARING = ['key', 'ref']

export {ComponentHelpers}
