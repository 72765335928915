import { ErrorHelpers } from 'shared/helpers/Error'

import history from './history'

import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'
import { UserProfileSelectors } from 'domain/UserProfile/selectors'
import * as BoardSelectors from 'domain/Board/selectors'
import * as IdeaSelectors from 'domain/Idea/selectors'

import { DocumentRecord } from 'shared/models/Document'
import { ForbiddenBoardSlugs } from 'shared/models/ForbiddenNames'

import appConfig, { AllViewTypes } from 'config/appConfig'

const NavigationActions = {

  enterTheBoard: (boardClientId, replace = false) => (_dispatch, getState) => {
    const boardSummary = ErrorHelpers.castToNotNullOrThrow(
      BoardSelectors.getBoardSummaryByClientId(getState(), boardClientId),
      `There is no boardSummary object with clientId=${boardClientId}`
    )
    const viewType = getState().get('viewType')

    _navigateToBoardSlugAndViewType(boardSummary.slug, viewType, {}, replace)
  },

  enterTheRootBoard: () => (_dispatch, getState) => {
    const viewType = getState().get('viewType')
    _navigateToBoardSlugAndViewType(appConfig.defaultBoardSlug, viewType)
  },

  enterTheBoardAttachedToAnIdea: (ideaClientId, replace = false) => (_dispatch, getState) => {
    const idea = ErrorHelpers.castToNotNullOrThrow(
      IdeaSelectors.getByClientId(getState(), ideaClientId) || null,
      `There is no idea with clientId=${ideaClientId}`
    )
    const boardClientId = idea.get("attachedBoardClientId")
    const boardSummary = ErrorHelpers.castToNotNullOrThrow(
      BoardSelectors.getAllBoardSummariesByBoardClientIds(getState())
        .find(boardSummary => boardSummary.clientId === boardClientId) || null,
      `There is no boardSummary object with clientId=${boardClientId}`
    )
    const viewType = getState().get('viewType')

    _navigateToBoardSlugAndViewType(boardSummary.slug, viewType, {}, replace)
  },

  enterTheViewType: (viewType: AllViewTypes) => (_dispatch, getState) => {
    const currentBoardSlug = BoardSelectors.getCurrentBoardSlug(getState())
    currentBoardSlug && _navigateToBoardSlugAndViewType(currentBoardSlug, viewType)
  },

  setQueryParams: (queryParams: object) => (_dispatch, getState) => {
    const currentBoardSlug = BoardSelectors.getCurrentBoardSlug(getState())
    const viewType = getState().get('viewType')
    currentBoardSlug && _navigateToBoardSlugAndViewType(currentBoardSlug, viewType, queryParams)
  },

  enterTheBoardAndViewType: (boardClientId, viewType: AllViewTypes) => (_dispatch, getState) => {
    const boardSummary = ErrorHelpers.castToNotNullOrThrow(
      BoardSelectors.getBoardSummaryByClientId(getState(), boardClientId),
      `There is no boardSummary object with clientId=${boardClientId}`
    )
    _navigateToBoardSlugAndViewType(boardSummary.slug, viewType)
  },

  enterTheBoardSlugAndViewType: (boardSlug, viewType: AllViewTypes) => () => {
    _navigateToBoardSlugAndViewType(boardSlug, viewType)
  },

  normalizeTheBoardSlugAndViewType: (boardSlug: string, viewType: string) => (dispatch) => {
    if(boardSlug !== boardSlug.toLowerCase() || viewType !== viewType.toLowerCase()) {
      dispatch(NavigationActions.enterTheBoardSlugAndViewType(boardSlug.toLowerCase(), viewType.toLowerCase() as any))
      return false
    }
    return true
  },

  navigateToUserAdminPage: () => () => {
    _navigate('/admin/users')
  },

  navigateToUserProfile: (userId: number) => (_dispatch, getState) => {
    const userProfile = UserProfileSelectors.getById(getState(), userId)
    if(!userProfile) {
      throw new Error(`No user profile found for userId: ${userId}`)
    }
    _navigateToBoardSlugAndViewType(userProfile.username, AllViewTypes.LIST)
  },

  navigateToCurrentUserProfile: () => (_dispatch, getState) => {
    const currentUserUsername = CurrentUserSelectors.getUsername(getState())
    _navigateToBoardSlugAndViewType(currentUserUsername, AllViewTypes.LIST)
  },

  navigateToAllBoardsPage: () => () => {
    _navigate('/boards')
  },

  navigateToAllUsersPage: () => () => {
    _navigate('/users')
  },
  navigateToLoginPage: () => () => {
    _navigate('/login')
  },
  navigateToSignupPage: () => () => {
    _navigate('/signup')
  },

  navigateToDocument: (document: DocumentRecord) => (_dispatch, getState) => {
    const boardSlug = BoardSelectors.getCurrentBoardSlug(getState())
    const viewType = getState().get('viewType')
    const documentSlug = (document.slugs || [])[0]
    if (boardSlug && documentSlug) {
      _navigateToBoardSlugAndViewType(boardSlug, viewType, { snapshot: documentSlug })
    }
  },

  navigate: _navigate,
}

export {NavigationActions}

export function getBoardUrl(
  boardSlug: string,
  viewType: AllViewTypes | null,
  queryParams={} as object,
) {
  const viewTypeOrDefault = viewType || appConfig.features.defaultViewType
  let boardSlugOrDefault = boardSlug
  if(typeof boardSlug !== 'string') {
    console.warn(`Board slugs must be strings. Received ${JSON.stringify(boardSlug)}`)
    boardSlugOrDefault = appConfig.defaultBoardSlug
  }
  if(ForbiddenBoardSlugs.includes(boardSlug)) {
    console.warn("Cannot navigate to a board with a forbidden name")
    boardSlugOrDefault = appConfig.defaultBoardSlug
  }

  const queryString = Object.entries(queryParams).map(([key, value]) => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  }).join("&")

  const boardSegment = appConfig.defaultBoardSlug === boardSlugOrDefault
    ? ''
    : boardSlug + '/'
  const viewTypeSegment = appConfig.features.defaultViewType === viewTypeOrDefault
    ? ''
    : viewTypeOrDefault
  return `/${boardSegment}${viewTypeSegment}${ queryString.length > 0 ? `?${queryString}` : "" }`
}

function _navigateToBoardSlugAndViewType(
  boardSlug: string,
  viewType: AllViewTypes | null,
  queryParams={} as object,
  replace = false,
) {
  const url = getBoardUrl(boardSlug, viewType, queryParams)
  _navigate(url, replace)

}

function _navigate (path, replace = false) {
  if (replace) {
    history.replace(path)
  } else {
    history.push(path)
  }
}
