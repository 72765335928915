import { Store } from 'redux'

const createBatchSubscribeMiddleware = () => {
  let isInSynchronousDispatch = false
  const listenersToFlush = new Set<() => void>()

  return {
    middleware: () => next => action => {
      const isTopLevel = !isInSynchronousDispatch
      isInSynchronousDispatch = true
      let returnedOrThrownValue
      let errorWasThrown = false
      try {
        returnedOrThrownValue = next(action)
      } catch(err) {
        errorWasThrown = true
        returnedOrThrownValue = err
      }

      if (isTopLevel) {
        for (const listener of listenersToFlush) {
          listener()
        }
        isInSynchronousDispatch = false
        listenersToFlush.clear()
      }

      if(errorWasThrown) {
        throw returnedOrThrownValue
      } else {
        return returnedOrThrownValue
      }
    },
    enhanceStore: (store: Store<any>) => {
      const _oldSubscribe = store.subscribe
      store.subscribe = (listener) => {
        const unsubscribe = _oldSubscribe(() => {
          if (!isInSynchronousDispatch) {
            listener()
          } else {
            listenersToFlush.add(listener)
          }
        })

        return unsubscribe
      }

      return store
    }
  }
}

export default createBatchSubscribeMiddleware