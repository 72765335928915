import * as React from 'react'
import classnames from 'classnames'
import styles from './Avatar.styl'

import ImageWithFallback from 'components/common/ImageWithFallback'
import {UserCircleIcon} from "lucide-react";

interface AvatarProps {
  href?: string | null
  onClick?: (event: React.MouseEvent<any>) => void
  tooltip?: string
  className?: string
}

export default class Avatar extends React.Component<AvatarProps> {
  render() {
    return <div
        className={classnames(styles.avatarContainer, this.props.className)}
        onClick={this.props.onClick}
        title={this.props.tooltip}
      >
      { !this.props.href
          ? this.renderFallback()
          : <ImageWithFallback
              className={styles.avatarIcon}
              imageSrc={this.props.href}
              fallbackChildren={this.renderFallback()}
            />
      }
    </div>
  }

  private renderFallback() {
    return <UserCircleIcon
        width={14}
        height={14}
    />
  }
}
