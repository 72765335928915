import * as React from 'react'

import { Router, Route, Switch, Redirect } from 'react-router'
import history from './history'

import NormalPageWrapper from './NormalPageWrapper'
import BoardViewTypeAndSlugReporter from './BoardViewTypeAndSlugReporter'

import Login from "components/Auth/Login"
import Signup from "components/Auth/Signup"

import { AllViewTypes } from 'config/appConfig'

import ContributorsView from 'components/contributors-view'
import JoinView from 'components/join-view'
import BoardsList from 'components/boardsList'
import UsersList from 'components/usersList'
import BoardOfIdea from 'components/board-of-idea'

import ChangelogContainer from 'components/changelog-view/'

import AdminUsers from 'components/admin/users/'

import DelayedGraphView from './delayed-graph-view'
import DelayedUserProfileOrListView from './delayed-userprofile-or-list-view'

import appConfig from 'config/appConfig'
import AttributeList from "components/AttributeList/AttributeList";

const { defaultBoardSlug } = appConfig
const { defaultViewType } = appConfig.features

const Graph = useDefaultSlug => props => <BoardViewTypeAndSlugReporter
  {...props}
  slug={useDefaultSlug ? defaultBoardSlug : undefined}
  Component={DelayedGraphView}
  viewType={AllViewTypes.GRAPH}
  passDataFetchPromise
  componentTriggersFetches
/>

const List = useDefaultSlug => props => <BoardViewTypeAndSlugReporter
  {...props}
  slug={useDefaultSlug ? defaultBoardSlug : undefined}
  Component={DelayedUserProfileOrListView}
  viewType={AllViewTypes.LIST}
  componentTriggersFetches
/>

const Routes = ({ children }: { children: React.ReactNode }) => (
  <Router history={history}>
    <>
    <Switch>
      <Route path="/login">
        {props => <NormalPageWrapper {...props} Component={Login} />}
      </Route>
      <Route path="/signup">
        { props => <NormalPageWrapper {...props} Component={Signup} /> }
      </Route>
      <Route path="/admin/users">
        { props => <NormalPageWrapper {...props} Component={AdminUsers} /> }
      </Route>
      <Route path="/boards">
        { props => <NormalPageWrapper {...props} Component={BoardsList} /> }
      </Route>
      <Route path="/users">
        { props => <NormalPageWrapper {...props} Component={UsersList} /> }
      </Route>

      <Route path="/user/:slug">
        {({ match: { params: { slug } } }) => <Redirect to={`/${slug}/${defaultViewType !== AllViewTypes.LIST ? "list/" : ""}`} /> }
      </Route>

      { defaultViewType === AllViewTypes.LIST
          && <Route exact path="/:sortField(new|top|status)?">
              { List(true) }
            </Route>
      }
      { defaultViewType === AllViewTypes.GRAPH
          && <Route exact path="/:transientDocumentType(all|empty)?">
                { Graph(true) }
              </Route>
      }
      <Route exact path="/graph/:transientDocumentType(all|empty)?">
        { Graph(true) }
      </Route>
      <Route exact path="/graph/:snapshot">
        { Graph(true) }
      </Route>
      <Route exact path={`/list/:sortField(new|top|status)?`}>
        { List(true) }
      </Route>
      <Route path="/contributors">
        { props => <BoardViewTypeAndSlugReporter {...props} Component={ContributorsView} viewType={AllViewTypes.CONTRIBUTORS} slug={defaultBoardSlug} /> }
      </Route>
      <Route path="/join">
        { props => <BoardViewTypeAndSlugReporter {...props} Component={JoinView} viewType={AllViewTypes.JOIN} slug={defaultBoardSlug} /> }
      </Route>
      <Route path="/changelog">
        { props => <BoardViewTypeAndSlugReporter {...props} Component={ChangelogContainer} viewType={AllViewTypes.CHANGELOG} slug={defaultBoardSlug} componentTriggersFetches /> }
      </Route>
      {/* { defaultViewType === AllViewTypes.GRAPH
          && <Route exact path="/:snapshot">
                { Graph(true) }
              </Route>
      } */}

      <Route path="/:viewType/board/of/:ideaClientId">
        { props => <BoardOfIdea
            viewTypeSlug={props.match.params.viewType}
            ideaClientId={props.match.params.ideaClientId}
          /> }
      </Route>

      {/*TODO: Make sure the registering of the :slug in the Redux store is not the responsibility of the components */}
      <Route exact path="/:boardClientId/attributes">
        {
          props => <AttributeList boardClientId={props.match.params.boardClientId}/>
        }
      </Route>
      { defaultViewType === AllViewTypes.LIST
          && <Route exact path="/:slug/:sortField(new|top|status)?">
                { List(false) }
              </Route>
      }
      { defaultViewType === AllViewTypes.GRAPH
          && <Route exact path="/:slug/:transientDocumentType(all|empty)?">
                { Graph(false) }
              </Route>
      }
      <Route exact path="/:slug/graph/:transientDocumentType(all|empty)?">
        { Graph(false) }
      </Route>
      <Route exact path="/:slug/graph/:snapshot">
        { Graph(false) }
      </Route>
      <Route exact path="/:slug/list/:sortField(new|top|status)?">
        { List(false) }
      </Route>
      <Route path="/:slug/contributors">
        { props => <BoardViewTypeAndSlugReporter {...props} Component={ContributorsView} viewType={AllViewTypes.CONTRIBUTORS} /> }
      </Route>
      <Route path="/:slug/join">
        { props => <BoardViewTypeAndSlugReporter {...props} Component={JoinView} viewType={AllViewTypes.JOIN} /> }
      </Route>
      <Route path="/:slug/changelog">
        { props => <BoardViewTypeAndSlugReporter {...props} Component={ChangelogContainer} viewType={AllViewTypes.CHANGELOG} componentTriggersFetches /> }
      </Route>
      { defaultViewType === AllViewTypes.GRAPH
          && <Route exact path="/:slug/:snapshot">
                { Graph(false) }
              </Route>
      }

      <Redirect from="*" to="/" />
    </Switch>
    {children}
    </>
  </Router>
)

export default Routes
