import * as React from 'react'
import classnames from 'classnames'

import styles from './Header.styl'

import Icon from 'components/common/Icon'
import AuthModal from 'components/Auth/Modal'

import { BoardSummary } from 'shared/models/BoardSummary'
import { UserProfileRecord } from 'shared/models/UserProfile'
import appConfig, { AllViewTypes } from 'config/appConfig'

import { tabsInOrder } from './ViewTypeTabs'
import { BoundActionGroups } from 'helpers/Component'

import { AuthActions } from 'auth/actions'
import { NavigationActions, getBoardUrl } from 'navigation/actions'

import { Link } from "react-router-dom"
import { PanelLeftIcon, MenuIcon, UserCircle2 } from "lucide-react"

export interface HeaderDataProps {
  canAdminUsers: boolean
  activeViewType: AllViewTypes
  pathFromRootBoard: BoardSummary[]
  currentBoardSlug: string
  search: React.ReactElement<any>
  hideNavigation?: boolean
  hideAuth?: boolean

  currentUserProfile: UserProfileRecord | null
}

type HeaderActionProps = BoundActionGroups<{
  AuthActions: typeof AuthActions,
  NavigationActions: typeof NavigationActions,
}>

type HeaderProps = HeaderDataProps & HeaderActionProps

interface HeaderState {
  menuOpen: boolean
}

export default class Header extends React.Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    menuOpen: false,
  }

  private _openMenu = () => this.setState({ menuOpen: true })
  private _closeMenu = () => this.setState({ menuOpen: false })
  private _toggleMenu = () => this.setState(state => ({ menuOpen: !state.menuOpen }))

  private _openAccountModal = () => AuthModal.openModal()

  render() {
    return (
      <div className={styles.header}>
        <div className={classnames(styles.desktopHeader)}>
          <div className={styles.panelButton}>
            <PanelLeftIcon size={16}/>
          </div>
          {this.renderBoardPath()}
          {this.renderSearchBar()}
          {this.props.currentBoardSlug && !this.props.hideNavigation && this.renderViewTypeBar()}
          {!this.props.hideNavigation && this.renderMenu()}
        </div>
      </div>
    )
  }

  private renderLogo() {
    const hasBoardPathLinks = this.props.pathFromRootBoard && this.props.pathFromRootBoard.length > 0

    return <>
      <a onClick={this.props.NavigationActions.enterTheRootBoard}>Ideaflow</a>
      { hasBoardPathLinks && <Icon
        fontAwesomeIcon='angle-right'
        className={styles.boardPathIcon}
        useDefaultCursor={true}
      /> }
    </>
  }

  private renderBoardPath() {
    const hasBoardPathLinks = this.props.pathFromRootBoard && this.props.pathFromRootBoard.length > 0

    return <div className={styles.boardPath}>
      <a onClick={this.props.NavigationActions.enterTheRootBoard}>Ideaflow</a>
      {hasBoardPathLinks && (
        <>
          <Icon
            fontAwesomeIcon='angle-right'
            className={styles.boardPathIcon}
            useDefaultCursor={true}
          />
          {this.renderBoardLinks(true)}
          </>
      )}
    </div>
  }

  private renderBoardLinks(isShortened) {
    const numLinksWhenShortened = this.props.search ? 2 : 3
    const maxChars = this.props.search ? 35 : 19
    const numLinks = this.props.pathFromRootBoard.length

    const includedLinks = isShortened && numLinks > numLinksWhenShortened ?
      this.props.pathFromRootBoard.slice(numLinks - numLinksWhenShortened)
      : this.props.pathFromRootBoard

    //Todo: At the moment, using a hacky fix. During hover,
    // shortBoardPath in css should be set display:none, I set it
    // to display:block, so it's always visible.
    // Commented out line 89 in this file where
    // we render larger link. And updated maxChars as 35
    // above.

    return <div className={isShortened ? styles.shortBoardPath : styles.expandedBoardPath}>
      { includedLinks.length < numLinks &&
        <span className={styles.boardLink}> ... </span>
      }

      {includedLinks.map((boardSummary, i) => {
        const { clientId, title } = boardSummary
        return <span key={clientId} className={styles.boardLink}>
          { (i > 0 || includedLinks.length < numLinks) && <Icon
            fontAwesomeIcon='angle-right'
            className={styles.boardPathIcon}
            useDefaultCursor={true}
          /> }
          <a onClick={() => this.props.NavigationActions.enterTheBoard(clientId)}>
            { isShortened && title && title.length > maxChars ? title.substring(0, maxChars) + '...' : title }
          </a>
        </span>
      })}
    </div>
  }

  private renderSearchBar() {
    if (!this.props.search) return null
    return (
      <div className={styles.searchBar}>
        {this.props.search && this.props.search}
      </div>
    )
  }

  private renderMenu() {
    return (
      <div className={styles.navLinkWrapper} onMouseLeave={this._closeMenu}>
        <div className={styles.menuButtonWrapper}>
          <div 
            className={styles.loginButton}
            onClick={() => {
              if (this.props.currentUserProfile && !this.props.currentUserProfile.isAnonymousUser()) {
                this.props.NavigationActions.navigateToCurrentUserProfile()
              } else {
                this._openAccountModal()
              }
            }}
          >
            <UserCircle2 size={24}/>
          </div>
          <div
            className={styles.menuButton}
            onMouseEnter={this._openMenu}
            onTouchStart={this._toggleMenu}
          >
            <MenuIcon width={18} height={18}/>
          </div>
        </div>
        {this.state.menuOpen &&
          <div className={classnames(styles.dropdown, styles.menu)}>
            { appConfig.features.enableBoards
                ? <div className={styles.navLink} onClick={this.props.NavigationActions.navigateToAllBoardsPage}>
                    View All Boards
                  </div>
                : null
            }
             <div className={styles.navLink} onClick={this.props.NavigationActions.navigateToAllUsersPage}>
              View All Users
            </div>
            {this.props.canAdminUsers &&
              <div className={styles.navLink} onClick={this.props.NavigationActions.navigateToUserAdminPage}>
                Manage Users
              </div>
            }
            {
              appConfig.features.views.includes(AllViewTypes.CHANGELOG) && this.props.currentBoardSlug
                ? <Link className={styles.navLink} to={getBoardUrl(this.props.currentBoardSlug, AllViewTypes.CHANGELOG)}>
                    History
                  </Link>
                : null
            }

            {this.props.currentUserProfile && !this.props.currentUserProfile.isAnonymousUser() &&
              <div className={styles.navLink} onClick={this.props.AuthActions.logout}>
                Logout
              </div>
            }

          </div>
        }
      </div>
    )
  }

  private renderViewTypeBar() {
    const currentTab = tabsInOrder.find(tab => this.props.activeViewType === tab.viewType)

    return (
      <>
      {/* When we position the view types absolutely, we add a spacer which will push
       the login and menu buttons to the right */}
      {!this.props.search && <div style={{ flex: 1 }} />}
      <div style={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
        <div style={{ flexBasis: 90 }} />
        <div 
          className={classnames(styles.viewTypeWrapper)}
          // Center absolutely when in the list view 
          style={!this.props.search ? {
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)"
          } : {}}
        >
          {tabsInOrder.map(tab =>
            <Link
              key={tab.label}
              className={classnames(styles.viewTypeTab, styles.navLink, currentTab === tab && styles.current) }
              to={getBoardUrl(this.props.currentBoardSlug, tab.viewType)}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </Link>
          )}
          </div>
      </div>
      </>
    )
  }
}

