import apiCall from 'helpers/apiCall'

import { pushAllObjects } from 'domain/GenericActions'

import {CurrentUserModuleState} from './_moduleState'

import UserProfile from 'shared/models/UserProfile'

const fetchCurrentUserLikes = () => async (dispatch) => {
  const ideaLikesPromise = apiCall({ path: `/ideaLikes/getAllForCurrentUser` })
  const commentLikesPromise = apiCall({ path: `/commentLikes/getAllForCurrentUser` })

  dispatch(pushAllObjects(await ideaLikesPromise))
  dispatch(pushAllObjects(await commentLikesPromise))
}

// current user uses different endpoint,
// as it's profile can have different set of data than all users
const loadCurrentUser = () => async (dispatch) => {
  const { userProfile } = await apiCall({
    path: '/userProfiles/currentUser',
    method: 'GET',
  })

  dispatch(CurrentUserModuleState.setPart('userProfile', UserProfile(userProfile)))
}

const CurrentUserActions = {
  fetchCurrentUserLikes,
  loadCurrentUser,
}

export {CurrentUserActions}
