import * as React from 'react'

import UserTable from 'components/common/UserTable/UserTable'
import { ProfileBoardMembershipSelectors } from 'domain/ProfileBoardMembership/selectors'
import { GenericProfileRecord } from 'shared/models/ProfileBoardMembership'
import ProfileBoardMembershipActions from 'domain/ProfileBoardMembership/actions'
import * as BoardSelectors from 'domain/Board/selectors'
import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'
import LayoutShell from '../layout/LayoutShell'

import styles from './ContributorsView.styl'

interface StateProps {
  currentBoardClientId: string
  peopleInCurrentBoard: GenericProfileRecord[]
}

const actionGroups = { ProfileBoardMembershipActions }

class ContributorsView extends React.Component<StateProps & BoundActionGroups<typeof actionGroups>> {
  constructor(props) {
    super(props)
  }

  async componentWillMount() {
    if (this.props.currentBoardClientId) {
      await this.props.ProfileBoardMembershipActions.getPeopleForBoard(this.props.currentBoardClientId)
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.currentBoardClientId && nextProps.currentBoardClientId !== this.props.currentBoardClientId) {
      await this.props.ProfileBoardMembershipActions.getPeopleForBoard(nextProps.currentBoardClientId)
    }
  }

  render() {
    return (
      <LayoutShell className={styles.contributorsViewWrapper}>
        <div className={styles.contributorsView}>
        <UserTable
          genericProfiles={this.props.peopleInCurrentBoard}
          currentBoardClientId={this.props.currentBoardClientId}
        />
        </div>
      </LayoutShell>
    )
  }
}

export default ComponentHelpers.createConnectedComponent<{}, StateProps, typeof actionGroups> ({
  actionGroups,
  mapStateToProps: (state): StateProps => {
    return {
      currentBoardClientId: BoardSelectors.getCurrentBoardClientId(state),
      peopleInCurrentBoard: ProfileBoardMembershipSelectors.getAll(state),
    }
  },
  component: ContributorsView
})


