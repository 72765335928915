import * as React from "react"

import Select from "react-select"
import { } from "reactstrap"

import { Button, ButtonTypes } from "components/common/Button"
import Icon from "components/common/Icon"

import PermissionEntryEditable, { getDefaultPermissionEntry } from "./PermissionEntryEditable"

import { UserProfileRecord } from 'shared/models/UserProfile'
import {
  ActorType, StandardPrivacyActor, OneResourceType, PermissionResourceType, Permission,
} from "shared/models/Permission"

import { ComponentHelpers, BoundActionGroups } from "helpers/Component"

import { UserProfileActions } from 'domain/UserProfile/actions'
import { UserProfileSelectors } from 'domain/UserProfile/selectors'
import { CustomStyles, EditedPermission } from "./PermissionsUI"

import styles from "./PermissionsEditor.styl"
import UserProfile from "shared/models/UserProfile";
import { IdeaPermissionAction } from "shared/models/Permission/Idea";
import { BoardPermissionAction } from "shared/models/Permission/Board";

const PermissionEditorContext = React.createContext<({
  allUsers: ReturnType<typeof UserProfileSelectors["getAll"]>
} & BoundActionGroups<typeof actionGroups>) | null>(null)

const actionGroups = {
  UserProfileActions
}

const Provider = ComponentHelpers.createConnectedComponent<{}, {}, typeof actionGroups>({
  actionGroups,
  mapStateToProps: (state, outerProps) => ({
    ...outerProps,
    allUsers: UserProfileSelectors.getAll(state)
  }),
  component: class Provider extends React.Component<React.ContextType<typeof PermissionEditorContext>> {
    render() {
      const { children, ...data } = this.props
      return <PermissionEditorContext.Provider value={data}>
        {children}
      </PermissionEditorContext.Provider>
    }
  }
})

export default function PermissionEditorWrapper<ResourceType extends PermissionResourceType> (props: PermissionsEditorProps<ResourceType> & { innerRef }) {
  const { innerRef, ...restProps } = props
  return <Provider>
    <PermissionsEditor<ResourceType> ref={innerRef} {...restProps} />
  </Provider>
}

export type PermissionsEditorInstance<ResourceType extends PermissionResourceType> = PermissionsEditor<ResourceType>

type PermissionsEditorProps<ResourceType extends PermissionResourceType> = {
  resourceType: OneResourceType<ResourceType>
  permissions: EditedPermission<ResourceType>[]
  owners: StandardPrivacyActor[]
  permissionsExplicitlySet: boolean
  customStyles?: CustomStyles
  canReplacePermissionsAndOwners: boolean
}

interface PermissionsEditorState<ResourceType extends PermissionResourceType> {
  newPermissions: EditedPermission<ResourceType>[]
  newOwners: StandardPrivacyActor[]
  newPermissionsExplicitlySet: boolean
}

/**
 * TODO: [#osdiabPrivacy] add a toggle for changing discoverability and commentability
 * TODO: [#osdiabPrivacy] add a way to relinquish ownership of an idea
 */
class PermissionsEditor<ResourceType extends PermissionResourceType> extends React.Component<
  PermissionsEditorProps<ResourceType>,
  PermissionsEditorState<ResourceType>
> {
  static contextType = PermissionEditorContext
  context!: NonNullable<React.ContextType<typeof PermissionEditorContext>>

  constructor(props: PermissionsEditorProps<ResourceType>) {
    super(props)
    this.state = {
      newPermissions: props.permissions,
      newOwners: props.owners,
      newPermissionsExplicitlySet: !!props.permissionsExplicitlySet,
    }
  }

  public getEditedPermissions() {
    return this.state.newPermissions
  }

  public getEditedOwners() {
    return this.state.newOwners
  }

  componentDidMount() {
    this.context.UserProfileActions.refetchAll()
  }

  private getIsDiscoverable() {
    // a single discoverable one is enough to call it discoverable
    return !!this.props.permissions.find(p => p.permission.actions[IdeaPermissionAction.DISCOVER])
  }

  private getIsCommentable() {
    // a single commentable one is enough to call it commentable
    return !!this.props.permissions.find(p => p.permission.actions[IdeaPermissionAction.ADD_COMMENT])
  }

  private handleChangePermissionValue = (
    index: number,
    value: Permission<ResourceType>['actions']
  ) => {
    if (!this.props.canReplacePermissionsAndOwners) {
      console.error("Shouldn't be able to change permission value, aborting")
      return;
    }
    const newPermissions = [...this.state.newPermissions];
    newPermissions[index] = {
      ...newPermissions[index],
      permission: {
        ...newPermissions[index].permission,
        actions: value
      }
    };

    this.setState({ newPermissions })
  }

  private handleAddPermissionRow = () => {
    // [#osdiabPrivacy] is it sensible to default to can_update?
    // [#osdiabPrivacy] TODO: allow for non-user additions
    if (!this.props.canReplacePermissionsAndOwners) {
      console.error("Shouldn't be able to change permission value, aborting")
      return;
    }

    const newPermissions = [
      ...this.state.newPermissions,
      {
        permission: {
          actor: null,
          actions: getDefaultPermissionEntry(
            this.props.resourceType,
            this.getBasePermission()
          ),
        },
        preexisting: false
      } as EditedPermission<ResourceType>
    ];

    this.setState({ newPermissions });
  }

  private getBasePermission(): Permission<ResourceType>['actions'] {
    if (this.props.resourceType === PermissionResourceType.ideas) {
      return {
        [IdeaPermissionAction.ADD_COMMENT]: this.getIsCommentable(),
        [IdeaPermissionAction.DISCOVER]: this.getIsDiscoverable(),
      } as any as Permission<ResourceType>['actions']
    } else {
      return {
        [BoardPermissionAction.DISCOVER]: this.getIsDiscoverable(),
      } as any as Permission<ResourceType>['actions']
    }
  }

  private handleRemovePermission = (idx: number) => () => {
    if (!this.props.canReplacePermissionsAndOwners) {
      console.error("Shouldn't be able to change permission value, aborting")
      return;
    }
    const newPermissions = [
      ...this.state.newPermissions.slice(0, idx),
      ...this.state.newPermissions.slice(idx + 1)
    ];

    const { actor } = this.state.newPermissions[idx].permission;
    const existingOwnerIdx = actor.type === ActorType.PUBLIC_ACTOR ? -1 : this.state.newOwners.findIndex(
      ({ type, identifier }) => type === actor.type && identifier === actor.identifier
    )
    const newOwners = existingOwnerIdx === -1 ? this.state.newOwners : [
      ...this.state.newOwners.slice(0, existingOwnerIdx),
      ...this.state.newOwners.slice(existingOwnerIdx + 1)
    ]

    this.setState({ newPermissions, newOwners })
  }
  /**
   * @param idx Index of the new permission in this.state.newPermissions
   */
  private handleSelectNewUser = (idx: number) => (userId: number) => {
    if (!this.props.canReplacePermissionsAndOwners) {
      console.error("Shouldn't be able to change permission value, aborting")
      return;
    }

    const prevPermissions = this.state.newPermissions;

    const modifiedPermission = {
      ...this.state.newPermissions[idx],
      permission: {
        ...prevPermissions[idx].permission,
        actor: { type: ActorType.USER as ActorType.USER, identifier: userId },
      },
    }
    const newPermissions = [
      ...prevPermissions.slice(0, idx),
      modifiedPermission,
      ...prevPermissions.slice(idx + 1)
    ];

    this.setState({ newPermissions });
  }

  private updateOwners = (newOwners: StandardPrivacyActor[]) => {
    if (!this.props.canReplacePermissionsAndOwners) {
      throw new Error("Shouldn't be able to change owners, aborting")
    }

    this.setState({ newOwners });
  }

  public render() {
    const { canReplacePermissionsAndOwners, customStyles = {} } = this.props

    return <>
      <ul className={styles.permissionsList}>
        {this.state.newPermissions.map((editedPermission, idx) =>
          <li key={idx} className={styles.permissionsEntry}>
            <PermissionEntryEditable
              resourceType={this.props.resourceType}
              basePermission={this.getBasePermission()}
              editedPermission={editedPermission}
              canReplacePermissions={canReplacePermissionsAndOwners}
              customStyles={customStyles}
              canRemovePermission={this.state.newPermissions.length > 1}

              handleChangePermissionValue={(value) => this.handleChangePermissionValue(idx, value)}
              handleRemovePermission={this.handleRemovePermission(idx)}
              handleSelectNewUser={this.handleSelectNewUser(idx)}
            />
          </li>
        )}
        {this.props.canReplacePermissionsAndOwners && (
          <div>
            {/* TODO: [#osdiabPrivacy] support groups here */}
            <Button
              type={ButtonTypes.PRIMARY}
              onClick={this.handleAddPermissionRow}
            >
              <Icon
                fontAwesomeIcon="plus"
                className={styles.addPermissionIcon}
              />
              Add permissions
            </Button>
          </div>
        )}
        <div>
          <hr />
          <h5>Owners:</h5>
          <Select<UserProfileRecord>
            value={this.state.newOwners.map(a => this.context.allUsers.get(a.identifier))}
            onChange={(selectedUsers: UserProfileRecord[]) => {
              if (selectedUsers.length === 0) return
              const actors = selectedUsers.map(user => ({ type: ActorType.USER as ActorType.USER, identifier: user.userId }))
              this.updateOwners(actors)
            }}
            options={this.context.allUsers.valueSeq().toArray()}
            formatOptionLabel={user => UserProfile.getDisplayName(user)}
            getOptionLabel={user => UserProfile.getDisplayName(user)}
            getOptionValue={user => UserProfile.getDisplayName(user)}
            isOptionSelected={(option, value) => value.includes(option)}
            isDisabled={!canReplacePermissionsAndOwners}
            isMulti
            isSearchable
            isClearable={false}
            menuPortalTarget={document.body}
            styles={{
              container: (base) => ({ ...base, width: "100%" }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </div>
        <div>
          <hr />
          <h5>Permission inheritance (advanced):</h5>
          <br />
          <label>
            <input
              type="checkbox"
              checked={this.state.newPermissionsExplicitlySet}
              onChange={() =>
                this.setState(state => ({ newPermissionsExplicitlySet: !state.newPermissionsExplicitlySet }))
              }
              disabled
            />
            &nbsp;Permissions should be kept when parent permissions change
          </label>
        </div>
      </ul>
    </>
  }
}