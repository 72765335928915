import * as React from 'react'

import styles from './Auth.styl'

import LayoutShell from "../layout/LayoutShell"

export default class AuthWrapper extends React.Component<any, any> {
  render() {
    return (
      <LayoutShell hideAuth>
        <div className={styles.outer}>
          <div className={styles.inner}>
            <h1>Ideaflow</h1>
            { this.props.children }
          </div>
        </div>
      </LayoutShell>
    )
  }
}
