import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

import { generateClientId } from 'shared/helpers/Id'

const IdeaInstanceDefaultValues = {
  id: NotNullOrUndefined,
  ideaClientId: NotNullOrUndefined,

  // FIXME: empty object is not a valid value for simulationPosition - it should be replaced with `null`
  // right now it is a dirty hack to make `ForceLayout.ts` work
  // fix this by making `ForceLayout` create new ideaInstances instead of mutating `simulationPosition`, and revert default value back to null/undefined
  simulationPosition: {} as any,
  colorId: null,
}

type IdeaInstanceRawType = {
  id: string
  ideaClientId: string

  simulationPosition: { x: number, y: number }
  colorId: number | null
}

type IdeaInstanceSourceObjectType = Partial<IdeaInstanceRawType>

class IdeaInstanceRecord extends CreateRecordClass<IdeaInstanceRawType>(IdeaInstanceDefaultValues, "IdeaInstance") {
  // TODO: this interface is ambiguous with `!!ideaInstance.simulationPosition`,
  // also checks for coord values will become useless after fixing default value above
  // to fix that, we need to hide public property `simulationPosition` and create getter and immutable setter (that would check new value for validity)
  isPlaced() {
    const simulationPosition = this.get('simulationPosition')
    return (!!simulationPosition) && (simulationPosition.x !== undefined) && (simulationPosition.y !== undefined)
  }

  getPosition() {
    return this.isPlaced() ? this.simulationPosition : { x: 0, y: 0 }
  }
}

type IdeaInstanceRecordT = IdeaInstanceRecord
export { IdeaInstanceRecordT as IdeaInstanceRecord }

const IdeaInstanceFactory = {
  create (rawData: IdeaInstanceSourceObjectType): IdeaInstanceRecord {
    return new IdeaInstanceRecord({
      ...rawData,
      id: generateClientId()
    })
  },

  copy (original: IdeaInstanceRecord): IdeaInstanceRecord {
    return new IdeaInstanceRecord(original)
  },
}

export default IdeaInstanceFactory
