//FRONTEND_ENVIRONMENT_CONFIG contains environment-specific settings exported to frontend via webpack
declare const FRONTEND_APP_ENVIRONMENT_CONFIG: any

const ENVIRONMENT_CONFIG = FRONTEND_APP_ENVIRONMENT_CONFIG

export enum AllViewTypes {
  GRAPH = "graph",
  CONTRIBUTORS = "contributors",
  LIST = "list",
  CHANGELOG = "changelog",
  THOUGHSTREAM = "thought",
  JOIN = "join"
}

// it's still convenient to keep this config here directly, not in `environmentConfig`,
// as it can be changed without the need to restart webpack that is required when changing environmentConfig.js
// as the latter one is exported via Webpack plugin
const appConfig = {
  apiPath: '/api/v1',
  appName: 'IdeaFlow',
  log: {
    actions: false,
    newStates: false,
    shouldComponentUpdateReasoning: false,
    canvasSpriteRerenders: true,
    unknownConnectionWarnings: false,
    unknownIdeaWarnings: false,
  },
  features: {
    enableUserRegistration: ENVIRONMENT_CONFIG.enableUserRegistration,
    enableFacebookLogin: ENVIRONMENT_CONFIG.enableFacebookLogin,
    views: ENVIRONMENT_CONFIG.availableViews,
    useWeightedPathSearch: ENVIRONMENT_CONFIG.useWeightedPathSearch,
    allowAnonymousUsers: ENVIRONMENT_CONFIG.allowAnonymousUsers,
    enableIdeaStatuses: ENVIRONMENT_CONFIG.enableIdeaStatuses,
    enableLogRocket: ENVIRONMENT_CONFIG.enableLogRocket,
    enableIdeaComments: ENVIRONMENT_CONFIG.enableIdeaComments,
    enableIdeaCommentLikes: ENVIRONMENT_CONFIG.enableIdeaCommentLikes,
    enableBoards: ENVIRONMENT_CONFIG.enableBoards,
    enableMultiedges: ENVIRONMENT_CONFIG.enableMultiedges,
    enableSelfedges: ENVIRONMENT_CONFIG.enableSelfedges,
    enableThoughtstreamView: ENVIRONMENT_CONFIG.enableThoughtstreamView,
    useThoughtstreamViewByDefault: ENVIRONMENT_CONFIG.useThoughtstreamViewByDefault,
    enablePathEquivalences: ENVIRONMENT_CONFIG.enablePathEquivalences,
    defaultTransientDocumentType: ENVIRONMENT_CONFIG.defaultTransientDocumentType,
    defaultViewType: ENVIRONMENT_CONFIG.defaultViewType,
    enableSemanticSearch: ENVIRONMENT_CONFIG.enableSemanticSearch,

    // TODO: Fix this feature before it's reenabled
    autosaveBeforeUnload: false,

    superUserCanResetPasswordsToTemporary: ENVIRONMENT_CONFIG.superUserCanResetPasswordsToTemporary as boolean,
  },

  topBannerMessageKeys: ENVIRONMENT_CONFIG.topBannerMessageKeys.split(",")
    .filter(topBannerMessageKey => topBannerMessageKey !== ""),
  defaultBoardSlug: "root"
}

export default appConfig
