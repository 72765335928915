import { Set, Map } from 'immutable'

import { AppStateRecord } from 'appRoot/state'
import { UserProfileRecord } from 'shared/models/UserProfile'

import * as EvImm from 'evolving-immutable'

const getUsersByUsername: (state: AppStateRecord) => Map<string, UserProfileRecord> = EvImm.startChain()
  .memoizeForValue()
  .addStep(state => UserProfileSelectors.getAll(state))
  .memoizeForValue()
  .addGroupStep((userProfile: UserProfileRecord) => userProfile.get("username"))
  .addMapStep((userProfiles: Set<UserProfileRecord>) => userProfiles.first())
  .endChain()

const UserProfileSelectors = {
  getAll(state: AppStateRecord) {
    return state.get('userProfiles')
  },

  getById(state: AppStateRecord, userId: number | null): UserProfileRecord | null {
    return userId !== null
      ? UserProfileSelectors.getAll(state).get(userId, null)
      : null
  },

  getByUsername(state: AppStateRecord, username: string): UserProfileRecord | null {
    const usersByUsername = getUsersByUsername(state)
    return usersByUsername.get(username, null)
  }
}

export { UserProfileSelectors }
