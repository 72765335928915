// Those actions should not be used from components directly - they are too low level

// TODO: split specific actions to corresponding modules
import { SET_LOADING, UNSET_LOADING } from './constants'

export function setLoading() {
  return { type: SET_LOADING }
}

export function unsetLoading() {
  return { type: UNSET_LOADING }
}
