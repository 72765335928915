import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'
import { generateClientId } from 'shared/helpers/Id'

const ConnectionDefaultValues = {
  id: NotNullOrUndefined,
  clientId: NotNullOrUndefined,
  sourceIdeaClientId: NotNullOrUndefined,
  targetIdeaClientId: NotNullOrUndefined,
  labelText: '',
  colorId: null,
  isDeleted: false,
}

type ConnectionRawType = {
  id: number
  clientId: string
  sourceIdeaClientId: string
  targetIdeaClientId: string
  labelText: string
  colorId: number | null
  isDeleted: boolean
}

export type ConnectionSourceObjectType = Partial<ConnectionRawType>

class ConnectionRecord extends CreateRecordClass<ConnectionRawType>(ConnectionDefaultValues, "Connection") {
  getOtherEndOfConnection (ideaClientId) {
    if (this.get('sourceIdeaClientId') === ideaClientId)
      return this.get('targetIdeaClientId');

    if (this.get('targetIdeaClientId') === ideaClientId)
      return this.get('sourceIdeaClientId');

    throw new Error('Suppliend ideaClientId is not either endpoint of the connection')
  }

  getClientId() {
    return this.get('clientId')
  }
}

type ConnectionRecordT = ConnectionRecord
export { ConnectionRecordT as ConnectionRecord }

const Connection = Object.assign(
  (stub : ConnectionSourceObjectType | ConnectionRecord = {}): ConnectionRecord => {
    if (!Connection.isConnection(stub)) {
      stub = stub as ConnectionSourceObjectType
      if(!stub.clientId) {
        stub = Object.assign({}, stub, { clientId: generateClientId() })
      }
    }
    return new ConnectionRecord(stub)
  },
  {
    isConnection: (obj): obj is ConnectionRecord => (obj instanceof ConnectionRecord),
  }
)

export default Connection
