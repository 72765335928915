import escapeStringRegexp from 'escape-string-regexp'

import { Attribute } from 'shared/models/Attribute'
import { AttributeValues } from 'shared/models/AttributeValue'

import { ErrorHelpers } from 'shared/helpers/Error'

type Criterion = string | number | {
  $lessThan?: number,
  $moreThan?: number,
}
export type Query = { [key: string]: Criterion | Criterion[] }

export function getExactMatch(match: string): string | null {
  const exactMatch = match.match(/^"([^]*)"$/)
  if (!exactMatch) return null
  return exactMatch[1]
}

export const forgivingRegexpFor = (match: string): RegExp | string => {
  try {
    const exactMatch = getExactMatch(match)
    if (exactMatch !== null) {
      return new RegExp(`^${escapeStringRegexp(exactMatch)}$`)
    } else {
      return new RegExp(escapeStringRegexp(match), 'i')
    }
  } catch(err) {
    console.warn('creating regexp failed', err)
    return match
  }
}

export const isEmpty = (query: Query) => {
  let isEmpty = true
  executeForEveryCriterion(query, (key, criterion) => {
    if(!isCriterionEmpty(key, criterion)) {
      isEmpty = false
    }
  })
  return isEmpty
}

const isCriterionEmpty = (key, criterion: Criterion) => {
  if (typeof criterion === 'object') {
    if (criterion.$lessThan !== undefined && !isNaN(criterion.$lessThan)) {
      return false
    }

    if (criterion.$moreThan !== undefined && !isNaN(criterion.$moreThan)) {
      return false
    }
    return true
  }
  else if (key == '$pathFromLabel' || key == '$pathToLabel') {
    return true;
  }
  else if (key == 'colorId') {
    return criterion === undefined || isNaN(criterion as number)
  }
  else {
    return (criterion + "").length === 0
  }
}

const matchCriterion = (
  ideaTitle: string,
  ideaColorId: number,
  ideaAttributes: AttributeValues | undefined,
  key: string, value: Criterion,
  allAttributes: Attribute[]
) => {
  let fitting = true

  const possibleAttribute = allAttributes.find((attribute) => attribute.name === key)

  const possibleAttributeValue = (possibleAttribute && ideaAttributes)
    ? ideaAttributes[possibleAttribute.clientId]
    : undefined

  if (value !== null && typeof value === 'object') {
    if (value.$lessThan !== undefined) {
      ErrorHelpers.assert(!!possibleAttribute, "No attribute with specified name found", { name: key })
      fitting = possibleAttributeValue !== undefined && possibleAttributeValue !== null &&
        fitting && possibleAttributeValue < value.$lessThan
    }
    if (value.$moreThan !== undefined) {
      ErrorHelpers.assert(!!possibleAttribute, "No attribute with specified name found", { name: key })
      fitting = possibleAttributeValue !== undefined && possibleAttributeValue !== null &&
        fitting && possibleAttributeValue > value.$moreThan
    }
  }
  else {
    if (key == '$pathFromLabel' || key == '$pathToLabel') {
      // Do nothing
    }
    else if (key == 'title') {
      fitting = !!(ideaTitle.match(forgivingRegexpFor(value as string)))
    }
    else if (key == 'colorId') {
      fitting = (ideaColorId === value)
    }
    else {
      ErrorHelpers.assert(!!possibleAttribute, "No attribute with specified name found", { name: key })
      fitting = (!!possibleAttributeValue) && !!(possibleAttributeValue.toString().match(forgivingRegexpFor(value as string)))
    }
  }
  return fitting
}

export function executeForEveryCriterion(query: Query, fn: (key: string, criterion: Criterion) => void) {
  Object.entries(query).forEach(([key, value]) => {
    const values = Array.isArray(value)
      ? value : [value]
    values.forEach(value => {
      fn(key, value)
    })
  })
}

export function ideaFitsQuery(query: Query, ideaTitle: string, ideaColorId: number, ideaAttributes: AttributeValues, allAttributes: Attribute[]) {
  let fitting = true
  executeForEveryCriterion(query, (key, value) =>
    fitting = fitting && matchCriterion(ideaTitle, ideaColorId, ideaAttributes, key, value, allAttributes)
  )
  return fitting
}

export const getAttributeClientIdsInvolvedInAQuery = ({ query, allAttributes }: { query: any, allAttributes: Attribute[] }) => {
  const attributeClientIds: string[] = []
  executeForEveryCriterion(query, key => {
    if(key !== 'title' && key !== 'colorId' && key !== '$pathFromLabel' && key !== '$pathToLabel') {
      const attribute = allAttributes.find((attribute) => attribute.name === key)
      if(!attribute) throw new Error(`No attribute with name: ${key}, check your query`)
      attributeClientIds.push(attribute.clientId)
    }
  })
  return attributeClientIds
}

// used to escape the string passed to search functions such that it can be used in a regex
export const escapeStringForRegex = (string) => {
  return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}
