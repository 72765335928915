import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

import { SelectorHelpers } from 'helpers/Selector'
import * as queryHelpers from 'shared/helpers/query'
import { UserProfileSelectors } from 'domain/UserProfile/selectors'
import { ProfileBoardMembershipSelectors } from 'domain/ProfileBoardMembership/selectors'

import { UserProfileSourceObjectType, UserProfileRecord } from 'shared/models/UserProfile'

import { AppStateRecord } from 'appRoot/state'
import { BasicSuggestion } from '../AutocompleteSearchBar'

export interface Suggestion extends BasicSuggestion {
  userProfile: UserProfileRecord
  userId: number
}

export default function createUserProfileSearchFactory() {
  return SelectorHelpers.createMemoizedSelectorFactory({
    extractPartialArgsFromFactoryParams: ({
      state,
      searchField,
    }: { state: AppStateRecord, searchField?: keyof UserProfileSourceObjectType }) => ({
      userProfiles: UserProfileSelectors.getAll(state),
      usersInCurrentBoard: ProfileBoardMembershipSelectors.getExistingUsersIndexedByUserId(state),
      searchField,
    }),

    extractPartialArgsFromSelectorParams: ({ searchQuery }: { searchQuery: string | null }) => ({ searchQuery: searchQuery || '' }),

    calculateValueFromCompleteArgs: ({
      userProfiles,
      usersInCurrentBoard,
      searchQuery,
      searchField,
    }) => {
      const searchQueryIsSet = searchQuery.length > 0
      if (!searchQueryIsSet) {
        return []
      }

      const queryRegExp = RegExp(queryHelpers.escapeStringForRegex(searchQuery), 'ig')

      const computeTextMatches = (userProfile: UserProfileRecord) => {
        const fieldValue = searchField ? userProfile[searchField] : userProfile.getDisplayName()
        return fieldValue && fieldValue.length > 0 ? fieldValue.match(queryRegExp) : []
      }

      console.log({ userProfiles: userProfiles
        .valueSeq().toArray()
      })
      const matches: Suggestion[] = userProfiles
        .valueSeq().toArray()
        .filter(userProfile => {
          const textMatches = computeTextMatches(userProfile)
          return textMatches && textMatches.length > 0
        })
        .map(userProfile => ({
          userProfile: userProfile,
          userId: userProfile.userId,
          title: searchField ? userProfile[searchField] : userProfile.getDisplayName(),
          textMatches: computeTextMatches(userProfile),
          visible: true,
          currentBoardMembershipClientId: (usersInCurrentBoard[userProfile.userId] ? usersInCurrentBoard[userProfile.userId].clientId : null)
        }))

      const sortedResults = sortBy(matches, ['title'])
      return [
        {
          group: "users",
          label: "Matching Users",
          searchResults: uniqBy(sortedResults, "title")
        }
      ]
    }
  })
}