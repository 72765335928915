import * as React from 'react'
import classnames from 'classnames'
import styles from './Icon.styl'

interface IconProps {
  // as we work only with FA now, we can make this prop required
  fontAwesomeIcon: string
  spin?: boolean
  tooltip?: string
  className?: string
  style?: object
  onMouseDown?: () => void
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  useDefaultCursor?: boolean
  stackedIcon?: string
  stackedIconClassName?: string
}

class Icon extends React.PureComponent<IconProps> {
  render() {
    if (!this.props.fontAwesomeIcon) {
      throw new Error('now only font-awesome-icons are implemented. set `fontAwesomeIcon` to the name of corresponding icon from font-awesome')
    }
    if (this.props.stackedIcon) {
      return (
        <span
          className={classnames(styles.iconStack, "fa-stack", this.props.spin && "fa-spin")}
          onClick={this._onClick}
          title={this.props.tooltip}
        >
          <i className={classnames([!this.props.useDefaultCursor && styles.iconWithCursor, 'fa', 'fa-stack-1x', `fa-${this.props.fontAwesomeIcon}`, this.props.className])} style={this.props.style}/>
          <i className={classnames([!this.props.useDefaultCursor && styles.iconWithCursor, 'fa', 'fa-stack-2x', `fa-${this.props.stackedIcon}`, this.props.stackedIconClassName])}/>
        </span>
      )
    } else {
      return (
        <i
          className={classnames(!this.props.useDefaultCursor && styles.iconWithCursor, 'fa', `fa-${this.props.fontAwesomeIcon}`, this.props.className, this.props.spin && "fa-spin")}
          style={this.props.style}
          onMouseDown={this._onMouseDown}
          onClick={this._onClick}
          title={this.props.tooltip}
        />
      )
    }
  }

  private _onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (this.props.onClick) {
      event.preventDefault()
      event.stopPropagation()
      this.props.onClick(event)
    }
  }

  private _onMouseDown = (event) => {
    if (this.props.onMouseDown) {
      event.preventDefault()
      event.stopPropagation()
      this.props.onMouseDown()
    }
  }
}

export default Icon
