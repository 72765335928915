import {AppStateRecord} from 'appRoot/state'
import * as Immutable from 'immutable'

const MODULES_STATE_FIELD_IN_ROOT_STATE = 'modulesState'
const MODULES_STATE_INITIAL_VALUE = Immutable.Map()

const SET_STATE_ACTION_TYPE = 'SET_STATE_ACTION_TYPE'

type ModuleName = string

interface CreateModuleStateArgs<StateShape> {
  moduleName: ModuleName
  initialValue: StateShape
}

interface CreateModuleStateInternalArgs<StateShape extends object> {
  placeIntoRootStateDirectly: boolean
  moduleName: ModuleName
  initialValue: StateShape
}

interface ModuleState<StateShape extends object> {
  get(globalState: AppStateRecord): StateShape

  getPart<StateKey extends keyof StateShape>(globalState: AppStateRecord, partKey: StateKey): StateShape[StateKey]

  setPart<StateKey extends keyof StateShape>(partKey: keyof StateShape, partValue: StateShape[StateKey])
}

const ModuleStateHelpers = {
  createModuleState<StateShape extends object> (
    {moduleName, initialValue}: CreateModuleStateArgs<StateShape>): ModuleState<StateShape> {

    return _createModuleStateInternal<StateShape>({
      placeIntoRootStateDirectly: false,
      moduleName,
      initialValue
    })
  },

  // FIXME: this is a workaround for `graphView` having fixed state part directly in the global state
  // to fix, split `graphView` into separate modules and remove this method as well as `placeIntoRootStateDirectly` option
  createGraphViewState<GraphViewStateShape extends object> (initialGraphViewState: GraphViewStateShape) {
    return _createModuleStateInternal({
      placeIntoRootStateDirectly: true,
      moduleName: 'graphView',
      initialValue: initialGraphViewState
    })
  }
}

function _createModuleStateInternal<StateShape extends object>({placeIntoRootStateDirectly, moduleName, initialValue}: CreateModuleStateInternalArgs<StateShape>): ModuleState<StateShape> {
  class ModuleStateRecordClass extends Immutable.Record(initialValue as object) {}
  const initialValueRecord = new ModuleStateRecordClass()

  const parentStatePath = placeIntoRootStateDirectly
    // FIXME: `placeIntoRootStateDirectly` is a workaround for `graphView` state being directly referenced everywhere
    // - this prevents from converting into a module state quickly,
    // so we have to comply to its fixed state form outside of module states
    // - to fix that, continue extracting smaller submodules using this API until `graphView` becomes empty
    ? []

    // FIXME: having MODULES_STATE_FIELD_IN_ROOT_SHAPE is a workaround for global state having strict shape (Record instead of Map)
    // - this forces to use a free-form (Immutable.Map) internal field for module states instead of placing them directly into the root state
    // - fix this by splitting the root state into smaller modules that use this API,
    // then converting root state to Immutable.Map
    : [MODULES_STATE_FIELD_IN_ROOT_STATE]

  const moduleStatePath = parentStatePath.concat(moduleName)

  return {
    get (state: AppStateRecord): StateShape {
      return _getModuleState(state)
    },

    getPart<PartName extends keyof StateShape>(state: AppStateRecord, partName: PartName): StateShape[PartName] {
      return _getModuleState(state).get(partName)
    },

    setPart<PartName extends keyof StateShape>(partName: PartName, partValue: StateShape[PartName]) {
      return (dispatch, getState) => {
        const currentState = _getModuleState(getState())

        const updatedState = currentState.set(partName, partValue)

        return dispatch(_setModuleState(updatedState))
      }
    }
  }

  function _getModuleState(state: AppStateRecord) {
    return state.hasIn(moduleStatePath) 
      ? state.getIn(moduleStatePath)
      : initialValueRecord
  }

  function _setModuleState(moduleState) {
    return {
      type: SET_STATE_ACTION_TYPE,
      moduleStatePath,
      moduleState
    }
  }
}

const moduleStateReducer = (reducer) => {
  reducer.handle(SET_STATE_ACTION_TYPE, (state: AppStateRecord, action) => state.setIn(action.moduleStatePath, action.moduleState))
}

export {ModuleStateHelpers, moduleStateReducer, MODULES_STATE_FIELD_IN_ROOT_STATE, MODULES_STATE_INITIAL_VALUE}
