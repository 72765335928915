import { AppStateRecord } from 'appRoot/state'

import keyBy from 'lodash/keyBy'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'

const ProfileBoardMembershipSelectors = {
  getAll(state: AppStateRecord) {
    const people = state.get('peopleInCurrentBoard')
      .filter((genericProfile) => !genericProfile.isDeleted)
      .valueSeq().toArray()
      
    return orderBy(uniqBy(people, (profile) => profile.userId + "_" + profile.unclaimedProfileClientId), ['created_at'], ['desc'])
  }, 
  getExistingUsersIndexedByUserId(state: AppStateRecord) {
    const existingUsers = this.getAll(state)
      .filter((genericProfile) => genericProfile.userId !== null)
    return keyBy(existingUsers, 'userId')
  }
}

export { ProfileBoardMembershipSelectors }
