import * as React from 'react'
import mapValues from 'lodash/mapValues'
import {ErrorHelpers} from 'shared/helpers/Error'
import * as PositioningHelpers from 'helpers/Positioning'
import ReactDOM from 'react-dom'

type ReactMouseEvent = React.MouseEvent<any>

type EventHandler = (event: ReactMouseEvent) => void

export type ActionData<AdditionalData> = {
  event: ReactMouseEvent,
  mousePosition: {
    clientX: number,
    clientY: number,
  }
} & AdditionalData

interface HighLevelAction<AdditionalData> {
  (actionData: ActionData<AdditionalData>): void
}

export interface HighLevelMouseActions<AdditionalData> {
  onClick?: HighLevelAction<AdditionalData>
  onDoubleClick?: HighLevelAction<AdditionalData>

  onMouseMove?: HighLevelAction<AdditionalData>

  onDraggingStart?: HighLevelAction<AdditionalData>
  onDraggingContinue?: HighLevelAction<AdditionalData>
  onDraggingEnd?: HighLevelAction<AdditionalData>,

  onMouseEnter?: HighLevelAction<AdditionalData>,
  onMouseLeave?: HighLevelAction<AdditionalData>,
}

type AvailableHighLevelActions<AdditionalData> = keyof HighLevelMouseActions<AdditionalData>

interface HighLevelActionPrecondition {
  (): boolean
}

export interface HighLevelActionPreconditions {
  canStartDragging?: HighLevelActionPrecondition
  canStartSelecting?: HighLevelActionPrecondition
  canClick?: HighLevelActionPrecondition
}

type AvailableActionPreconditions = keyof HighLevelActionPreconditions

export interface MouseEventHandlers {
  onClick: EventHandler
  onDoubleClick: EventHandler
  onMouseDown: EventHandler
  onMouseMove: EventHandler
  onMouseUp: EventHandler,
  onMouseEnter: EventHandler,
  onMouseLeave: EventHandler,
}

const MouseHelpers = {
  createIsolatedMouseEventHandlers,

  createMouseEventHandlersFactoryWithSharedMouseDragState,

  init () {
    mouseStateChecker.init()
  },

  enforceMouseEventsOnNewElement: (elementRef, handlers: MouseEventHandlers) => mouseStateChecker.enforceMouseEventsOnNewElement(elementRef, handlers),
}

export {MouseHelpers}

const MouseDraggingStates = {
  UP: Symbol('up'),
  JUST_PRESSED_DOWN: Symbol('just-pressed-down'),
  DRAGGING: Symbol('dragging'),
  SKIPPED: Symbol('skipped')
}

interface CreateMouseEventHandlersFactoryArgs<AdditionalData> {
  highLevelActions: HighLevelMouseActions<AdditionalData>,
}

interface CreateIsolatedMouseEventHandlersArgs {
  highLevelActions: HighLevelMouseActions<{}>,
}

interface MouseEventHandlersFactoryArgs<AdditionalData>{
  createAdditionalData: () => AdditionalData,
  preconditions: HighLevelActionPreconditions
}

export interface MouseEventHandlersFactory<AdditionalData> {
  (args: MouseEventHandlersFactoryArgs<AdditionalData>): MouseEventHandlers
}

// handle top-level mouse events and prevent any mouse event from propagating further
function createIsolatedMouseEventHandlers(
  {highLevelActions}: CreateIsolatedMouseEventHandlersArgs
): MouseEventHandlers {
  const handlerFactory = createMouseEventHandlersFactoryWithSharedMouseDragState<null>({highLevelActions})

  return handlerFactory({
    createAdditionalData: () => null,
    preconditions: {}
  })
}

function createMouseEventHandlersFactoryWithSharedMouseDragState<AdditionalData extends object>({highLevelActions}: CreateMouseEventHandlersFactoryArgs<AdditionalData>): MouseEventHandlersFactory<AdditionalData> {
  let currentMouseDragState = MouseDraggingStates.UP
  let actionDataOnMouseDown: ActionData<AdditionalData> | null = null

  return ({createAdditionalData, preconditions}) => {
    return _addEventPropagationStop({
      //don't do anything in higher-level onClick, as we have to use onMouseUp anyway
      onClick: () => {
      },

      onMouseDown: (event) => {
        if (!mouseButtonChecker.isLeftMouseButtonInvolved(event)) {
          return
        }

        actionDataOnMouseDown = _prepareActionData(event)
        currentMouseDragState = MouseDraggingStates.JUST_PRESSED_DOWN
      },

      onMouseMove: (event) => {
        if (!mouseStateChecker.hasMouseReallyMoved(event)) {
          return
        }

        if (!_checkPrecondition('canStartSelecting')) {
          event.preventDefault()
        }

        switch (currentMouseDragState) {
          case MouseDraggingStates.JUST_PRESSED_DOWN:
            if (_checkPrecondition('canStartDragging')) {
              currentMouseDragState = MouseDraggingStates.DRAGGING
              // Pass the data from the onMouseDown to the onDraggingStart to make sure the correct mouse
              // position and state (like isFromVertex) is used in the dragging logic
              _triggerAction('onDraggingStart', event, actionDataOnMouseDown)
              actionDataOnMouseDown = null
            }

            else {
              currentMouseDragState = MouseDraggingStates.SKIPPED
            }
            break

          case MouseDraggingStates.DRAGGING:
            _triggerAction('onDraggingContinue', event)
            break

          case MouseDraggingStates.UP:
            _triggerAction('onMouseMove', event)
            break
        }
      },

      onMouseUp: (event) => {
        if (!mouseButtonChecker.isLeftMouseButtonInvolved(event)) {
          return
        }

        const previousMouseState = currentMouseDragState
        currentMouseDragState = MouseDraggingStates.UP
        actionDataOnMouseDown = null

        switch (previousMouseState) {
          case MouseDraggingStates.JUST_PRESSED_DOWN:
            if (_checkPrecondition('canClick')) {
              _triggerAction('onClick', event)
            }
            break

          case MouseDraggingStates.DRAGGING:
            _triggerAction('onDraggingEnd', event)
            break
        }
      },

      onDoubleClick: (event) => {
        _triggerAction('onDoubleClick', event)
      },

      onMouseEnter: (event) => {
        _triggerAction('onMouseEnter', event)
      },

      onMouseLeave: (event) => {
        _triggerAction('onMouseLeave', event)
      },
    })

    function _prepareActionData(event: ReactMouseEvent): ActionData<AdditionalData> {
      return {
        event,
        mousePosition: _extractMousePosition(event),
        // usual double hack for TS not recognizing generics as spreadable types
        ...(createAdditionalData() as any)
      }
    }

    function _triggerAction(actionName: AvailableHighLevelActions<AdditionalData>, event: ReactMouseEvent, actionData?: ActionData<AdditionalData>) {
      const action = highLevelActions[actionName]
      if (action) {
        action(actionData || _prepareActionData(event))
      }
    }

    function _checkPrecondition(preconditionName: AvailableActionPreconditions) {
      const precondition = preconditions && preconditions[preconditionName]
      return (!precondition) || precondition()
    }
  }
}

function _addEventPropagationStop (handlers: MouseEventHandlers): MouseEventHandlers {
  return mapValues(handlers, handler => (event: ReactMouseEvent) => {
    event.stopPropagation()
    handler(event)
  })
}

type MouseDownParams = {
  time: number
  position: {clientX: number, clientY: number}
  target: any
}

const mouseStateChecker = {
  _recentMouseDownParams: null as MouseDownParams | null,
  _isInitialized: false,

  constants: {
    DRAGGING_TIME_THRESHOLD_IN_MS: 50,
    DRAGGING_DISTANCE_THRESHOLD_IN_PX: 5,
  },

  init() {
    _addGlobalEventHandler('mousedown', (event) => {
      this._recentMouseDownParams = {
        time: Date.now(),
        position: _extractMousePosition(event),
        target: event.target
      }
    })

    // `mouseup` should reset our tracking of recent `mousedown`
    _addGlobalEventHandler('mouseup', () => this._resetRecentMouseDownParams())

    _addGlobalEventHandler('mousemove', (event) => {
      this._currentMousePosition = _extractMousePosition(event)
    })

    this._isInitialized = true
  },

  _resetRecentMouseDownParams () {
    this._recentMouseDownParams = null
  },

  hasMouseReallyMoved(event) {
    ErrorHelpers.assert(this._isInitialized, 'mouseStateChecker is not initialized yet. Call mouseHelpers.init() first')

    // This check is needed only for some rare cases when in some environments
    // a `mousemove` event is generated immediately after `mousedown` event
    // without actual mouse movement and any visible reason for that;
    // We ran into that when the app was demo'ed via a WebEx call with Elizabeth with screenshare

    //so we check that both time and distance differ significantly from the recent mouse down event, if such exists
    if (!this._recentMouseDownParams) {
      return true
    }

    else if (
      this._currentTimeDiffersFromLatMouseDown() &&
      this._currentMousePositionDiffersFromLastMouseDown(event)
    ) {
      // after the first successful check all next checks should succeed as well,
      // but they will not if mouse pointer returns back to the last position of `mousedown`,
      // so we reset recent `mousedown` position to indicate that no check is needed anymore
      this._resetRecentMouseDownParams()
      return true
    }

    return false
  },

  // TODO: maybe just expose some rendering method (instead of handlers) that would both attach handlers and fire them on initial render
  // - that removes client's responsibility of managing initial render amd enforcing mouse events
  enforceMouseEventsOnNewElement (elementRef, availableHandlers: MouseEventHandlers) {
    const _fireHandler = (handler) => {
      if (handler) {
        const fakeEvent = {
          stopPropagation: () => {},
          preventDefault: () => {},
          ...this._currentMousePosition,
        }
        handler(fakeEvent)
      }
    }

    if (this._currentMousePosition) {
      const mouseIsOnElement = PositioningHelpers.clientCoordsBelongToDomElement({
        element: ReactDOM.findDOMNode(elementRef) as Element,
        clientX: this._currentMousePosition.clientX,
        clientY: this._currentMousePosition.clientY
      })

      if (mouseIsOnElement) {
        // WORKAROUND: didn't figure yet how to fire native events, so just use prepared handlers for it
        // see previous not working approach with mouse events: https://github.com/IdeaFlowCo/ideapad/pull/769/files#diff-19226973093da8e34a3d94ef6bcf1bc2R248
        _fireHandler(availableHandlers.onMouseEnter)
        _fireHandler(availableHandlers.onMouseMove)
      }
    }
  },

  _currentTimeDiffersFromLatMouseDown() {
    return Date.now() - this._recentMouseDownParams.time >= this.constants.DRAGGING_TIME_THRESHOLD_IN_MS
  },

  _currentMousePositionDiffersFromLastMouseDown(event) {
    const currentMousePosition = _extractMousePosition(event)

    const dx = currentMousePosition.clientX- this._recentMouseDownParams.position.clientX
    const dy = currentMousePosition.clientY - this._recentMouseDownParams.position.clientY

    const distanceFromLastMouseDown = Math.sqrt(dx * dx + dy * dy)

    return distanceFromLastMouseDown >= this.constants.DRAGGING_DISTANCE_THRESHOLD_IN_PX
  }
}

const mouseButtonChecker = {
  isLeftMouseButtonInvolved (mouseEvent) {
    return this._isMouseButtonPressed(mouseEvent, this._buttonCodes.LEFT)
  },

  _isMouseButtonPressed: (event, buttonCode) => event.button === buttonCode,

  _buttonCodes: {
    LEFT: 0,
    RIGHT: 2
  },
}

// TODO: maybe extract to smth like `GlobalEvents` and use everywhere instead of `window.addEventListener`
function _addGlobalEventHandler(eventName, handler) {
  //always use capture phase to capture all events, including those with `stopPropagation` callded,
  // as global event handlers are only needed for stuff not related to component hierarchy
  // and local event cloaking
  const useCapture = true
  window.addEventListener(eventName, handler, useCapture)
}

const _extractMousePosition = (event: ReactMouseEvent | MouseEvent) => ({
  clientX: event.clientX,
  clientY: event.clientY,
})
