type Coordinate = number

type PossiblePoint = PointClass | any

export interface CoordinatePair {
  x: Coordinate
  y: Coordinate
}

type SerializedPoint = [Coordinate, Coordinate]

interface PointFactory {
  (x: Coordinate, y: Coordinate): PointClass
  fromXY: (xy: CoordinatePair) => PointClass
  hydrate: (serialized: SerializedPoint) => PointClass
  isPoint: (possiblePoint: PossiblePoint) => boolean
}

const pointFactory: PointFactory = Object.assign(
  function PointFactory(x: Coordinate, y: Coordinate): PointClass {
    return new PointClass(x, y)
  }, {
    fromXY : function (xy: CoordinatePair) : PointClass {
      return pointFactory(xy.x, xy.y)
    },

    hydrate : function (serialized: SerializedPoint) : PointClass {
      return pointFactory(serialized[0], serialized[1]);
    },

    isPoint : function (possiblePoint: PossiblePoint) : boolean {
      return PointClass.isPoint(possiblePoint)
    }
  }
)

export default pointFactory

export class PointClass {
  x: Coordinate
  y: Coordinate

  //TODO: remove this - a cloned Point should not care that it was cloned
  mark?: number

  constructor (x: Coordinate, y: Coordinate) {
    this.x = x
    this.y = y
  }

  static isPoint(possiblePoint: PossiblePoint) {
    //use prototype method to avoid storing extra variable in class to minimize instance size
    return possiblePoint._getTypeToken && possiblePoint._getTypeToken() === POINT_TYPE_TOKEN
  }

  _getTypeToken () {
    return POINT_TYPE_TOKEN
  }

  serialize(): SerializedPoint {
    return [this.x, this.y]
  }

  equal(other: PointClass, epsilon = 0) {
    return this.sub(other).dist(zeroZero) <= epsilon;
  }

  toString() {
    return this.x + " " + this.y;
    //return JSON.stringify(this);
  }

  clone() {
    const clonedPoint = pointFactory(this.x, this.y);
    if (this.mark !== undefined) {
      clonedPoint.mark = this.mark;
    }
    return clonedPoint;
  }

  add(other: PointClass) {
    return pointFactory(this.x + other.x, this.y + other.y);
  }

  sub(other: PointClass) {
    return pointFactory(this.x - other.x, this.y - other.y);
  }

  mult(scalar: number) {
    return pointFactory(this.x * scalar, this.y * scalar);
  }

  pointwiseMult(other: PointClass) {
    return pointFactory(this.x * other.x, this.y * other.y);
  }

  pointwiseMax(other: PointClass) {
    return pointFactory(Math.max(this.x, other.x), Math.max(this.y, other.y));
  }

  pointwiseMin(other: PointClass) {
    return pointFactory(Math.min(this.x, other.x), Math.min(this.y, other.y));
  }

// If three points are colinear return how far on the vector a->b c lies
  div(a: PointClass, b: PointClass) {

    if (this.sub(a).dot(b.sub(a)) > 0) {
      return a.dist(this) / a.dist(b);
    } else {
      return -a.dist(this) / a.dist(b);
    }
  }

  dot(other: PointClass) {
    return this.x * other.x + this.y * other.y;
  }

  dist(other: PointClass) {
    return Math.sqrt(this.distSquare(other))
  }

  distSquare(other: PointClass) {
    const dx = this.x - other.x;
    const dy = this.y - other.y;
    return dx * dx + dy * dy;
  }

  norm() {
    const length = this.dist(zeroZero);
    if (length == 0) throw new Error('Zero vector normalization attempt');
    return this.mult(1 / length);
  }

  safeNorm() {
    const length = this.dist(zeroZero)
    if (length == 0) return oneZero
    return this.mult(1 / length)
  }

  normal() {
    return pointFactory(this.y, -this.x)
  }

  isNaN() {
    return isNaN(this.x) || isNaN(this.y);
  }

  rotate(center: PointClass, angle: number) {
    const s = Math.sin(angle);
    const c = Math.cos(angle);

    let result = pointFactory(this.x - center.x, this.y - center.y);
    result = pointFactory(result.x * c - result.y * s, result.x * s + result.y * c);
    return pointFactory(result.x + center.x, result.y + center.y);
  }

  scale(center: PointClass, ratio: number) {
    return pointFactory(
        (this.x - center.x) * ratio + center.x,
        (this.y - center.y) * ratio + center.y
    );
  }

  round(digits = 1) {
    return pointFactory(
        Math.round(this.x * Math.pow(10, digits)) / Math.pow(10, digits),
        Math.round(this.y * Math.pow(10, digits)) / Math.pow(10, digits)
    );
  }

  toTranslate(unit = 'px') {
    return `translate(${this.x}${unit},${this.y}${unit})`;
  }

  interpolateTo(other: PointClass, t: number) {
    return this.add(other.sub(this).mult(t));
  }

  snapToGridTopLeft(gridResolution: number) {
    return pointFactory(
        this.x - _positiveModulo(this.x, gridResolution),
        this.y - _positiveModulo(this.y, gridResolution)
    );
  }

  snapToGridBottomRight(gridResolution: number) {
    return pointFactory(
        this.x + _positiveModulo(gridResolution - this.x, gridResolution),
        this.y + _positiveModulo(gridResolution - this.y, gridResolution)
    );
  }

  angle() {
    return Math.atan2(this.y, this.x)
  }
}

const _positiveModulo = (a: number, mod: number) => {
  return (mod + (a % mod)) % mod;
}

const zeroZero = pointFactory(0, 0)
const oneZero = pointFactory(1, 0)

const POINT_TYPE_TOKEN: symbol = Symbol()
