import { ThunkAction } from 'redux-thunk'
import { AppStateRecord } from 'appRoot/state'
import {
  PermissionResourceType,
  permissionsTableForEntity,
  OutboundPermissionsPayload,
  OutboundOwnersPayload,
  ownersTableForEntity,
  StandardPrivacyActor,
  OneResourceType,
  Permission
} from 'shared/models/Permission'

import { pushAllObjects } from 'domain/GenericActions'
import apiCall from 'helpers/apiCall'

export function replacePermissions<ResourceType extends PermissionResourceType>(
  resourceType: OneResourceType<ResourceType>,
  resourceClientId: string,
  newPermissions: Permission<ResourceType>[]
) {
  return (async (dispatch) => {
    await apiCall({
      path: `/${resourceType}/replacePermissions/${resourceClientId}`,
      method: 'POST',
      data: { permissions: newPermissions }
    })

    dispatch(pushPermissions(resourceType, [{
      clientId: resourceClientId,
      permissions: newPermissions
    }]))
  }) as ThunkAction<void, AppStateRecord, void>
}

export function replaceOwners<ResourceType extends PermissionResourceType>(
  resourceType: OneResourceType<ResourceType>,
  resourceClientId: string,
  newOwners: StandardPrivacyActor[]
) {
  return (async (dispatch) => {
    await apiCall({
      path: `/${resourceType}/replaceOwners/${resourceClientId}`,
      method: 'POST',
      data: { newOwners }
    })

    dispatch(pushOwners(resourceType, [{
      clientId: resourceClientId,
      owners: newOwners
    }]))
  }) as ThunkAction<void, AppStateRecord, void>
}


export function pushPermissions<ResourceType extends PermissionResourceType>(
  resourceType: OneResourceType<ResourceType>,
  permissions: OutboundPermissionsPayload<ResourceType>[]
) {
  return (dispatch) => {
    dispatch(pushAllObjects({ [permissionsTableForEntity(resourceType)]: permissions }))
  }
}

export function pushOwners<ResourceType extends PermissionResourceType>(
  resourceType: OneResourceType<ResourceType>,
  owners: OutboundOwnersPayload[]
) {
  return (dispatch) => {
    dispatch(pushAllObjects({ [ownersTableForEntity(resourceType)]: owners }))
  }
}
