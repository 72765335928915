import apiCall from 'helpers/apiCall'
import {ErrorHelpers} from 'shared/helpers/Error'
import * as Immutable from 'immutable'

let _uiStateMap
let _isLoaded = false

let previousSetPromise = Promise.resolve()

const StoredUIState = {
  get (storedUIStateKey: string, defaultUIState: any, debugComponentName) {
    _assertIsMap(defaultUIState)
    return _getUIStateMap(debugComponentName).get(storedUIStateKey, defaultUIState)
  },

  set (storedUIStateKey: string, newState, debugComponentName) {
    _assertIsMap(newState)
    _setInUIStateMap(storedUIStateKey, newState, debugComponentName)

    // as we do all persistence in the background to allow for synchronous interface,
    // we have to ensure that actual calls are performed one by one in the same turn they were issued
    previousSetPromise = previousSetPromise.then(() => apiCall({
      path: `/uiState/${storedUIStateKey}`,
      method: 'PATCH',
      data: newState.toJS()
    }))
  },

  init: function () {
    return apiCall({
      path: `/uiState`,
      method: 'GET'
    }).then((data) => {
      _isLoaded = true
      _uiStateMap = Immutable.fromJS(data)
    })
  }
}

function _getUIStateMap (debugComponentName) {
  ErrorHelpers.assert(_isLoaded, `StoredUIState is not initialized yet! Call StoredUIState.init() and wait for it to finish before rendering component ${debugComponentName}`)
  return _uiStateMap
}

function _setInUIStateMap (key, newValue, debugComponentName) {
  _uiStateMap = _getUIStateMap(debugComponentName).set(key, newValue)
}

function _assertIsMap(value) {
  ErrorHelpers.assert(Immutable.Map.isMap(value), 'only Immutable.Map is allowed now to use in StoredUIState')
}
export {StoredUIState}
