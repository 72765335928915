import { CreateRecordClass } from './CreateRecord'

import { memoize } from 'shared/helpers/memoize'

const ColorDefaultValues = {
  id: 0,
  backgroundColor: '',
  textColor: '',
  groupId: -1,
  orderInGroup: 0,
}

type ColorRawType = {
  id: number,
  backgroundColor: string,
  textColor: string,
  groupId: number,
  orderInGroup: number,
}

export type ColorSourceObjectType = Partial<ColorRawType>

/**
 * @props {int} id
 * @props {string} backgroundColor
 * @props {string} textColor
 * @props {int} groupId
 * @props {int} orderInGroup
 */
class ColorRecord extends CreateRecordClass<ColorRawType>(ColorDefaultValues, "Color") {}

type ColorRecordT = ColorRecord
export { ColorRecordT as ColorRecord }

const Color = Object.assign(
  (stub: ColorSourceObjectType | ColorRecord): ColorRecord => new ColorRecord(stub),
  {
    isColor: (obj): obj is ColorRecord => (obj instanceof ColorRecord),

    foregroundForBackground: memoize.cacheFunctionWithSingleScalarArgument(
      (bgColor: string): string | undefined => {
        if (!bgColor) return undefined
        return luminosity(bgColor) > 0.5 ? '4b4b4b' : 'fafafa'
      }
    ),

    borderForBackground: memoize.cacheFunctionWithSingleScalarArgument(
      (bgColor: string): string | undefined => {
        if (!bgColor) return undefined
        return shade(bgColor, -0.3)
      }
    ),

    defaultColor: new ColorRecord({
      id: null as any,
      backgroundColor: 'fafafa'
    })
  }
)

export default Color;

const hexToDec = (hex: string) => parseInt(hex, 16)
const decToHex = (dec: number) => {
   const hex = dec.toString(16)
   return hex.length === 1 ? `0${hex}` : hex
}

const getRGBFromColor = (color) => {
  return {
    r: hexToDec(color.substring(0, 2)),
    g: hexToDec(color.substring(2, 4)),
    b: hexToDec(color.substring(4, 6)),
  }
}

const boundANumber = (minBound, maxBound) => (number) => 
  Math.min(Math.max(number, minBound), maxBound)

function shade(color, shade) {
  let {r, g, b} = getRGBFromColor(color)

  shade = (1 + shade) / 1

  const eightBitBound = boundANumber(0,255)
  r = eightBitBound(Math.round(r * shade))
  g = eightBitBound(Math.round(g * shade))
  b = eightBitBound(Math.round(b * shade))

  return `${decToHex(r)}${decToHex(g)}${decToHex(b)}`
}

function luminosity(color) {
  const {r, g, b} = getRGBFromColor(color)
  return 0.2126 * r / 255 + 0.7152 * g / 255 + 0.0722 * b / 255
}