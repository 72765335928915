import React from 'react'
import classnames from 'classnames'

import { Modal } from "reactstrap"
import modalStyles from "helpers/Modal.styl"

export class SimpleModal extends React.Component<{ isOpen: boolean, className?: string, close: () => void, fixedSize?: boolean }> {
  render() {
    return <Modal
      size="lg"
      isOpen={this.props.isOpen}
      modalClassName={modalStyles.modalWrapper}
      contentClassName={classnames(
        modalStyles.modalContent,
        this.props.fixedSize && modalStyles.modalContentFixedSize,
        this.props.className,
      )}
      toggle={this.props.close}
      autoFocus={false}
    >
      {this.props.children}
    </Modal>
  }
}