import * as React from 'react'

import Media from 'react-media'

// This value must be the same as the one in variables.styl
const MOBILE_WIDTH = 750

export const MobileDetector = (props) => {
  return <Media query={`(max-width: ${MOBILE_WIDTH}px)`}>
    {props.children}
  </Media>
}

export default function withMobileDetector<P>(Component: React.ComponentClass<P & { isOnMobile?: boolean }>) {
  const displayName = `withMobileDetector(${Component.displayName || Component.name})`

  function MobileDetectorWithRef(props: P, ref) {
    return <MobileDetector>
      {(matches: boolean) => <Component {...props as any} isOnMobile={matches} ref={ref} />}
    </MobileDetector>
  }
  (MobileDetectorWithRef as any).displayName = displayName

  return React.forwardRef(MobileDetectorWithRef)
}
