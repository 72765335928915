import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

import { generateClientId } from 'shared/helpers/Id'

// TODO: Introduce typing to the following models too
import ConnectionInstance, { ConnectionInstanceRecord } from './ConnectionInstance'
import IdeaInstanceFactory, { IdeaInstanceRecord } from './IdeaInstance'

import ImmutableGraph from '../helpers/ImmutableGraph'
import Point, { PointClass } from '../helpers/Point'
import AffineTransform from '../helpers/AffineTransform'
import Rectangle, { RectangleType } from '../helpers/Rectangle'

import Color from './Color'
import { ConnectionRecord } from './Connection'
import { GraphVertexConstants } from './graph/GraphVertexConstants'

const zeroZeroPoint = Point(0,0)

const defaultCanvasRectangle = Rectangle.rectangleFromPoints(
  zeroZeroPoint,
  Point(500, 400),
) as RectangleType
const defaultDistance = 60
const smallSpreadConstant = 20

const GraphLayoutDefaultValues = {
  clientId: NotNullOrUndefined,

  ideaInstanceById: NotNullOrUndefined,
  connectionInstanceById: NotNullOrUndefined,
  imageTransform: AffineTransform.identityTransform,

  repulsionCoefficient: 2.0,
}

type GraphLayoutRawType = {
  clientId: string

  ideaInstanceById: { [ideaInstanceId: string]: IdeaInstanceRecord }
  connectionInstanceById: { [connectionInstanceId: string]: ConnectionInstanceRecord }
  imageTransform: AffineTransform,

  repulsionCoefficient: number,
}

export type GraphLayoutSourceObjectType = Partial<GraphLayoutRawType>

class GraphLayoutRecord extends CreateRecordClass<GraphLayoutRawType>(GraphLayoutDefaultValues, "GraphLayout") {
  constructor(stub) {
    if (stub instanceof GraphLayoutRecord) {
      super(stub)
      return
    }
    stub = {...stub, ...stub.graphLayout};
    stub.clientId = stub.clientId || generateClientId()

    if (stub.ideaInstanceById) {
      stub.ideaInstanceById = Object.keys(stub.ideaInstanceById)
        .reduce((map, key) => {
          map[key] = IdeaInstanceFactory.copy(stub.ideaInstanceById[key])
          return map
        }, {})
    } else {
      stub.ideaInstanceById = {}
    }

    if (stub.connectionInstanceById) {
      stub.connectionInstanceById = Object.keys(stub.connectionInstanceById)
        .reduce((map, key) => {
          map[key] = ConnectionInstance(stub.connectionInstanceById[key])
          return map
        }, {})
    } else {
      stub.connectionInstanceById = {}
    }

    // TODO: improve this
    if (stub.imageTransform) {
      if (stub.imageTransform.shiftVector && stub.imageTransform.scaleVector) {
        stub.imageTransform = new AffineTransform(
          Point.fromXY(stub.imageTransform.shiftVector),
          Point.fromXY(stub.imageTransform.scaleVector)
        )

      } else if (stub.imageTransform[0] && stub.imageTransform[1]) {
        stub.imageTransform = AffineTransform.Hydrate(stub.imageTransform)

      } else {
        stub.imageTransform = AffineTransform.identityTransform
      }
    }

    super(stub);
  }

  getConnectionInstanceByConnectionClientId () {
    const connectionInstanceByConnectionClientId: { [key: string]: ConnectionInstanceRecord } = {}
    const connectionInstanceById = this.get('connectionInstanceById')

    Object.keys(connectionInstanceById).forEach(id => {
      const connectionInstance = connectionInstanceById[id]
      connectionInstanceByConnectionClientId[connectionInstance.connectionClientId] = connectionInstance
    })

    return connectionInstanceByConnectionClientId
  }

  getAllConnectionInstances () {
    const connectionInstanceById = this.get('connectionInstanceById')
    return Object.keys(connectionInstanceById).map(instanceId => connectionInstanceById[instanceId])
  }

  getConnectionInstancesForIdeaInstance (ideaInstanceId) {
    return this.getAllConnectionInstances().filter(connectionInstance => connectionInstance.isAttachedToIdeaInstance(ideaInstanceId))
  }

  getBoundingRectangleForWholeGraphLayout (padding: number) {
    return this.getBoundingRectangleForInstances(Object.keys(this.ideaInstanceById), padding, padding)
  }

  //TODO: merge paddingX and paddingY, as they are always equal
  getBoundingRectangleForInstances(ideaInstanceIds: string[], paddingX: number, paddingY: number) {
    const existingIdeaInstancesPositionPoints: PointClass[] = []

    ideaInstanceIds.forEach((ideaInstanceId) => {
      const ideaInstance = this.ideaInstanceById[ideaInstanceId]

      // FIXME: find out why this filter is needed and add explanation here
      // probably there is an ability to delete an idea while evolving,
      // but in that case it's better to stop current evolving to avoid unpredictable situations
      if (ideaInstance) {
        existingIdeaInstancesPositionPoints.push(Point.fromXY(ideaInstance.getPosition()))
      }
    })

    // account for vertex sizes as padding is given related to vertex borders, and we will use centers below
    const paddingFromVertexCentersToBorders = Point(GraphVertexConstants.vertexWidth / 2, GraphVertexConstants.vertexHeight / 2)

    const totalPadding = paddingFromVertexCentersToBorders.add(Point(paddingX, paddingY))

    return Rectangle.rectangleFromPoints(
      ...existingIdeaInstancesPositionPoints.map(point => point.add(totalPadding)),
      ...existingIdeaInstancesPositionPoints.map(point => point.sub(totalPadding)),
    )
  }
}

type GraphLayoutRecordT = GraphLayoutRecord
export { GraphLayoutRecordT as GraphLayoutRecord }

const constants = {
  zoomSpeed: 10,
  defaultVertexColor: Color({
    id: 1,
    backgroundColor: 'FAFAFA',
    textColor: '4B4B4B',
  }),
  defaultEdgeColor: Color({
    id: 2,
    backgroundColor: 'DADADA',
    textColor: '4B4B4B',
  }),
  serverSideEvolveThreshold: 3000,
}

const isGraphLayout = (obj): obj is GraphLayoutRecord => (obj instanceof GraphLayoutRecord);

const createImmutableGraph = (graphLayout: GraphLayoutRecord) => {
  const { ideaInstanceById, connectionInstanceById } = graphLayout

  return ImmutableGraph.empty
    .addManyVertices(
      Object.keys(ideaInstanceById)
        .map(id => ideaInstanceById[id])
    )
    .addManyEdges(
      Object.keys(connectionInstanceById)
        .map(id => {
          const connectionInstance = connectionInstanceById[id]
          return {
            id: connectionInstance.id,
            sourceId: connectionInstance.sourceInstanceId,
            targetId: connectionInstance.targetInstanceId,
          }
        })
    )
}

const getIdeaInstanceIdByIdeaClientId = (graphLayout: GraphLayoutRecord) => {
  const ideaInstanceIdByIdeaClientId: { [ideaClientId: string]: string } = {}
  const { ideaInstanceById } = graphLayout

  Object.keys(ideaInstanceById).forEach(id => {
    const ideaInstance = ideaInstanceById[id]
    ideaInstanceIdByIdeaClientId[ideaInstance.ideaClientId] = id
  })

  return ideaInstanceIdByIdeaClientId
}

const getIdeaInstanceByIdeaClientId = (graphLayout: GraphLayoutRecord) => {
  const ideaInstanceByIdeaClientId: { [ideaClientId: string]: IdeaInstanceRecord } = {}
  const { ideaInstanceById } = graphLayout

  Object.keys(ideaInstanceById).forEach(id => {
    const ideaInstance = ideaInstanceById[id]
    ideaInstanceByIdeaClientId[ideaInstance.ideaClientId] = ideaInstance
  })

  return ideaInstanceByIdeaClientId
}

const getIdeaClientIds = (graphLayout: GraphLayoutRecord) => {
  return Object.keys(getIdeaInstanceByIdeaClientId(graphLayout))
}

function ensureIdeasAndConnectionsHaveInstances(
  graphLayout: GraphLayoutRecord,
  ideaClientIds: Iterable<string>,
  connections: Iterable<ConnectionRecord>
) {
  const ideaInstanceByIdeaClientId = GraphLayout.getIdeaInstanceByIdeaClientId(graphLayout)
  const connectionInstanceByConnectionClientId = graphLayout.getConnectionInstanceByConnectionClientId()

  const newIdeaInstancesById = {}
  for (const ideaClientId of ideaClientIds) {
    if(!ideaInstanceByIdeaClientId[ideaClientId]) {
      const instance = IdeaInstanceFactory.create({
        ideaClientId,
      })

      ideaInstanceByIdeaClientId[ideaClientId] = instance
      newIdeaInstancesById[instance.get('id')] = instance
    }
  }
  const updatedConnectionInstancesById = { ...graphLayout.connectionInstanceById }
  for (const connection of connections) {
    const sourceInstance = ideaInstanceByIdeaClientId[connection.sourceIdeaClientId]
    const targetInstance = ideaInstanceByIdeaClientId[connection.targetIdeaClientId]
    // No need to add the connection instance if it doesn't connect exactly two ideaInstances present in the graphLayout
    if (!targetInstance || !sourceInstance) continue
    const existingConnectionInstance = connectionInstanceByConnectionClientId[connection.clientId]
    if(existingConnectionInstance) {
      // Verify corectness of the existing connectionInstance. Does it connect correct ideaInstances?
      const existingSourceInstance = graphLayout.ideaInstanceById[existingConnectionInstance.sourceInstanceId]
      const existingTargetInstance = graphLayout.ideaInstanceById[existingConnectionInstance.targetInstanceId]

      // If the connectionInstance is already in the graph, don't create a new one
      if (
        existingSourceInstance.ideaClientId === connection.sourceIdeaClientId &&
        existingTargetInstance.ideaClientId === connection.targetIdeaClientId
      ) continue

      // If the incorrect connectionInstance is in the graph remove it and add the correct one
      console.warn("Inconsistent connection instance must be corrected", {
        existingConnectionInstance: existingConnectionInstance.toJS(),
        existingSourceInstance: existingSourceInstance.toJS(),
        existingTargetInstance: existingTargetInstance.toJS(),
        connection: connection.toJS(),
      })
      delete updatedConnectionInstancesById[existingConnectionInstance.id]
    }

    const instance = ConnectionInstance({
      connectionClientId: connection.clientId,
      sourceInstanceId: sourceInstance.id,
      targetInstanceId: targetInstance.id,
    })
    updatedConnectionInstancesById[instance.get('id')] = instance
  }

  return graphLayout
    .set('ideaInstanceById', {
      ...graphLayout.ideaInstanceById,
      ...newIdeaInstancesById,
    })
    .set('connectionInstanceById', updatedConnectionInstancesById)
}

const addIdeaInstance = (graphLayout: GraphLayoutRecord, ideaInstance: IdeaInstanceRecord) => {
  return graphLayout
    .update('ideaInstanceById', ideaInstanceById => ({
      ...ideaInstanceById,
      [ideaInstance.id]: ideaInstance
    }))
}

const restrictGraphLayoutToSetOfIdeas = (graphLayout: GraphLayoutRecord, ideaClientIds: Iterable<string>) => {
  const ideaClientIdsSet = new Set(ideaClientIds)

  const oldIdeaInstanceById = graphLayout.ideaInstanceById
  const oldConnectionInstanceById = graphLayout.connectionInstanceById

  const newIdeaInstanceIds = Object.keys(oldIdeaInstanceById).filter(id => {
    const ideaInstance = oldIdeaInstanceById[id]
    return ideaClientIdsSet.has(ideaInstance.ideaClientId)
  })
  const newConnectionInstanceIds = Object.keys(oldConnectionInstanceById).filter(id => {
    const connectionInstance = oldConnectionInstanceById[id]
    const sourceInstance = oldIdeaInstanceById[connectionInstance.sourceInstanceId]
    const targetInstance = oldIdeaInstanceById[connectionInstance.targetInstanceId]
    return (
      ideaClientIdsSet.has(sourceInstance.ideaClientId) &&
      ideaClientIdsSet.has(targetInstance.ideaClientId)
    )
  })

  return graphLayout
    .set('ideaInstanceById', newIdeaInstanceIds.reduce((map, id) => {
      map[id] = oldIdeaInstanceById[id];
      return map;
    }, {}))
    .set('connectionInstanceById', newConnectionInstanceIds.reduce((map, id) => {
      map[id] = oldConnectionInstanceById[id];
      return map;
    }, {}))

}

const _findPlacedRelatedInstanceIds = (graph, ideaInstanceId, ideaInstanceById) => {
  // Find the first connected instance with position
  const placedIdeaInstanceIds = [
    ...graph.getOutEdges(ideaInstanceId)
      .map(connection => connection.targetId),
    ...graph.getInEdges(ideaInstanceId)
      .map(connection => connection.sourceId)
  ].filter(
    (parentInstanceId: string) =>
      ideaInstanceById[parentInstanceId].isPlaced()
  )
  return new Set(placedIdeaInstanceIds)
}

const _getRelatedInstancesByPlacedInstanceId = (graphLayout: GraphLayoutRecord) => {
  const graph = GraphLayout.createImmutableGraph(graphLayout)
  const { ideaInstanceById } = graphLayout

  const unplacedRelatedInstancesByPlacedInstanceId = {}
  const instancesWithNoRelatedPlacedInstance: IdeaInstanceRecord[] = []
  const numberOfPlacedRelatedInstancesByRelatedInstance: { [ideaInstanceId: string]: number } = {}

  for(const instance of Object.values(ideaInstanceById)) {
    if(instance.isPlaced()) continue

    const relatedInstanceIds = _findPlacedRelatedInstanceIds(
      graph, instance.id, ideaInstanceById
    )
    if(relatedInstanceIds.size !== 0) {
      numberOfPlacedRelatedInstancesByRelatedInstance[instance.id] = relatedInstanceIds.size
      for (const relatedInstanceId of relatedInstanceIds) {
        if(unplacedRelatedInstancesByPlacedInstanceId[relatedInstanceId] === undefined) {
          unplacedRelatedInstancesByPlacedInstanceId[relatedInstanceId] = []
        }
        unplacedRelatedInstancesByPlacedInstanceId[relatedInstanceId]
          .push(instance.id)
      }
    } else {
      instancesWithNoRelatedPlacedInstance.push(instance)
    }
  }

  return {
    unplacedRelatedInstancesByPlacedInstanceId,
    numberOfPlacedRelatedInstancesByRelatedInstance,
    instancesWithNoRelatedPlacedInstance,
  }
}

const _randomFloatFromMinusHalfToPlusHalf = () => Math.random() - 0.5

const _getTheCenterOfARectangle = (rectangle) => zeroZeroPoint
  .add(rectangle.topLeftCorner)
  .add(rectangle.bottomRightCorner)
  .mult(0.5)

const _placeUnplacedInstancesWithNoRelatedPlacedInstance =
(
  instancesWithNoRelatedPlacedInstance,
  canvasRectangle, spreadWidth, spreadHeight
) => {
  const newIdeaInstanceById = {}
  // Place all the instances with no already placed related instances
  instancesWithNoRelatedPlacedInstance.forEach(instance => {
    // position idea instance off the middle of screen by the defined random spread
    const simulationPosition = _getTheCenterOfARectangle(canvasRectangle)
      .add(Point(
        _randomFloatFromMinusHalfToPlusHalf() * spreadWidth,
        _randomFloatFromMinusHalfToPlusHalf() * spreadHeight
      ))

    newIdeaInstanceById[instance.id] =
      instance.set('simulationPosition', simulationPosition)
  })
  return newIdeaInstanceById
}

const _placeUnplacedInstancesWithPlacedRelatedInstances =
(
  ideaInstanceById,
  unplacedRelatedInstancesByPlacedInstanceId,
  numberOfPlacedRelatedInstancesByRelatedInstance
) => {
  const newIdeaInstanceById = {}
  Object.keys(unplacedRelatedInstancesByPlacedInstanceId).forEach(placedInstanceId => {
    const placedInstance = ideaInstanceById[placedInstanceId]
    const unplacedRelatedInstances = unplacedRelatedInstancesByPlacedInstanceId[placedInstanceId]

    unplacedRelatedInstances.forEach((childId, i) => {
      if(newIdeaInstanceById[childId] === undefined) {
        newIdeaInstanceById[childId] = ideaInstanceById[childId]
          .set('simulationPosition', Point.fromXY({ x: 0, y: 0 }))
      }
      const previousSimulationPosition =
        newIdeaInstanceById[childId].get('simulationPosition')
      const weight = 1/numberOfPlacedRelatedInstancesByRelatedInstance[childId]

      const dx = Math.sin(i*Math.PI*2/unplacedRelatedInstances.length) * defaultDistance
      const dy = Math.cos(i*Math.PI*2/unplacedRelatedInstances.length) * defaultDistance

      const nextSimulationPosition = {
        x: previousSimulationPosition.x +
          (placedInstance.simulationPosition.x + dx)*weight,
        y: previousSimulationPosition.y +
          (placedInstance.simulationPosition.y + dy)*weight,
      }

      newIdeaInstanceById[childId] = newIdeaInstanceById[childId]
        .set('simulationPosition', Point.fromXY(nextSimulationPosition))
    })
  })
  return newIdeaInstanceById
}

enum SpreadType {
  FULL_SIZE_OF_CANVAS_SPREAD,
  FULL_WIDTH_OF_CANVAS_SPREAD,
  SMALL_CONSTANT_SPREAD
}

const ensureIdeaInstancesArePlaced = (graphLayout: GraphLayoutRecord, canvasRectangle = defaultCanvasRectangle, spreadType = SpreadType.FULL_SIZE_OF_CANVAS_SPREAD) => {
  const spreadWidth = (spreadType === SpreadType.SMALL_CONSTANT_SPREAD)
    ? smallSpreadConstant : canvasRectangle.width
  const spreadHeight = (spreadType === SpreadType.SMALL_CONSTANT_SPREAD)
    ? smallSpreadConstant : canvasRectangle.height

  const {
    unplacedRelatedInstancesByPlacedInstanceId,
    numberOfPlacedRelatedInstancesByRelatedInstance,
    instancesWithNoRelatedPlacedInstance,
  } = _getRelatedInstancesByPlacedInstanceId(graphLayout)

  const updatedIdeaInstanceById = {
    ..._placeUnplacedInstancesWithNoRelatedPlacedInstance(
      instancesWithNoRelatedPlacedInstance,
      canvasRectangle, spreadWidth, spreadHeight
    ),
    ..._placeUnplacedInstancesWithPlacedRelatedInstances(
      graphLayout.get('ideaInstanceById'),
      unplacedRelatedInstancesByPlacedInstanceId,
      numberOfPlacedRelatedInstancesByRelatedInstance,
    ),
  }

  return graphLayout.set('ideaInstanceById', {
    ...graphLayout.ideaInstanceById,
    ...updatedIdeaInstanceById,
  })
}

const patchIdeaInstancesInGraphLayout =
  (oldGraphLayout: GraphLayoutRecord, newGraphLayout: GraphLayoutRecord, preservedIdeaInstancesIds) => {
  const preservedIdeaInstances = preservedIdeaInstancesIds.map(
    ideaInstanceId => oldGraphLayout.ideaInstanceById[ideaInstanceId]
  )
  const preservedIdeaInstancesById = Object.assign(
    {},
    ...preservedIdeaInstances.map(ideaInstance => ({[ideaInstance.id] : ideaInstance}))
  )
  return oldGraphLayout.set('ideaInstanceById', {
    ...newGraphLayout.ideaInstanceById,
    ...preservedIdeaInstancesById
  })
}

const hasIdeaInstanceId = (graphLayout: GraphLayoutRecord, ideaInstanceId: string) => {
  return graphLayout.get('ideaInstanceById')[ideaInstanceId] !== undefined
}

const createGraphLayoutWithMergedIdeas = (graphLayout: GraphLayoutRecord, remapOldIdeaClientId: (ideaClientId: string) => string): GraphLayoutRecord => {
  const ideaClientIdToStillPresentIdeaInstanceId = {}
  const presentRemappedIdeaClientIds = {}
  const updatedIdeaInstanceById: GraphLayoutRecord["ideaInstanceById"] = {}
  const updatedConnectionInstanceById: GraphLayoutRecord["connectionInstanceById"] = {}
  Object.keys(graphLayout.ideaInstanceById).forEach(ideaInstanceId => {
    const ideaInstance = graphLayout.ideaInstanceById[ideaInstanceId]
    const oldIdeaClientId = ideaInstance.ideaClientId
    const newIdeaClientId = remapOldIdeaClientId(oldIdeaClientId)
    if(newIdeaClientId !== oldIdeaClientId) {
      presentRemappedIdeaClientIds[oldIdeaClientId] = true
      return
    }
    ideaClientIdToStillPresentIdeaInstanceId[ideaInstance.ideaClientId] = ideaInstanceId
    updatedIdeaInstanceById[ideaInstanceId] = ideaInstance
  })

  for(let presentRemappedIdeaClientId in presentRemappedIdeaClientIds) {
    const requiredIdeaClientId = remapOldIdeaClientId(presentRemappedIdeaClientId)
    if(!ideaClientIdToStillPresentIdeaInstanceId[requiredIdeaClientId]) {
      // The graph layout contains an idea instance for one of the remapped ideas
      // (clientId ${presentRemappedIdeaClientId}), but not an idea instance for
      // the idea it is remapped to (clientId ${requiredIdeaClientId})
      const allIdeaInstancesOfIdeasMappingToRequiredIdea = Object.keys(graphLayout.ideaInstanceById)
        .map(ideaInstanceId => graphLayout.ideaInstanceById[ideaInstanceId])
        .filter(ideaInstance => remapOldIdeaClientId(ideaInstance.ideaClientId) === requiredIdeaClientId)
      const ideaInstance = averageIdeaInstances(allIdeaInstancesOfIdeasMappingToRequiredIdea)
        .set("id", generateClientId())
        .set("ideaClientId", requiredIdeaClientId)
      ideaClientIdToStillPresentIdeaInstanceId[requiredIdeaClientId] = ideaInstance.id
      updatedIdeaInstanceById[ideaInstance.id] = ideaInstance
    }
  }

  const remapIdeaInstanceId = (ideaInstanceId) => {
    const oldIdeaClientId = graphLayout.ideaInstanceById[ideaInstanceId].ideaClientId
    const newIdeaClientId = remapOldIdeaClientId(oldIdeaClientId)
    const newIdeaInstanceId = ideaClientIdToStillPresentIdeaInstanceId[newIdeaClientId]
    if(!newIdeaInstanceId) {
      throw new Error(`
        The idea instance (id ${ideaInstanceId}, ideaClientId ${oldIdeaClientId})
        has no other idea instance it can be remapped to. The idea it remaps to ${newIdeaClientId}
        has no corresponding idea instance (available idea instances by ideaClientId
        ${JSON.stringify(ideaClientIdToStillPresentIdeaInstanceId)})`
      )
    }
    return newIdeaInstanceId
  }

  Object.keys(graphLayout.connectionInstanceById).forEach(connectionInstanceId => {
    const connectionInstance = graphLayout.connectionInstanceById[connectionInstanceId]
    const updatedConnectionInstance = connectionInstance
      .set("sourceInstanceId", remapIdeaInstanceId(connectionInstance.sourceInstanceId))
      .set("targetInstanceId", remapIdeaInstanceId(connectionInstance.targetInstanceId))
    updatedConnectionInstanceById[connectionInstanceId] = updatedConnectionInstance
  })

  // Do some basic sanity checks on the resulting graph layout
  Object.keys(updatedIdeaInstanceById)
    .map(id => updatedIdeaInstanceById[id])
    .forEach(ideaInstance => {
      if(!ideaInstance.id) throw new Error('No id')
      if(!ideaInstance.ideaClientId) throw new Error('No ideaClientId')
    })
  Object.keys(updatedConnectionInstanceById)
    .map(id => updatedConnectionInstanceById[id])
    .forEach(connectionInstance => {
      if(!connectionInstance.id) throw new Error('No id')
      if(!connectionInstance.connectionClientId) throw new Error('No connectionClientId')
      if(!connectionInstance.sourceInstanceId) throw new Error('No sourceInstanceId')
      if(!connectionInstance.targetInstanceId) throw new Error('No targetInstanceId')
      if(!updatedIdeaInstanceById[connectionInstance.sourceInstanceId]) throw new Error('source idea instance does not exist')
      if(!updatedIdeaInstanceById[connectionInstance.targetInstanceId]) throw new Error('target idea instance does not exist')
    })
  return graphLayout
    .set("ideaInstanceById", updatedIdeaInstanceById)
    .set("connectionInstanceById", updatedConnectionInstanceById)
}

const averageIdeaInstances = (ideaInstances: IdeaInstanceRecord[]): IdeaInstanceRecord => {
  if(ideaInstances.length === 0) {
    throw new Error("Can't average 0 idea instances")
  }
  const outputIdeaInstance = {}
  const sumSimulationPosition = {x:0,y:0}
  ideaInstances.forEach(ideaInstance => {
    sumSimulationPosition.x += ideaInstance.simulationPosition.x
    sumSimulationPosition.y += ideaInstance.simulationPosition.y
    Object.assign(outputIdeaInstance, ideaInstance.toJS())
  })
  const averageSimulationPosition = {
    x: sumSimulationPosition.x / ideaInstances.length,
    y: sumSimulationPosition.y / ideaInstances.length
  }
  Object.assign(outputIdeaInstance, {
    simulationPosition: averageSimulationPosition
  })

  return IdeaInstanceFactory.copy(outputIdeaInstance as any)
}

const GraphLayout = (stub: GraphLayoutSourceObjectType | GraphLayoutRecord): GraphLayoutRecord => {
  if (stub instanceof GraphLayoutRecord) {
    return stub as GraphLayoutRecord
  } else {
    return ensureIdeaInstancesArePlaced(new GraphLayoutRecord(stub))
  }
}

GraphLayout.constants = constants
GraphLayout.isGraphLayout = isGraphLayout
GraphLayout.createImmutableGraph = createImmutableGraph
GraphLayout.hasIdeaInstanceId = hasIdeaInstanceId
GraphLayout.getIdeaInstanceIdByIdeaClientId = getIdeaInstanceIdByIdeaClientId
GraphLayout.getIdeaInstanceByIdeaClientId = getIdeaInstanceByIdeaClientId
GraphLayout.getIdeaClientIds = getIdeaClientIds
GraphLayout.ensureIdeasAndConnectionsHaveInstances = ensureIdeasAndConnectionsHaveInstances
GraphLayout.addIdeaInstance = addIdeaInstance
GraphLayout.restrictGraphLayoutToSetOfIdeas = restrictGraphLayoutToSetOfIdeas
GraphLayout.ensureIdeaInstancesArePlaced = ensureIdeaInstancesArePlaced
GraphLayout.patchIdeaInstancesInGraphLayout = patchIdeaInstancesInGraphLayout
GraphLayout.createGraphLayoutWithMergedIdeas = createGraphLayoutWithMergedIdeas
GraphLayout.SpreadType = SpreadType

export default GraphLayout;
