import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'

export interface ProfileBoardMembershipSource {
  clientId: string,
  boardClientId: string,
  userId: number | null,
  unclaimedProfileClientId: string | null,
  creatorUserId: number,
  isDeleted: boolean,
  created_at: string
}

export const GenericProfileDefaultValues = {
  clientId: NotNullOrUndefined,
  type: "unclaimedProfile" as "unclaimedProfile",
  isContributor: false,
  unclaimedProfileClientId: null,
  userId: null,
  name: '',
  email: '',
  isEmailPublic: true,
  bio: '',
  website: '',
  avatar: null,
  isDeleted: false,
  created_at: '' as any as Date,
}

type GenericProfileRawType = {
  clientId: string
  type: "userProfile" | "unclaimedProfile"
  isContributor: boolean
  unclaimedProfileClientId: string | null
  userId: number | null
  name: string
  email: string
  isEmailPublic: boolean
  bio: string
  website: string
  avatar: string | null
  isDeleted: boolean
}

type GenericProfileRawTypeExternal = GenericProfileRawType & {
  created_at: string
}

type GenericProfileRawTypeInternal = GenericProfileRawType & {
  created_at: Date
}

export type GenericProfileObjectType = Partial<GenericProfileRawTypeExternal>

class GenericProfileRecord extends CreateRecordClass<GenericProfileRawTypeInternal>(GenericProfileDefaultValues, "GenericProfile") {}

type GenericProfileRecordT = GenericProfileRecord
export { GenericProfileRecordT as GenericProfileRecord }

const GenericProfile = Object.assign(
  (stub: GenericProfileObjectType | GenericProfileRecord = {}): GenericProfileRecord => {
    if(!GenericProfile.isGenericProfile(stub)) {
      return new GenericProfileRecord({
        ...stub,
        created_at: new Date(stub.created_at || Date.now() as any)
      })
    } else {
      return new GenericProfileRecord(stub)
    }
  },
  {
    isGenericProfile: (obj): obj is GenericProfileRecord => (obj instanceof GenericProfileRecord)
  },
)

export default GenericProfile


