import * as React from 'react'

import AuthDialog from './AuthDialog'

import {ModalHelpers, ModalTypes, ModalSizes} from 'helpers/Modal'

const AccountManager = {
  openModal: () => {
    let realCloseModal: () => void
    const closeModal = () => realCloseModal()

    realCloseModal = ModalHelpers.globalModals.showModal({
      type: ModalTypes.INFO,
      size: ModalSizes.LARGE,
      header: 'Login or sign up to IdeaFlow',
      contents: React.createElement(AuthDialog, {
        closeSelf: closeModal
      }),

      escapeHandler: closeModal,

      onModalDisplayed: null
    })
  }
}

export default AccountManager
