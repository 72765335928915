import * as React from 'react'

import styles from './AdminUsers.styl'

import LayoutShell from 'components/layout/LayoutShell'
import {UserProfileRecord} from 'shared/models/UserProfile'

import { Label, FormGroup, Form } from 'components/common/Forms'
import AttributePicker from 'components/common/attributes/AttributePicker'

import { BoundActionGroups } from 'helpers/Component'
import { UserProfileActions } from 'domain/UserProfile/actions'
import { AttributeActions } from 'domain/Attribute/actions'
import { TeamCodesActions } from 'managers/TeamCodes'

import { renderRelationEditor } from 'components/common/RelationEditor'

import { TeamCode } from 'shared/TeamCodes'
import Checkbox from 'components/common/Checkbox'

import appConfig from 'config/appConfig'

export interface AdminUsersStateProps {
  users: UserProfileRecord[]

  canManageTeamCodes: boolean
  canAdminUsers: boolean

  teamCodesAreActive: boolean
  teamCodeAttributeClientId: string

  allTeamCodes: Promise<TeamCode[]>

  userHasTeamCode: (user, teamCode) => boolean
}

interface AdminUsersState {
  allTeamCodes: TeamCode[]
}

export type AdminUsersActionGroups = BoundActionGroups<{
  UserProfileActions: typeof UserProfileActions,
  AttributeActions: typeof AttributeActions,
  TeamCodesActions: typeof TeamCodesActions,
}>

export default class AdminUsers extends React.Component<AdminUsersStateProps & AdminUsersActionGroups, AdminUsersState> {
  constructor(props) {
    super(props)

    this.state = { allTeamCodes: [] }
  }

  componentDidMount() {
    this.props.UserProfileActions.refetchAll()
    this.props.AttributeActions.refetchAll()
    this._receiveTeamCodes(this.props.allTeamCodes)
  }

  componentWillReceiveProps(nextProps: AdminUsersStateProps & AdminUsersActionGroups) {
    if(this.props.allTeamCodes !== nextProps.allTeamCodes) {
      this._receiveTeamCodes(nextProps.allTeamCodes)
    }
  }

  private async _receiveTeamCodes(allTeamCodesPromise) {
    this.setState({ allTeamCodes: await allTeamCodesPromise })
  }

  render() {
    return (
      <LayoutShell>
        <div className={styles.pageBody}>
          <h3>Manage user rights</h3>

          <table className={styles.usersTable}>
            <thead>
            <tr>
              <th>Username</th>
              {editableUserFlags.map((flagName) => {
                return <th
                  key={flagName}
                >
                  {flagCaptions[flagName]}
                </th>
              })}
              {appConfig.features.superUserCanResetPasswordsToTemporary && this.props.canAdminUsers && <th>
                Set temporary password
              </th>}
            </tr>
            </thead>
            <tbody>
            {this.props.users.map((user) => {
              return <tr
                key={user.userId}
              >
                <td>{user.getDisplayName()}</td>
                {editableUserFlags.map((flagName) => {
                  const currentFlagValue = !!user[flagName]

                  return <td
                    key={flagName}
                    className={styles.flagCell}
                  >
                    <Label>
                      <Checkbox
                        checked={currentFlagValue}
                        onChange={() => this.props.UserProfileActions.setUserPermission(user.userId, flagName, !currentFlagValue)}
                      />
                      &nbsp;
                    </Label>
                  </td>
                })}
                {appConfig.features.superUserCanResetPasswordsToTemporary && this.props.canAdminUsers && <td>
                  <button onClick={() => this.props.UserProfileActions.setTemporaryPassword(user.userId)}>
                    Set temporary password
                  </button>
                </td>}
              </tr>
            })}
            </tbody>
          </table>

          {this.props.canManageTeamCodes
            ? <div>
              <h3>Manage team codes</h3>

              <Form inline> <FormGroup>
                <Label>
                  <span>Set team code attribute:</span>
                  <AttributePicker
                    value={this.props.teamCodeAttributeClientId}
                    onChange={(attributeClientId) => this.props.TeamCodesActions.setTeamCodeAttribute(attributeClientId)}
                  />
                </Label>
              </FormGroup>
              </Form>

              {this.props.teamCodesAreActive && renderRelationEditor<UserProfileRecord, TeamCode>({
                entityGroups: {
                  first: {
                    items: this.props.users,
                    caption: 'Users',
                    renderItem: user => user.getDisplayName(),
                    getItemKey: user => user.userId
                  },
                  second: {
                    items: this.state.allTeamCodes,
                    caption: 'Team codes',
                    renderItem: teamCode => teamCode,
                    getItemKey: teamCode => teamCode,
                  }
                },
                relationExists: (user, teamCode) => this.props.userHasTeamCode(user.userId, teamCode),
                toggleRelation: (user, teamCode, shouldHaveTeamCode) => this.props.UserProfileActions.toggleTeamCode(user.userId, teamCode, shouldHaveTeamCode),
              })}
            </div>
            : null}

        </div>
      </LayoutShell>
    )
  }
}

const editableUserFlags = [
  'isSuperUser',
  'canAdminUsers',
  'canEditRestrictedAttributes',
  'canManageTeamCodes'
]
const flagCaptions = {
  isSuperUser: 'Is superuser',
  canAdminUsers: 'Can administer users',
  canEditRestrictedAttributes: 'Has full access to attributes',
  canManageTeamCodes: 'Can manage team codes'
}
