import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'
import { generateClientId } from 'shared/helpers/Id'

const IdeaDefaultValues = {
  clientId: NotNullOrUndefined,
  userId: NotNullOrUndefined,
  title: '',

  likeCount: 0,
  commentCount: 0,
  colorId: null,

  isDeleted: false,
  anonymous: false,
  status: 'not-acknowledged',
  attachedBoardClientId: null,
  permissionsExplicitlySet: false,

  createdAt: NotNullOrUndefined,
  updatedAt: NotNullOrUndefined,
}

type IdeaRawType = {
  clientId: string
  userId: number
  title: string

  likeCount: number
  commentCount: number
  colorId: number | null

  isDeleted: boolean
  anonymous: boolean
  status: string // TODO: Use the existing enum
  attachedBoardClientId: string | null
  permissionsExplicitlySet: boolean

  createdAt: Date
  updatedAt: Date
}

export type IdeaSourceObjectType = Partial<IdeaRawType> & {
  created_at?: string
  updated_at?: string
}

class IdeaRecord extends CreateRecordClass<IdeaRawType>(IdeaDefaultValues, "Idea") {
  getShortTitle(this: IdeaRecord, numChars: number) {
    if (this.title.length < numChars) {
      return this.title
    }

    const shortTitle = this.title.substring(0, numChars)
    const finalWordBreakRe = /\s+\w*$/
    return shortTitle.split(finalWordBreakRe)[0]
  }

  statusAsOrderedNumeric(this: IdeaRecord) {
    switch (this.status) {
      case 'not-acknowledged': return 0
      case 'acknowledged': return 1
      case 'in-progress': return 2
      case 'done': return 3
    }
    console.warn("Invalid status", this.toJS())
    return 4
  }

  matchesFilter(filteredClientIds: string[]) {
    return !filteredClientIds || filteredClientIds.indexOf(this.clientId) !== -1
  }
}

type IdeaRecordT = IdeaRecord
export { IdeaRecordT as IdeaRecord }

const ideaAttributesOverwriteObject = { clientId: '', status: '', likeCount: 0, commentCount: 0, createdAt: new Date(), updatedAt: new Date() }
const ideaEmpty = { userId: null, title: '', colorId: null, isDeleted: false, anonymous: false, attachedBoardClientId: null, permissionsExplicitlySet: false }
const createIdea = (stub: IdeaSourceObjectType) => {
  Object.assign(ideaAttributesOverwriteObject, ideaEmpty, stub)
  ideaAttributesOverwriteObject.clientId = stub.clientId || generateClientId()
  ideaAttributesOverwriteObject.status = stub.status || 'not-acknowledged'
  ideaAttributesOverwriteObject.likeCount = stub.likeCount || 0
  ideaAttributesOverwriteObject.commentCount = stub.commentCount || 0
  ideaAttributesOverwriteObject.createdAt = new Date(stub.createdAt || stub.created_at || Date.now() as any)
  ideaAttributesOverwriteObject.updatedAt = new Date(stub.updatedAt || stub.updated_at || Date.now() as any)
  return new IdeaRecord(ideaAttributesOverwriteObject)
}

const Idea = Object.assign(
  (stub: IdeaSourceObjectType | IdeaRecord = {}): IdeaRecord => {
    if(!Idea.isIdea(stub)) {
      stub = stub as IdeaSourceObjectType
      return createIdea(stub)
    }
    return new IdeaRecord(stub)
  },
  {
    isIdea: (obj): obj is IdeaRecord => (obj instanceof IdeaRecord),
    createIdea,
  }
)

export default Idea;
