import * as memoize from 'shared/helpers/memoize'
import apiCall from 'helpers/apiCall'

import { AttributeValues } from 'shared/models/AttributeValue'

import { AppStateRecord } from 'appRoot/state'

const _getAttributeValuesByIdeaClientId = (state: AppStateRecord) => state.get('attributeValuesByIdeaClientId')

export const AttributeValueSelectors = {
  getAttributeValuesOfIdea: memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (state: AppStateRecord, ideaClientId: string) => ({
      attributeValuesByIdeaClientId: _getAttributeValuesByIdeaClientId(state),
      ideaClientId
    }),
    slottingFunction: ({ ideaClientId }) => ideaClientId,
    calculateResult: ({ attributeValuesByIdeaClientId, ideaClientId }): AttributeValues | null => {
      return attributeValuesByIdeaClientId.get(ideaClientId, null)
    }
  }),

  // The following two selectors similarily to the IdeaSearchBar/selectors.ts select
  // server-side data through api calls an don't rely on the app state at all
  // This is done because this data doesn't need to be shared - it's needed only in small amount of components
  ideaHasAttributes: memoize.memoizeValueForRecentArguments(
    async (ideaClientId: string): Promise<boolean> => {
      return await apiCall({
        path: `/attributeValues/ideaHasAttributes/${ideaClientId}`
      })
    }
  ),

  getAllAvailableAttributeValues: memoize.memoizeValueForRecentArguments(
    async (attributeClientId: string): Promise<{ values: any[] }> => {
      return await apiCall({
        path: `/attributeValues/allAvailableAttributeValues/${attributeClientId}`
      })
    }
  ),
}