import { Record, Map, Set } from 'immutable'

import initialPathEquivalencesState from './submenus/LeftSidebar/PathEquivalences/state'

import * as FixedPointsPanZoom from './FixedPointsPanZoom'
import {ModuleStateHelpers} from 'helpers/ModuleState'

import { DocumentRecord } from 'shared/models/Document'
import { ColorRule } from 'shared/models/ColorRule'
import AffineTransform from 'shared/helpers/AffineTransform'
import { AppStateRecord } from 'appRoot/state'

const graphViewDefaults = {
  modalText: undefined as any,
  shortestDag: Map({}) as any,

  canvasClientRect: { width: 0, height: 0, left: 0, top: 0 } as { width: number, height: number, left: number, top: number },
  centralCanvasPartClientRectangle: undefined as { width: number, height: number, left: number, top: number } | undefined,

  activeDocument: undefined as DocumentRecord | undefined,
  adHocDocument: undefined as DocumentRecord | undefined,

  colorRulesResults: {
    isPending: true as boolean,

    // TODO: Those two are really only needed to prevent the recomputeColorRulesOverridesIfNeedBe action
    // from requesting the data too often. Consider making them private to that action
    colorRulesInOrder: [] as ColorRule[],
    visibleIdeaClientIds: {} as { [ideaClientId: string]: boolean },

    matchedIdeaClientIdsByColorRuleId: {} as { [colorRuleId: string]: string[] }
  },

  // TODO: move all of the following props to the graph-view/mode
  isRectSelecting: false as boolean,
  isConnecting: false as boolean,
  isDragging: false as boolean,
  isPanning: false as boolean,
  isMerging: false as boolean,
  isExpanding: false as boolean,

  evolveTheActiveGraphLayout: false as boolean,

  //simulationState: undefined as any,

  rectSelectionStartPosition: undefined as any,
  rectSelectionCurrentPosition: undefined as any,

  panStartPoint: undefined as any,
  panStartImageTransform: undefined as any,

  dynamicImageTransform: undefined as AffineTransform | undefined,

  hoveredIdeaInstanceId: null as string | null,

  pathIdeasClientIds: Set() as Set<string>,
  pathConnectionsClientIds: Set() as Set<string>,

  firstIdeaClientId: null as string | null,
  firstIdeaInstanceId: null as string | null,

  draggedIdeaInstanceId: null as string | null,
  draggedIdeaInstanceInitialPosition: undefined as any,
  draggingStartPosition: undefined as any,

  hoveredConnectionInstanceId: null as string | null,
  hoveredConnectionClientId: null as string | null,
  hoveredSidebarIdeaClientId: null as string | null,

  selectedConnectionInstanceId: null as string | null,
  selectedConnectionClientId: null as string | null,

  showEdgeLabels: true as boolean,
  panStartFixedPointsById: Map() as Map<string, any>,
  fixedPointsPanZoomState: FixedPointsPanZoom.defaultFixedPointsPanZoomState as any,

  cursorPositionInGraphView: null as any,

  mouseInGraphView: false as boolean,

  usingTransientDocument: false as boolean,

  ...initialPathEquivalencesState,
}

class GraphViewState extends Record(graphViewDefaults) {}

const initialGraphViewState = new GraphViewState()

export type GraphViewStateRecord = GraphViewState

export type GraphViewRawType = typeof graphViewDefaults

const graphViewModuleState = ModuleStateHelpers.createGraphViewState<GraphViewRawType>(graphViewDefaults)

// TODO: replace with use of specific submodule states
const getInGraphView = <K extends keyof GraphViewRawType>(state: AppStateRecord, fieldName: K): GraphViewRawType[K] => {
  return graphViewModuleState.getPart(state, fieldName)
}

export {initialGraphViewState, getInGraphView}
