import {
  PUSH_COLORS
} from './constants'

import Color from 'shared/models/Color'

import { AppStateRecord } from 'appRoot/state'

export default (reducer) => {

  reducer.handle(PUSH_COLORS,  (state: AppStateRecord, action) => {
    return state.withMutations(function (st) {
      action.colors.forEach((color) => {
        st.setIn(
          ['colors', color.id],
          Color(color)
        )
      })
    })
  })

  // reducer.handle(DELETE_LABEL,  (state,action) => {
  //   return state.deleteIn(['labels', action.label.clientId])
  // })

}
