import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import parseQuery from 'parse-query'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import { BoardActions, AdditionalBoardFetchParameters } from 'domain/Board/actions'

import { AllViewTypes } from 'config/appConfig'

type BoardViewTypeAndSlugReporterExternalProps<ComponentClass> = {
  Component: ComponentClass,
  viewType: AllViewTypes
  slug?: string,
  // The child component wants to trigger data fetching by itself with additional data
  componentTriggersFetches?: boolean
  passDataFetchPromise?: boolean
} & RouteComponentProps<{ slug: string }>

type BoardViewTypeAndSlugReporterProps<ComponentClass> = BoardViewTypeAndSlugReporterExternalProps<ComponentClass>
  & BoundActionGroups<typeof BoardViewTypeAndSlugReporterActionGroups>

type BoardViewTypeAndSlugReporterState = {
  dataFetchPromise: Promise<any> | null
}

class BoardViewTypeAndSlugReporter<ComponentClass extends React.ComponentClass<{}>> extends React.Component<BoardViewTypeAndSlugReporterProps<ComponentClass>, BoardViewTypeAndSlugReporterState> {
  constructor(props) {
    super(props)
    this.state = { dataFetchPromise: null }
  }

  render() {
    const queryParameters = parseQuery(this.props.location.search)
    // slugChanged prop is passed in order to separate the component-specific loading from board-specific loading
    return <this.props.Component
      ref={this.componentRef}
      dataFetchPromise={this.props.passDataFetchPromise ? this.state.dataFetchPromise : null}
      dataFetcher={this.dataFetcher}
      params={this.props.match.params}
      {...queryParameters}
    />
  }

  private readonly componentRef = React.createRef<ComponentClass>()
  private dataFetcher: (additionalData?: AdditionalBoardFetchParameters) => Promise<void> = this.createDataFetcher(this.props)

  // This component re-mounts every time the view changes, for example graph -> list.
  componentWillMount() {
    if (!this.props.componentTriggersFetches) {
      this.dataFetcher()
    }
  }
  componentWillReceiveProps(nextProps: BoardViewTypeAndSlugReporterProps<ComponentClass>) {
    this.dataFetcher = this.createDataFetcher(nextProps, this.props)
    if (!this.props.componentTriggersFetches) {
      this.dataFetcher()
    }
  }

  private createDataFetcher(
    nextProps: BoardViewTypeAndSlugReporterProps<ComponentClass>,
    prevProps?: BoardViewTypeAndSlugReporterProps<ComponentClass>
  ) {
    return async (additionalData?: AdditionalBoardFetchParameters) => {
      const slugChanged = !prevProps || this.getSlug(nextProps) !== this.getSlug(prevProps)
      if (!slugChanged && additionalData === undefined) return;
      const dataFetchPromise = this.props.BoardActions.setBoardSlugAndViewType(
        this.getSlug(nextProps),
        nextProps.viewType,
        additionalData
      )
      this.setState({ dataFetchPromise })
      await dataFetchPromise
    }
  }

  private getSlug(props: BoardViewTypeAndSlugReporterProps<ComponentClass>) {
    return props.slug !== undefined ? props.slug : props.match.params.slug
  }
}

const BoardViewTypeAndSlugReporterActionGroups = {
  BoardActions,
}
export default ComponentHelpers.createConnectedComponent<
  BoardViewTypeAndSlugReporterExternalProps<any>,
  BoardViewTypeAndSlugReporterExternalProps<any>,
  typeof BoardViewTypeAndSlugReporterActionGroups
>({
  actionGroups: BoardViewTypeAndSlugReporterActionGroups,
  mapStateToProps: (_state, ownProps) => ownProps,
  component: BoardViewTypeAndSlugReporter
})