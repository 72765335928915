import * as types from './constants'

import { AppStateRecord } from 'appRoot/state'

export default (reducer) => {
  reducer.handle(types.LOGIN_FAILED, (state: AppStateRecord, action) => {
    return state
      .set('loginError', action.error)
  })

  reducer.handle(types.LOGIN_REQUIRED, (state: AppStateRecord, action) => {
    return state
      .delete('loginError')
      .set('loginRedirect', action.loginRedirect)
  })

  reducer.handle(types.LOGIN_VERIFIED, (state: AppStateRecord) => {
    return state
      .delete('loginError')
      .delete('loginRedirect')
  })


  reducer.handle(types.LOGOUT,  (state: AppStateRecord) => {
    return state
      .delete('loginError')
      .delete('loginRedirect')
  })

}
