// Deduplicate this with the IdeaSearchBar/index.tsx

import * as React from 'react'

import createUserProfileSearchFactory, { Suggestion } from './selectors'
import { ComponentHelpers } from 'helpers/Component'
import AutocompleteSearchBar, { AutocompleteSearchBarProps } from '../AutocompleteSearchBar'
import { ModalHelpers, ModalsClass } from 'helpers/Modal'

interface OuterProps {
  searchField?: "name" | "website" | "email" | "bio"

  omitInputStyling: AutocompleteSearchBarProps<Suggestion>["omitInputStyling"]
  bare?: AutocompleteSearchBarProps<Suggestion>["bare"]
  maxNumberOfResultsToDisplay: AutocompleteSearchBarProps<Suggestion>["maxNumberOfResultsToDisplay"]
  useInlineDropdown?: AutocompleteSearchBarProps<Suggestion>["useInlineDropdown"]
  overflowSide?: AutocompleteSearchBarProps<Suggestion>["overflowSide"]
  allowMultipleLines?: AutocompleteSearchBarProps<Suggestion>["allowMultipleLines"]
  placeholder: AutocompleteSearchBarProps<Suggestion>["placeholder"]

  exposeFocusHandler?: (config: { focus: () => void, isFocused: () => boolean }) => void

  onUserProfileSuggestionSelected: (userProfileSuggestion: Suggestion) => void
  onHighlightedSuggestionChange: (userProfileSuggestion: Suggestion) => void

  initialValue
  disabled
  autofocus
  hasNoInitialSelection

  onQueryChange?: (query: string) => void

  canAddNewIdea?: boolean
  additionalFilter?: (selectorParam: any) => boolean
  populateOnFocus?: boolean
  className?: string

  allowDefaultActions?: AutocompleteSearchBarProps<Suggestion>["allowDefaultActions"]
  onBackspace?: AutocompleteSearchBarProps<Suggestion>["onBackspace"]
  onDelete?: AutocompleteSearchBarProps<Suggestion>["onDelete"]
  onEnter?: AutocompleteSearchBarProps<Suggestion>["onEnter"]
  onTab?: AutocompleteSearchBarProps<Suggestion>["onTab"]
  onArrowUp?: AutocompleteSearchBarProps<Suggestion>["onArrowUp"]
  onArrowDown?: AutocompleteSearchBarProps<Suggestion>["onArrowDown"]
  onMouseOut?: AutocompleteSearchBarProps<Suggestion>["onMouseOut"]
}

type UserProfileSearchBarProps = {
  getSearchResults: AutocompleteSearchBarProps<Suggestion>["getSearchResults"]
} & OuterProps

class UserProfileSearchBarWithModals extends React.Component<UserProfileSearchBarProps & { modals: ModalsClass }> {
  handleSuggestionSelected = async (selectedSuggestion, _allCurrentSearchResults) => {
    if (selectedSuggestion.userProfile) {
      this.props.onUserProfileSuggestionSelected(selectedSuggestion)
    }
  }

  _onQueryChange = (newQuery) => {
    this.props.onQueryChange && this.props.onQueryChange(newQuery)
  }

  _onHighlightedSuggestionChange = (suggestion) => {
    this.props.onHighlightedSuggestionChange  && this.props.onHighlightedSuggestionChange(suggestion)
  }

  _getSuggestionTextParts = (suggestion) => {
    return { prefix: '', suffix: suggestion.currentBoardMembershipClientId === null ? "" : " (Already in board)" }
  }

  render() {
    return (
      <AutocompleteSearchBar
        SearchBarActions={{ performSearch: () => ({ fastResultsPromise: Promise.resolve(null), slowResultsPromise: Promise.resolve(null) }) }}
        getSearchResults={this.props.getSearchResults}
        placeholder={this.props.placeholder}
        exposeFocusHandler={this.props.exposeFocusHandler}
        handleSuggestionSelected={this.handleSuggestionSelected}
        handleOnChange={this._onQueryChange}
        handleOnHighlightedSuggestionChange={this._onHighlightedSuggestionChange}
        getSuggestionTextParts={this._getSuggestionTextParts}
        maxNumberOfResultsToDisplay={this.props.maxNumberOfResultsToDisplay}
        omitInputStyling={this.props.omitInputStyling}
        bare={this.props.bare}
        useInlineDropdown={this.props.useInlineDropdown}
        allowMultipleLines={false}
        className={this.props.className}
        initialValue={this.props.initialValue || ''}
        disabled={this.props.disabled}
        autofocus={this.props.autofocus}
        hasNoInitialSelection={this.props.hasNoInitialSelection}
        onMouseOut={this.props.onMouseOut}
        onBackspace={this.props.onBackspace}
        onDelete={this.props.onDelete}
        onEnter={this.props.onEnter}
        onTab={this.props.onTab}
        onArrowUp={this.props.onArrowUp}
        onArrowDown={this.props.onArrowDown}
        allowDefaultActions={this.props.allowDefaultActions}
      />
    )
  }
};

const UserProfileSearchBarInner = ModalHelpers.addModalsToComponent(UserProfileSearchBarWithModals)

const UserProfileSearchBar = ComponentHelpers.createConnectedComponent({
  actionGroups: {},
  mapStateToPropsFactory: () => {
    const searchFactory = createUserProfileSearchFactory()

    return (state, outerProps: OuterProps) => ({
      ...outerProps,
      getSearchResults: searchFactory({
        state,
        searchField: outerProps.searchField,
      })
    })
  },

  component: UserProfileSearchBarInner,
})

export default UserProfileSearchBar
