import Point, { PointClass } from './Point'
import { RectangleType } from './Rectangle'

export default class AffineTransform {
  static identityTransform = new AffineTransform(Point(0, 0), Point(1, 1))

  shiftVector: PointClass
  scaleVector: PointClass

  constructor (shiftVector: PointClass, scaleVector: PointClass) {
    this.shiftVector = shiftVector;
    this.scaleVector = scaleVector;
  }

  static fittingRectangleInRectangeLetterboxed = (rectInner: RectangleType, rectOutter: RectangleType) => {
    if (rectInner.aspect < rectOutter.aspect) {
      const marginLeftRight = (rectOutter.aspect - rectInner.aspect) * rectOutter.height / 2;
      return AffineTransform.fromTwoPointPairs(
          [rectInner.topLeftCorner, rectOutter.topLeftCorner.add(Point(marginLeftRight, 0))],
          [rectInner.bottomRightCorner, rectOutter.bottomRightCorner.sub(Point(marginLeftRight, 0))]
      )
    } else {
      const marginTopBottom = (1 / rectOutter.aspect - 1 / rectInner.aspect) * rectOutter.width / 2;
      return AffineTransform.fromTwoPointPairs(
          [rectInner.topLeftCorner, rectOutter.topLeftCorner.add(Point(0, marginTopBottom))],
          [rectInner.bottomRightCorner, rectOutter.bottomRightCorner.sub(Point(0, marginTopBottom))]
      )
    }
  }

  static fromPointPairAndScale = ([point1, point2]: [PointClass, PointClass], scale: PointClass) => {
    return new AffineTransform(point2.sub(point1.pointwiseMult(scale)), scale);
  }

  static fromTwoPointPairs = ([point1, point2]: [PointClass, PointClass], [point3, point4]: [PointClass, PointClass]) => {
    const scale = Point(
        (point4.x - point2.x) / (point3.x - point1.x),
        (point4.y - point2.y) / (point3.y - point1.y)
    )
    return AffineTransform.fromPointPairAndScale([point1, point2], scale);
  }

  static Hydrate(serialized: [[number, number], [number, number]]) {
    return new AffineTransform(
        Point.hydrate(serialized[0]),
        Point.hydrate(serialized[1])
    );
  }

  Serialize() {
    return [
      this.shiftVector.serialize(),
      this.scaleVector.serialize()
    ];
  }

// First scale then shift
  Transform(point: PointClass) {
    const scaledPoint = Point(point.x * this.scaleVector.x, point.y * this.scaleVector.y);
    const scaledShiftedPoint = Point(scaledPoint.x + this.shiftVector.x, scaledPoint.y + this.shiftVector.y);
    return scaledShiftedPoint;
  }

  Reverse() {
    const reverseShiftVector = Point(-this.shiftVector.x / this.scaleVector.x, -this.shiftVector.y / this.scaleVector.y);
    const reverseScaleVector = Point(1 / this.scaleVector.x, 1 / this.scaleVector.y);
    return new AffineTransform(
        reverseShiftVector,
        reverseScaleVector
    )
  }

  Translate(delta: PointClass) {
    const newShiftVector = Point(this.shiftVector.x + delta.x, this.shiftVector.y + delta.y);
    return new AffineTransform(
        newShiftVector,
        this.scaleVector
    )
  }

  ScaleOrigin(ratioVector: PointClass) {
    return new AffineTransform(
        Point(this.shiftVector.x * ratioVector.x, this.shiftVector.y * ratioVector.y),
        Point(this.scaleVector.x * ratioVector.x, this.scaleVector.y * ratioVector.y)
    )
  }

  Scale(centerVector: PointClass, ratioVector: PointClass) {
    return this
        .Translate(centerVector.mult(-1))
        .ScaleOrigin(ratioVector)
        .Translate(centerVector);
  }

  toTransformString(measureUnit: string) {
    const shiftVector = this.shiftVector
    const scaleVector = this.scaleVector
    return `translate(${shiftVector.x}${measureUnit},${shiftVector.y}${measureUnit}) scale(${scaleVector.x},${scaleVector.y})`;
  }

  interpolateTo (otherTransform: AffineTransform, interpolationDegree: number) {
    return new AffineTransform(
      this.shiftVector.interpolateTo(otherTransform.shiftVector, interpolationDegree),
      this.scaleVector.interpolateTo(otherTransform.scaleVector, interpolationDegree),
    )
  }
}
