import { Permission, PermissionResourceType, StandardPrivacyActor, OneResourceType } from "shared/models/Permission"

/**
 * Rather than setting permissions directly, we set permissions via
 * easy to use aliases, that map onto true permissions in the backend.
 *
 * Values are meant to be displayed on screen..
 */
export enum SelectablePermissionOption {
  CAN_READ = "Can Read",
  CAN_UPDATE = "Can Update",
  HIDDEN = "Hidden"
}

export type CustomStyles = {
  deleteButton?: string,
  permissionActorIcon?: string
  action?: string
  permissionsIcon?: string
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

/**
 * New permission that isn't ready to commit yet
 */
type PendingPermission<ResourceType extends PermissionResourceType> = Omit<Permission<OneResourceType<ResourceType>>, "actor"> & {
  actor: StandardPrivacyActor | null
}

export type EditedPermission<ResourceType extends PermissionResourceType> = {
  permission: Permission<OneResourceType<ResourceType>> | PendingPermission<ResourceType>,
  preexisting: boolean
}