import * as React from 'react'
import classnames from 'classnames'

import pluralize from 'helpers/pluralize'
import apiCall from 'helpers/apiCall'

import { ErrorHelpers } from 'shared/helpers/Error'
import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'
import { AuthActions } from 'auth/actions'

import appConfig from 'config/appConfig'

import Input from 'components/common/Input'

import styles from './AuthDialog.styl'

import { AppStateRecord } from 'appRoot/state'
import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'

import { UserProfileRecord } from 'shared/models/UserProfile'

interface OuterProps {
  closeSelf?: () => void
  initialAuthState?: AuthState
  disableSignup?: boolean
  disableLogin?: boolean
}

interface StateProps {
  loginError: string
  user: UserProfileRecord | null
}

const mapStateToProps = (state: AppStateRecord, outerProps: OuterProps): StateProps & OuterProps => ({
  loginError: state.get('loginError'),
  user: CurrentUserSelectors.getCurrentUserProfile(state),
  ...outerProps,
})

const actionGroups = {
  AuthActions,
}

interface State {
  accountState: AuthState
  internalError: string | null
  isFetchingStats: boolean
  userStats: {
    ideas: number
    comments: number
    ideaLikes: number
    commentLikes: number
  }
}

type ConnectedProps = OuterProps & BoundActionGroups<typeof actionGroups> & StateProps

export enum AuthState {
  FacebookLogin = "FacebookLogin",
  EmailLogin = "EmailLogin",
  EmailSignUp = "EmailSignUp",
  PasswordChange = "PasswordChange",
}

class AuthDialog extends React.Component<ConnectedProps, State> {

  private readonly loginFormRef: React.RefObject<HTMLFormElement> = React.createRef<HTMLFormElement>()
  private readonly signupFormRef: React.RefObject<HTMLFormElement> = React.createRef<HTMLFormElement>()
  private readonly passwordChangeFormRef: React.RefObject<HTMLFormElement> = React.createRef<HTMLFormElement>()

  constructor(props: ConnectedProps) {
    super(props)
    this.state = {
      accountState: props.initialAuthState || AuthState.FacebookLogin,
      internalError: null,
      isFetchingStats: false,
      userStats: {
        ideas: 0,
        comments: 0,
        ideaLikes: 0,
        commentLikes: 0
      }
    }
  }

  componentDidMount() {
    if (this.state.accountState === AuthState.EmailSignUp) {
      this.fetchUserStats()
    }
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    if (this.props.user === nextProps.user) return
    if (this.state.accountState === AuthState.EmailSignUp) {
      this.fetchUserStats()
    }
  }

  private switchToLogin = () => {
    this.setState({ accountState: AuthState.EmailLogin, internalError: null })
  }

  private switchToFacebookSignUp = () => {
    this.setState({ accountState: AuthState.FacebookLogin, internalError: null })
  }

  private switchToEmailSignUp = () => {
    this.setState({ accountState: AuthState.EmailSignUp, internalError: null })
    this.fetchUserStats()
  }

  async fetchUserStats() {
    if (!this.props.user || !this.props.user.isAnonymousUser()) return null

    this.setState({ isFetchingStats: true })

    try {
      const userStats = await apiCall({ path: `/userProfiles/currentUserStats` })
      this.setState({ isFetchingStats: false, userStats })
    } catch (e) {
      this.setState({ isFetchingStats: false })
    }
  }

  private handleFacebookLogin(e) {
    e.preventDefault();
    window.location.assign("/api/v1/auth/facebook")
  }

  private handleLogin = async (event) => {
    event.preventDefault()
    const loginForm = ErrorHelpers.castToNotNullOrThrow(
      this.loginFormRef.current,
      "loginForm was not rendered"
    )
    const formData = new FormData(loginForm)
    const { loginSuccess, isPasswordTemporary } = await this.props.AuthActions.login({
      email: formData.get("email"),
      password: formData.get("password"),
    })
    if (loginSuccess) {
      if (isPasswordTemporary) {
        this.setState({ accountState: AuthState.PasswordChange })
      } else {
        this.props.AuthActions.finalizeTheAuthOperations()
        this.props.closeSelf && this.props.closeSelf()
      }
    }
  }

  private handleSignup = async (event) => {
    event.preventDefault()
    const signupForm = ErrorHelpers.castToNotNullOrThrow(
      this.signupFormRef.current,
      "loginForm was not rendered"
    )
    const formData = new FormData(signupForm)
    if (formData.get("password1") !== formData.get("password2")) {
      this.setState({ internalError: "Passwords don't match" })
      return
    }
    try {
      await this.props.AuthActions.regularSignup({
        shouldMerge: formData.get("shouldMerge") === "true",
        email: formData.get("email"),
        password: formData.get("password1"),
      })
      this.props.AuthActions.finalizeTheAuthOperations()
      this.props.closeSelf && this.props.closeSelf()
    } catch(err) {
      this.setState({ internalError: err.message })
    }
  }

  private handlePasswordChange = async (event) => {
    event.preventDefault()
    const passwordChangeForm = ErrorHelpers.castToNotNullOrThrow(
      this.passwordChangeFormRef.current,
      "passwordChangeForm was not rendered"
    )
    const formData = new FormData(passwordChangeForm)

    if (formData.get("newPassword1") !== formData.get("newPassword2")) {
      this.setState({ internalError: "Passwords don't match" })
      return
    }
    try {
      await this.props.AuthActions.changePassword({
        currentPassword: formData.get("currentPassword"),
        newPassword: formData.get("newPassword1"),
      })
      this.props.AuthActions.finalizeTheAuthOperations()
      this.props.closeSelf && this.props.closeSelf()
    } catch(err) {
      this.setState({ internalError: err.message })
    }
  }

  private renderFacebookLogin = () => {
    if (!appConfig.features.enableFacebookLogin) {
      return this.renderEmailLogin()
    }
    return (
      <div className={styles.modal}>
        <div className={classnames(styles.loginForm)}>
          { this.renderPossibleError() }
          <button onClick={this.handleFacebookLogin} className={classnames(styles.facebookButton)}>
            Continue with Facebook
          </button>
        </div>
        <div className={classnames(styles.otherOptions)}>
        <span><a href="#" onClick={() => this.switchToLogin()}>Login with email</a></span>
        </div>
      </div>
    )
  }

  private renderEmailLogin = () => {
    return (
      <div className={classnames(styles.modal, styles.emailSignupModal)}>
        { appConfig.features.enableFacebookLogin && <a className={styles.backButton} href="#" onClick={this.switchToFacebookSignUp}>&lt; Back</a> }
        <div className={classnames(styles.loginForm)}>
          <form ref={this.loginFormRef} onSubmit={this.handleLogin}>
            <Input className={classnames(styles.loginInput, styles.emailSignupInput)} size="small" type="email" name="email" placeholder="Email" focusOnMount={true} />
            <Input className={classnames(styles.loginInput, styles.passwordLoginInput)} size="small" type="password" name="password" placeholder="Password"  />
            { appConfig.features.enableUserRegistration && (!this.props.disableSignup)
              ? <div className={styles.toggleText}>
                  <a href="#" onClick={this.switchToEmailSignUp}>Create account</a>
                </div>
              : <div className={styles.toggleText}>&nbsp;</div>
            }
            { this.renderPossibleError() }
            <button className={styles.loginButton}>Login to IdeaFlow</button>
          </form>
        </div>
      </div>
    )
  }

  private renderEmailSignUp = () => {
    const { ideas, comments, ideaLikes, commentLikes } = this.state.userStats
    console.log(this.state.userStats)
    return (
      <div className={classnames(styles.modal, styles.emailSignupModal)}>
        { !this.props.disableLogin && <div className={styles.topOptions}>
          <a className={styles.backButton} href="#" onClick={this.switchToLogin}>&lt; Back</a>
        </div> }

        <div className={classnames(styles.loginForm)}>
          <form ref={this.signupFormRef} onSubmit={this.handleSignup}>
            <Input className={classnames(styles.loginInput, styles.emailSignupInput)} size="small" type="email" name="email" placeholder="Email" focusOnMount={true}/>
            <Input className={classnames(styles.loginInput, styles.splitLeftInput)} size="small" type="password" name="password1" placeholder="Password" />
            <Input className={classnames(styles.loginInput, styles.splitRightInput)} size="small" type="password" name="password2" placeholder="Confirm Password" />
            <div className={styles.toggleText}>&nbsp;</div>
            { this.renderPossibleError() }
            { ideas + comments + ideaLikes + commentLikes >= 1 &&
              <>
                <p className={styles.accountMergeMessage}>
                  Do you want to associate
                  { ideas > 0 && <span> {pluralize('post', ideas)} </span> }
                  { ideas + comments > 1 && ", " }
                  { comments > 0 && <span> {pluralize('comment', comments)} </span> }
                  { ideas > 0 && ideaLikes + commentLikes > 0 && " and " }
                  { ideaLikes + commentLikes > 0 && <span> {pluralize('like', ideaLikes + commentLikes) } </span> }
                  made anonymously during this browser session with your new account?
                </p>
                <div className={styles.radioButtonContainer}>
                  <input type="radio" name="shouldMerge" value="true"/>
                  Yes
                </div>
                <div className={styles.radioButtonContainer}>
                  <input type="radio" name="shouldMerge" value="false" defaultChecked />
                  No
                </div>
              </>
            }
            <button className={styles.loginButton} disabled={this.state.isFetchingStats}>
              Sign up for IdeaFlow
            </button>
          </form>
        </div>
      </div>
    )
  }

  private renderPasswordChange = () => {
    return (
      <div className={classnames(styles.modal, styles.emailSignupModal)}>
        <div className={classnames(styles.loginForm)}>
          <form ref={this.passwordChangeFormRef} onSubmit={this.handlePasswordChange}>
            <Input className={classnames(styles.loginInput, styles.emailSignupInput)} size="small" type="password" name="currentPassword" placeholder="Old temporary password" focusOnMount={true}/>
            <Input className={classnames(styles.loginInput, styles.splitLeftInput)} size="small" type="password" name="newPassword1" placeholder="Password" />
            <Input className={classnames(styles.loginInput, styles.splitRightInput)} size="small" type="password" name="newPassword2" placeholder="Confirm Password" />
            <div className={styles.toggleText}>&nbsp;</div>
            { this.renderPossibleError() }
            <button className={styles.loginButton}>Set a new password</button>
          </form>
        </div>
      </div>
    )
  }

  private renderPossibleError = () => {
    const { internalError } = this.state
    const error = internalError || this.props.loginError

    return <span className={styles.error}>{error}</span>
  }

  render() {
    // The renderXYZ methods are not called directly in order to make sure
    // the DOM is never shared after transitioning from one accountState to another
    switch(this.state.accountState) {
      case AuthState.FacebookLogin:
        return <this.renderFacebookLogin />
      case AuthState.EmailLogin:
        return <this.renderEmailLogin />
      case AuthState.EmailSignUp:
        return <this.renderEmailSignUp />
      case AuthState.PasswordChange:
        return <this.renderPasswordChange />
      default:
        return <this.renderFacebookLogin />
    }
  }
}

export default ComponentHelpers.createConnectedComponent<OuterProps, OuterProps, typeof actionGroups>({
  actionGroups,
  mapStateToProps,
  component: AuthDialog
})
