import * as React from 'react'

import { ComponentHelpers, BoundActionGroups } from "helpers/Component"
import { BoardActions } from "domain/Board/actions"
import { NavigationActions } from "navigation/actions"
import { AllViewTypes } from 'config/appConfig';
import styles from "components/graph-view/GraphView.styl";

interface OuterProps {
  viewTypeSlug: AllViewTypes,
  ideaClientId: string,
}

interface StateProps {
  viewType: AllViewTypes
}

type Props = OuterProps & StateProps & BoundActionGroups<typeof actionGroups>

class BoardOfIdea extends React.Component<Props> {
  componentDidMount() {
    const {
      BoardActions,
      ideaClientId,
      viewTypeSlug,
      viewType
    } = this.props
    if (viewTypeSlug !== viewType) {
      BoardActions.setViewType(viewTypeSlug)
    }
    BoardActions.fetchAndEnterBoardOfIdea(ideaClientId)
  }

  render() {
    return (
       <div className={styles.notReadyToRenderWarning}>
         <div className={styles.logoContainer}>
           <img src={require('../../media/icons/logo.svg')} className={styles.logo} />
           <div>Redirecting...</div>
         </div>
       </div>
    )
  }
}

const actionGroups = {
  BoardActions,
  NavigationActions,
}

const ConnectedComponent = ComponentHelpers.createConnectedComponent<
  OuterProps, StateProps, typeof actionGroups
>({
  actionGroups,
  mapStateToProps: (state, outerProps): StateProps => {
    return {
      ...outerProps,
      viewType: state.get('viewType'),
    }
  },
  component: BoardOfIdea,
})

export default ConnectedComponent
