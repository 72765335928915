import * as React from 'react'
import classnames from 'classnames'

import { UserProfileRecord } from 'shared/models/UserProfile'
import { BoardSummary } from 'shared/models/BoardSummary'

import * as BoardSelectors from "domain/Board/selectors"

import { UserProfileActions } from 'domain/UserProfile/actions'
import { NavigationActions } from 'navigation/actions'
import { AuthActions } from 'auth/actions'
import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import { ErrorHelpers } from 'shared/helpers/Error'

import Icon from 'components/common/Icon'
import BoardInfo from './BoardInfo'

import styles from './UserProfileBox.styl'

import Avatar from 'components/common/Avatar'

interface EditableUserProfile {
  name: string
  email: string
  website: string
  bio: string
  isEmailPublic: boolean
}

interface UserProfileBoxProps {
  user: UserProfileRecord
  isUserProfileForCurrentUser?: boolean
  className?: string
}

interface State {
  isEditing: boolean
  isChangingPassword: boolean
  isChangingUsername: boolean
  passwordChangeError: string | null
  usernameChangeError: string | null
  editableColumns: EditableUserProfile
}

const actionGroups = {
  NavigationActions,
  UserProfileActions,
  AuthActions,
}

type UserProfileBoxStateProps = UserProfileBoxProps & {
  boardSummary: BoardSummary
}

type UserProfileBoxPropsFinal = UserProfileBoxStateProps & BoundActionGroups<typeof actionGroups>

class UserProfileBox extends React.Component<UserProfileBoxPropsFinal, State> {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      isChangingPassword: false,
      isChangingUsername: false,
      passwordChangeError: null,
      usernameChangeError: null,
      editableColumns: {
        name: this.props.user.name,
        website: this.props.user.website,
        email: this.props.user.email,
        bio: this.props.user.bio,
        isEmailPublic: this.props.user.isEmailPublic,
      }
    }
  }

  componentDidUpdate(_prevProps: UserProfileBoxPropsFinal, prevState: State) {
    this._tryFocusing(prevState)
  }

  private _tryFocusing(prevState: State) {
    if(this.state.isEditing && !prevState.isEditing) {
      this.nameInput && this.nameInput.focus()
    }
  }

  private startEditing = () => {
    this.setState({
      isEditing: true,
      isChangingPassword: false,
      editableColumns: {
        name: this.props.user.name,
        website: this.props.user.website,
        email: this.props.user.email,
        bio: this.props.user.bio,
        isEmailPublic: this.props.user.isEmailPublic,
      }
    })
  }

  private startChangingPassword = () => {
    this.setState({ isChangingPassword: true })
  }

  private startChangingUsername = () => {
    this.setState({ isChangingUsername: true })
  }

  private saveChanges = () => {
    this.props.UserProfileActions.updateUser(this.props.user.userId, this.state.editableColumns)
    this.setState({ isEditing: false })
  }

  private cancelEditing = () => {
    this.setState({ isEditing: false })
  }

  private confirmPasswordChange = async () => {
    const passwordForm = ErrorHelpers.castToNotNullOrThrow(this.passwordForm, "Password form is unavailable")
    const formData = new FormData(passwordForm)

    if(formData.get("newPassword1") !== formData.get("newPassword2")) {
      this.setState({ passwordChangeError: "Passwords don't match" })
      return
    }
    try {
      await this.props.AuthActions.changePassword({
        currentPassword: formData.get("currentPassword"),
        newPassword: formData.get("newPassword1"),
      })
      this.cancelPasswordChange()
    } catch(err) {
      this.setState({ passwordChangeError: err.message })
    }
  }

  private confirmUsernameChange = async () => {
    if(!this.newUsernameInput) return
    const newUsername = this.newUsernameInput.value

    try {
      await this.props.AuthActions.changeUsername({ newUsername })
      this.cancelUsernameChange()
    } catch(err) {
      this.setState({ usernameChangeError: err.message })
    }
  }

  private cancelPasswordChange = () => {
    this.setState({ isChangingPassword: false, passwordChangeError: null })
  }

  private cancelUsernameChange = () => {
    this.setState({ isChangingUsername: false, usernameChangeError: null })
  }

  private setEditableColumnValue = <T extends keyof EditableUserProfile>(propName: T) => (newValue: EditableUserProfile[T]) => {
    this.setState({ editableColumns: { ...this.state.editableColumns, [propName]: newValue } })
  }

  private renderIsEmailPublicCheckbox(isDisabled) {
    return (<div className={styles.checkboxLabel} >
      <input
        type='checkbox'
        checked={this.state.editableColumns.isEmailPublic ? true : false}
        onChange={ () => !isDisabled && this.setEditableColumnValue("isEmailPublic")(!this.state.editableColumns.isEmailPublic) }
        disabled={isDisabled}
      />
      Display email publicly
    </div>)
  }

  private renderUserProfile() {
    const hideEmail = !this.props.user.isEmailPublic && !this.props.isUserProfileForCurrentUser
    const { website } = this.props.user
    const websiteWithProtocol = /:\/\//.test(website) ? website : 'https://' + website

    return <div className={styles.content}>
      <div
        className={styles.header}
        onClick={() => this.props.NavigationActions.navigateToUserProfile(this.props.user.userId)}
      >
        <Avatar className={styles.avatar} href={this.props.user.avatar} tooltip='User Avatar' />
        <div className={styles.name}>{this.props.user.getDisplayName()}</div>
      </div>

      <div className={styles.fields}>
        <div className={styles.label}>Email</div>
        {this.props.user.email
          ? <div className={classnames(styles.field, hideEmail && styles.privateField)}>{hideEmail ? 'Private' : this.props.user.email}</div>
          : <div className={styles.missingField}>Not provided</div>
        }
        { this.props.isUserProfileForCurrentUser && this.renderIsEmailPublicCheckbox(true) }
        <div className={styles.label}>Website</div>
        {this.props.user.website
          ? <div className={styles.field}><a href={websiteWithProtocol} target="_blank">{this.props.user.website}</a></div>
          : <div className={styles.missingField}>Not provided</div>
        }
        <div className={styles.label}>Interests</div>
        {this.props.user.bio
          ? <div className={styles.bio}>{this.props.user.bio}</div>
          : <div className={styles.missingBioField}>Not provided</div>
        }
      </div>

      {this.isEditable && <div className={styles.links}>
        <div className={styles.editLink} onClick={this.startEditing}>Edit Profile</div>
      </div>}
      {this.isEditable && <BoardInfo boardSummary={this.props.boardSummary} />}
    </div>
  }

  private get isEditable() {
    return this.props.isUserProfileForCurrentUser || this.props.user.status === 'anon'
  }

  private passwordForm: HTMLFormElement | null = null

  private renderUserPasswordChange = () =>
    <form ref={passwordForm => this.passwordForm = passwordForm}>
      <div className={styles.label}>Current password</div>
      { this.state.passwordChangeError !== null &&
          <div className={styles.error}>Error: {this.state.passwordChangeError}</div>
      }
      <input
        name="currentPassword"
        type="password"
        className={styles.input}
      />
      <div className={styles.label}>New password</div>
      <input
        name="newPassword1"
        type="password"
        className={styles.input}
      />
      <div className={styles.label}>Repeat new password</div>
      <input
        name="newPassword2"
        type="password"
        className={styles.input}
      />
      <div className={styles.links}>
        <div className={styles.editLink} onClick={this.cancelPasswordChange}>Cancel</div>
        <div className={styles.editLink} onClick={this.confirmPasswordChange}>Change password</div>
      </div>
    </form>

  private newUsernameInput: HTMLInputElement | null = null
  private renderUsernameChange = () =>
    <>
      <p>
        Your current username is {this.props.user.get("username")}.
      </p>
      <p style={{color: 'red'}}>
        Changing it will prevent links that currently lead to your user profile page from working.
      </p>
      { this.state.usernameChangeError !== null &&
          <div className={styles.error}>Error: {this.state.usernameChangeError}</div>
      }
      <input
        ref={newUsernameInput => this.newUsernameInput = newUsernameInput}
        defaultValue={this.props.user.get("username")}
      />
      <div className={styles.links}>
        <div className={styles.editLink} onClick={this.cancelUsernameChange}>Cancel</div>
        <div className={styles.editLink} onClick={this.confirmUsernameChange}>Change username</div>
      </div>
    </>

  private renderEditingProfile() {
    return <>
      <div className={styles.header}>
        <Icon className={styles.avatar} fontAwesomeIcon='user-circle-o' tooltip='User Avatar' />
      </div>

      <div className={styles.label}>Name</div>
      <input
        ref={nameInput => this.nameInput = nameInput}
        value={this.state.editableColumns.name}
        onChange={ (event) => this.setEditableColumnValue('name')(event.target.value) }
        className={styles.input}
      />

      <div className={styles.label}>Email</div>
      <input value={this.state.editableColumns.email} onChange={ (event) => this.setEditableColumnValue('email')(event.target.value) } className={styles.input} />
      { this.renderIsEmailPublicCheckbox(false) }

      <div className={styles.label}>Website</div>
      <input value={this.state.editableColumns.website} onChange={ (event) => this.setEditableColumnValue("website")(event.target.value) } className={styles.input} />

      <div className={styles.label}>Interests</div>
      <textarea value={this.state.editableColumns.bio} onChange={ (event) => this.setEditableColumnValue("bio")(event.target.value) } className={styles.textarea} />

      { this.isEditable && this.props.isUserProfileForCurrentUser && this.props.user.status !== "anon" &&
        <>
          <div className={styles.editLink} onClick={this.startChangingPassword}>Change password</div>
          <div className={styles.editLink} onClick={this.startChangingUsername}>Change username</div>
        </>}

      <div className={styles.links}>
        <div className={styles.editLink} onClick={this.cancelEditing}>Cancel</div>
        <div className={styles.editLink} onClick={this.saveChanges}>Save Changes</div>
      </div>
    </>
  }

  private nameInput: HTMLInputElement | null

  render() {
    let child
     if(this.state.isChangingPassword) {
      child = this.renderUserPasswordChange()
    } else if(this.state.isChangingUsername) {
      child = this.renderUsernameChange()
    } else if(this.state.isEditing) {
      child = this.renderEditingProfile()
    } else {
      child = this.renderUserProfile()
    }
    return <div className={classnames(styles.userProfileBox, this.props.className)}>
      {child}
    </div>
  }
}

export default ComponentHelpers.createConnectedComponent<UserProfileBoxProps, UserProfileBoxStateProps, typeof actionGroups> ({
  actionGroups,
  mapStateToProps: (state, outerProps) => ({
    ...outerProps,
    boardSummary: BoardSelectors.getAllBoardSummariesByBoardClientIds(state).get(outerProps.user.attachedBoardClientId)
  }),
  component: UserProfileBox,
})
