import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { Manager, Reference, Popper } from 'react-popper'
import ResizeDetector from 'react-resize-detector'

import styles from './Dropdown.styl'

export type OverflowSide = "left" | "right"

interface DropdownProps {
  show: boolean
  contentsRenderer: () => React.ReactChild
  useInlineDropdown?: boolean
  overflowSide?: OverflowSide
}

class Dropdown extends React.Component<DropdownProps, { tetherContainerMinWidth: string }> {
  constructor (props: DropdownProps) {
    super(props)

    this.state = {
      tetherContainerMinWidth: "0px"
    }
  }

  private tetherTarget: HTMLElement
  componentWillMount() {
    this.tetherTarget = document.body.appendChild(document.createElement("div"))
  }

  componentWillUnmount() {
    document.body.removeChild(this.tetherTarget)
  }

  render() {
    return <Manager>
      <Reference>
        {({ ref }) => <div ref={ref}>
          <ResizeDetector handleWidth onResize={this.handleResize} />
          {this.props.children}
        </div>}
      </Reference>
      {this.props.show && this._renderDropdownContents()}
    </Manager>
  }

  handleResize = (width: number) => {
    this.setState({
      tetherContainerMinWidth: width + "px"
    })
  }

  private _renderDropdownContents() {
    const dropdownContents = this.props.contentsRenderer()

    // This is a workaround currently needed for GraphVertex where regular detached popover
    // looses its position after resizing
    // so we just render inlined dropdown in that case
    if(this.props.useInlineDropdown) {
      return dropdownContents
    }
    return <Popper
      // placement="bottom-end" will align the dropdown top right corner with the
      // bottom right corner of the target, and therefore it will overflow to the left.
      // Similarily "bottom-start" overflows the dropdown to the right
      // https://popper.js.org/popper-documentation.html#Popper.placements
      placement={ this.props.overflowSide === "left" ? "bottom-end" : "bottom-start" }
      modifiers={{
        preventOverflow: { enabled: false },
        hide: { enabled: false },
        offset: { offset: '-1, 3' }
      }}
    >
      {({ style, ref }) => ReactDOM.createPortal(
        <div ref={ref} style={{ zIndex: 9999, ...style }} className={styles.tetherContainer}>
          <div
            style={{ minWidth: this.state.tetherContainerMinWidth }}
          >
            {dropdownContents}
          </div>
        </div>,
        this.tetherTarget
      )}
    </Popper>
  }
}

export default Dropdown
