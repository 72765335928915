const ErrorHelpers = {
  assert (condition: boolean, errorMessage: string, additionalData?) {
    // TODO: get rid of arguments by using rest operator
    // (current TS version (2.2) gives errors for calls like assert(condition, message, dataObject)
    const additionalDataProvided = arguments.length > 2
    if (!condition) {
      throw new Error(errorMessage + (additionalDataProvided ? ` Additional data: ${_stringifyData(additionalData)}` : ''))
    }
  },

  castToNotNullOrThrow<T>(value: T | null, errorMessage: string, additionalData?): T {
    ErrorHelpers.assert(value !== null, errorMessage, additionalData)
    return value as T
  }
}

export {ErrorHelpers}

function _stringifyData (data) {
  try {
    return JSON.stringify(data)
  }

  catch (error) {
    return `[could not stringify additional data - error occured while calling JSON.stringify: '${error.message}']`
  }
}
