type SortingFunction<Entity> = (entityA: Entity, entityB: Entity) => number
export const createFallbackSortingFunction = <Entity>(...fallbackSortingFunctions: SortingFunction<Entity>[]): SortingFunction<Entity> => {
  return (entityA: Entity, entityB: Entity) => {
    for(const sortingFunction of fallbackSortingFunctions) {
      const result = sortingFunction(entityA, entityB)
      if(result !== 0) return result
    }
    return 0
  }
}

export const createSortingFunctionFromPriorityFunction = <Entity>(priorityFn: (entity: Entity) => number, { reverse=false }={ reverse: false as boolean }): SortingFunction<Entity> => {
  return (entityA: Entity, entityB: Entity) => {
    const priorityA = priorityFn(entityA)
    const priorityB = priorityFn(entityB)
    return priorityB > priorityA
      ? reverse ? -1 : +1 
      : priorityB == priorityA
        ? 0
        : reverse ? +1 : -1
  }
}