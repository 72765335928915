import * as React from 'react'

import LayoutShell from '../layout/LayoutShell'

import UserGrid from 'components/common/UserGrid'
import { UserProfileActions } from 'domain/UserProfile/actions'
import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'

import styles from './usersList.styl'

const actionGroups = {
  UserProfileActions
}

class UsersList extends React.Component<BoundActionGroups<typeof actionGroups>> {
  componentWillMount() {
    this.props.UserProfileActions.refetchAll()
  }

  render() {
    return (
      <LayoutShell className={styles.usersList}>
        <div className={styles.usersListBody}>
          <UserGrid />
        </div>
      </LayoutShell>
    )
  }
}

export default ComponentHelpers.createConnectedComponent({
  actionGroups,
  mapStateToProps: () => ({}),
  component: UsersList
})
