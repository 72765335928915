import * as React from 'react'
import styles from './styles.styl'
import Icon from 'components/common/Icon'
import classnames from 'classnames'

interface IconMenuProps {
  items: {
    fontAwesomeIcon: string
    tooltip: string
    action?: () => void
    className?: string
  } []
}

class IconMenu extends React.Component<IconMenuProps> {
  render() {
    return <span>
      {
        this.props.items.map((item, index) => item && <Icon
          key={index}
          fontAwesomeIcon={item.fontAwesomeIcon}
          tooltip={item.tooltip}
          onClick={item.action}
          className={classnames(styles.item, item.className)}
        />)

      }
    </span>
  }
}

export default IconMenu
