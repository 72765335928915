import { UserProfileRecord } from 'shared/models/UserProfile'

import {ModuleStateHelpers} from 'helpers/ModuleState'

// workaround for attempts to access user profile before authorization
export const UNAUTHORIZED_USER_PROFILE = {
  userId: null,
  username: null,
  isSuperUser: false,
} as UserProfileRecord
// FIXME: HACK: TODO: Remove the UNAUTHORIZED_USER_PROFILE as a default value for the userProfile

const CurrentUserModuleState = ModuleStateHelpers.createModuleState({
  moduleName: 'CurrentUser',
  initialValue: {userProfile: UNAUTHORIZED_USER_PROFILE}
})

export {CurrentUserModuleState}
