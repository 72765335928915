import { BoardPermissionAction } from "shared/models/Permission/Board"
import { IdeaPermissionAction } from "shared/models/Permission/Idea"

export const SET_PERMISSIONS_ACTION = Symbol("set_permissions")

/**
 * Actor types defined by a type and identifier
 */
export enum ActorType {
  USER = "user",

  /**
   * Actor types that don't include identifier
   */
  PUBLIC_ACTOR = "public",
  SUPERUSER_ACTOR = "superuser_actor"
}

export const PUBLIC_ACTOR = { type: ActorType.PUBLIC_ACTOR as ActorType.PUBLIC_ACTOR }

export const SUPERUSER_ACTOR = { type: ActorType.SUPERUSER_ACTOR as ActorType.SUPERUSER_ACTOR }

// If we need more StandardActorTypes than ActorType.USER we can use the following code
// in order to distinguish a subset of ActorType that we want to consider Standard
// For now just use ActorType.USER
// export const StandardActorType = {
//   USER: ActorType.USER,
// }
// export type StandardActorType = typeof StandardActorType[keyof typeof StandardActorType]

/**
 * Well-defined (i.e. not public) privacy actor
 */
export type StandardPrivacyActor = {
  type: ActorType.USER,
  identifier: number
}

/**
 * Owners, matching how they are serialized over the network
 */
export interface OutboundOwnersPayload {
  clientId: string;
  owners: StandardPrivacyActor[];
}

export type OwnersLookupTable = {[clientId: string]: StandardPrivacyActor[]}

/**
 * Identifies an identity the user can assume to take an action
 */
export type PrivacyActor = StandardPrivacyActor | typeof PUBLIC_ACTOR

export type ActingActor = StandardPrivacyActor | typeof PUBLIC_ACTOR | typeof SUPERUSER_ACTOR

/**
 * Types of resources that can have a permission set on them
 */
export enum PermissionResourceType {
  boards = "boards",
  ideas = "ideas",
};

type PermissionActionByEntity<ResourceType extends PermissionResourceType> =
    ResourceType extends PermissionResourceType.boards
      ? BoardPermissionAction
  : ResourceType extends PermissionResourceType.ideas
      ? IdeaPermissionAction
  : never

type PermissionTableLiteralTypeByEntity = {
  "boards": "boardsPermissions",
  "ideas": "ideasPermissions",
}

type OwnerTableLiteralTypeByEntity = {
  "boards": "boardsOwners",
  "ideas": "ideasOwners",
}

export type PermissionAction<E extends PermissionResourceType> = PermissionActionByEntity<OneResourceType<E>>

// Very hacky way to avoid distributive conditional types
type DontDistribute<A> = { m: A }

// This is to force all parametrizations of the PermissionAction type to be exactly one member of the PermissionResourceType enum
type OneResourceTypeH<E extends PermissionResourceType, DelayNever = never> =
  DontDistribute<E | DelayNever> extends DontDistribute<never>
    ? never
  : DontDistribute<E | DelayNever> extends DontDistribute<PermissionResourceType.boards>
      ? PermissionResourceType.boards
  : DontDistribute<E | DelayNever> extends DontDistribute<PermissionResourceType.ideas>
      ? PermissionResourceType.ideas
  : never

export type OneResourceType<E extends PermissionResourceType, DelayNever = never> = E & OneResourceTypeH<E, DelayNever>

export type PermissionsLookupTable<ResourceType extends PermissionResourceType> =
  {[clientId: string]: Permission<ResourceType>[]}

export type BoolTable<Key extends string> = {
  [k in Key]: boolean
}

/**
 * Describes general form of a permission as used in the application
 */
export type Permission<ResourceType extends PermissionResourceType> = {
  actor: PrivacyActor,
  actions: BoolTable<PermissionAction<ResourceType>>,
}

/**
 * Permissions, matching how they are serialized over the network
 */
export interface OutboundPermissionsPayload<ResourceType extends PermissionResourceType> {
  clientId: string;
  permissions: Permission<ResourceType>[];
};

/**
 * Convert internal permissions mapping format to frontend permissions
 * format to be consumed by the user
 */
export function convertPermissionsMapToOutboundPayload<
  ResourceType extends PermissionResourceType
>(
  permissionsMap: PermissionsLookupTable<ResourceType>
): OutboundPermissionsPayload<ResourceType>[] {
  return Object.entries(permissionsMap)
    .map<OutboundPermissionsPayload<ResourceType>>(
      ([clientId, permissions]) => ({ clientId, permissions })
    )
}

/**
 * Convert internal owners mapping format to frontend owners
 * format to be consumed by the user
 */
export function convertOwnersMapToOutboundPayload(
  owners: OwnersLookupTable
): OutboundOwnersPayload[] {
  return Object.entries(owners).map(
    ([clientId, owners]) => ({ clientId, owners })
  );
}

export function resourceTableForEntity<ResourceType extends PermissionResourceType>(
  resource: OneResourceType<ResourceType>
): string {
  return `${resource}` as any
}

export function permissionsTableForEntity<ResourceType extends PermissionResourceType>(
  resource: OneResourceType<ResourceType>
): PermissionTableLiteralTypeByEntity[OneResourceType<ResourceType>] {
  return `${resource}Permissions` as any
}

export function ownersTableForEntity<ResourceType extends PermissionResourceType>(
  resource: OneResourceType<ResourceType>
): OwnerTableLiteralTypeByEntity[OneResourceType<ResourceType>] {
  return `${resource}Owners` as any
}

// TODO: Make sure the same permission logic is used here and in filterEntitiesByPermissions
// Achieve this by generalizing the permission applying algorithm
export function verifyHasPermission<ResourceType extends PermissionResourceType>(
  _resourceType: OneResourceType<ResourceType>,
  allPermissionsForAResource: Permission<ResourceType>[],
  allOwnersForAResource: StandardPrivacyActor[],

  // TODO: Replace this with a proper request that allows the verification of SET_PERMISSIONS_ACTION against owners
  actors: ActingActor[],
  operations: (PermissionAction<ResourceType> | typeof SET_PERMISSIONS_ACTION)[]
) {
  allPermissionsForAResource = allPermissionsForAResource || []
  allOwnersForAResource = allOwnersForAResource || []
  if (actors.find(actor => actor.type === ActorType.SUPERUSER_ACTOR)) {
    return true
  }

  const privacyActors = actors as PrivacyActor[]
  const privacyActorsByPriority = {
    "low": privacyActors.filter(privacyActor => privacyActor.type === ActorType.PUBLIC_ACTOR),
    "high": privacyActors.filter(privacyActor => privacyActor.type !== ActorType.PUBLIC_ACTOR),
  }

  const permissionsTableOps: PermissionAction<ResourceType>[] = operations.filter(
    (o): o is PermissionAction<ResourceType> => o !== SET_PERMISSIONS_ACTION
  )
  const shouldCheckOwners = permissionsTableOps.length !== operations.length
  const shouldCheckPermissions = permissionsTableOps.length > 0

  let isExplicitlyAllowedLow = false
  let isExplicitlyAllowedHigh = false
  let isExplicitlyRejectedLow = false
  let isExplicitlyRejectedHigh = false

  const isOwner = () => !!allOwnersForAResource.find(
    owner => !!privacyActors.find(
      privacyActor => areAuthorsEquivalent(owner, privacyActor)
    )
  )

  if (shouldCheckOwners) {
    return isOwner()
  }

  if (!shouldCheckPermissions) {
    return true
  }

  for (const actor of privacyActorsByPriority["low"]) {
    for (const permission of allPermissionsForAResource) {
      const isApplicable = areAuthorsEquivalent(permission.actor, actor)
      if (isApplicable) {
        for (const operation of permissionsTableOps) {
          if (permission.actions[operation] === true) {
            isExplicitlyAllowedLow = true
          } else {
            isExplicitlyRejectedLow = true
          }
        }
      }
    }
  }
  for (const actor of privacyActorsByPriority["high"]) {
    for (const permission of allPermissionsForAResource) {
      const isApplicable = areAuthorsEquivalent(permission.actor, actor)
      if (isApplicable) {
        for (const operation of permissionsTableOps) {
          if (permission.actions[operation] === true) {
            isExplicitlyAllowedHigh = true
          } else {
            isExplicitlyRejectedHigh = true
          }
        }
      }
    }
  }

  const isAllowed = (isExplicitlyAllowedLow && !isExplicitlyRejectedLow && !isExplicitlyRejectedHigh) ||
    (isExplicitlyAllowedHigh && !isExplicitlyRejectedHigh)
  if (!isAllowed) return isOwner()

  return true
}

function areAuthorsEquivalent(author1: ActingActor, author2: ActingActor): boolean {
  const sameType = author1.type === author2.type
  const sameIdentifierIfPresent = ("identifier" in author1) && ("identifier" in author2)
    ? author1.identifier === author2.identifier
    : ("identifier" in author1) === ("identifier" in author2)
  return sameIdentifierIfPresent && sameType
}