import { Set } from 'immutable'

import Point from 'shared/helpers/Point'
import Rectangle from 'shared/helpers/Rectangle'

import * as memoize from 'shared/helpers/memoize'

import * as IdeaSelectors from 'domain/Idea/selectors'
import * as ConnectionSelectors from 'domain/Connection/selectors'

import { IdeaInstanceSelectors } from 'domain/IdeaInstance/selectors'

import {GraphViewIdeaSelectionSelectors} from './ideaSelection/selectors'

// Lose the type - TypeScript doesn't like attaching methods onto a function
import GraphLayout, { GraphLayoutRecord } from 'shared/models/GraphLayout'

import { AppStateRecord } from 'appRoot/state'
import { DocumentRawType } from 'shared/models/Document'

import {getInGraphView} from './GraphViewState'
import { IdeaInstanceRecord } from 'shared/models/IdeaInstance';

export const getCursorAdjustment = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    return {
      canvasClientRect: getInGraphView(state, 'canvasClientRect'),
    }
  },
  calculateResult: ({ canvasClientRect }) => (position) => {
    const point = Point.fromXY(position)
    const offset = Point(canvasClientRect.left, canvasClientRect.top)
    return point.sub(offset)
  }
})

// TODO: replace everywhere with getActiveDocumentClientId that is memoized better (doesn't change on every active doc change like graph panning)
// (if the doc is needed by itself, it should be retrieved directly and its specific part should be memoizes
export const getActiveDocument = state => getInGraphView(state, 'activeDocument')

export function getActiveDocumentField<K extends keyof DocumentRawType>(
  state: AppStateRecord, fieldName: K
): DocumentRawType[K] {
    const activeDocument = getActiveDocument(state)
    return activeDocument ? activeDocument.get(fieldName) : null
  }

// TODO: remove use of this method as it is prone to using stale data
// - switch all layout-focused public actions to accept id instead of layout
export const getActiveGraphLayout = memoize.memoizeValueForRecentArguments(memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    activeGraphLayoutClientId: getActiveGraphLayoutClientId(state),
    graphLayouts: state.get('graphLayouts'),
  }),
  calculateResult: ({ activeGraphLayoutClientId, graphLayouts }): (GraphLayoutRecord | null) => {
    return activeGraphLayoutClientId && graphLayouts
      ? graphLayouts.get(activeGraphLayoutClientId, null)
      : null
  }
}))

export const getActiveGraphLayoutClientId = (state: AppStateRecord) => getActiveDocumentField(state, 'graphLayoutClientId')

export const getActiveGraphLayoutGraph = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    activeGraphLayout: getActiveGraphLayout(state),
  }),
  calculateResult: ({ activeGraphLayout }) => {
    return activeGraphLayout && GraphLayout.createImmutableGraph(activeGraphLayout)
  }
})

export const getReverseImageTransform = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    return {
      imageTransform: getCurrentGraphLayoutImageTransform(state)
    }
  },
  calculateResult: ({ imageTransform }) => {
    return (position) => imageTransform.Reverse().Transform(position)
  }
})

const emptyObject = {}

export const getVisibleIdeaInstanceIdByIdeaClientIds = memoize.memoizeReturnedObject(memoize.memoizeValueForRecentPreparedArguments({
  //TODO: tie it to instanceIds instead of the whole graph layout
  prepareArgument: (state: AppStateRecord) => ({
    activeGraphLayout: getActiveGraphLayout(state)
  }),
  calculateResult: ({ activeGraphLayout }): { [ideaClientId: string] : string } => {
    return activeGraphLayout
      ? GraphLayout.getIdeaInstanceIdByIdeaClientId(activeGraphLayout)
      : emptyObject
  }
}))

export const getAllIdeaClientIdsInActiveGraphLayout = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    const activeGraphLayoutClientId = getActiveGraphLayoutClientId(state)
    return {
      currentInstances: IdeaInstanceSelectors.getAllInGraphLayout(state, activeGraphLayoutClientId)
    }
  },
  calculateResult: ({currentInstances}) => {
    return Set(currentInstances.map(ideaInstance => ideaInstance.ideaClientId))
  }
})

type CountByIdeaClientId = {[ideaClientId: string]: number}

export const getAbsentRelatedIdeasCountForIdeasInGraphLayout = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => ({
    relatedIdeaClientIdsGetter: IdeaSelectors.getRelatedIdeaClientIdsGetter(state),
    allVisibleIdeaClientIds: getAllIdeaClientIdsInActiveGraphLayout(state)
  }),
  calculateResult: ({allVisibleIdeaClientIds, relatedIdeaClientIdsGetter}): CountByIdeaClientId => {
    const result: CountByIdeaClientId = {}

    allVisibleIdeaClientIds.forEach(ideaClientId => {
      const relatedIdeaClientIds = relatedIdeaClientIdsGetter(ideaClientId)

      let hiddenIdeasCount = 0
      relatedIdeaClientIds.forEach((relatedIdeaClientId) => {
        if(!allVisibleIdeaClientIds.has(relatedIdeaClientId)) {
          hiddenIdeasCount++
        }
      })

      result[ideaClientId] = hiddenIdeasCount
    })

    return result
  }
})

const emptySet = Set() as Set<any>
export const getHighlightState = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    const hoveredIdeaClientId = GraphViewIdeaSelectionSelectors.getHoveredIdeaClientId(state)

    const selectedIdeaClientIds = GraphViewIdeaSelectionSelectors.getSelectedIdeaClientIds(state)

    const hoveredConnectionClientId = getInGraphView(state, 'hoveredConnectionClientId');
    return {
      relatedIdeaClientIds: hoveredIdeaClientId
        ? IdeaSelectors.getRelatedIdeaClientIds(state, hoveredIdeaClientId)
        : emptySet,
      hoveredConnection: ConnectionSelectors.getByClientId(state, hoveredConnectionClientId),
      hoveredIdeaClientId,
      selectedIdeaClientIds,
      pathIdeasClientIds: getInGraphView(state, 'pathIdeasClientIds'),
      pathConnectionsClientIds: getInGraphView(state, 'pathConnectionsClientIds'),
      isDragging: getInGraphView(state, 'isDragging'),
      isConnecting: getInGraphView(state, 'isConnecting'),
      isMerging: getInGraphView(state, 'isMerging'),
      hoveredSidebarIdeaClientId: getInGraphView(state, 'hoveredSidebarIdeaClientId'),
    }
  },

  slottingFunction: (({ hoveredIdeaClientId, hoveredConnection}) => (
    (hoveredIdeaClientId || 'NO_IDEA_HOVERED') +
    (hoveredConnection ? hoveredConnection.clientId : 'NO_CONNECTION_HOVERED')
  )),

  calculateResult: ({
    relatedIdeaClientIds,
    hoveredConnection,
    hoveredIdeaClientId,
    selectedIdeaClientIds,
    pathIdeasClientIds,
    pathConnectionsClientIds,
    isDragging,
    isConnecting,
    isMerging,
    hoveredSidebarIdeaClientId,
  }) => {
    let highlightedIdeaClientIds: Set<string> = emptySet
    let highlightedConnectionClientIds: Set<string> = emptySet

    const shouldHoverBeHighlighted = hoveredIdeaClientId !== null
    const connectionIsHovered = !!hoveredConnection
    const shouldPathBeHighlighted = (!connectionIsHovered) && (!pathIdeasClientIds.isEmpty())
    const shouldSingleIdeaBeHighlighted = hoveredSidebarIdeaClientId !== null

    const canHighlightSpecificIdeasOnly = (!isDragging) && (!isConnecting) && (!isMerging)

    if (canHighlightSpecificIdeasOnly) {
      if (shouldHoverBeHighlighted) {
        highlightedIdeaClientIds = relatedIdeaClientIds.add(hoveredIdeaClientId)
      }
      else if (shouldPathBeHighlighted) {
        highlightedIdeaClientIds = pathIdeasClientIds
        highlightedConnectionClientIds = pathConnectionsClientIds
      }
      else if (shouldSingleIdeaBeHighlighted) {
        highlightedIdeaClientIds = Set([hoveredSidebarIdeaClientId!])
      }
      else if (connectionIsHovered) {
        highlightedIdeaClientIds = Set([hoveredConnection!.get('sourceIdeaClientId'), hoveredConnection!.get('targetIdeaClientId')])
      }
      else {
        highlightedIdeaClientIds = emptySet
      }

      if (!highlightedIdeaClientIds.isEmpty()) {
        highlightedIdeaClientIds = highlightedIdeaClientIds
          .concat(selectedIdeaClientIds)
      }
    }

    else {
      highlightedIdeaClientIds = emptySet
    }

    return { highlightedIdeaClientIds, highlightedConnectionClientIds }
  }
})

export const getColorRulesResults = (state: AppStateRecord) => getInGraphView(state, 'colorRulesResults')

export const colorRulesOverridesMustBeRecomupted = (state: AppStateRecord) => {
  const colorRulesResults = getColorRulesResults(state)

  const visibleIdeaInstanceByIdeaClientIds = getVisibleIdeaInstanceIdByIdeaClientIds(state)
  const activeDocument = getActiveDocument(state)

  return (visibleIdeaInstanceByIdeaClientIds !== colorRulesResults.visibleIdeaClientIds as any)
    || (activeDocument.colorRulesInOrder !== colorRulesResults.colorRulesInOrder)
}

type ColorOverrides = {[ideaClientId: string]: number};

export const getColorRulesColorOverrides = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    return {
      colorRulesResults: getColorRulesResults(state),
    }
  },
  calculateResult: ({ colorRulesResults }): ColorOverrides => {
    const { matchedIdeaClientIdsByColorRuleId, colorRulesInOrder, isPending } = colorRulesResults

    if(isPending) return {}

    const colorOverrides: ColorOverrides = {}
    for(const colorRule of colorRulesInOrder) {
      for(const ideaClientId of (matchedIdeaClientIdsByColorRuleId[colorRule.id] || [])) {
        colorOverrides[ideaClientId] = colorRule.colorId
      }
    }
    return colorOverrides
  }
})

export const getCentralCanvasPartRectangle = memoize.memoizeValueForRecentArguments(
  (state: AppStateRecord) => {
    const canvasClientRect = getInGraphView(state, 'canvasClientRect')
    const centralClientRect = getInGraphView(state, 'centralCanvasPartClientRectangle')

    if (!canvasClientRect || !centralClientRect) {
      return null
    }

    const dx = centralClientRect.left - canvasClientRect.left
    const dy = centralClientRect.top - canvasClientRect.top

    return Rectangle.rectangleFromPoints(
      Point(dx,dy),
      Point(centralClientRect.width + dx, centralClientRect.height + dy)
    )
  }
)

export const getSimulationCanvasRectangle = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord) => {
    return {
      canvasRectangle: getCentralCanvasPartRectangle(state),
      reverseImageTransform: getReverseImageTransform(state)
    }
  },
  calculateResult: ({ canvasRectangle, reverseImageTransform }) => {
    const topLeftSimulation = reverseImageTransform(canvasRectangle.topLeftCorner)
    const bottomRightSimulation = reverseImageTransform(canvasRectangle.bottomRightCorner)

    return Rectangle.rectangleFromPoints(
      topLeftSimulation,
      bottomRightSimulation
    )
  }
})

export const getCurrentGraphLayoutImageTransform = (state: AppStateRecord) => {
  return state.graphView.dynamicImageTransform || getActiveGraphLayout(state).imageTransform
}

export const getIdeaInstanceById = (state: AppStateRecord, ideaInstanceId: string | null | undefined) => {
  return ideaInstanceId
    ? IdeaInstanceSelectors.getInGraphLayoutById(state, getActiveGraphLayoutClientId(state), ideaInstanceId)
    : null
}

export const getIdeaInstanceByIdeaClientId = (
  state: AppStateRecord,
  ideaClientId: string
): IdeaInstanceRecord => {
  return IdeaInstanceSelectors.getInGraphLayoutByIdeaClientId(state, getActiveGraphLayoutClientId(state), ideaClientId)
}

export const getIdeaInstanceIdByIdeaClientId = (state: AppStateRecord, ideaClientId: string) => {
  return getIdeaInstanceByIdeaClientId(state, ideaClientId).id
}

export function getIdeaClientIdByIdeaInstanceId(state: AppStateRecord, ideaInstanceId: string | null | undefined) {
  const ideaInstance = getIdeaInstanceById(state, ideaInstanceId)
  return ideaInstance
    ? IdeaSelectors.actualizeIdeaClientId(state, ideaInstance.ideaClientId)
    : null
}

export function actualizeIdeaInstanceId(state: AppStateRecord, ideaInstanceId: string | null | undefined): string | null {
  // idea instance can be deleted alreaady by some previous action, even if its id is stored somewhere,
  // so in that case we should use `null` instead
  if (ideaInstanceId && getIdeaInstanceById(state, ideaInstanceId)) {
    return ideaInstanceId;
  } else {
    return null;
  }
}
