import * as React from 'react'
import classnames from 'classnames'

import Icon from 'components/common/Icon'
import CustomIcon from 'components/common/CustomIcon'

import { NavigationActions, getBoardUrl } from 'navigation/actions'
import { AuthActions } from 'auth/actions'

import * as BoardSelectors from 'domain/Board/selectors'
import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'

import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'
import { AppStateRecord } from 'appRoot/state'

import appConfig, { AllViewTypes } from 'config/appConfig'
import { UserProfileRecord } from 'shared/models/UserProfile'

import { ModalHelpers, ModalTypes, ModalSizes } from 'helpers/Modal'

import { tabsInOrder } from './ViewTypeTabs'

import styles from './MobileMenuModal.styl'

import { Link } from "react-router-dom"

const actionGroups = {
  //TODO: convert actions to `export {%ActionsGroup%}` to remove this wildcast
  NavigationActions,
  AuthActions
}

interface OuterProps {
  dismiss: () => void
}

interface StateProps extends OuterProps {
  canAdminUsers: boolean
  activeViewType: AllViewTypes
  currentBoardSlug: string
  currentUserProfile: UserProfileRecord | null
}

type Props = StateProps & BoundActionGroups<typeof actionGroups>

class _MobileMenuModal extends React.Component<Props> {
  render() {
    return (
      <div className={classnames(styles.container)}>
        <div className={classnames(styles.navigationMenu)}>
          {this.renderCloseButton()}
          {this.renderTabLinks()}
          {this.renderDropdownEquivalent()}
        </div>
      </div>
    )
  }

  private renderCloseButton() {
    return (
      <div
        className={classnames(styles.modalCloseButton)}
        onClick={this.props.dismiss}>
        <CustomIcon name='close-light' />
      </div>
    )
  }

  private renderTabLinks() {
    const currentTab = tabsInOrder.find(tab => (
      this.props.activeViewType === tab.viewType)
    )

    return (
      <div className={classnames(styles.tabLinks)}>
        {tabsInOrder.map(tab => this.renderTabLink(tab, currentTab === tab))}
      </div>
    )
  }

  private renderTabLink(tab, isCurrentTab: boolean) {
    return (
      <Link
        key={tab.label}
        className={classnames(styles.tabLink, isCurrentTab && styles.tabLinkCurrent) }
        onClick={this.props.dismiss}
        to={getBoardUrl(this.props.currentBoardSlug, tab.viewType)}
      >
        {tab.icon}
        <span>{tab.labelLong}</span>
      </Link>
    )
  }

  // TODO(felipap): rename asa purpose becomes clearer.
  private renderDropdownEquivalent() {
    let signInAction = null
    if (!this.props.currentUserProfile || this.props.currentUserProfile.isAnonymousUser()) {
      signInAction = (
        <a
          className={classnames(styles.navLink, styles.signInAction)}
          onClick={this.wrapDismissToCall(this.props.NavigationActions.navigateToLoginPage)}>
          Sign up
        </a>
      )
    }

    let logOutAction = null
    if (this.props.currentUserProfile && !this.props.currentUserProfile.isAnonymousUser()) {
      logOutAction = (
        <a
          className={classnames(styles.navLink, styles.logOutAction)}
          onClick={this.wrapDismissToCall(this.props.AuthActions.logout)}>
          Log out
        </a>
      )
    }

    let profileLink = null
    if (this.props.currentUserProfile && !this.props.currentUserProfile.isAnonymousUser()) {
      profileLink = (
        <a
          className={classnames(styles.navLink, styles.signInAction)}
          onClick={this.wrapDismissToCall(this.props.NavigationActions.navigateToCurrentUserProfile)}>
          Go To Profile
        </a>
      )
    }

    return (
      <div className={classnames(styles.dropdownEquivalent)}>
        {profileLink}
        {signInAction}
        {logOutAction}

        {appConfig.features.enableBoards &&
          <div
            className={classnames(styles.navLink)}
            onClick={this.wrapDismissToCall(this.props.NavigationActions.navigateToAllBoardsPage)}>
            All Boards
          </div>}
          <div
            className={classnames(styles.navLink)}
            onClick={this.wrapDismissToCall(this.props.NavigationActions.navigateToAllUsersPage)}>
            All Users
          </div>
        {this.props.canAdminUsers &&
          <div
            className={classnames(styles.navLink)}
            onClick={this.wrapDismissToCall(this.props.NavigationActions.navigateToUserAdminPage)}>
            Manage Users
          </div>}
        {appConfig.features.views.includes(AllViewTypes.CHANGELOG) && this.props.currentBoardSlug &&
          <Link
            className={classnames(styles.navLink)}
            onClick={this.props.dismiss}
            to={getBoardUrl(this.props.currentBoardSlug, AllViewTypes.CHANGELOG)}
          >
            History
          </Link>}
      </div>
    )
  }

  /**
   * Wrap a function so that calls to it also trigger this.props.dismiss().
   */
  private wrapDismissToCall = (fn: (...args: any[]) => any) => {
    return (...args) => {
      this.props.dismiss()
      return fn.apply(this, args)
    }
  }
}

const MobileMenuModal = ComponentHelpers.createConnectedComponent<OuterProps, StateProps, typeof actionGroups>({
  actionGroups,

  mapStateToProps: (state: AppStateRecord, outerProps: OuterProps): StateProps => {
    return {
      ...outerProps,
      activeViewType: state.get('viewType'), // TODO: Create a selector for this
      canAdminUsers: CurrentUserSelectors.canAdminUsers(state),
      currentBoardSlug: BoardSelectors.getCurrentBoardSlug(state),
      currentUserProfile: CurrentUserSelectors.getCurrentUserProfile(state),
    }
  },

  component: _MobileMenuModal,
})

export function showMobileMenuModal() {
  const safeDismiss = () => dismiss && dismiss()

  let dismiss = ModalHelpers.globalModals.showModal({
    type: ModalTypes.INFO,
    size: ModalSizes.FULLSCREEN,
    contents: <MobileMenuModal dismiss={safeDismiss} />,
    onModalDisplayed: null,
    contentClassName: styles.modalContent,
  })

  return dismiss
}

export default MobileMenuModal
