import React, { Component } from 'react'
import { getIcon } from 'helpers/icons'

interface IconProps {
  name: string
  fill?: string
  stroke?: string
  strokeWidth?: string
  style?: { [key: string]: any }
  onClick?: (event: React.MouseEvent<any>) => void
  className?: string
  title?: string
}

export default class CustomIcon extends Component<IconProps,{}> {
  private getIconStyle() {
    return {
      fill: this.props.fill,
      stroke: this.props.stroke,
      strokeWidth: this.props.strokeWidth,
      display: 'flex',
      alignItems: 'center' as any,
      ...this.props.style,
    }
  }

  render() {
    return (
      <div
        style={this.getIconStyle()}
        title={this.props.title}
        className={this.props.className}
        onClick={ this.props.onClick }
        dangerouslySetInnerHTML={{ __html: getIcon(this.props.name) }}
      />
    )
  }
}
