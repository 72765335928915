import {ModuleStateHelpers} from 'helpers/ModuleState'
import { ChangelogEntry } from 'shared/models/Changelog'

const _stateAccessors = ModuleStateHelpers.createModuleState({
  moduleName: 'ChangeLog',
  initialValue: {
    changeLogRecords: [] as ChangelogEntry[]
  }
})

const ChangeLogSelectors = {
  getAll: (state) => _stateAccessors.get(state).changeLogRecords
}

const ChangeLogActions = {
  reset: (changeLogData) => _stateAccessors.setPart('changeLogRecords', changeLogData)
}

//TODO: merge into Domain actions/selectors (needs unification first
export {ChangeLogSelectors, ChangeLogActions}
