import * as React from 'react'

import { BoardShapedObject } from './index'
import Board from './Board'

import styles from './Node.styl'

interface NodeProps {
  board: BoardShapedObject
  getBoardByClientId: (boardClientId: string) => BoardShapedObject
  expanded: boolean
}

interface NodeState {
  expanded: boolean
}

class Node extends React.Component<NodeProps, NodeState> {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  _toggleChildren = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  render() {
    if (!this.props.expanded || !this.props.board) return null
    return (
      <div>
        <Board board={this.props.board} toggleChildren={this._toggleChildren} isExpanded={this.state.expanded} />
        {this.props.board.childBoardClientIds &&
          <div className={styles.expandedNodes}>
            {this.props.board.childBoardClientIds.map(boardClietId =>
              <Node
                board={this.props.getBoardByClientId(boardClietId)}
                getBoardByClientId={this.props.getBoardByClientId}
                expanded={this.state.expanded}
                key={boardClietId}
              />
            )}
          </div>
        }
      </div>
    )
  }
}

export default Node
