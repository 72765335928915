// This module assumes requestIdleCallback is available
// The polyfill is included in the webpack.config.frontend.js

import { ErrorHelpers } from 'shared/helpers/Error'

export function createBatchOnIdle<T,M>(batchExecutor: (batchedElement: T[]) => M) {
  let batchElements: T[] = []
  let batchDispatch: null | ((action: M) => void) = null

  function flush() {
    const _batchDispatch = ErrorHelpers.castToNotNullOrThrow(
      batchDispatch,
      "You must call `.setDispatch` before enqueuing any elements"
    )
    const batchedElementsToFlush = batchElements
    batchElements = []
    _batchDispatch(
      batchExecutor(batchedElementsToFlush)
    )
  }

  function batchOnIdle(batchElement) {
    if (batchElements.length === 0) {
      // @ts-ignore
      requestIdleCallback(flush)
    }
    batchElements.push(batchElement)
  }

  function setDispatch(dispatch) {
    batchDispatch = dispatch
  }

  return { batchOnIdle, setDispatch }
}