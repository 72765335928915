import * as React from 'react'
import { ComponentHelpers } from 'helpers/Component'

import { AuthActions } from 'auth/actions'
import { NavigationActions } from 'navigation/actions'

import * as BoardSelectors from 'domain/Board/selectors'

import {CurrentUserSelectors} from 'domain/CurrentUser/selectors'

import Header, { HeaderDataProps } from './Header'

import { AppStateRecord } from 'appRoot/state'

interface HeaderOuterProps {
  search: React.ReactElement<any>,
  hideNavigation?: boolean,
  hideAuth?: boolean
}

const actionGroups = {
  AuthActions,
  NavigationActions,
}

const mapStateToProps = (state: AppStateRecord, outerProps: HeaderOuterProps): HeaderDataProps => {
  return {
    canAdminUsers: CurrentUserSelectors.canAdminUsers(state),
    activeViewType: state.get('viewType'), // TODO: Create a selector for this

    pathFromRootBoard: BoardSelectors.getPathFromRootBoardToCurrentBoard(state),
    currentBoardSlug: BoardSelectors.getCurrentBoardSlug(state),

    currentUserProfile: CurrentUserSelectors.getCurrentUserProfile(state),

    ...outerProps,
  }
}

export default ComponentHelpers.createConnectedComponent<
  HeaderOuterProps, HeaderDataProps, typeof actionGroups
>({
  actionGroups,
  mapStateToProps,
  component: Header
})