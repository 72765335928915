import appConfig, { AllViewTypes } from 'config/appConfig';
import { ListIcon, SettingsIcon, UsersRoundIcon } from "lucide-react";
import React from 'react';
import { GraphIcon } from './GraphIcon';

export const tabsInOrder = [{
  viewType: AllViewTypes.LIST,
  label: 'List',
  labelLong: 'List View',
  icon: <ListIcon width={16} height={16}/>,
}, {
  viewType: AllViewTypes.GRAPH,
  label: 'Graph',
  labelLong: 'Graph View',
  icon: <GraphIcon width={16} height={16}/>,
}, {
  viewType: AllViewTypes.CONTRIBUTORS,
  label: 'People',
  labelLong: 'People View',
  icon: <UsersRoundIcon width={16} height={16}/>,
}, {
  viewType: AllViewTypes.THOUGHSTREAM,
  label: 'Thoughtstream',
  labelLong: 'Thoughtstream',
  icon: <SettingsIcon width={16} height={16}/>,
}].filter(tab => appConfig.features.views.includes(tab.viewType))

