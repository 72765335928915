import * as React from 'react'

import AuthModal from 'components/Auth/Modal'

import { BoardSummary } from 'shared/models/BoardSummary'
import { UserProfileRecord } from 'shared/models/UserProfile'
import appConfig from 'config/appConfig'

import CustomIcon from 'components/common/CustomIcon'

import { AppStateRecord } from 'appRoot/state'
import { ComponentHelpers, BoundActionGroups } from 'helpers/Component'
import { AuthActions } from 'auth/actions'
import { NavigationActions } from 'navigation/actions'
import * as BoardSelectors from 'domain/Board/selectors'
import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'

import { showMobileMenuModal } from './MobileMenuModal'

import styles from './HeaderMobile.styl'

interface OuterProps {
  hideAuth?: boolean
}

export interface HeaderDataProps extends OuterProps {
  pathFromRootBoard: BoardSummary[]
  currentUserProfile: UserProfileRecord | null
}

const actionGroups = {
  AuthActions,
  NavigationActions,
}

type HeaderProps = HeaderDataProps & BoundActionGroups<typeof actionGroups>

interface State {
}

class HeaderMobile extends React.Component<HeaderProps, State> {
  render() {
    return (
      <div className={styles.header}>
        {this.renderRootLink()}
        {this.renderBoardPath()}
        {this.renderMenuButtons()}
      </div>
    )
  }

  private renderRootLink() {
    return (
      <div className={styles.rootLink}>
        <a onClick={this.props.NavigationActions.enterTheRootBoard}>
          Ideaflow
        </a>
      </div>
    )
  }

  private renderBoardPath() {
    if (!this.props.pathFromRootBoard || this.props.pathFromRootBoard.length === 0) {
      return null
    }

    let parentLink
    if (this.props.pathFromRootBoard.length > 1) {
      const parentSummary = this.props.pathFromRootBoard[this.props.pathFromRootBoard.length-2]
      parentLink = (
        <a
          className={styles.boardPathParentLink}
          onClick={() => this.props.NavigationActions.enterTheBoard(parentSummary.clientId)}>
          <CustomIcon name='level-up-light' />
        </a>
      )
    }

    const { title: boardName, clientId: boardId } =
      this.props.pathFromRootBoard[this.props.pathFromRootBoard.length-1]

    return (
      <div className={styles.boardPath}>
        {parentLink}
        <a className={styles.boardPathLink} onClick={() => this.props.NavigationActions.enterTheBoard(boardId)}>
          {boardName}
        </a>
      </div>
    )
  }

  private renderMenuButtons() {
    return (
      <div className={styles.menuButtons}>
        {
          (!this.props.currentUserProfile || this.props.currentUserProfile.isAnonymousUser())
          && this.renderSigninLink()
        }
        <CustomIcon name='menu-light' onClick={showMobileMenuModal} />
      </div>
    )
  }

  // TODO: Unify this between HeaderMobile and Header
  private renderSigninLink() {
    if (this.props.hideAuth) {
      return null
    }

    const text = appConfig.features.enableUserRegistration ? 'Sign Up / Login' : 'Login'
    return (
      <div className={styles.loginLink} onClick={AuthModal.openModal}>
        {text}
      </div>
    )
  }
}

const mapStateToProps = (state: AppStateRecord, outerProps: OuterProps): HeaderDataProps => {
  return {
    pathFromRootBoard: BoardSelectors.getPathFromRootBoardToCurrentBoard(state),
    currentUserProfile: CurrentUserSelectors.getCurrentUserProfile(state),
    ...outerProps,
  }
}

export default ComponentHelpers.createConnectedComponent<
  OuterProps, HeaderDataProps, typeof actionGroups
>({
  actionGroups,
  mapStateToProps,
  component: HeaderMobile
})
