import * as React from 'react'
import {Input} from 'reactstrap'
import {MouseEventHandlers, MouseHelpers} from 'helpers/mouse'

interface CheckboxProps {
  checked: boolean
  onChange?: (newValue: boolean) => void
  className?: string
}

class Checkbox extends React.Component<CheckboxProps> {
  _mouseEventHandlers: MouseEventHandlers
  constructor(props) {
    super(props)

    // we don't want to propagate any events further, so use isolated event handlers
    this._mouseEventHandlers = MouseHelpers.createIsolatedMouseEventHandlers({
      highLevelActions: {
        onClick: () => this.props.onChange && this.props.onChange(!this.props.checked),
      },
    })
  }

  render() {
    return <Input
      type="checkbox"
      checked={this.props.checked || false}
      className={this.props.className}
      {...this._mouseEventHandlers}
    />
  }
}

export default Checkbox
