// TODO: Remove this in favour of https://github.com/errendir/evolving-immutable

import * as Immutable from 'immutable'
import sortBy from 'lodash/sortBy'

import * as memoize from 'shared/helpers/memoize'

interface MemoizedArgumentBuilder<OriginalArguments, MemoizedArgument> {
  (params: OriginalArguments): MemoizedArgument
}

interface MemoizedResultCalculator<MemoizedArgument, Result> {
  (params: MemoizedArgument): Result
}

interface CreateMemoizedSelectorFactoryArgs<FactoryParams, ArgsFromFactory, SelectorParams, ArgsFromSelector, Result> {
  extractPartialArgsFromFactoryParams: MemoizedArgumentBuilder<FactoryParams, ArgsFromFactory>,
  extractPartialArgsFromSelectorParams: MemoizedArgumentBuilder<SelectorParams, ArgsFromSelector>,
  calculateValueFromCompleteArgs: MemoizedResultCalculator<ArgsFromFactory & ArgsFromSelector, Result>
}

function createMemoizedSelectorFactory<FactoryParams, ArgsFromFactory extends object, SelectorParams, ArgsFromSelector extends object, Result>({
  extractPartialArgsFromFactoryParams,
  extractPartialArgsFromSelectorParams,
  calculateValueFromCompleteArgs
}: CreateMemoizedSelectorFactoryArgs<FactoryParams, ArgsFromFactory, SelectorParams, ArgsFromSelector, Result>): ((factoryParams: FactoryParams) => (selectorParams: SelectorParams) => Result) {
  return memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (factoryParams: FactoryParams) => extractPartialArgsFromFactoryParams(factoryParams),
    calculateResult: (argsFromFactory: ArgsFromFactory) => memoize.memoizeValueForRecentPreparedArguments({
      prepareArgument: extractPartialArgsFromSelectorParams,
      calculateResult: (argsFromSelector: ArgsFromSelector) => calculateValueFromCompleteArgs({
        // FIXME: now TS fails when using spread without such explicit cast
        // remove cast after this fix gets adopted: https://github.com/Microsoft/TypeScript/pull/13288
        ...(argsFromFactory as object),
        ...(argsFromSelector as object)
      } as (ArgsFromFactory & ArgsFromSelector))
    })
  })
}

interface SortFieldExtractor<Item> {
  (item: Item): string
}

interface SortableCollection<Item>{
  sortBy: (fieldExtractor: SortFieldExtractor<Item>) => SortableCollection<Item>
}

function sortCollectionAlphabetically<Item>(
  collection: SortableCollection<Item> | Array<Item>,
  sortFieldExtractor: SortFieldExtractor<Item>
): typeof collection {
  const possibleSortable = collection as SortableCollection<Item>

  return (possibleSortable.sortBy)
    ? possibleSortable.sortBy(sortFieldExtractor)
    : sortBy(collection as Array<Item>, sortFieldExtractor)
}

const SelectorHelpers = {
  groupBy: (collection, attribute) => {
    return collection.reduce((map, record) =>
        map.update(
          record.get ? record.get(attribute) : record[attribute],
          Immutable.List(),
          list => list.push(record)
        ),
      Immutable.Map().asMutable()
    ).asImmutable()
  },

  createMemoizedSelectorFactory,

  sortCollectionAlphabetically
}

export {SelectorHelpers}
