import {
  permissionsTableForEntity, ownersTableForEntity,
  PermissionResourceType,
  PUBLIC_ACTOR, SUPERUSER_ACTOR, ActingActor, ActorType, StandardPrivacyActor
} from 'shared/models/Permission'
import { AppStateRecord } from 'appRoot/state';

import { UserProfileSelectors } from 'domain/UserProfile'
import { CurrentUserSelectors } from 'domain/CurrentUser/selectors'

const getPermissionsByIdeaClientId = (state: AppStateRecord) => state.get(permissionsTableForEntity(PermissionResourceType.ideas))
export const getPermissionsForIdea = (state: AppStateRecord, ideaClientId: string) => {
  const permissions = getPermissionsByIdeaClientId(state).get(ideaClientId)
  return permissions ? permissions.permissions : null
}
const getPermissionsByBoardClientId = (state: AppStateRecord) => state.get(permissionsTableForEntity(PermissionResourceType.boards))
export const getPermissionsForBoard = (state: AppStateRecord, boardClientId: string) => {
  const permissions = getPermissionsByBoardClientId(state).get(boardClientId)
  return permissions ? permissions.permissions : null
}

const getOwnersByIdeaClientId = (state: AppStateRecord) => state.get(ownersTableForEntity(PermissionResourceType.ideas))
export const getOwnersForIdea = (state: AppStateRecord, ideaClientId: string) => {
  const owners = getOwnersByIdeaClientId(state).get(ideaClientId)
  return owners ? owners.owners : null
}
export const getCurrentUserIsIdeaOwner = (state: AppStateRecord, ideaClientId: string) => {
  const owners = getOwnersForIdea(state, ideaClientId)
  const currentUserId = CurrentUserSelectors.getId(state)
  return owners &&
    !!owners.find(o => o.type === ActorType.USER && o.identifier === currentUserId)
}
const getOwnersByBoardClientId = (state: AppStateRecord) => state.get(ownersTableForEntity(PermissionResourceType.boards))
export const getOwnersForBoard = (state: AppStateRecord, boardClientId: string) => {
  const owners = getOwnersByBoardClientId(state).get(boardClientId)
  return owners ? owners.owners : null
}
export const getCurrentUserIsBoardOwner = (state: AppStateRecord, boardClientId: string) => {
  const owners = getOwnersForBoard(state, boardClientId)
  const currentUserId = CurrentUserSelectors.getId(state)
  return owners &&
    !!owners.find(o => o.type === ActorType.USER && o.identifier === currentUserId)
}

// Make sure this is equivalen to the backend/models/Permissions.ts#getActorsForUser
// Otherwise some UI may not correctly reflect what is possible and what is not
export const getActorsForUser = (state: AppStateRecord) => (userId: number | typeof PUBLIC_ACTOR): ActingActor[] => {
  if (!(typeof userId === "number")) {
    // all users, logged in or not, can act as public
    return [PUBLIC_ACTOR]
  }

  const actingActors: ActingActor[] = [
    PUBLIC_ACTOR,
    { type: ActorType.USER, identifier: userId } as StandardPrivacyActor
  ]

  const currentUserProfile = UserProfileSelectors.getById(state, userId)
  if (currentUserProfile && currentUserProfile.isSuperUser) {
    actingActors.push(SUPERUSER_ACTOR)
  }
  return actingActors
}