import { CreateRecordClass, NotNullOrUndefined } from './CreateRecord'
import { generateClientId } from 'shared/helpers/Id'

const CommentLikeDefaultValues = {
  clientId: NotNullOrUndefined,
  commentClientId: NotNullOrUndefined,
  userId: NotNullOrUndefined,
  isDeleted: false,
  createdAt: NotNullOrUndefined,
  updatedAt: NotNullOrUndefined,
  type: "commentLike" as "commentLike"
}

type CommentLikeRawType = {
  clientId: string
  commentClientId: string
  userId: number
  isDeleted: boolean
  createdAt: Date
  updatedAt: Date
  type: "commentLike"
}

type CommentLikeSourceObjectType = Partial<CommentLikeRawType> & {
  created_at?: string
  updated_at?: string
}

class CommentLikeRecord extends CreateRecordClass<CommentLikeRawType>(CommentLikeDefaultValues, "CommentLike") {}

type CommentLikeRecordT = CommentLikeRecord
export { CommentLikeRecordT as CommentLikeRecord }

const commentLikeAttributesOverwriteObject = { clientId: '', status: '', createdAt: new Date(), updatedAt: new Date() }
const createCommentLike = (stub: CommentLikeSourceObjectType) => {
  commentLikeAttributesOverwriteObject.clientId = stub.clientId || generateClientId()
  commentLikeAttributesOverwriteObject.createdAt = new Date(stub.createdAt || stub.created_at || Date.now() as any)
  commentLikeAttributesOverwriteObject.updatedAt = new Date(stub.updatedAt || stub.updated_at || Date.now() as any)
  return new CommentLikeRecord(Object.assign({}, stub, commentLikeAttributesOverwriteObject))
}

const CommentLike = Object.assign(
  (stub: CommentLikeSourceObjectType | CommentLikeRecord = {}): CommentLikeRecord => {
    if(!CommentLike.isCommentLike(stub)) {
      stub = stub as CommentLikeSourceObjectType
      return createCommentLike(stub)
    }
    return new CommentLikeRecord(stub)
  },
  {
    isCommentLike: (obj): obj is CommentLikeRecord => (obj instanceof CommentLikeRecord),
    createCommentLike,
  }
)

export default CommentLike
