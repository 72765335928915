import { GraphLayoutSelectors } from '../GraphLayout/selectors'
import * as memoize from 'shared/helpers/memoize'

const emptyObject = {}

import { AppStateRecord } from 'appRoot/state'
import { IdeaInstanceRecord } from 'shared/models/IdeaInstance'

const IdeaInstanceSelectors = {
  getInGraphLayoutById (state: AppStateRecord, graphLayoutClientId, ideaInstanceId) {
    return _getIdeaInstancesByIdsFromGraphLayout(state, graphLayoutClientId)[ideaInstanceId]
  },

  getInGraphLayoutByIdeaClientId (state: AppStateRecord, graphLayoutClientId, ideaClientId) {
    const foundInstances = _findIdeaInstancesInGraphLayout(
      state, graphLayoutClientId,
      (ideaInstance) => ideaInstance.ideaClientId === ideaClientId
    )

    return foundInstances[0]
  },

  getColorIdsFromIdeaInstancesFromGraphLayout: memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (state: AppStateRecord, graphLayoutClientId: string) => ({
      graphLayoutClientId,
      coloredIdeaInstance: _getColoredIdeaInstancesFromGraphLayout(state, graphLayoutClientId)
    }),
    slottingFunction: ({ graphLayoutClientId }) => graphLayoutClientId,
    calculateResult: ({ coloredIdeaInstance }): Set<number> => {
      const colorIds = new Set<number>()
      for (const ideaInstance of coloredIdeaInstance) {
        if (typeof ideaInstance.colorId === "number") {
          colorIds.add(ideaInstance.colorId)
        }
      }
      return colorIds
    }
  }),

  getAllInGraphLayout: memoize.memoizeValueForRecentPreparedArguments({
    prepareArgument: (state: AppStateRecord, graphLayoutClientId: string) => ({
      graphLayoutClientId,
      ideaInstancesByIds: _getIdeaInstancesByIdsFromGraphLayout(state, graphLayoutClientId)
    }),
    slottingFunction: ({ graphLayoutClientId }) => graphLayoutClientId,
    calculateResult: ({ ideaInstancesByIds }) =>
      Object.values(ideaInstancesByIds),
  }),
}

const _getColoredIdeaInstancesFromGraphLayout = memoize.memoizeValueForRecentPreparedArguments({
  prepareArgument: (state: AppStateRecord, graphLayoutClientId: string) => ({
    graphLayoutClientId,
    ideaInstancesByIds: _getIdeaInstancesByIdsFromGraphLayout(state, graphLayoutClientId)
  }),
  slottingFunction: ({ graphLayoutClientId }) => graphLayoutClientId,
  calculateResult: ({ ideaInstancesByIds }) => {
    return _findIdeaInstancesInIdeaInstancesByIds(
      ideaInstancesByIds,
      (ideaInstance: IdeaInstanceRecord) => ideaInstance.colorId !== null
    )
  }
})

function _getIdeaInstancesByIdsFromGraphLayout (state: AppStateRecord, graphLayoutClientId: string) {
  const graphLayout = GraphLayoutSelectors.getByClientId(state, graphLayoutClientId)
  return graphLayout ? graphLayout.get('ideaInstanceById') : emptyObject
}

function _findIdeaInstancesInIdeaInstancesByIds(
  ideaInstancesByIds: { [ideaInstanceId: string]: IdeaInstanceRecord },
  predicate: (ideaInstance: IdeaInstanceRecord) => boolean
) {
  const foundInstances: IdeaInstanceRecord[] = []

  for (const ideaInstanceId in ideaInstancesByIds) {
    const ideaInstance = ideaInstancesByIds[ideaInstanceId]
    if (predicate(ideaInstance)) {
      foundInstances.push(ideaInstance)
    }
  }

  return foundInstances
}

function _findIdeaInstancesInGraphLayout (state: AppStateRecord, graphLayoutClientId, predicate) {
  const ideaInstancesByIds = _getIdeaInstancesByIdsFromGraphLayout(state, graphLayoutClientId)
  return _findIdeaInstancesInIdeaInstancesByIds(ideaInstancesByIds, predicate)
}

export { IdeaInstanceSelectors }
